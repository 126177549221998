import React from "react";
import styles from "./styles.scss";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";
import Report from "./components/Report";

type Props = {
    setActiveReportTabId: (tab: string) => void;
};

const ReportsList: React.FC<Props> = ({ setActiveReportTabId }) => {
    const { archivedReports } = useSelector((state: appStateType) => state.conversionbalancer);

    return (
        <>
            <div className={styles.reportBox}>
                {archivedReports.map(
                    ({
                        _id,
                        createdAt,
                        createdBy,
                        funnelId,
                        funnelCopy,
                        fromDate,
                        toDate,
                        getFunnelError,
                        creatingGoogleAdsClientError,
                        googleAdsCampaigns,
                        pullingGoogleAdsCampaignsError,
                        bigQueryRows,
                        bigQueryError,
                        totalBigQueryRows,
                        totalBigQueryError,
                        googleConversions,
                        googleConversionsOriginalResponse,
                        googleConversionsError,
                        error,
                        conversionsDifference,
                        bqSessions,
                        bqSessionsError,
                        conversionsDraft,
                        conversionsDraftError
                    }) => (
                        <Report
                            key={_id}
                            id={_id}
                            createdAt={createdAt}
                            createdBy={createdBy?.login || ""}
                            funnel={funnelId}
                            funnelCopy={funnelCopy}
                            fromDate={fromDate}
                            toDate={toDate}
                            googleAdsCampaigns={googleAdsCampaigns}
                            getFunnelError={getFunnelError}
                            creatingGoogleAdsClientError={creatingGoogleAdsClientError}
                            pullingGoogleAdsCampaignsError={pullingGoogleAdsCampaignsError}
                            bigQueryError={bigQueryError}
                            bigQueryRows={bigQueryRows}
                            totalBigQueryRows={totalBigQueryRows}
                            totalBigQueryError={totalBigQueryError}
                            googleConversionsError={googleConversionsError}
                            googleConversions={googleConversions}
                            googleConversionsOriginalResponse={googleConversionsOriginalResponse}
                            otherError={error}
                            conversionsDifference={conversionsDifference}
                            bqSessions={bqSessions}
                            bqSessionsError={bqSessionsError}
                            conversionsDraft={conversionsDraft}
                            conversionsDraftError={conversionsDraftError}
                            setActiveReportTabId={setActiveReportTabId}
                        />
                    )
                )}
            </div>
        </>
    );
};

export default ReportsList;
