import styles from "./styles.scss";
import * as React from "react";
import { useState } from "react";
import { liveDeployment as LiveDeployment } from "reducers/deploymentReducer";
import { DiodeStatus, Diode } from "components/Diode";
import Modal from "components/Modal";

type Props = {
    liveDeployment: LiveDeployment;
    deployment: any;
};

export const TestsStatus: React.FC<Props> = ({ liveDeployment, deployment }) => {
    const deploymentInfo = liveDeployment || deployment;
    const [isModalOpened, toggleModal] = useState(false);
    const diodeStatus = getDiodeStatus(deploymentInfo);

    return (
        <>
            {diodeStatus && <Diode status={diodeStatus} onClick={() => toggleModal(true)} />}
            {isModalOpened && (
                <Modal onClose={() => toggleModal(false)} title="Test results">
                    <p className={styles.modalItem}>{diodeStatus}</p>
                    <p className={styles.modalItem}>{deploymentInfo?.testsResult?.buildLog}</p>
                </Modal>
            )}
        </>
    );
};

function getDiodeStatus(deploymentInfo: LiveDeployment): DiodeStatus | null {
    const isTestRunning = deploymentInfo?.testsStarted && !deploymentInfo?.testsFinished;
    const isTestFinished = deploymentInfo?.testsResult?.status === "done";
    const isTestFailed = deploymentInfo?.testsResult?.status === "failed";

    if (isTestRunning) {
        return "PENDING";
    } else if (isTestFinished) {
        return "SUCCESS";
    } else if (isTestFailed) {
        return "FAILURE";
    }

    return null;
}
