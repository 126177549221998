import * as React from "react";
const { useEffect } = React;
import _ from "lodash";
import styles from "./styles.scss";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";

const CRMS: React.FC = () => {
    const token = useSelector((state: appStateType) => state.auth.token).replace("Bearer ", "");

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "visible";
        };
    });

    return (
        <>
            {token && token != "" && (
                <iframe
                    className={styles.iframe}
                    src={`https://creative.ejam.com/?token=${token}`}
                ></iframe>
            )}
        </>
    );
};

export default CRMS;
