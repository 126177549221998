import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function assignId(request: any) {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.ASSIGN_ADACCOUNT_START });
        api()
            .put("fbspend/assign", request)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ASSIGN_ADACCOUNT,
                    payload: { account: res.data.account }
                });
            })
            .catch((err: AxiosError) => {
                const error = err.response ? err.response.data || "" : err.response;
                dispatch({ type: actionTypes.ASSIGN_ADACCOUNT_ERROR, payload: { error } });
            });
    };
}
