import * as React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import * as actions from "./actions";
import { set as setType } from "types/set";
import { language as languageType } from "types/language";
import { appStateType } from "reducers";
import _ from "lodash";

interface State {
    set: any;
    language: any;
    account: any;
}

class AdAccount extends React.PureComponent<any, State> {
    constructor(props: object) {
        super(props);

        this.state = {
            set: undefined,
            language: undefined,
            account: {}
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.account) {
            this.setState({ account: this.props.account });
            const { language = undefined, set = undefined } = this.props.account;
            if (language) {
                this.setState({ language });
            }
            if (set) {
                this.setState({ set });
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { sets } = this.props.sets;
        const { set } = this.state;
        if (set == null && sets) {
            this.setState({ set: sets[0]._id });
        }
    }

    handleClick(account_id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        let { set, language } = this.state;

        if (!language && set) {
            const { languagesMap } = this.props.languages;
            const languages = Array.from(languagesMap.values()) as languageType[];
            const possibleLanguages = languages.filter(
                (language: languageType) => language.set == set
            );
            language = possibleLanguages[0]._id;
        }
        if (!set || !language) return;
        this.props.assignId({ account_id, language, set });
    }

    handleSelectSet(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: set } = e.target as HTMLInputElement;

        this.setState({ set });
    }

    handleSelectLanguage(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: selectedLanguageId } = e.target as HTMLInputElement;
        this.setState({ language: selectedLanguageId });
    }

    render() {
        const { sets } = this.props.sets;
        const set = this.state.set;
        const { languagesMap } = this.props.languages;

        const languages = Array.from(languagesMap.values()) as languageType[];
        const possibleLanguages = languages.filter((language: languageType) => language.set == set);
        const { account } = this.state;
        return (
            <div className={`${styles.panel_wide} ${styles.form_wide}`}>
                Assign{" "}
                <span className={styles.id}>
                    <a
                        href={`https://business.facebook.com/adsmanager/manage/campaigns?act=${account.account_id}`}
                        target="_blank"
                    >
                        {account.account_id}
                    </a>
                </span>{" "}
                account:
                <div>
                    <button
                        onClick={this.handleClick.bind(this, account.account_id)}
                        className={styles.primary}
                        title="Assign"
                    >
                        <img src="/img/svg/save.svg" alt="Assign" />
                    </button>
                    <select onChange={this.handleSelectSet.bind(this)} value={this.state.set}>
                        <option disabled>Choose set</option>
                        {sets.map((set: setType, index: number) => (
                            <option key={index} value={set._id}>
                                {set.name}
                            </option>
                        ))}
                    </select>{" "}
                    <select
                        onChange={this.handleSelectLanguage.bind(this)}
                        value={this.state.language}
                    >
                        <option disabled>Choose language</option>
                        {possibleLanguages.map((language: languageType, index: number) => (
                            <option key={index} value={language._id}>
                                {language.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    sets: state.sets,
    languages: state.languages,
    analytics: state.analytics,
    accounts: state.analytics.adAccounts
});

export default connect(mapStateToProps, actions)(AdAccount);
