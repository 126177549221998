const parentContains = (parent: Node, el: Node | null): boolean => {
    let result = false;

    if (parent === el) {
        return true;
    }

    while (el && parent && parent != el) {
        if (parent.contains(el)) {
            result = true;
            break;
        } else {
            el = el.parentNode;
        }
    }

    return result;
};

export default parentContains;
