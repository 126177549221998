import * as React from "react";
import * as actions from "./actions";
import LoadingIndicator from "components/LoadingIndicator";
import _ from "lodash";
import ads from "./icons/ads.svg";
import getDollars from "services/getDollars";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { getTokens } from "../Tokens/actions";
import { set } from "types/set";
interface State {
    from: any;
    to: any;
    adAccount: string;
    selectedSet: set | null;
}

interface Props {
    costs: {
        isLoading: boolean;
        spend: any;
        error: string;
    };
    sets: any;
    tokens: any;
    getTokens: () => void;
    getSpend: (from: any, to: any, adAccount: string) => void;
    getProductCost: (from: any, to: any, setId: string) => void;
}
class Spend extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            from: this.getTodaysDate(),
            to: this.getTodaysDate(),
            adAccount: "",
            selectedSet: null
        };
    }
    componentDidMount() {
        this.props.getTokens();
    }

    getTodaysDate(now = new Date()) {
        const zeroFormat = (number: number) =>
            number < 10 ? "0" + number : number;

        var day = zeroFormat(now.getDate());
        var month = zeroFormat(now.getMonth() + 1);
        var year = now.getFullYear();

        return `${year}-${month}-${day}`;
    }

    handleFromDate(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: from } = e.target as HTMLInputElement;
        this.setState({ from });
    }

    handleToDate(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: to } = e.target as HTMLInputElement;
        this.setState({ to });
    }

    handleAdAccount(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: adAccount } = e.target as HTMLInputElement;
        this.setState({ adAccount });
    }

    handleReportClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const { selectedSet, from, to: toInput, adAccount: ad } = this.state;
        const toDate = new Date(toInput);
        toDate.setDate(toDate.getDate() + 1);
        const to = this.getTodaysDate(toDate);

        const adAccount = ad || this.props.tokens.accounts[0];

        if (adAccount === undefined) {
            alert("Add a new account in tokens panel for use this function.");
            return;
        }

        this.props.getSpend(from, to, adAccount);
    }

    render() {
        const { from, to } = this.state;
        const { accounts } = this.props.tokens;
        const { isLoading = false, spend = {}, error = "" } =
            this.props.costs || {};
        const { sets } = this.props.sets;
        const facebookCost = Number(_.get(spend, "spend", 0));

        return (
            <>
                <header>
                    <h1>Spend</h1>
                </header>
                <main>
                    <div className={`${styles.panel_wide} ${styles.form_wide}`}>
                        <div className={styles.input_group}>
                            <label>Set:</label>
                            <select onChange={this.handleAdAccount.bind(this)}>
                                {sets.map((set: set, index: number) => (
                                    <option key={index} value={set.name}>
                                        {set.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.input_group}>
                            <label>From:</label>
                            <input
                                type="date"
                                value={from}
                                onChange={this.handleFromDate.bind(this)}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>To:</label>
                            <input
                                type="date"
                                value={to}
                                onChange={this.handleToDate.bind(this)}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>Ad Account:</label>
                            <select onChange={this.handleAdAccount.bind(this)}>
                                {accounts.map(
                                    (account: string, index: number) => (
                                        <option key={index} value={account}>
                                            {account}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        {isLoading ? (
                            <div className={styles.input_group}>
                                <LoadingIndicator className={styles.loader} />
                            </div>
                        ) : (
                            <div className={styles.input_group}>
                                <button
                                    className={styles.primary}
                                    onClick={this.handleReportClick.bind(this)}
                                >
                                    Report
                                </button>
                            </div>
                        )}
                    </div>
                    {error ? (
                        <div className={styles.panel_wide}>
                            <p className={styles.error}>Error: {error}</p>
                        </div>
                    ) : null}
                    {!error && spend.hasOwnProperty("spend") ? (
                        <div className={styles.panel_wide}>
                            <div className={styles.spend_part}>
                                <img src={ads} />
                                <p>Ads cost: {getDollars(facebookCost)}</p>
                            </div>
                        </div>
                    ) : null}
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    tokens: state.tokens,
    costs: state.spend,
    sets: state.sets
});

export default connect(
    mapStateToProps,
    { ...actions, getTokens }
)(Spend);
