import * as React from "react";
import AnalyticsBar from "scenes/Dashboard/SchemaCMSEditor/components/AnalyticsBar";
import DataPiece from "scenes/Dashboard/SchemaCMSEditor/components/DataPiece";
import SpaceBetween from "scenes/Dashboard/SchemaCMSEditor/components/SpaceBetween";
import SwitchButton from "components/SwitchButton";
import getDollars from "services/getDollars";
import styles from "./styles.scss";
import { DynamicActiveModel } from "types/pricing";

interface Props extends DynamicActiveModel {}

const DynamicActiveModelBox: React.FC<Props> = ({ title }) => {
    const preformanceDataPieceProps = {
        fontSize: 16,
        primaryColor: "green",
        reverse: true
    };

    return (
        <div className={styles.modelBox}>
            <header className={styles.header}>
                <SpaceBetween>
                    <span className={styles.headline}>{title}</span>
                    <SwitchButton isActive={false} />
                </SpaceBetween>
            </header>
            <AnalyticsBar className={styles.analyticsBar}>
                <DataPiece label="Running" value="0d" reverse />
                <DataPiece label="Last Update" value="5m" reverse />
            </AnalyticsBar>
            <section className={styles.chartSection}>
                <div className={styles.chartWrapper}>
                    <h4 className={styles.chartTitle}>
                        Posterior Distribution
                    </h4>
                </div>
                <div className={styles.performance}>
                    <div className={styles.data}>
                        <DataPiece
                            label="Views"
                            value="100"
                            {...preformanceDataPieceProps}
                        />
                        <DataPiece
                            label="Conversions"
                            value="75"
                            {...preformanceDataPieceProps}
                        />
                        <DataPiece
                            label="GP"
                            value={getDollars(5373.73)}
                            {...preformanceDataPieceProps}
                        />
                    </div>
                    <hr />
                    <div className={styles.data}>
                        <DataPiece
                            label="GP/View"
                            value={getDollars(14.08)}
                            {...preformanceDataPieceProps}
                        />
                        <DataPiece
                            label="GP/Order"
                            value={getDollars(78.19)}
                            {...preformanceDataPieceProps}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DynamicActiveModelBox;
