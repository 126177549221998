import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { brand } from "types/brand";

export function getBrands() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BRANDS_START
        });

        api()
            .get("brand")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_BRANDS,
                    payload: { brands: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_BRANDS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export const addBrand = (brandData: brand) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_BRAND_START
        });

        api()
            .post("brand", brandData)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_BRAND,
                    payload: { brand: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_BRAND_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export function removeBrand(brandId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_BRAND_START,
            payload: {
                id: brandId
            }
        });
        api()
            .delete("brand", { data: { brandId } })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REMOVE_BRAND,
                    payload: { brand: res.data.brand }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REMOVE_BRAND_ERROR,
                    payload: { error: err.response, id: brandId }
                });
            });
    };
}
