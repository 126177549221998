import * as React from "react";
import Button from "components/Button";
import DynamicActiveModelBox from "../../components/DynamicActiveModelBox";
import ModelBox from "../../components/ModelBox";
import SpaceBetween from "scenes/Dashboard/SchemaCMSEditor/components/SpaceBetween";
import styles from "./styles.scss";
import { DynamicActiveModel, PricingModel } from "types/pricing";

type Props = {};

const DynamicActivePricing: React.FC<Props> = () => {
    return (
        <section className={styles.modelList}>
            <header className={styles.header}>
                <SpaceBetween>
                    <h3 className={styles.headline}>Pricing Models</h3>
                    <div>
                        <Button design="button" background="pinkGradient" flat>
                            Dynamic Active Pricing
                        </Button>
                        <Button
                            background="purple"
                            color="white"
                            design="button"
                            disabled
                            flat
                            marginLeft
                        >
                            Add New Model
                        </Button>
                    </div>
                </SpaceBetween>
            </header>
            <main className={styles.dynamicPricingList}>
                <header className={styles.header}>
                    <h3 className={styles.headline}>Dynamic Active Pricing</h3>
                </header>
                <div className={styles.list}>
                    {[
                        { title: "DAM 1", _id: "1" },
                        { title: "DAM 2", _id: "2" }
                    ].map((model: DynamicActiveModel) => (
                        <DynamicActiveModelBox {...model} key={model._id} />
                    ))}
                </div>
            </main>
        </section>
    );
};

export default DynamicActivePricing;
