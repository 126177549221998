import * as actionTypes from "actionTypes";
import { Subscription } from "types/Subscription";

export type subscriptionsStateType = {
    subscriptions: Subscription[];
    isPullingSubscriptions: boolean;
    isSavingSubscription: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: subscriptionsStateType = {
    subscriptions: [],
    isPullingSubscriptions: false,
    isSavingSubscription: false
};

export const subscriptionsStorage: { [key: string]: boolean } = {};

export default (
    state: subscriptionsStateType = initialState,
    action: IAction
): subscriptionsStateType => {
    switch (action.type) {
        case actionTypes.GET_SUBSCRIPTIONS_START:
            return {
                ...state,
                isPullingSubscriptions: true
            };
        case actionTypes.GET_SUBSCRIPTIONS:
            return {
                ...state,
                isPullingSubscriptions: false,
                subscriptions: action.payload.subscriptions
            };
        case actionTypes.GET_SUBSCRIPTIONS_ERROR:
            return {
                ...state,
                isPullingSubscriptions: false
            };
        case actionTypes.ADD_SUBSCRIPTION_START:
            return {
                ...state,
                isSavingSubscription: true
            };
        case actionTypes.ADD_SUBSCRIPTION:
            return {
                ...state,
                isSavingSubscription: false,
                subscriptions: [...state.subscriptions, action.payload.subscription]
            };
        case actionTypes.ADD_SUBSCRIPTION_ERROR:
            return {
                ...state,
                isSavingSubscription: false
            };
        default:
            return state;
    }
};
