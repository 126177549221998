import { merge, throttle } from "lodash";
import { appStateType, reducers, storageState } from "reducers";
import { applyMiddleware, createStore, compose, Action } from "redux";
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from "redux-thunk";
// import logger from "redux-logger";
import { getStorageState, loadState, saveState } from "services/localStorage";
import { SIGN_OUT_USER } from "actionTypes";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";

export type AppActions = {
    type: string;
    payload?: any;
};

export type IThunkDispatch = ThunkDispatch<appStateType, unknown, AppActions>;
// export type AppState = ReturnType<typeof reducers>;

const loadedState = loadState() || {};
const initialState = createStore<appStateType, AppActions, unknown, unknown>(reducers).getState();
const preloadedState: appStateType = merge({}, initialState, loadedState);

let middlewares = [thunk as ThunkMiddleware<appStateType, AppActions, unknown>];

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
        _wq: any;
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore<appStateType, AppActions, unknown, unknown>(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware<IThunkDispatch, appStateType>(...middlewares))
);

if (preloadedState.auth.token) {
    let tokenData = jwtDecode(preloadedState.auth.token);
    if (tokenData.exp < Date.now() / 1000) {
        window.localStorage.clear();
        store.dispatch({
            type: SIGN_OUT_USER
        });
    }
}

store.subscribe(
    throttle(() => {
        const state = store.getState();
        const processedState = getStorageState(state as appStateType, storageState);
        saveState(processedState);
    }, 500)
);

window["store"] = store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<T = void> = ThunkAction<T, appStateType, unknown, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
