import { MenuOptionType } from "types/MenuOptionType";
import rocket from "assets/icons/menu/rocket.svg";
import pen from "assets/icons/menu/pen.svg";
import cloud from "assets/icons/menu/cloud.svg";
import crms2 from "assets/icons/menu/crms2.svg";
import hacker from "assets/icons/menu/hacker.svg";
import pie from "assets/icons/menu/pie.svg";
import like from "assets/icons/menu/like.svg";
import drip from "assets/icons/menu/drip.svg";
import balance from "assets/icons/menu/balance.svg";
import user from "assets/icons/menu/user.svg";
import sdk from "assets/icons/menu/sdk.svg";
import cart from "assets/icons/menu/cart.svg";
import soldout from "assets/icons/menu/soldout.svg";

const menu: MenuOptionType[] = [
    {
        to: "/dashboard/deployment",
        label: "CPDS",
        icon: rocket,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER", "ED_CMS_EDITOR"]
    },
    {
        to: "/dashboard/cms",
        label: "CMS",
        icon: pen,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER", "ED_CMS_EDITOR"]
    },
    {
        to: "/dashboard/cdncloud",
        label: "Cloud CDN",
        icon: cloud,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER", "ED_CMS_EDITOR"]
    },
    {
        to: "/dashboard/crms",
        label: "CASE",
        icon: crms2,
        allowedRoles: [
            "CRMS_ADMIN",
            "CRMS_VIDEO_CREATOR",
            "CRMS_IMAGE_CREATOR",
            "CRMS_COPYWRITER",
            "CRMS_UX_DESIGNER",
            "CRMS_MEDIA_BUYER",
            "CRMS_MANAGER",
            "CRMS_ANALYST",
            "CRMS_GOOGLE_VIDEO_CONTENT_CREATOR",
            "CRMS_GOOGLE_IMAGE_CONTENT_CREATOR",
            "CRMS_GOOGLE_COPYWRITER",
            "CRMS_GOOGLE_VIDEO_ADMIN",
            "CRMS_GOOGLE_IMAGE_ADMIN",
            "CRMS_GOOGLE_COPY_ADMIN",
            "CRMS_GOOGLE_ANALYST",
            "CRMS_GOOGLE_MANAGER"
        ]
    },
    {
        to: "/dashboard/hackertools",
        label: "Hacker Tools",
        icon: hacker,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER", "ED_CMS_EDITOR"]
    },
    {
        to: "/dashboard/analytics",
        exact: false,
        label: "Analytics",
        icon: pie,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER", "ED_CMS_EDITOR"]
    },
    {
        to: "/dashboard/brands",
        label: "Brands",
        icon: like,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER"]
    },
    {
        to: "/dashboard/drip",
        label: "CC Drip",
        icon: drip,
        allowedRoles: ["ED_ADMIN"]
    },
    {
        to: "/dashboard/conversionsbalancer",
        label: "Conversions Balancer",
        icon: balance,
        allowedRoles: ["ED_ADMIN", "ED_GOOGLE_MEDIA_BUYER"]
    },
    {
        to: "/dashboard/users",
        label: "Users",
        icon: user,
        allowedRoles: ["ED_SUPER_ADMIN", "ED_DEVELOPER"]
    },
    {
        to: "/dashboard/sdk",
        label: "SDK",
        icon: sdk,
        allowedRoles: ["ED_SUPER_ADMIN", "SDK", "SDK_ADMIN", "SDK_EDITOR"]
    },
    {
        to: "/dashboard/soldoutproducts",
        label: "Sold Out Products",
        icon: soldout,
        allowedRoles: ["ED_SUPER_ADMIN", "ED_DEVELOPER"]
    },
    {
        to: "/dashboard/subscriptions",
        label: "Subscriptions",
        icon: drip,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER", "ED_CMS_EDITOR"]
    },
    {
        to: "/dashboard/orders",
        label: "Ebanx Orders",
        icon: cart,
        allowedRoles: ["ED_ADMIN", "ED_DEVELOPER"],
        menu: [
            {
                to: "/dashboard/orders/pending",
                label: "Pending",
                exact: true
            },
            {
                to: "/dashboard/orders/complete",
                label: "Complete",
                exact: true
            },
            {
                to: "/dashboard/orders/abandoned",
                label: "Abandoned",
                exact: true
            }
        ]
    }
];

export default menu;
