import _ from "lodash";

export const getLiveDeployment = (liveDeployments: [] = [], pipelineResponse: any = {}) => {
    return liveDeployments.find(
        (item: { buildNumber: string }) =>
            pipelineResponse &&
            "build_number" in pipelineResponse &&
            item.buildNumber == pipelineResponse.build_number
    );
};
