import * as React from "react";
import styles from "./styles.scss";

const getPercentageValue = (value: any) =>
    !isNaN(value) ? `${value}%` : value;

interface Props {
    progress?: number;
    ref?: React.RefObject<HTMLDivElement>;
}

const Stripe = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const progress = getPercentageValue(props.progress);

    return (
        <div className={styles.stripe} style={{ width: progress }} ref={ref}>
            <div className={styles.animation} />
        </div>
    );
});

Stripe.defaultProps = {
    progress: 0
};

export default Stripe;
