import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import { appStateType } from "reducers";
import { groupBy } from "lodash";
import { upsellsFlow } from "types/upsellsFlow";
import { odooProduct } from "types/odooProduct";

export function getUpsellsFlow(setId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_UPSELLS_FLOW_START
        });

        api()
            .get(`upsell/flow?setId=${setId}`)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_UPSELLS_FLOW,
                    payload: { upsellsFlow: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_UPSELLS_FLOW_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export const addUpsellsFlow = ({
    languageId,
    setId,
    title,
    _id,
    order
}: {
    languageId: string;
    setId: string;
    title?: string;
    _id?: string;
    order: number;
}) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_UPSELLS_FLOW_START,
            payload: {
                id: _id
            }
        });

        api()
            .post("upsell/flow", {
                languageId,
                setId,
                title,
                _id,
                order
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_UPSELLS_FLOW,
                    payload: { upsellsFlow: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_UPSELLS_FLOW_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export function updateUpsellsFlow(setId: string, disabled: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_UPSELLS_FLOW_START
        });

        api()
            .put("upsell/flow", { setId, disabled })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.UPDATE_UPSELLS_FLOW,
                    payload: { setId, disabled }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPDATE_UPSELLS_FLOW_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function duplicateUpsellsFlow(setId: string, flowId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.COPY_UPSELL_FLOW_START
        });

        api()
            .put("upsell/flow/duplicate", { setId, flowId })
            .then((res: AxiosResponse) => {
                const { upsellFLows, upsellContainers } = res.data;

                dispatch({
                    type: actionTypes.COPY_UPSELL_FLOW,
                    payload: { upsellFLows, upsellContainers }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.COPY_UPSELL_FLOW_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function archieveUpsellsFlow(flowId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ARCHIEVE_UPSELL_FLOW_START
        });

        api()
            .delete(`upsell/flow?flowId=${flowId}`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ARCHIEVE_UPSELL_FLOW,
                    payload: { flowId }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ARCHIEVE_UPSELL_FLOW_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function reorderUpsellsFlow(
    sourceIndex: number,
    destinationIndex: number,
    flowId: string,
    flows: upsellsFlow[]
) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REORDER_UPSELL_FLOW_START
        });

        const flow = flows.find(el => el._id === flowId);
        if (!flow) {
            return;
        }

        let newFlows = [...flows];
        newFlows.splice(sourceIndex, 1);
        newFlows.splice(destinationIndex, 0, flow);
        const newFlowsOrder = newFlows.map((el, index) => ({ _id: el._id, order: index }));

        dispatch({
            type: actionTypes.TEMP_REORDER_UPSELL_FLOW,
            payload: { flows: newFlowsOrder }
        });

        api()
            .put("upsell/flow/reorder", { flows: newFlowsOrder })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REORDER_UPSELL_FLOW,
                    payload: { flows: res.data.flows }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REORDER_UPSELL_FLOW_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function getUpsellContainer(setId: string, languageId: string, crm: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_UPSELL_CONTAINER_START
        });

        return api()
            .get(`upsell/container?setId=${setId}`)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_UPSELL_CONTAINER,
                    payload: { upsellContainer: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                })
            )
            .finally(() => {
                dispatch<any>(getUpsellReport({ setId, languageId, crm, from: null, to: null }));
            });
    };
}

export function getUpsellReport({
    setId,
    languageId,
    crm,
    from: analyticsRangeFrom,
    to: analyticsRangeTo
}: {
    setId: string;
    languageId: string;
    crm: string;
    from?: null | Date;
    to?: null | Date;
}) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.GET_UPSELL_REPORT_START
        });

        api()
            .post("report/upsellsflow", {
                setId,
                languageId,
                analyticsRangeFrom: analyticsRangeFrom ? analyticsRangeFrom.toISOString() : "",
                analyticsRangeTo: analyticsRangeTo ? analyticsRangeTo.toISOString() : ""
            })
            .then((res: AxiosResponse) => {
                const upsellReport = res.data.upsells;

                dispatch({
                    type: actionTypes.GET_UPSELL_REPORT,
                    payload: {
                        report: res.data
                    }
                });
                const intervalId = setInterval(() => {
                    const {
                        elements: { elementsTree },
                        products: {
                            konnektiveOfferProducts,
                            odooProducts,
                            odooProductsDev,
                            odooProductsProd
                        }
                    } = getState();

                    let isCMSProductsLoaded = false;

                    switch (crm) {
                        case "ODOO":
                            isCMSProductsLoaded = !!odooProducts.length;
                            break;
                        case "ODOO_PROD":
                            isCMSProductsLoaded = !!odooProductsProd.length;
                            break;
                        case "ODOO_DEV":
                            isCMSProductsLoaded = !!odooProductsDev.length;
                            break;
                        case "KONNEKTIVE":
                        default:
                            isCMSProductsLoaded = !!konnektiveOfferProducts.length;
                            break;
                    }

                    let isElementsLoaded = elementsTree && elementsTree.newUpsells;

                    if (upsellReport.length) {
                        isElementsLoaded = isElementsLoaded && elementsTree.newUpsells.length;
                    }

                    if (isCMSProductsLoaded && isElementsLoaded) {
                        clearInterval(intervalId);
                        dispatch<any>(createUpsellReports(upsellReport, crm));
                    }
                }, 100);
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_UPSELL_REPORT_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export const createUpsellReports = (upsellReport: any[], crm: string) => {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const defaultUpsellReportValues = {
            upsellViews: 0,
            upsellSales: 0,
            grossProfit: 0,
            revenue: 0,
            cog: 0,
            cos: 0
        };

        const {
            products: { konnektiveUpsellProducts, odooProducts, odooProductsProd, odooProductsDev },
            elements: { elementsTree },
            upsell: { upsellContainer }
        } = getState();

        if (!upsellReport || !upsellReport.length) {
            upsellContainer.forEach(el => {
                el.variants.forEach(el2 => {
                    dispatch({
                        type: actionTypes.ADD_UPSELL_REPORT,
                        payload: {
                            elementId: el2.elementId,
                            report: defaultUpsellReportValues
                        }
                    });
                });
            });
            return;
        }

        const isOdooCrm = crm !== "KONNEKTIVE";

        const groupedReport = groupBy(upsellReport, "_id");

        Object.entries(groupedReport).forEach(([elementId, reports]: [string, any[]]) => {
            const upsell = elementsTree?.newUpsells?.find((el: any) => el._elementId === elementId);

            let upsellPrice = Number(upsell?.upsellPrice?.variants?.[0]?.value || 0);
            let productId = Number(upsell?.product?.variants?.[0]?.value);

            let cog: number, cos: number;

            if (isOdooCrm) {
                let finalOdooProducts: odooProduct[] = [];

                switch (crm) {
                    case "ODOO":
                        finalOdooProducts = odooProducts;
                        break;
                    case "ODOO_DEV":
                        finalOdooProducts = odooProductsDev;
                        break;
                    case "ODOO_PROD":
                    default:
                        finalOdooProducts = odooProductsProd;
                        break;
                }

                const rightProduct = finalOdooProducts.find(p => p.sid === productId);

                if (rightProduct) {
                    cog = rightProduct.cog;
                    cos = rightProduct.cos;
                }

                if (upsellPrice === 0 && rightProduct) {
                    upsellPrice = Number(rightProduct.price);
                }
            } else {
                const rightProduct = konnektiveUpsellProducts.find(
                    p => Number(p.productId) === productId
                );

                if (rightProduct) {
                    cog = Number(rightProduct.productCost);
                    cos = Number(rightProduct.shippingCost);

                    if (upsellPrice === 0) {
                        upsellPrice = Number(rightProduct.cycle1_price);
                    }
                }
            }

            const reducedReport = reports.reduce(
                (acc, current) => {
                    const { upsellViews, upsellSales } = current;
                    let newCog = upsellSales * cog;
                    let newCos = upsellSales * cos;

                    const revenue = upsellPrice * upsellSales;

                    return {
                        upsellViews: acc.upsellViews + upsellViews,
                        upsellSales: acc.upsellSales + upsellSales,
                        revenue: acc.revenue + revenue,
                        grossProfit: acc.grossProfit + revenue - newCog - newCos,
                        cog: acc.cog + newCog,
                        cos: acc.cos + newCos
                    };
                },
                { ...defaultUpsellReportValues }
            );

            dispatch({
                type: actionTypes.ADD_UPSELL_REPORT,
                payload: {
                    elementId,
                    report: reducedReport
                }
            });
        });
    };
};
