import * as React from "react";
import * as actions from "./actions";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { set } from "types/set";
import SwitchButton from "components/SwitchButton";

class SchemaCMS extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
        this.handleAddClick = this.handleAddClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleAddClick(e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.addSet();
    }

    handleSaveClick(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.updateName(id);
    }

    handleCopyClick(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.duplicateSet(id);
    }

    handleOnChange(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.props.updateSetField(id, value);
    }

    archieveSet(id: string, isActive: boolean) {
        this.props.archieveSet(id, isActive);
    }

    render() {
        const { handleAddClick, handleSaveClick, handleOnChange } = this;
        const { sets, getSetsLoading, addSetLoading, editSetLoadingIds } = this.props.sets;

        return (
            <>
                <header>
                    <h1>Schema CMS</h1>
                </header>
                <main>
                    <div className={styles.panel_wide}>
                        {getSetsLoading ? (
                            <LoadingIndicator />
                        ) : (
                            sets.map((set: set) => (
                                <div key={set._id} className={styles.bar}>
                                    {editSetLoadingIds.includes(set._id) ? (
                                        <LoadingIndicator className={styles.loader} />
                                    ) : (
                                        <>
                                            <button
                                                onClick={handleSaveClick.bind(this, set._id)}
                                                title="Save"
                                            >
                                                <img src="/img/svg/save.svg" />
                                            </button>
                                        </>
                                    )}
                                    <input
                                        className={styles.setName}
                                        placeholder="Name"
                                        type="text"
                                        value={set.name}
                                        onChange={handleOnChange.bind(this, set._id)}
                                    />
                                    <Link to={`/dashboard/cms/${set._id}`} title="Edit">
                                        <button className={styles.primary_button}>
                                            <img src="/img/svg/edit.svg" />
                                        </button>
                                    </Link>
                                    <button
                                        onClick={this.handleCopyClick.bind(this, set._id)}
                                        title="Duplicate"
                                    >
                                        <img src="/img/svg/copy.svg" />
                                    </button>
                                    <SwitchButton
                                        isActive={!!set.isActive}
                                        onSwitch={(e, isActive) => {
                                            this.props.archieveSet(set._id, isActive);
                                        }}
                                        disabled={false}
                                        className={styles.archieveSwitch}
                                    />
                                </div>
                            ))
                        )}
                        {addSetLoading ? (
                            <LoadingIndicator className={styles.loader} />
                        ) : (
                            <button onClick={handleAddClick} className={styles.primary}>
                                Add new set
                            </button>
                        )}
                    </div>
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    sets: state.sets
});

export default connect(mapStateToProps, actions)(SchemaCMS);
