import * as React from "react";
import CampaignKeys from "./components/CampaignKeys";
import DynamicForm from "./components/DynamicForm";
import LoadingIndicator from "components/LoadingIndicator";
import ServerFields from "./components/ServerFields";
import _ from "lodash";
import styles from "./styles.scss";
import withServerSettings, { WithServerSettings } from "hoc/withServerSettings";

const Server: React.FC<WithServerSettings> = props => {
    const {
        deployment: { savingServerError, isSavingServer, isPullingServers },
        handleCampaignKeyChange,
        handleDynamicFormChange,
        handleOnChange,
        handleSaveClick,
        match: {
            params: { id }
        },
        mode = "edit",
        settings: {
            campaignKeys,
            domain,
            host,
            otherSettings,
            password,
            pixels,
            username
        },
        whitelist: { whitelist }
    } = props;

    return !isPullingServers ? (
        <div className={styles.wrapper}>
            <div className={styles.panel_wide}>
                <ServerFields
                    domain={domain}
                    handleOnChange={handleOnChange}
                    host={host}
                    mode={mode}
                    password={password}
                    username={username}
                    whitelist={whitelist}
                />
            </div>
            <DynamicForm
                handleDynamicFormChange={(e: React.SyntheticEvent) =>
                    handleDynamicFormChange("pixels", e)
                }
                formData={pixels}
                formName="Pixels"
            />
            <DynamicForm
                handleDynamicFormChange={(e: React.SyntheticEvent) =>
                    handleDynamicFormChange("otherSettings", e)
                }
                formData={otherSettings}
                formName="Other Settings"
            />
            <CampaignKeys
                campaignKeys={campaignKeys}
                handleCampaignKeyChange={handleCampaignKeyChange}
            />
            <button
                onClick={(e: React.SyntheticEvent) => handleSaveClick(id, e)}
                className={`${styles.primary} ${
                    isSavingServer ? styles.withLoader : null
                }`}
                disabled={isSavingServer}
            >
                {`${props.mode === "edit" ? "UPDATE" : "ADD NEW"} SERVER`}
                {isSavingServer && <LoadingIndicator />}
            </button>
            {savingServerError && <p>{savingServerError}</p>}
        </div>
    ) : (
        <LoadingIndicator />
    );
};

export default withServerSettings(Server);
