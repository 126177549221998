import * as actionTypes from "actionTypes";

export type hackertoolsStateType = {
    cachedSets: string[];
    error: object;
    gettingCachedSets: boolean;
    ip: string;
    isLoading: boolean;
    removingSession: boolean;
    sessionRemovingError: string;
    validTo: Date | string;
};

const initialState: hackertoolsStateType = {
    cachedSets: [],
    error: {},
    gettingCachedSets: false,
    ip: "",
    isLoading: false,
    removingSession: false,
    sessionRemovingError: "",
    validTo: ""
};

export const hackertoolsStorage: { [key: string]: boolean } = {
    error: true,
    ip: true,
    validTo: true
};

interface IAction {
    type: string;
    payload: any;
}

export default (
    state: hackertoolsStateType = initialState,
    action: IAction
): hackertoolsStateType => {
    switch (action.type) {
        case actionTypes.ADD_NEW_WILDCARD_START:
            return {
                ...state,
                ip: "",
                isLoading: true
            };
        case actionTypes.ADD_NEW_WILDCARD:
            return {
                ...state,
                ip: action.payload.ip,
                validTo: action.payload.validTo,
                isLoading: false
            };
        case actionTypes.ADD_NEW_WILDCARD_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case actionTypes.REMOVE_SESSION_START:
            return {
                ...state,
                removingSession: true
            };
        case actionTypes.REMOVE_SESSION:
            return {
                ...state,
                removingSession: false,
                cachedSets: state.cachedSets.filter(
                    (setId: string) => setId !== action.payload.setId
                )
            };
        case actionTypes.REMOVE_SESSION_ERROR:
            return {
                ...state,
                sessionRemovingError: action.payload.error,
                removingSession: false
            };
        case actionTypes.GET_CACHED_SETS_START:
            return {
                ...state,
                gettingCachedSets: true
            };
        case actionTypes.GET_CACHED_SETS:
            return {
                ...state,
                cachedSets: action.payload.cachedSets,
                gettingCachedSets: false
            };
        case actionTypes.GET_CACHED_SETS_ERROR:
            return {
                ...state,
                gettingCachedSets: false
            };
        default:
            return state;
    }
};
