import * as React from "react";
import styles from "./styles.scss";
import classNames from "classnames";

const getCssValue = (value: number) => (!isNaN(value) ? `${value}px` : value);

interface Props {
    size?: number;
    loaded?: boolean;
    children?: any;
    className?: string;
    white?: boolean;
}

const LoadingIndicator = ({
    size: defaultSize = 30,
    loaded,
    children = null,
    className,
    white = false
}: Props) => {
    if (loaded) return children;

    const size = getCssValue(defaultSize);

    return (
        <div
            className={classNames(className, styles.loadingBox)}
            style={{ width: size, height: size }}
        >
            <div className={styles.spinner}>
                <svg className={styles.circular} viewBox="25 25 50 50">
                    <circle
                        className={white ? styles.whitePath : styles.path}
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                    />
                </svg>
            </div>
        </div>
    );
};

export default LoadingIndicator;
