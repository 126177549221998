import * as React from "react";
import styles from "./styles.scss";
interface Props {
  address?: string;
  address2?: string;
  city?: string;
  postalCode?: any;
  state?: string;
  country?: string;
  data: any;
  headline: string;
}

const Address = ({ data = {}, headline }: Props) => {
  const {
    address,
    address2,
    city,
    postalCode,
    state,
    country,
    firstName,
    lastName,
    phoneNumber
  } = data;
  return data != undefined ? (
    <div className={styles.address}>
      <h3>{headline}:</h3>
      <div>
        <p>Customer:</p>
        <p>
          {firstName} {lastName}
        </p>
      </div>
      <div>
        <p>Phone:</p>
        <p> {phoneNumber}</p>
      </div>
      {address && (
        <div>
          <p>Address:</p>
          <p>{address}</p>
        </div>
      )}
      {address2 && (
        <div>
          <p>Apartment or Suite:</p> <p>${address2}</p>
        </div>
      )}
      {city && (
        <div>
          <p>City:</p>
          <p>{city}</p>
        </div>
      )}
      {postalCode && (
        <div>
          <p>Postal code:</p>
          <p>{postalCode}</p>
        </div>
      )}
      {state && (
        <div>
          <p>State:</p>
          <p>{state}</p>
        </div>
      )}
      {country && (
        <div>
          <p>Country:</p>
          <p>{country}</p>
        </div>
      )}
    </div>
  ) : null;
};

export default Address;