import * as React from "react";
import * as actions from "./actions";
import AddFile from "./components/AddFile";
import Files from "./components/Files";
import Modal from "components/Modal/scenes/Upload";
import magnifier from "assets/icons/search.svg";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";

interface Props {
    fileView: "half" | "wide";
    files: any[];
    handleChooseFile?: (props?: any) => void;
    isPullingFiles: boolean;
    mode: "normal" | "popup";
    pullFiles: () => void;
}

class CDNCloud extends React.PureComponent<any, any> {
    static defaultProps: Props = {
        fileView: "half",
        files: [],
        isPullingFiles: false,
        mode: "normal",
        pullFiles: () => []
    };
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            isModalOpen: false,
            search: ""
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.props.pullFiles();

        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    handleOpenModal() {
        this.setState({ isModalOpen: true });
    }

    handleCloseModal() {
        this.setState({ isModalOpen: false });
    }

    handleChangeSearch(e: React.SyntheticEvent) {
        const { value } = e.target as HTMLInputElement;
        this.setState({ search: value });
    }

    filterFiles(files: any[]) {
        const { search } = this.state;
        const lc = (string: string) => string.toLowerCase();

        if (search !== "") {
            return files.filter(
                (file: any) =>
                    lc(file.name).includes(lc(search)) ||
                    file.tags
                        .filter((tag: any) => tag.name)
                        .find((tag: any) => lc(tag.name).includes(lc(search)))
            );
        } else {
            return files;
        }
    }

    render() {
        const { isModalOpen } = this.state;
        const { fileView, files, isPullingFiles, mode, uploadFiles } = this.props;

        return (
            <>
                {mode === "normal" && (
                    <header>
                        <h1>Cloud CDN</h1>
                    </header>
                )}
                <main className={mode === "normal" ? styles.panelWide : styles.fullWidth}>
                    <AddFile
                        uploadFiles={uploadFiles}
                        onClick={this.handleOpenModal.bind(this)}
                        mode={mode}
                    />
                    <div className={styles.searchWrapper}>
                        <img src={magnifier} alt="search icon" />
                        <input
                            className={styles.search}
                            onChange={this.handleChangeSearch.bind(this)}
                            placeholder="Search files"
                            type="text"
                            ref={this.inputRef}
                        />
                    </div>
                    <Files
                        files={this.filterFiles(files)}
                        isPullingFiles={isPullingFiles}
                        fileView={fileView}
                        handleChooseFile={this.props.handleChooseFile}
                    />
                </main>
                {isModalOpen && (
                    <Modal className={styles.modal} onClose={this.handleCloseModal.bind(this)} />
                )}
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    files: state.file.files,
    isPullingFiles: state.file.isPullingFiles
});

export default connect(mapStateToProps, { ...actions })(CDNCloud);
