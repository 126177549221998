import React, { useEffect } from "react";
import styles from "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { appStateType } from "reducers";
import { deleteFunnel, setEditFunnel } from "./actions";
import SetsList from "../../components/SetsList";
import trashIcon from "assets/icons/delete.svg";
import editIcon from "assets/icons/pencil2.svg";
import SVG from "react-inlinesvg";
import JsonFormatter from "components/JsonFormatter";
import LoadingIndicator from "components/LoadingIndicator";

type Props = {
    setActiveFunnelTabId: (tab: string) => void;
};

const FunnelsList: React.FC<Props> = ({ setActiveFunnelTabId }) => {
    const dispatch = useDispatch();
    const { funnels, isLoadingFunnels } = useSelector(
        (state: appStateType) => state.conversionbalancer
    );

    const handleDeleteFunnel = (id: string) => (e: React.SyntheticEvent<HTMLDivElement>) => {
        dispatch(deleteFunnel(id));
    };

    const handleEditFunnel = (id: string) => (e: React.SyntheticEvent<HTMLDivElement>) => {
        dispatch(setEditFunnel(id));
        setActiveFunnelTabId("CREATE_FUNNEL");
    };

    useEffect(() => {
        dispatch(setEditFunnel(null));
    }, []);

    return (
        <>
            {isLoadingFunnels ? (
                <div className={styles.info}>
                    <LoadingIndicator />
                </div>
            ) : funnels.length > 0 ? (
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>FF Funnel ID:</th>
                            <th>Funnel Name:</th>
                            <th>Sets:</th>
                            <th>Google Ad Account Id:</th>
                            <th>Google Campaigns:</th>
                            <th>Active Conversion Action:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funnels.map(funnel => (
                            <tr key={funnel._id}>
                                <td>{funnel.ffId}</td>
                                <td>{funnel.name}</td>
                                <td>
                                    <SetsList setsList={funnel.sets} marginTop={10} />
                                </td>
                                <td>{funnel.googleAdAccountId}</td>
                                <td>
                                    <JsonFormatter
                                        headline={`${funnel.selectedCampaigns.length} campaigns:`}
                                        body={funnel.selectedCampaigns}
                                    />
                                </td>
                                <td>{funnel.selectedConversionAction}</td>
                                <td>
                                    <div className={styles.actions}>
                                        <div
                                            className={styles.action}
                                            onClick={handleDeleteFunnel(funnel._id)}
                                        >
                                            <SVG src={trashIcon} className={styles.icon} />
                                        </div>
                                        <div
                                            className={styles.action}
                                            onClick={handleEditFunnel(funnel._id)}
                                        >
                                            <SVG src={editIcon} className={styles.icon} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className={styles.info}>No funnels created yet.</div>
            )}
        </>
    );
};

export default FunnelsList;
