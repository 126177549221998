import * as React from "react";
import ConfirmRemoving from "components/Modal/scenes/ConfirmRemoving";
import DeploymentDetails from "components/Modal/scenes/DeploymentDetails";
import styles from "./styles.scss";
import { Link } from "react-router-dom";

class LastDeployment extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isMenuOpen: false
        };
    }

    toggleMenu() {
        this.setState(() => ({ isMenuOpen: !this.state.isMenuOpen }));
    }

    render() {
        const {
            availableVersion,
            basename,
            details,
            removeDeployments,
            server,
            set,
            template,
            version
        } = this.props;
        const liveDeploymentLink = `${server.domain.name}/${basename}/${
            Object.keys(server.campaignKeys)[0]
        }/${template.redirect}`;

        const editDeploymentLink = `/dashboard/deployment/servers/${server._id}/add?basename=${basename}&set=${set._id}&template=${template._id}&version=${version._id}`;

        return (
            <div className={styles.deployment}>
                <div>
                    <span>Basename:</span> {basename}
                </div>
                <div>
                    <span>Template:</span> {template ? template.name : "Not found"}
                </div>
                <div>
                    <span>Set:</span> {set.name}
                </div>
                <div>
                    <span>Version:</span> {version.tag}{" "}
                    {availableVersion != undefined ? (
                        <span
                            className={styles.availableVersion}
                        >{`(available: ${availableVersion})`}</span>
                    ) : null}
                </div>
                <div>
                    <a href={liveDeploymentLink} target="_blank">
                        <button>Link</button>
                    </a>
                </div>

                <div className={styles.menu}>
                    <div className={styles.menu_icon} onClick={() => this.toggleMenu()}>
                        <img src="/img/svg/menu.svg" alt="edit" />
                    </div>
                    {this.state.isMenuOpen && (
                        <div className={styles.menu_nav}>
                            <ul>
                                <li>
                                    <Link to={editDeploymentLink}>Edit</Link>
                                </li>
                                {details && (
                                    <li>
                                        <DeploymentDetails buttonText="Details" {...details} />
                                    </li>
                                )}
                                <li>
                                    <ConfirmRemoving
                                        action={() => {
                                            this.toggleMenu();
                                            removeDeployments(server._id, basename);
                                        }}
                                        buttonText="Remove"
                                        confirmKey={basename}
                                        description="Remove this deployment from the server."
                                    />
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default LastDeployment;
