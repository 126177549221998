import * as actionTypes from "actionTypes";
import api from "api";
import { Action, Dispatch } from "redux";
import { AxiosResponse, AxiosError } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { appStateType } from "reducers";

export function updateFile({
    tag,
    name,
    hash
}: {
    tag: string;
    name: string;
    hash: string;
}) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.UPDATE_FILE_START
        });

        api()
            .put("file", { tag, name, hash })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.UPDATE_FILE,
                    payload: { file: res.data }
                });
                dispatch(pullTags());
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPDATE_FILE_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function deleteFile(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_FILE_START
        });

        api()
            .delete("file", { data: { id } })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DELETE_FILE,
                    payload: { file: res.data }
                });
            })
            .catch((err: AxiosError) => {
                console.log(err.response, "err");
                dispatch({
                    type: actionTypes.DELETE_FILE_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function deleteErrors() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_FILE_ERRORS
        });
    };
}

export function pullTags() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.START_PULL_TAGS
        });

        api()
            .get("file/tags")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_TAGS,
                    payload: { tags: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ERROR_PULL_TAGS,
                    payload: { error: err.response }
                });
            });
    };
}
