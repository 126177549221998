import * as React from "react";
import * as moment from "moment-timezone";
import CampaignLink from "../CampaignLink";
import DeploymentDetails from "components/Modal/scenes/DeploymentDetails";
import EstimatedTime from "./components/EstimatedTime";
import InsetButton, { Inset } from "./components/InsetButton";
import Link from "./components/Link";
import LoadingIndicator from "components/LoadingIndicator";
import cx from "classnames";
import pencil from "assets/icons/deploymentButtons/pencil.svg";
import money from "assets/icons/deploymentButtons/money.svg";
import stats from "assets/icons/deploymentButtons/stats.svg";
import deploymentIcon from "assets/icons/deploymentButtons/deployment.svg";
import konnektiveLogo from "assets/konnektive.png";
import odooLogo from "assets/odoo.png";
import statuses from "assets/deploymentMessages";
import styles from "./styles.scss";
import upsell from "assets/icons/deploymentButtons/upsell.svg";
import { getCampaignNames } from "services/getCampaignNames";
import { getFileObject } from "services/getFileObject";
import { language } from "types/language";
import { TestsStatus } from "./components/TestsStatus";

class DeploymentCard extends React.Component<any, any> {
    static defaultProps = {
        deployment: {
            basename: "",
            details: {},
            estimatedTime: 0,
            pipelineResponse: {}
        },
        server: {
            campaignKeys: {},
            domain: {}
        },
        template: {},
        version: {}
    };

    state = {
        imageUrl: "",
        brand: { name: "", logo: "" }
    };

    findLogo() {
        const {
            deployment: { brand }
        } = this.props;

        if (brand.logo && !this.state.imageUrl) {
            const file = getFileObject(brand.logo);
            const { extensions = [], hash, path } = file as any;
            const [extension] = extensions;
            const cloudfront = "https://d3hlrrbqydii6y.cloudfront.net";
            const imageUrl = `${cloudfront}/${path}/${hash}.${extension}`;

            this.setState({ imageUrl });

            return imageUrl;
        }

        return this.state.imageUrl;
    }

    getStatus() {
        const { deployment, liveDeployment = {} } = this.props;
        const { pipelineResponse = {}, estimatedTime = 0 } = deployment;

        const { statusText = "NO PIPELINES RESPONSE" } = pipelineResponse || {};

        if (!statusText) return null;

        let finalStatus = {
            ...statuses[statusText.toLowerCase()],
            estimatedTime,
            statusText: statusText.toLowerCase()
        };

        if (
            finalStatus.statusText == "stopped" ||
            finalStatus.statusText == "successful" ||
            finalStatus.statusText == "failed"
        ) {
            return null;
        }

        if (liveDeployment.hasOwnProperty("status")) {
            let { status, estimatedTime: liveEstimatedTime = 0 } = liveDeployment;

            finalStatus = {
                ...statuses[status.toLowerCase()],
                estimatedTime: liveEstimatedTime,
                statusText: status.toLowerCase()
            };

            if (finalStatus.statusText == "successful") {
                return null;
            }
        }

        return finalStatus;
    }

    getSetLanguage(setId: string) {
        const { languages } = this.props;
        const setLanguages = languages.filter((language: language) => language.set == setId);

        const usLanguage = setLanguages.find((l: language) => l.countryCode === "us");

        if (usLanguage) {
            return usLanguage._id;
        } else if (setLanguages.length > 0) {
            return setLanguages[0]._id;
        }

        return null;
    }

    render() {
        const {
            deployment: {
                _id,
                basename,
                pipelineResponse,
                details,
                brand,
                server = { _id: "" },
                set,
                template,
                version,
                createdAt
            },
            liveDeployment
        } = this.props;
        const { deployment } = this.props;

        const status = this.getStatus();
        const { estimatedTime = 0, loader = false } = status || {};
        const { created_on = "NO PIPELINES RESPONSE" } = pipelineResponse || {};
        const estimatedTimeSecs = moment.duration(estimatedTime, "m").asSeconds();
        const editDeploymentLink = `/dashboard/deployment/servers/${server._id}/add?basename=${basename}&set=${set._id}&template=${template._id}&version=${version._id}&id=${_id}`;
        const analyticsLink = `/dashboard/analytics/cms/${set._id}/`;
        const setLang = this.getSetLanguage(set._id);

        const crmImage = (() => {
            switch (set.crm) {
                case "ODOO":
                    return (
                        <div className={styles.crmLogoContainer}>
                            <img src={odooLogo} />
                            <div className={styles.text}>STAGING</div>
                        </div>
                    );
                case "ODOO_PROD":
                    return (
                        <div className={styles.crmLogoContainer}>
                            <img src={odooLogo} />
                            <div className={styles.text}>PRODUCTION</div>
                        </div>
                    );
                case "ODOO_DEV":
                    return (
                        <div className={styles.crmLogoContainer}>
                            <img src={odooLogo} />
                            <div className={styles.text}>DEVELOPMENT</div>
                        </div>
                    );
                case "KONNEKTIVE":
                    return (
                        <div className={styles.crmLogoContainer}>
                            <img src={konnektiveLogo} />
                        </div>
                    );
                default:
                    return <div>CRM ISSUE!</div>;
            }
        })();

        const crm = set.crm;
        const domainName = server.domain.name;
        const redirect = template.redirect;
        const selected = getCampaignNames(server.campaignKeys)[0];
        const isCampaigns = crm === "KONNEKTIVE" && !details.otherSettings.useEjamAbstra;

        const liveDeploymentLink = isCampaigns
            ? `${domainName}/${basename}/${selected}/${redirect}`
            : `${domainName}/${basename}/${redirect}`;

        const editPricingsLink = `/dashboard/cms/${set._id}/${setLang}/${version._id}/?redirect=pricing&previewUrl=${liveDeploymentLink}`;
        const editUpsellLink = `/dashboard/cms/${set._id}/${setLang}/${version._id}/?redirect=upsells&previewUrl=${liveDeploymentLink}`;
        const editContentLink = `/dashboard/cms/${set._id}/${setLang}/${version._id}/?redirect=content&previewUrl=${liveDeploymentLink}`;

        return (
            <div
                className={cx(styles.deployment, {
                    [styles.active]: status
                })}
            >
                <div className={styles.title}>
                    <span>{template.shortcut}</span> {brand.name || ""}
                    <span className={styles.date}>{moment(createdAt).format("MM/DD/YYYY")}</span>
                    <TestsStatus liveDeployment={liveDeployment} deployment={deployment} />
                </div>
                <div className={styles.details}>
                    <div className={styles.actions}>
                        <div className={styles.imageWrapper}>
                            <img src={this.findLogo()} />
                        </div>
                        <Link to={editPricingsLink} type="EDIT">
                            <InsetButton flat disabled={setLang === null}>
                                Pricing Engine
                                <Inset>
                                    <img src={money} style={{ transform: "scale(1.5)" }} />
                                </Inset>
                            </InsetButton>
                        </Link>
                        <Link to={editContentLink} type="EDIT">
                            <InsetButton flat disabled={setLang === null}>
                                Content
                                <Inset>
                                    <img src={pencil} />
                                </Inset>
                            </InsetButton>
                        </Link>
                        <Link to={editDeploymentLink} type="EDIT">
                            <InsetButton flat>
                                Deployment
                                <Inset>
                                    <img src={deploymentIcon} />
                                </Inset>
                            </InsetButton>
                        </Link>
                    </div>
                    <div className={styles.data}>
                        <div>
                            <span>Domain:</span> {server.domain.name}
                        </div>
                        <div>
                            <span>Basename:</span> {basename}
                        </div>
                        <div>
                            <span>Content Set:</span> {set.name}
                        </div>
                        <div>
                            <span>Version:</span> {version.tag}
                        </div>
                        <div>
                            <span>CRM:</span> <div className={styles.crmLogo}>{crmImage}</div>
                        </div>
                        {status && loader ? (
                            <LoadingIndicator className={styles.absoluteLoader} size={50} white />
                        ) : null}
                    </div>
                </div>
                {status ? (
                    <EstimatedTime
                        liveDeploymentLink={`${server.domain.name}/${basename}/cms/${template.redirect}`}
                        estimatedTime={estimatedTimeSecs}
                        status={status}
                        created_on={created_on}
                    />
                ) : (
                    <footer className={styles.cardFooter}>
                        {details && <DeploymentDetails buttonText="Details" {...details} />}
                        <Link to={editUpsellLink} type="EDIT">
                            <InsetButton flat disabled={setLang === null}>
                                Upsell
                                <Inset>
                                    <img src={upsell} />
                                </Inset>
                            </InsetButton>
                        </Link>
                        <Link to={analyticsLink} type="EDIT">
                            <InsetButton flat>
                                Analytics
                                <Inset>
                                    <img src={stats} />
                                </Inset>
                            </InsetButton>
                        </Link>
                        <CampaignLink
                            basename={basename}
                            domainName={server.domain.name}
                            campaignKeys={getCampaignNames(server.campaignKeys)}
                            redirect={template.redirect}
                            crm={set.crm}
                            isCampaigns={isCampaigns}
                        />
                    </footer>
                )}
            </div>
        );
    }
}

export default DeploymentCard;
