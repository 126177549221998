import * as actionTypes from "actionTypes";

export type spendStateType = {
    spend: null | object;
    isLoading: boolean;
    error: string;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: spendStateType = {
    spend: {},
    isLoading: false,
    error: ""
};

export const spendStorage: { [key: string]: boolean } = {};

export default (state: spendStateType = initialState, action: IAction): spendStateType => {
    switch (action.type) {
        case actionTypes.GET_SPEND_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_SPEND:
            return {
                ...state,
                isLoading: false,
                spend: {
                    ...state.spend,
                    ...action.payload.spend
                },
                error: ""
            };
        case actionTypes.GET_SPEND_ERROR:
            return {
                ...state,
                isLoading: false,
                spend: null,
                error: action.payload.error
            };
        default:
            return state;
    }
};
