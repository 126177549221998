import * as React from "react";
import DataPiece from "scenes/Dashboard/SchemaCMSEditor/components/DataPiece";
import getDollars from "services/getDollars";
import styles from "./styles.scss";
import Headline from "../Headline";
import getPercentage from "services/getPercentage";
import _ from "lodash";

interface Props {
    performance: any;
}

const Performance: React.FC<Props> = ({ performance = {} }) => {
    const dataPieceProps = {
        fontSize: 16,
        primaryColor: "green",
        reverse: true
    };

    const DP = (props: any) => (
        <>
            <DataPiece {...dataPieceProps} {...props} />
        </>
    );

    const {
        conversions = 0,
        views = 0,
        unitsSold = 0,
        cog = 0,
        cos = 0,
        revenue = 0
    } = performance;

    return (
        <div className={styles.performance}>
            <Headline title="Performance" />
            <div className={styles.data}>
                <DP label="Revenue" value={getDollars(revenue)} />
                <DP label="Margin" value={`${getPercentage((revenue - (cog + cos)) / revenue)}`} />
                <DP label="Conversions" value={conversions} />
                <DP label="COGS" value={getDollars(cog + cos)} />
                <DP label="Total Units Sold" value={unitsSold} />
                <DP label="CVR" value={getPercentage(conversions / views)} />
                <DP label="Gross Profit" value={getDollars(revenue - cog - cos)} />
                <DP label="GP / View" value={getDollars((revenue - cog - cos) / views)} />
                <DP label="GP / Order" value={getDollars((revenue - cog - cos) / conversions)} />
                <DP label="Views" value={views} />
                <DP label="EPV" value={getDollars(revenue / views)} />
                <DP label="AOV" value={getDollars(revenue / conversions)} />
            </div>
        </div>
    );
};

export default Performance;
