import * as actionTypes from "actionTypes";
import isEmpty from "services/isEmpty";

export type dripStateType = {
    cards: object[];
    wrongCards: object[];
    isPullingCards: boolean;
    settings: {
        campaignId: number;
        productId: number;
        _id: string;
    }[];
    isPullingSettings: boolean;
    pullingError: object[];
    isUploadingCards: boolean;
    file: File | null;
    pullingErrors: null | Error;
    isPullingReport: boolean;
    report: any;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: dripStateType = {
    cards: [],
    wrongCards: [],
    isPullingCards: false,
    settings: [],
    isPullingSettings: false,
    pullingError: [],
    isUploadingCards: false,
    file: null,
    pullingErrors: null,
    isPullingReport: true,
    report: null
};

export const dripStorage: { [key: string]: boolean } = {};

export default (state: dripStateType = initialState, action: IAction): dripStateType => {
    let inputSettings, settings, error, file, id: string, _id;
    switch (action.type) {
        case actionTypes.START_PULL_DRIP_CARDS:
            return {
                ...state,
                isPullingCards: true
            };
        case actionTypes.PULL_DRIP_CARDS:
            return {
                ...state,
                isPullingCards: false,
                cards: action.payload.cards
            };
        case actionTypes.ERROR_PULL_DRIP_CARDS:
            return {
                ...state,
                isPullingCards: false,
                pullingErrors: action.payload.error
            };
        case actionTypes.START_PULL_DRIP_SETTINGS:
            return {
                ...state,
                isPullingSettings: true
            };
        case actionTypes.PULL_DRIP_SETTINGS:
            inputSettings = action.payload.settings;
            settings = !!inputSettings ? inputSettings : [];
            return {
                ...state,
                settings,
                isPullingSettings: false
            };
        case actionTypes.ERROR_PULL_DRIP_SETTINGS:
            return {
                ...state,
                isPullingSettings: false,
                pullingErrors: action.payload.error
            };
        case actionTypes.UPDATE_DRIP_SETTING:
            let { name, value } = action.payload;
            id = action.payload.id;

            return {
                ...state,
                settings: state.settings.map((cron: any) =>
                    id == cron._id
                        ? {
                              ...cron,
                              [name]: value
                          }
                        : cron
                )
            };
        case actionTypes.START_SAVE_DRIP_SETTINGS:
            return {
                ...state,
                isPullingSettings: true
            };
        case actionTypes.SAVE_DRIP_SETTINGS:
            return {
                ...state,
                settings: state.settings.map((setting: any) =>
                    setting._id == action.payload.settings._id ? action.payload.settings : setting
                ),
                isPullingSettings: false
            };
        case actionTypes.ERROR_SAVE_DRIP_SETTINGS:
            return {
                ...state,
                isPullingSettings: false
            };
        case actionTypes.TOGGLE_DRIP_START:
            return {
                ...state,
                isPullingSettings: true
            };
        case actionTypes.TOGGLE_DRIP:
            let { _id } = action.payload.settings;
            return {
                ...state,
                settings: state.settings.map((setting: any) =>
                    setting._id == _id ? action.payload.settings : setting
                ),
                isPullingSettings: false
            };
        case actionTypes.TOGGLE_DRIP_ERROR:
            return {
                ...state,
                isPullingSettings: false
            };
        case actionTypes.DRIP_GET_FILE:
            return {
                ...state,
                file: action.payload.file
            };
        case actionTypes.START_DRIP_UPLOAD_CARDS:
            return {
                ...state,
                isUploadingCards: true,
                wrongCards: []
            };
        case actionTypes.DRIP_UPLOAD_CARDS:
            const { insertedCards, wrongCards } = action.payload;

            if (wrongCards.length > 0) console.log("WRONG CARDS:", wrongCards);

            return {
                ...state,
                isUploadingCards: false,
                cards: [...state.cards, ...insertedCards],
                wrongCards
            };
        case actionTypes.ERROR_DRIP_UPLOAD_CARDS:
            return {
                ...state,
                isUploadingCards: false
            };
        case actionTypes.ADD_SETTINGS:
            return {
                ...state,
                settings: [...state.settings, action.payload.settings]
            };
        case actionTypes.DELETE_SETTINGS:
            return {
                ...state,
                settings: state.settings.filter((cron: any) => cron._id != action.payload.id)
            };
        case actionTypes.DELETE_CARD:
            return {
                ...state,
                cards: state.cards.filter((card: any) => card._id != action.payload.id)
            };
        case actionTypes.TOGGLE_CARD:
            const isDeclined = action.payload.isDeclined;
            id = action.payload.id;

            return {
                ...state,
                cards: state.cards.map((card: any) =>
                    card._id === id ? { ...card, isDeclined: !isDeclined } : card
                )
            };
        case actionTypes.CC_DRIP_REPORT_START:
            return {
                ...state,
                isPullingReport: true
            };
        case actionTypes.CC_DRIP_REPORT:
            return {
                ...state,
                report: action.payload,
                isPullingReport: false
            };
        case actionTypes.CC_DRIP_REPORT_ERROR:
            return {
                ...state,
                isPullingReport: false
            };
        default:
            return state;
    }
};
