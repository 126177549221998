import React, { useState } from "react";
import FlavourBox from "../../components/FlavourBox";
import styles from "./styles.scss";
import Headline from "../../../../components/Headline";
import Button from "components/Button";
import { PricingContext } from "../..";
import { useDispatch } from "react-redux";
import { savePricingContainer, updatePricingContainerDraft } from "../../actions";
import { PricingContainer } from "types/pricing";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";

type Props = {
    isSdk?: boolean;
};

const PricingInit: React.FC<Props> = ({ isSdk = false }) => {
    let { containerDraft = null, setId = "", languageId = "", versionId = "" } = React.useContext(
        PricingContext
    );
    const containerDraft2 = useSelector((state: appStateType) => state.pricing.containerDraft);

    if (containerDraft2) {
        if (setId == "") {
            setId = containerDraft2.set;
        }
    }
    const dispatch = useDispatch();
    const { _id, set, midasState, variants } = containerDraft || ({} as PricingContainer);

    const handlePricingType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let { value } = e.target;

        dispatch(
            updatePricingContainerDraft({
                ...containerDraft,
                type: value as "BUNDLE" | "PRODUCT",
                _id,
                set,
                midasState,
                variants
            })
        );
    };

    const handleSubmit = () => {
        dispatch(savePricingContainer(setId, languageId, versionId, isSdk));
    };

    return (
        <div className={styles.initContainer}>
            <div className={styles.editor}>
                <Headline
                    name={"Pricing Container Type"}
                    description={
                        'select "BUNDLE" for bundle pricing(ET4) and products for product pricing(ET1)'
                    }
                    isArray={false}
                    isGroup={true}
                    isParent={true}
                >
                    <div>
                        <select
                            value={containerDraft?.type}
                            onChange={handlePricingType}
                            className={styles.value}
                        >
                            <option value="" disabled>
                                Select a value
                            </option>
                            <option value="PRODUCT">Product based</option>
                            <option value="BUNDLE">Bundle Based</option>
                        </select>
                    </div>
                </Headline>
            </div>
            {containerDraft?.type === "BUNDLE" ? (
                <div className={styles.bundleContainer}>
                    <FlavourBox />
                </div>
            ) : null}

            <Button className={styles.submit} background="green" onClick={handleSubmit} flat>
                Submit Container
            </Button>
        </div>
    );
};

export default PricingInit;
