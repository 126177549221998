import { odooProduct } from "types/odooProduct";
import store from "store";

export const getOdooProduct = (sid: number, crm: "ODOO" | "ODOO_DEV" | "ODOO_PROD") => {
    const appState = store.getState();
    const { odooProducts = [], odooProductsProd = [], odooProductsDev = [] } = appState.products;

    let products: odooProduct[] = [];

    switch (crm) {
        case "ODOO":
            products = odooProducts;
            break;
        case "ODOO_DEV":
            products = odooProductsDev;
            break;
        case "ODOO_PROD":
        default:
            products = odooProductsProd;
            break;
    }

    return products.find((product: odooProduct) => product.sid === sid);
};
