import * as React from "react";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import _ from "lodash";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { set } from "types/set";
import { useDispatch, useSelector } from "react-redux";
import { removeSession, getCachedSets } from "./actions";

const Session: React.FC = () => {
    const setsReducer = useSelector((state: appStateType) => state.sets);
    const hackertools = useSelector((state: appStateType) => state.hackertools);
    const [set, selectSet] = React.useState("default");
    const dispatch = useDispatch();
    const { sets } = setsReducer;
    const {
        cachedSets,
        gettingCachedSets,
        removingSession,
        sessionRemovingError
    } = hackertools;

    React.useEffect(() => {
        dispatch(getCachedSets());
    }, []);

    const handleChange = (value: string) => {
        if (value) {
            selectSet(value);
        }
    };

    const handleRemove = () => {
        if (set && set !== "default") {
            dispatch(removeSession(set));
        }
    };

    if (gettingCachedSets) {
        return (
            <div className={styles.panel}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            <div className={styles.panel}>
                {cachedSets.length > 0 ? (
                    <>
                        <p>Choose set to remove your session:</p>
                        <select
                            onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                                handleChange(e.currentTarget.value)
                            }
                            value={set}
                        >
                            <option value="default">Select set</option>
                            {sets
                                .filter((set: set) =>
                                    cachedSets.includes(set._id)
                                )
                                .map((set: set) => (
                                    <option value={set._id} key={set._id}>
                                        {set.name}
                                    </option>
                                ))}
                        </select>
                        <div className={styles.row}>
                            {removingSession ? (
                                <LoadingIndicator />
                            ) : (
                                <Button onClick={handleRemove} flat>
                                    Remove session
                                </Button>
                            )}
                        </div>
                        {sessionRemovingError && (
                            <div className={styles.row}>
                                {sessionRemovingError}
                            </div>
                        )}
                    </>
                ) : (
                    <p>You haven't session in the cache.</p>
                )}
            </div>
        </>
    );
};

export default Session;
