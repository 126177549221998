import api from "api";
import axios, { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function loadAdAccounts(userID: string, access_token: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_ADACCOUNTS_START
    });

    api()
      .post("fbspend/adaccounts", { access_token, userID })
      .then((res: AxiosResponse) => {
        dispatch({
          type: actionTypes.GET_ADACCOUNTS,
          payload: { adaccounts: res.data.adaccounts }
        });
      })
      .catch((err: AxiosError) =>
        dispatch({
          type: actionTypes.GET_ADACCOUNTS_ERROR,
          payload: { error: err.response }
        })
      );
  };
}
