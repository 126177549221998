import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { appStateType } from "reducers";
import { createTreeNode } from "scenes/Dashboard/SchemaCMSEditor/actions";
import { getElements } from "scenes/Dashboard/Analytics/Scenes/CMSAnalytics/actions";
import { schemaType } from "scenes/Dashboard/SchemaCMSEditor/components/NodeComponent";
import { buildTree, copyTree } from "components/Modal/scenes/Duplication/actions";
import { upsellContainer } from "types/upsellContainer";
import { refreshContent } from "scenes/Dashboard/SetPage/Elements/actions";

export const addUpsellContainer = ({
    isDownsell,
    title,
    upsellsFlowId,
    index
}: {
    isDownsell: boolean;
    title?: string;
    upsellsFlowId: string;
    index: number;
}) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_UPSELL_CONTAINER_START
        });

        api()
            .post("upsell/container", {
                isDownsell,
                title,
                upsellsFlowId,
                index
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_UPSELL_CONTAINER,
                    payload: { upsellContainer: res.data, upsellsFlowId }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const updateUpsellContainer = ({
    _id,
    isDownsell,
    title,
    upsellsFlowId
}: {
    _id: string;
    isDownsell: boolean;
    title: string;
    upsellsFlowId: string;
}) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_UPSELL_CONTAINER_START
        });

        api()
            .put("upsell/container", {
                _id,
                isDownsell,
                title
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_UPSELL_CONTAINER,
                    payload: { upsellContainer: res.data, upsellsFlowId }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const duplicateUpsellContainer = (
    setId: string,
    containerId: string,
    flowId: string,
    order: number
) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.COPY_UPSELL_CONTAINER_START
        });

        api()
            .put("upsell/container/duplicate", {
                setId,
                flowId,
                containerId,
                order
            })
            .then((res: AxiosResponse) => {
                const { container } = res.data;

                dispatch({
                    type: actionTypes.COPY_UPSELL_CONTAINER,
                    payload: { container, flowId }
                });

                dispatch<any>(refreshContent(setId));
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.COPY_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                });
            });
    };
};

export const archieveUpsellContainer = (containerId: string, flowId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ARCHIEVE_UPSELL_CONTAINER_START
        });

        api()
            .delete(`upsell/container?containerId=${containerId}&flowId=${flowId}`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ARCHIEVE_UPSELL_CONTAINER,
                    payload: { containerId }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ARCHIEVE_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                });
            });
    };
};

export const reorderUpsellContainer = (
    sourceIndex: number,
    destinationIndex: number,
    containerId: string,
    containers: upsellContainer[]
) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REORDER_UPSELL_CONTAINER_START
        });

        const container = containers.find(el => el._id === containerId);

        if (!container) {
            return;
        }

        if (container.isDownsell && destinationIndex === 0) {
            return;
        }

        const newContainers = [...containers];

        newContainers.splice(sourceIndex, 1);
        newContainers.splice(destinationIndex, 0, container);

        const newContainersOrder = newContainers.map((el, index) => ({ _id: el._id, index }));

        dispatch({
            type: actionTypes.TEMP_REORDER_UPSELL_CONTAINER,
            payload: { containers: newContainersOrder }
        });

        api()
            .put("upsell/container/reorder", {
                containers: newContainersOrder
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REORDER_UPSELL_CONTAINER
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REORDER_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                });
            });
    };
};

export const removeUpsellContainer = ({
    _id,
    upsellsFlowId
}: {
    _id: string;
    upsellsFlowId: string;
}) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_UPSELL_CONTAINER_START
        });

        api()
            .delete("upsell/container", {
                data: {
                    _id,
                    upsellsFlowId
                }
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.REMOVE_UPSELL_CONTAINER,
                    payload: { upsellContainer: res.data, upsellsFlowId }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.REMOVE_UPSELL_CONTAINER_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const addUpsell = ({
    _id,
    cloneElement,
    isActive,
    languageId,
    schema,
    setId,
    title,
    upsellContainerId
}: {
    _id?: string;
    cloneElement?: string;
    isActive?: boolean;
    languageId?: string;
    schema?: schemaType;
    setId?: string;
    title?: string;
    upsellContainerId?: string;
}) => {
    return async (dispatch: ThunkDispatch<appStateType, void, any>) => {
        dispatch({
            type: actionTypes.ADD_UPSELL_START,
            payload: {
                upsellContainerId
            }
        });

        let query = {
            _id,
            elementId: null,
            isActive,
            languageId,
            setId,
            title,
            upsellContainerId
        };

        if (setId && languageId && schema) {
            const elementsChain = await dispatch(
                createTreeNode({
                    setId,
                    languageId,
                    path: "newUpsells.newUpsell.workaround",
                    value: "",
                    schema
                })
            );

            const upsellElement = (elementsChain as any).find((el: any) => el.name === "newUpsell");

            const {
                newElement: { _id: elementId }
            } = upsellElement;

            if (cloneElement) {
                dispatch(buildTree(cloneElement, languageId));
                dispatch(
                    copyTree({
                        set: setId,
                        language: languageId,
                        copyVariants: true,
                        parent: elementId
                    })
                );
            }

            query.elementId = elementId;
        }

        api()
            .post("upsell", query)
            .then((res: AxiosResponse) => {
                if (setId) {
                    dispatch(getElements(setId));
                }
                dispatch({
                    type: actionTypes.ADD_UPSELL,
                    payload: { upsell: res.data, upsellContainerId }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_UPSELL_ERROR,
                    payload: { error: err.response, upsellContainerId }
                })
            );
    };
};
