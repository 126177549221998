import edit from "assets/icons/edit.svg";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import { user } from "types/user";
import {
    addUser,
    getUsers,
    getUserRoles,
    updateUserPassword,
    updateUserRoles,
    removeUser
} from "./actions";
import styles from "./styles.scss";
import uuid from "uuid";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { UserRole } from "types/userRole";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";

const animatedComponents = makeAnimated();

const defaultUser = {
    login: "",
    password: "",
    roles: [],
    allowedBrands: []
};

interface stateUser {
    _id?: string;
    login: string;
    password: string;
    roles: {
        value: UserRole;
        label: string;
    }[];
    allowedBrands: {
        value: string;
        label: string;
    }[];
}

interface column {
    columnName: string;
    key: string;
    inputType: "TEXT" | "PASSWORD" | "ROLES" | "BRANDS";
}

const columns: column[] = [
    {
        columnName: "LogIn Username",
        key: "login",
        inputType: "TEXT"
    },
    {
        columnName: "Password",
        key: "password",
        inputType: "PASSWORD"
    },
    {
        columnName: "Roles",
        key: "roles",
        inputType: "ROLES"
    },
    {
        columnName: "Allowed Brands",
        key: "allowedBrands",
        inputType: "BRANDS"
    }
];

const User: React.FC = () => {
    const [user, setUser] = React.useState<stateUser>(defaultUser);
    const [users, setUsers] = React.useState<user[]>([]);
    const [userRoles, setUserRoles] = React.useState<UserRole[]>([]);
    const [isOpen, openModal] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const brands = useSelector((state: appStateType) => state.brand.brands);
    const isUserId = user.hasOwnProperty("_id");
    const roleOptions = userRoles?.map(el => ({ label: el, value: el }));
    const allowedBrandsOptions = brands?.map(el => ({ label: el.name, value: el._id || "" }));

    React.useEffect(() => {
        setLoading(true);
        getUsers()
            .then((users: user[]) => {
                setUsers(users);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, []);

    React.useEffect(() => {
        getUserRoles()
            .then((roles: UserRole[]) => {
                setUserRoles(roles);
            })
            .catch(() => {});
    }, []);

    const handleModal = () => {
        openModal(isOpen => !isOpen);
    };

    const handleCancel = () => {
        openModal(false);
        setUser(defaultUser);
    };

    const handleEdit = (userId: string) => {
        const user = users.find((user: user) => user._id === userId);
        if (user) {
            const roles = user?.roles?.map(el => ({ label: el, value: el })) || [];
            const allowedBrands = user?.allowedBrands?.map(el => ({ label: el, value: el })) || [];
            setUser({ ...user, roles, allowedBrands, password: "" });
            window.scrollTo(0, 0);
        } else {
            setUser(defaultUser);
        }
        openModal(true);
    };

    const handleChange = (key: string, value: any) => {
        setUser({ ...user, [key]: value });
    };

    const handleSelectChange = (selectedOptions: { value: UserRole; label: string }[]) => {
        setUser({ ...user, roles: selectedOptions });
    };

    const handleSelectBrand = (selectedOptions: { value: string; label: string }[]) => {
        setUser({ ...user, allowedBrands: selectedOptions });
    };

    const selectAllBrands = () => {
        setUser({ ...user, allowedBrands: allowedBrandsOptions });
    };

    const selectNoBrands = () => {
        setUser({ ...user, allowedBrands: [] });
    };

    const handleSave = () => {
        setLoading(true);
        const { login, password } = user;
        const roles = user?.roles?.map(el => el.value) || [];
        const allowedBrands = user?.allowedBrands?.map(el => el.value) || [];

        if (!login || !password || !roles.length) {
            return;
        }

        addUser({ ...user, roles, allowedBrands })
            .then(user => {
                const newUsers = [user, ...users];
                setUsers(newUsers);
                setUser(defaultUser);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const generatePassword = () => {
        let password = uuid.v4().replace(/-/g, "").substr(0, 24);
        setUser({ ...user, password });
    };

    const copyPassword = () => {
        const passwordEl: HTMLInputElement | null = document.querySelector("input#password");
        if (passwordEl) {
            passwordEl.focus();

            navigator.clipboard.writeText(user.password).catch(err => {
                console.log("COPY_ERROR:", err.message);
            });
        }
    };

    const updatePassword = () => {
        if (!user._id || !user.password) {
            return;
        }

        setLoading(true);
        updateUserPassword(user._id, user.password)
            .then(newUser => {
                const newUsers = users.map(el => (el._id === newUser._id ? newUser : el));
                setUsers(newUsers);
                setUser({ ...user, password: "" });
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const updateRoles = () => {
        if (!user._id || !user.roles.length) {
            return;
        }

        setLoading(true);
        const roles = user.roles.map(el => el.value);
        const allowedBrands = user.allowedBrands.map(el => el.value);

        updateUserRoles(user._id, { roles, allowedBrands })
            .then(newUser => {
                const newUsers = users.map(el => (el._id === newUser._id ? newUser : el));
                const newRoles = newUser.roles.map((el: string) => ({ label: el, value: el }));
                setUsers(newUsers);
                setUser({ ...user, roles: newRoles });
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const handleRemove = () => {
        if (!user._id || !user.roles.length) {
            return;
        }

        setLoading(true);
        removeUser(user._id)
            .then(user => {
                const newUsers = users.filter(el => el._id !== user._id);
                setUsers(newUsers);
                setUser(defaultUser);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const getInput = (column: column) => {
        switch (column.inputType) {
            case "TEXT":
                return (
                    <input
                        disabled={column.key === "login" && isUserId}
                        className={styles.field}
                        value={user[column.key]}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleChange(column.key, e.currentTarget.value)
                        }
                        placeholder={column.columnName}
                    />
                );
            case "PASSWORD":
                return (
                    <>
                        <h2 className={styles.h2}>Password:</h2>
                        <input
                            className={styles.field}
                            value={user[column.key]}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                handleChange(column.key, e.currentTarget.value)
                            }
                            placeholder={column.columnName}
                            disabled
                            id="password"
                        />
                        <div>
                            <Button
                                background="green"
                                className={styles.button}
                                flat
                                onClick={generatePassword}
                                marginRight
                            >
                                Create Password
                            </Button>
                            <Button
                                background="green"
                                className={styles.button}
                                flat
                                onClick={copyPassword}
                                marginLeft
                            >
                                Copy Password
                            </Button>
                            {isUserId ? (
                                <Button
                                    background="green"
                                    className={styles.button}
                                    flat
                                    onClick={updatePassword}
                                    marginLeft
                                >
                                    Update Password
                                </Button>
                            ) : null}
                        </div>
                    </>
                );
            case "ROLES":
                return (
                    <>
                        <h2 className={styles.h2}>Roles:</h2>
                        <div>
                            <Select
                                value={user.roles}
                                onChange={handleSelectChange}
                                options={roleOptions}
                                isMulti
                                name="roles"
                                placeholder="User Roles"
                                isSearchable
                                className={styles.select}
                                components={animatedComponents}
                            />
                        </div>
                        {isUserId ? (
                            <div>
                                <Button
                                    background="green"
                                    className={styles.button}
                                    flat
                                    onClick={updateRoles}
                                >
                                    Update Roles
                                </Button>
                            </div>
                        ) : null}
                    </>
                );
            case "BRANDS":
                return (
                    <>
                        <h2 className={styles.h2}>Allowed Brands:</h2>
                        <div>
                            <Select
                                value={user.allowedBrands}
                                onChange={handleSelectBrand}
                                options={allowedBrandsOptions}
                                isMulti
                                name="brands"
                                placeholder="Allowed Brands"
                                isSearchable
                                className={styles.select}
                                components={animatedComponents}
                            />
                        </div>
                        {isUserId ? (
                            <div>
                                <Button
                                    background="green"
                                    className={styles.button}
                                    flat
                                    onClick={updateRoles}
                                >
                                    💾 Save Allowed Brands
                                </Button>
                                <Button
                                    background="purple"
                                    className={styles.button}
                                    flat
                                    onClick={selectAllBrands}
                                >
                                    ⭐ Select All Brands
                                </Button>
                                <Button
                                    background="darkPurple"
                                    className={styles.button}
                                    flat
                                    onClick={selectNoBrands}
                                >
                                    ❌ Clear All Brands
                                </Button>
                            </div>
                        ) : null}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <header className={styles.header}>
                <h1>Users Management Panel</h1>
            </header>
            <main>
                <section className={styles.section}>
                    <div className={styles.addContainer}>
                        <Button
                            background="green"
                            className={styles.button}
                            disabled={isLoading}
                            flat
                            onClick={handleModal}
                        >
                            Add new user
                        </Button>
                    </div>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.th}>Edit</th>
                                    {columns.map(col =>
                                        col.inputType !== "PASSWORD" ? (
                                            <th className={styles.th} key={col.key}>
                                                {col.columnName}
                                            </th>
                                        ) : null
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user: user, index: number) => (
                                    <tr key={user._id}>
                                        <td>
                                            <Button onClick={() => handleEdit(user._id || "")}>
                                                <img src={edit} />
                                            </Button>
                                        </td>
                                        {columns.map(col => {
                                            switch (col.inputType) {
                                                case "TEXT":
                                                    return <td key={col.key}>{user[col.key]}</td>;
                                                case "PASSWORD":
                                                    return null;
                                                case "ROLES":
                                                    return (
                                                        <td key={col.key}>
                                                            {user[col.key].join(", ")}
                                                        </td>
                                                    );
                                                case "BRANDS":
                                                    if (user[col.key]) {
                                                        const brandNames = user[col.key].map(
                                                            id =>
                                                                brands.find(
                                                                    brand => brand._id === id
                                                                )?.name || ""
                                                        );

                                                        return (
                                                            <td key={col.key}>
                                                                {brandNames.join(", ")}
                                                            </td>
                                                        );
                                                    }
                                                default:
                                                    return <td key={col.key}>{user[col.key]}</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {isOpen ? (
                        <Modal
                            title={isUserId ? "Update user details" : "Create a new user"}
                            onClose={handleModal}
                        >
                            <div className={styles.form}>
                                {columns.map(col => (
                                    <React.Fragment key={col.key}>{getInput(col)}</React.Fragment>
                                ))}
                                <div>
                                    {!isUserId ? (
                                        <Button
                                            background="green"
                                            className={styles.button}
                                            disabled={isLoading}
                                            flat
                                            onClick={handleSave}
                                            marginRight
                                        >
                                            {isLoading ? (
                                                <LoadingIndicator size={18} />
                                            ) : (
                                                "Add User"
                                            )}
                                        </Button>
                                    ) : null}
                                    {isUserId ? (
                                        <Button
                                            background="purple"
                                            className={styles.button + " " + styles.removeButton}
                                            disabled={isLoading}
                                            flat
                                            onClick={handleRemove}
                                            marginRight
                                        >
                                            {isLoading ? (
                                                <LoadingIndicator size={18} />
                                            ) : (
                                                "Remove User"
                                            )}
                                        </Button>
                                    ) : null}
                                    <Button
                                        className={styles.button}
                                        disabled={isLoading}
                                        onClick={handleCancel}
                                        flat
                                        marginLeft
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    ) : null}
                </section>
            </main>
        </>
    );
};

export default User;
