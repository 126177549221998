import classNames from "classnames";
import * as React from "react";
import styles from "./styles.scss";
const { useEffect, useState } = React;

interface Props {
    className?: string;
    disabled?: boolean;
    isActive: boolean;
    onLoad?: any;
    onSwitch?: (e: React.SyntheticEvent, isActive: boolean) => void;
    color?: "default" | "green";
}

const SwitchButton: React.FC<Props> = ({
    color = "green",
    disabled = false,
    isActive = false,
    onLoad,
    onSwitch,
    className = ""
}) => {
    const [activeState, setActive] = useState(Boolean(Number(isActive)));
    useEffect(() => onLoad && onLoad(null, false), []);

    useEffect(() => {
        setActive(!!isActive);
    }, [isActive]);

    const clickAction = (e: React.SyntheticEvent) => {
        if (onSwitch) {
            onSwitch(e, !activeState);
        }
        setActive(!activeState);
    };

    return (
        <div
            className={classNames(styles.switcher, {
                [className]: true,
                [styles.active]: activeState,
                [styles.disabled]: disabled,
                [styles.green]: color === "green"
            })}
            onClick={e => (disabled ? null : clickAction(e))}
        >
            <div className={styles.circle} />
        </div>
    );
};

export default SwitchButton;
