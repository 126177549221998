export type tabsType =
    | "header"
    | "footer"
    | "content"
    | "presells"
    | "upsells"
    | "newUpsells"
    | "variantsConfig"
    | "pricing";

export const tabs: tabsType[] = [
    "header",
    "footer",
    "content",
    "variantsConfig",
    "presells",
    "upsells"
];
