import * as React from "react";
import Modal from "../../index";
import styles from "./styles.scss";
import { set } from "types/set";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import Button from "components/Button";

interface Props {
    handleChooseSet?: (sets: any) => void;
    onClose?: () => void;
    sets?: set[];
    selectedSets: string[];
    ref?: any;
}

interface State {
    name: string;
    isModalOpen: boolean;
    searchInput: string;
}

class SetSelector extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            isModalOpen: false,
            searchInput: ""
        };

        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({ isModalOpen: true });
    }

    handleCloseModal() {
        const { onClose } = this.props;

        this.setState({ isModalOpen: false });

        if (onClose) {
            onClose();
        }
    }

    handleAddSet(setId: string) {
        const { handleChooseSet, selectedSets } = this.props;
        const result = [...selectedSets, setId];

        if (handleChooseSet) handleChooseSet(result);
    }

    handleRemoveSet(setId: string) {
        const { handleChooseSet, selectedSets } = this.props;
        const result = selectedSets.filter(
            (selectedSetId: string) => selectedSetId !== setId
        );

        if (handleChooseSet) handleChooseSet(result);
    }

    findSets(setIds: string[]) {
        const { sets = [] } = this.props;

        const newSets = setIds.map((setId: string) => {
            const set = sets.find((set: set) => set._id === setId);

            if (set) {
                return set;
            } else {
                return {};
            }
        });

        return newSets;
    }

    render() {
        const { sets = [], selectedSets } = this.props;
        const { isModalOpen, searchInput } = this.state;

        if (!isModalOpen) return null;

        return (
            <Modal onClose={this.handleCloseModal.bind(this)}>
                <div className={styles.setSelector}>
                    <section className={styles.panel}>
                        <header>Select sets</header>

                        <div className={styles.content}>
                            <input
                                placeholder="Type set name"
                                className={styles.searchInput}
                                value={searchInput}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    this.setState({
                                        searchInput: e.target.value
                                    })
                                }
                            />
                            <div className={styles.sets}>
                                {sets
                                    .filter((set: set) => {
                                        if (searchInput != "") {
                                            return (
                                                set.name
                                                    .toLowerCase()
                                                    .includes(
                                                        searchInput.toLowerCase()
                                                    ) &&
                                                !selectedSets.includes(set._id)
                                            );
                                        }

                                        return !selectedSets.includes(set._id);
                                    })
                                    .sort((a: set, b: set) =>
                                        a.name > b.name ? 1 : -1
                                    )
                                    .map((set: set) => (
                                        <div
                                            className={styles.row}
                                            key={set._id}
                                        >
                                            <div>{set.name}</div>
                                            <Button
                                                onClick={this.handleAddSet.bind(
                                                    this,
                                                    set._id
                                                )}
                                                flat
                                                size="tiny"
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </section>
                    {selectedSets.length > 0 && (
                        <section className={styles.panel}>
                            <header>Added sets</header>
                            <div className={styles.content}>
                                <div className={styles.sets}>
                                    {this.findSets(selectedSets)
                                        .sort((a: set, b: set) =>
                                            a.name > b.name ? 1 : -1
                                        )
                                        .map((set: set) => {
                                            return (
                                                <div
                                                    className={styles.row}
                                                    key={set._id}
                                                >
                                                    <div>{set.name}</div>
                                                    <Button
                                                        onClick={this.handleRemoveSet.bind(
                                                            this,
                                                            set._id
                                                        )}
                                                        flat
                                                        size="tiny"
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    sets: state.sets.sets
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
    forwardRef: true
})(SetSelector);

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
    <ConnectedComponent {...props} ref={ref} />
));
