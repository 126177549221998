import viewPage from "assets/icons/deploymentButtons/view-page.svg";
import * as React from "react";
import InsetButton, { Inset } from "../DeploymentCard/components/InsetButton";
import styles from "./styles.scss";
import { set } from "types/set";

type State = {
    campaignKeys: string[];
    selected: string;
};

type Props = {
    basename: string;
    campaignKeys: string[];
    domainName: string;
    redirect: string;
    crm: set["crm"];
    isCampaigns: boolean;
};

class CampaingLink extends React.PureComponent<Props, State> {
    state = {
        campaignKeys: [],
        selected: ""
    };

    componentDidMount() {
        const { campaignKeys } = this.props;

        if (campaignKeys.length > 0) {
            this.setState({ campaignKeys, selected: campaignKeys[0] });
        }
    }

    selectKey = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        this.setState({ selected: value });
    };

    render() {
        const { basename, domainName, redirect, crm = "KONNEKTIVE", isCampaigns } = this.props;
        const { selected, campaignKeys } = this.state;

        const liveDeploymentLink = isCampaigns
            ? `${domainName}/${basename}/${selected}/${redirect}`
            : `${domainName}/${basename}/${redirect}`;

        return selected != "" ? (
            <>
                {isCampaigns ? (
                    <select onChange={this.selectKey} className={styles.select}>
                        {campaignKeys.map(key => (
                            <option key={key}>{key}</option>
                        ))}
                    </select>
                ) : null}
                <a
                    href={liveDeploymentLink}
                    target="_blank"
                    style={{ gridColumn: isCampaigns ? "auto" : "1 / 4" }}
                >
                    <InsetButton flat>
                        View Page
                        <Inset>
                            <img src={viewPage} />
                        </Inset>
                    </InsetButton>
                </a>
            </>
        ) : null;
    }
}

export default CampaingLink;
