import io from "socket.io-client";

const { baseURL = "", NODE_ENV } = process.env;
const connectionURL =
    NODE_ENV === "production" ? window.location.origin : baseURL.replace("/api", "");

const socket = io(connectionURL, {
    transports: ["websocket"],
    secure: true
});

export default socket;
