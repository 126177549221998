import * as React from "react";
import * as actions from "./actions";
import * as dashboardActions from "../../../actions";
import * as isoCountries from "assets/isoCountries.js";
import * as moment from "moment-timezone";
import File from "../../../CDNCloud/components/File";
import LoadingIndicator from "components/LoadingIndicator";
import TotalsTable from "./components/TotalsTable";
import _ from "lodash";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { brick } from "types/brick";
import { connect } from "react-redux";
import { element } from "types/element";
import { getFileObject, isFile } from "services/getFileObject";
import { language } from "types/language";
import { reportQuery } from "types/report";
import { set } from "types/set";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ActionMeta } from "react-select";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

interface State {
    selectedSet: set | undefined;
    selectedLanguage: language | undefined;
    from: any;
    to: any;
    adAccount: any;
    token: string;
    possibleLanguages: language[];
    countryCode: string[] | undefined;
    paymentMethod: string[] | undefined;
}

type dynamicTotal = [string, any[]];

class CMSAnalytics extends React.PureComponent<any, State> {
    constructor(props: object) {
        super(props);

        this.state = {
            selectedSet: undefined,
            selectedLanguage: undefined,
            from: this.getTodaysDate(),
            to: this.getTodaysDate(),
            adAccount: undefined,
            token: "",
            possibleLanguages: [],
            countryCode: undefined,
            paymentMethod: undefined
        };
    }

    getTodaysDate(now = new Date()) {
        return moment(now).format("YYYY-MM-DD");
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { sets } = this.props.sets;
        const { languagesMap } = this.props.languages;
        const {
            match: {
                params: { setId }
            }
        } = this.props;

        if (!sets) {
            this.props.getSets();
        }
        const { selectedSet } = this.state;

        const providedSet = sets.find((set: set) => set._id === setId);
        if (selectedSet == null && sets) {
            this.setState({ selectedSet: providedSet || sets[0] });
        }

        if (!languagesMap) {
            this.props.getLanguages();
        }

        this.props.getDistinctField("paymentMethod");
        this.props.getDistinctField("countryCode");
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { sets } = this.props.sets;
        const { selectedSet, selectedLanguage } = this.state;
        const {
            match: {
                params: { setId }
            }
        } = this.props;
        const providedSet = sets.find((set: set) => set._id === setId);

        if (selectedSet == null && sets) {
            this.setState({ selectedSet: providedSet || sets[0] });
        }

        if (
            prevState.selectedSet !== selectedSet ||
            (!selectedLanguage && prevProps !== this.props)
        ) {
            const { languagesMap } = this.props.languages;
            const languages = Array.from(languagesMap.values()) as language[];
            const possibleLanguages = languages.filter(
                (language: language) => language.set == selectedSet!._id
            );
            this.setState({ possibleLanguages });

            if (possibleLanguages) {
                this.setState({ selectedLanguage: possibleLanguages[0] });
            }
        }
    }

    handleReportClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const getLanguage = () => {
            const { languagesMap } = this.props.languages;
            return Array.from(languagesMap.values())[0] as language;
        };

        const {
            selectedSet,
            selectedLanguage,
            from,
            to: toInput,
            token,
            countryCode = [],
            paymentMethod = [],
            adAccount: account
        } = this.state;

        const toDate = new Date(toInput);
        const to = this.getTodaysDate(toDate);

        const setId = (selectedSet || this.props.sets.sets[0])._id;

        const query: reportQuery = {
            selectedSet: setId,
            selectedLanguage: (selectedLanguage || getLanguage())._id,
            from,
            to,
            countryCode,
            paymentMethod
        };

        this.props.getReport(query);

        const { accounts } = this.props;
        let firstAccountId;
        if (accounts.length > 0) firstAccountId = accounts[0].id;
        const adAccount = account || firstAccountId;
        if (token && adAccount) {
            this.props.getSpend(from, to, adAccount, token);
        }

        this.props.getElements(setId);
        this.props.getBricks(setId);
    }

    handleSelectSet(
        selectedOption: SelectOption,
        config: ActionMeta<{ name: string; action: string }>
    ) {
        if (!selectedOption) {
            this.setState(state => ({ ...state, selectedSet: undefined, possibleLanguages: [] }));
        }

        const { value: selectedSetId } = selectedOption;
        const { sets } = this.props.sets;
        const selectedSet = sets.find((set: set) => set._id == selectedSetId);

        const { languagesMap } = this.props.languages;
        const languages = Array.from(languagesMap.values()) as language[];
        const selectedLanguage = languages.filter(
            (language: language) => language.set == selectedSet._id
        )[0];

        this.setState({ selectedSet, selectedLanguage });
    }

    handleSelectLanguage(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: selectedLanguageId } = e.target as HTMLInputElement;
        const { languagesMap } = this.props.languages;
        const languages = Array.from(languagesMap.values()) as language[];
        const selectedLanguage = languages.find(
            (language: language) => language._id == selectedLanguageId
        ) as language;
        this.setState({ selectedLanguage });
    }

    handleFromDate(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: from } = e.target as HTMLInputElement;
        this.setState({ from });
    }

    handleToDate(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value: to } = e.target as HTMLInputElement;
        this.setState({ to });
    }

    handleSelectFilter(
        selectedOption: SelectOption[],
        config: ActionMeta<{ name: string; action: string }>
    ) {
        let value: undefined | string[];

        if (selectedOption && selectedOption.length) {
            value = selectedOption.map(opt => opt.value);
        }

        this.setState(state => ({
            ...state,
            [config.name as string]: value
        }));
    }

    getVariantValue(value: string | number | boolean) {
        if (typeof value === "boolean") {
            if (value === true) {
                return "ON";
            } else {
                return "OFF";
            }
        }

        return value;
    }

    render() {
        const { elements: elementsInput } = this.props.elements;
        const { report, isLoading } = this.props.analytics;
        const { sets } = this.props.sets;
        const { from, to, selectedSet } = this.state;

        let colored: any = [];

        const { bricksMap } = this.props.bricks;
        const bricks = Array.from(bricksMap.values());

        if (report.hasOwnProperty("variants")) {
            report.variants.map((variant: any, index: number) => {
                const slices = String(variant.value).split(",");

                if (slices.length > 1) {
                    const ids: string[] = [];

                    slices.forEach((elementId: string) => {
                        if (elementId.match(/^[0-9a-fA-F]{24}$/)) {
                            const parent = elementsInput.find(
                                (element: element) => element._id == elementId
                            ) as element;
                            if (parent != undefined) {
                                ids.push(parent.name);
                            } else {
                                report.variants[index].value =
                                    "*** Elements array order do not exist anymore! ***";
                            }
                        }
                    });

                    if (ids.length > 0) {
                        report.variants[index].value = ids.join(" -> ");
                    }
                }
            });
        }

        const groupedBricks = _.groupBy(report.variants, "brick");

        Object.keys(groupedBricks).forEach((key, index) => {
            const group = groupedBricks[key];
            if (group.length > 1)
                colored.push(...group.map((el: object) => ({ ...el, color: index % 2 })));
        });

        if (bricks.length > 0) {
            const foundBricks: string[] = [];
            colored.forEach((variant: any) => {
                if (!foundBricks.includes(variant.brick)) {
                    const brick = bricks.find(
                        (brick: brick) => brick._id == variant.brick
                    ) as brick;

                    if (brick != undefined) {
                        const element = elementsInput.find(
                            (element: element) => element._id == brick.element
                        ) as element;

                        const parentElement = elementsInput.find(
                            (element: element) => element._id == element.parent
                        ) as element;

                        if (parentElement && parentElement.name) {
                            variant.parentName = parentElement.name;
                        }

                        variant.name = element ? element.name : "";
                        foundBricks.push(variant.brick);
                    } else {
                        const el = elementsInput.find(
                            (element: element) => element._id == variant.value
                        );

                        if (el != undefined) {
                            variant.value = "CHOOSE ONE: " + el.name;
                        }
                    }
                }
            });
        }

        const { checkoutTotals, dynamicTotals } = report;

        let totals: any[] | null = null;

        if (checkoutTotals != null) {
            const standardizeUpsells = {
                sales: checkoutTotals.upsellSales,
                views: checkoutTotals.upsellViews,
                revenue: checkoutTotals.upsellRevenue,
                productsCost: checkoutTotals.upsellProductsCost,
                partialSales: 0
            };
            const standardizeCheckout = {
                sales: checkoutTotals.sales,
                views: checkoutTotals.views,
                revenue: checkoutTotals.revenue,
                productsCost: checkoutTotals.productsCost,
                partialSales: checkoutTotals.partialSales
            };
            const cartTotals = _.mergeWith({}, standardizeUpsells, standardizeCheckout, _.add);
            totals = [
                {
                    ...checkoutTotals,
                    type: "Checkout Totals"
                },
                {
                    ...standardizeUpsells,
                    type: "Upsell Totals"
                },
                {
                    ...cartTotals,
                    type: "Cart Totals"
                }
            ];
        }

        return (
            <>
                <div className={`${styles.panel_wide}`}>
                    <div className={`${styles.form_wide}`}>
                        <div className={styles.input_group}>
                            <label>Set:</label>
                            <Select
                                options={sets.map((el: set) => ({
                                    value: el._id,
                                    label: el.name
                                }))}
                                components={animatedComponents}
                                name="selectedSet"
                                placeholder="Select ContentSet"
                                onChange={this.handleSelectSet.bind(this)}
                                value={
                                    selectedSet
                                        ? { value: selectedSet._id, label: selectedSet.name }
                                        : null
                                }
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        position: "absolute",
                                        background: "#f5f5f5",
                                        marginTop: "2px",
                                        zIndex: 9999,
                                        minWidth: "500px"
                                    }),
                                    container: () => ({
                                        width: "100%",
                                        minWidth: "500px"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>Version:</label>
                            <select onChange={this.handleSelectLanguage.bind(this)}>
                                {this.state.possibleLanguages.map(
                                    (language: language, index: number) => (
                                        <option key={index} value={language._id}>
                                            {language.name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <div className={styles.input_group}>
                            <label>From:</label>
                            <input
                                type="date"
                                value={from}
                                onChange={this.handleFromDate.bind(this)}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>To:</label>
                            <input type="date" value={to} onChange={this.handleToDate.bind(this)} />
                        </div>
                    </div>
                    <div className={`${styles.form_wide}`}>
                        {Object.entries(this.props.filters).map(
                            ([filter, values]: [string, []]) => {
                                if (values.length) {
                                    const selectOptions = values.map((el: string) => {
                                        if (filter === "countryCode") {
                                            const countryCode = el.toUpperCase();
                                            const fullCountryName = isoCountries[countryCode];

                                            return {
                                                value: countryCode,
                                                label: `${countryCode} (${fullCountryName})`
                                            };
                                        }

                                        return {
                                            value: el,
                                            label: el
                                        };
                                    });

                                    return (
                                        <div className={styles.input_group} key={filter}>
                                            <label>{_.startCase(filter)}:</label>

                                            <Select
                                                options={selectOptions}
                                                components={animatedComponents}
                                                name={filter}
                                                placeholder={`All ${filter}`}
                                                onChange={this.handleSelectFilter.bind(this)}
                                                defaultValue={[]}
                                                isClearable
                                                isSearchable
                                                isMulti
                                                styles={{
                                                    menu: () => ({
                                                        border: "1px solid gray",
                                                        borderRadius: "4px",
                                                        overflowX: "hidden",
                                                        position: "absolute",
                                                        background: "#f5f5f5",
                                                        marginTop: "2px",
                                                        minWidth: "500px"
                                                    }),
                                                    container: () => ({
                                                        width: "100%",
                                                        minWidth: "500px"
                                                    })
                                                }}
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                    </div>
                    <div className={`${styles.form_wide}`}>
                        {isLoading ? (
                            <div className={styles.input_group}>
                                <LoadingIndicator className={styles.loader} />
                            </div>
                        ) : (
                            <div className={styles.input_group}>
                                <button
                                    onClick={this.handleReportClick.bind(this)}
                                    className={styles.primary}
                                >
                                    REPORT
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {totals != undefined ? (
                    <div className={styles.panel_wide} style={{ overflowX: "scroll" }}>
                        <h2>Totals:</h2>
                        <table>
                            <thead>
                                <tr>
                                    <td>Type:</td>
                                    <td>Views:</td>
                                    <td>Conversions:</td>
                                    <td>CVR:</td>
                                    <td>Revenue:</td>
                                    <td>EPV:</td>
                                    <td>AOV:</td>
                                    <td>Spend:</td>
                                    <td>Profit:</td>
                                    <td>Partial Sales:</td>
                                    <td>SMS Consent Rate:</td>
                                </tr>
                            </thead>
                            <tbody>
                                {totals.map(total => (
                                    <tr key={total.type}>
                                        <td>{total.type}</td>
                                        <td>{total.views}</td>
                                        <td>{total.sales}</td>
                                        <td>{getPercentage(total.sales / total.views)}</td>
                                        <td>{getDollars(total.revenue)}</td>
                                        <td>{getDollars(total.revenue / total.views)}</td>
                                        <td>{getDollars(total.revenue / total.sales)}</td>
                                        <td>
                                            {total.type != "Upsell Totals"
                                                ? getDollars(total.productsCost)
                                                : getDollars(total.productsCost)}
                                        </td>
                                        <td>
                                            {total.type != "Upsell Totals"
                                                ? getDollars(total.revenue - total.productsCost)
                                                : getDollars(total.revenue - total.productsCost)}
                                        </td>
                                        <td>{total.partialSales}</td>
                                        <td>{getPercentage(total.contactOptIns / total.views)}</td>
                                        <td>{getPercentage(total.smsConsents / total.views)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}
                {dynamicTotals
                    ? Object.entries(dynamicTotals).map(([name, value]: dynamicTotal) => {
                          if (value.length > 1) {
                              return (
                                  <React.Fragment key={name}>
                                      <div
                                          className={styles.panel_wide}
                                          style={{
                                              overflowX: "scroll"
                                          }}
                                      >
                                          <h2>
                                              {_.startCase(name.replace("Totals", ""))}
                                              s:
                                          </h2>
                                          <TotalsTable
                                              name={name}
                                              data={_.sortBy(value, "views").reverse()}
                                          />
                                      </div>
                                  </React.Fragment>
                              );
                          } else {
                              return null;
                          }
                      })
                    : null}

                {colored.length > 0 ? (
                    <div className={styles.panel_wide} style={{ overflowX: "scroll" }}>
                        <h2>Variants:</h2>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name:</td>
                                    <td>Variant:</td>
                                    <td>Checkout Views:</td>
                                    <td>Checkout Conversions:</td>
                                    <td>Checkout CVR:</td>
                                    <td>Checkout Revenue:</td>
                                    <td>Checkout EPV</td>
                                    <td>Checkout AOV:</td>
                                    <td>Upsells Views:</td>
                                    <td>Upsells Conversions:</td>
                                    <td>Upsells CVR:</td>
                                    <td>Upsells Revenue:</td>
                                    <td>Upsells EPV</td>
                                    <td>Upsells AOV:</td>
                                    <td>Total Upgrades:</td>
                                    <td>Upgrade TR:</td>
                                    <td>Partials:</td>
                                    <td>SMS Consent Rate:</td>
                                </tr>
                            </thead>
                            <tbody>
                                {colored.map((row: any, index: number) => (
                                    <tr key={index} className={row.color == 1 ? styles.gray : null}>
                                        {row.name == undefined ? (
                                            <td
                                                style={{
                                                    border: "none",
                                                    backgroundColor: "white"
                                                }}
                                            />
                                        ) : (
                                            <td
                                                className={`${styles.fatCell} ${
                                                    index === 0 ? styles.first : null
                                                }`}
                                            >
                                                {row.name}
                                            </td>
                                        )}
                                        <td
                                            style={{ minWidth: "300px" }}
                                            className={isFile(row.value) ? styles.fileVariant : ""}
                                        >
                                            {isFile(row.value) ? (
                                                <File
                                                    file={getFileObject(row.value)}
                                                    fileView="half"
                                                />
                                            ) : (
                                                this.getVariantValue(row.value)
                                            )}{" "}
                                        </td>
                                        <td>{row.views}</td>
                                        <td>{row.sales}</td>
                                        <td>{getPercentage(row.sales / row.views)}</td>
                                        <td>{getDollars(row.revenue)}</td>
                                        <td>{getDollars(row.revenue / row.views)}</td>
                                        <td>{getDollars(row.revenue / row.sales)}</td>
                                        <td>{row.upsellViews}</td>
                                        <td>{Math.floor(row.upsellSales)}</td>
                                        <td>{getPercentage(row.upsellSales / row.upsellViews)}</td>
                                        <td>{getDollars(row.upsellRevenue)}</td>
                                        <td>{getDollars(row.upsellRevenue / row.upsellViews)}</td>
                                        <td>{getDollars(row.upsellRevenue / row.upsellSales)}</td>
                                        <td>{row.totalUpgrades}</td>
                                        <td>{getPercentage(row.totalUpgrades / row.sales)}</td>
                                        <td>{row.partialSales}</td>
                                        <td>{getPercentage(row.contactOptIns / row.views)}</td>
                                        <td>{getPercentage(row.smsConsents / row.views)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    elements: state.elements,
    sets: state.sets,
    languages: state.languages,
    analytics: state.analytics,
    filters: state.analytics.filters,
    bricks: state.bricks,
    spend: state.spend
});

export default connect(mapStateToProps, { ...actions, ...dashboardActions })(CMSAnalytics);
