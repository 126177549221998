import * as React from "react";
import { sortBy, get } from "lodash";
import cx from "classnames";
import styles from "./styles.scss";

class CampaignKeys extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            country: "",
            key: "",
            table: {
                countries: ["US"],
                keys: ["default"]
            }
        };
    }

    componentDidMount() {
        this.setCampaignKeys();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.campaignKeys !== prevProps.campaignKeys) {
            this.setCampaignKeys();
        }
    }

    setCampaignKeys() {
        let { campaignKeys } = this.props;
        if (campaignKeys) {
            const mergeObjects = (prev: any, next: any) => ({
                ...prev,
                ...next
            });
            const campaigns: any[] = Object.values(campaignKeys);
            const countries = Object.keys(campaigns.reduce(mergeObjects, {}));
            this.setState({
                table: {
                    countries,
                    keys: this.sortKeys(Object.keys(campaignKeys))
                }
            });
        }
    }

    sortKeys(array: string[]) {
        return sortBy(array, (item: any) => {
            if (item == "default") return 1;
            return -1;
        });
    }

    handleOnChange(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value, name } = e.target as HTMLInputElement;
        this.setState({ [name]: value });
    }

    render() {
        const { handleCampaignKeyChange } = this.props;
        const { table } = this.state;

        const tableClass = cx(styles.table, {
            [styles.plainData]: !handleCampaignKeyChange,
            [styles.panel]: !!handleCampaignKeyChange
        });

        return (
            <div className={tableClass}>
                <header className={styles.header}>
                    <h2>Campaign Keys</h2>
                </header>
                <div className={styles.tableWrapper}>
                    <table>
                        <tbody>
                            <tr className={styles.countries}>
                                <th />
                                {table.countries.map((country: string) => (
                                    <th key={country}>{country}</th>
                                ))}
                            </tr>
                            {table.keys.map((key: string) => {
                                let length = table.countries.length;

                                if (key === "default") {
                                    length = 1;
                                }

                                return (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        {Array(length)
                                            .fill("")
                                            .map((key2, index) => {
                                                const value = get(
                                                    this.props,
                                                    ["campaignKeys", key, table.countries[index]],
                                                    ""
                                                );

                                                return (
                                                    <td key={index}>
                                                        {handleCampaignKeyChange ? (
                                                            <input
                                                                type="number"
                                                                onChange={handleCampaignKeyChange.bind(
                                                                    this,
                                                                    key,
                                                                    table.countries[index]
                                                                )}
                                                                value={value}
                                                                disabled={key !== "default"}
                                                            />
                                                        ) : (
                                                            value
                                                        )}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default CampaignKeys;
