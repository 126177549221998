import * as React from "react";
import styles from "./styles.scss";

type Props = {
    link: string;
    withIcons: boolean;
};

const linkTitles = {
    pricing: "Pricing Engine",
    hotdeployments: "🔥Hot Deployments",
    sdklive: "SDK Live",
    newUpsells: "Upsells",
    variantsConfig: "Product Variants"
};

const linkIcon = {
    newUpsells: "upsells"
};

const IconLink: React.FC<Props> = ({ link, withIcons }) => {
    const title = linkTitles[link] || link;
    const icon = linkIcon[link] || link;

    return (
        <>
            {withIcons && <img src={`/img/svg/${icon}.svg`} />}
            <span className={styles.capitalize}>{title}</span>
        </>
    );
};

export default IconLink;
