import logo from "assets/logo.svg";
import * as React from "react";
import { connect } from "react-redux";
import { NavLink as Link, withRouter } from "react-router-dom";
import { appStateType } from "reducers";
import * as actions from "./actions";
import Bookmark from "./components/Bookmark";
import styles from "./styles.scss";
import Guardian from "services/Guardian";
import cx from "classnames";
import logout from "assets/icons/menu/logout.svg";

class Menu extends React.Component<any, any> {
    isButtonActive(url: string, exact: boolean) {
        const path = window.location.pathname;
        return exact ? path === url : path.includes(url);
    }

    render() {
        const { isButtonActive } = this;
        const { list } = this.props;

        return list.map((option: any, index: number) => (
            <React.Fragment key={`${index}`}>
                <Link
                    isActive={isButtonActive.bind(this, option.to, option.exact)}
                    to={option.to}
                    className={styles.option}
                    activeClassName={styles.option_active}
                >
                    <img src={option.icon} alt={option.label} />
                    {option.label}
                </Link>
                {option.hasOwnProperty("menu") && this.isButtonActive(option.to, false) ? (
                    <nav>
                        {option.menu!.map((option2: any, index2: number) => (
                            <Bookmark
                                isOpen={this.isButtonActive(option.to, false)}
                                isActive={this.isButtonActive(option2.to, option2.exact)}
                                key={`${index2}-bookmark`}
                            >
                                <Link to={option2.to}>{option2.label}</Link>
                            </Bookmark>
                        ))}
                    </nav>
                ) : null}
            </React.Fragment>
        ));
    }
}

class SideMenu extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
        this.signOutUser = this.signOutUser.bind(this);
    }

    signOutUser() {
        this.props.logout();
    }

    render() {
        const { signOutUser } = this;
        const {
            auth: { user },
            ui: { isSideMenuHidden },
            switchSideMenu
        } = this.props;
        const authenticatedMenu = Guardian.getAuthenticatedMenu();

        return (
            <div className={cx(styles.sideMenu, { [styles.hidden]: isSideMenuHidden })}>
                <main>
                    <div className={styles.maxw}>
                        <img className={styles.logo} src={logo} alt="Ejam.com logo" />
                        <nav>
                            <Menu list={authenticatedMenu} />
                        </nav>
                        <div className={styles.userBox}>
                            <div className={styles.user}>User: {user.login}</div>
                            <div className={styles.logout} onClick={signOutUser}>
                                <img src={logout} alt="logout" />
                                LOGOUT
                            </div>
                        </div>
                    </div>
                </main>
                <div className={styles.hideBox} onClick={switchSideMenu}>
                    <div className={styles.line}></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    ...state
});

export default withRouter(connect(mapStateToProps, actions)(SideMenu));
