import * as React from "react";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { getMatchedSets, replaceProductIds } from "./actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { set } from "types/set";

const animatedComponents = makeAnimated();
type SelectOption = {
    value: string;
    label: string;
};

const Session: React.FC = () => {
    const [products, setIds] = React.useState({ replaceIds: "", productIds: "" });
    const [isLoading, setLoading] = React.useState(false);
    const [matchedSets, setMatchedSets] = React.useState<SelectOption[]>([]);
    const [selectedSets, setSelectedSets] = React.useState<SelectOption[]>([]);
    const [status, setStatus] = React.useState<"" | "SEARCH_SET" | "REPLACE">("");
    const [isAllSelected, setAllSelected] = React.useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setIds({ ...products, [name]: value });
        if (status !== "") {
            setStatus("");
        }
    };

    const getSetLabel = (
        name: string,
        isAvailableInCms: boolean,
        isAvailableInPricing: boolean
    ) => {
        if (isAvailableInCms && isAvailableInPricing) {
            return `${name}(CMS, Pricing)`;
        }
        if (isAvailableInPricing) {
            return `${name}(Pricing)`;
        }
        return `${name}(CMS)`;
    };

    const findMatchedSets = () => {
        setLoading(true);
        getMatchedSets(products.productIds)
            .then(data => {
                const newMatchedSets = data.sets.map(
                    (el: {
                        set: set;
                        isAvailableInPricing: boolean;
                        isAvailableInCms: boolean;
                    }) => ({
                        value: el.set._id,
                        label: getSetLabel(
                            el.set.name,
                            el.isAvailableInCms,
                            el.isAvailableInPricing
                        )
                    })
                );
                setMatchedSets(newMatchedSets);
                setSelectedSets([]);
                setStatus("SEARCH_SET");
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const handleSelect = (selectedOptions: SelectOption[]) => {
        const options = selectedOptions || [];
        setSelectedSets(options);
    };

    const selectAllSets = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setAllSelected(checked);

        // if (checked) {
        //     setSelectedSets(matchedSets);
        // } else {
        //     setSelectedSets([]);
        // }
    };

    const handleReplace = () => {
        const { productIds, replaceIds } = products;

        if (status !== "SEARCH_SET") {
            console.log("PLEASE SEARCH SETS FIRST AND THEN SUBMIT");
            return;
        }

        if (!selectedSets.length && !isAllSelected) {
            console.log("PLEASE SELECT SOME SETS FIRST");
            return;
        }

        setLoading(true);

        const replacedSets = isAllSelected ? matchedSets : selectedSets;

        const selectedSetIds = replacedSets.map(el => el.value);

        setStatus("REPLACE");
        replaceProductIds(productIds, replaceIds, selectedSetIds)
            .then(data => {
                setLoading(false);
                setIds({
                    productIds: "",
                    replaceIds: ""
                });
                setMatchedSets([]);
                setSelectedSets([]);
            })
            .catch(err => {
                setStatus("SEARCH_SET");
                setLoading(false);
            });
    };

    if (isLoading) {
        return (
            <div className={styles.panel}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            <div className={styles.panel}>
                <div className={styles.row}>
                    <p>Add Product Ids:</p>
                    <input
                        name="productIds"
                        className={styles.input}
                        placeholder="add product ids which needs to be replaced"
                        onChange={handleChange}
                        value={products.productIds}
                    />
                </div>
                <div className={styles.row}>
                    <p>Add Replace Product Ids:</p>
                    <input
                        name="replaceIds"
                        className={styles.input}
                        placeholder="add replace product ids "
                        onChange={handleChange}
                        value={products.replaceIds}
                    />
                </div>
                <Button marginTop onClick={findMatchedSets}>
                    Search Sets
                </Button>

                {status === "SEARCH_SET" ? (
                    <div>
                        {matchedSets.length ? (
                            <div>
                                <label className={styles.label}>
                                    Select All Matched Sets
                                    <input
                                        type="checkbox"
                                        onChange={selectAllSets}
                                        checked={isAllSelected}
                                        className={styles.checkbox}
                                    ></input>
                                </label>
                                {!isAllSelected ? (
                                    <Select
                                        options={matchedSets}
                                        components={animatedComponents}
                                        isMulti
                                        name="selectedSets"
                                        placeholder="Select Sets"
                                        onChange={handleSelect}
                                        value={selectedSets}
                                    />
                                ) : null}
                                <Button marginTop onClick={handleReplace}>
                                    Replace Products
                                </Button>
                            </div>
                        ) : (
                            <div>0 sets found with given productIds</div>
                        )}
                    </div>
                ) : null}
                {status === "REPLACE" ? (
                    <div>
                        Product Id Replacement started. please check selected sets after some time
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Session;
