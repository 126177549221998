import * as React from "react";
import File from "../File";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { Fragment } from "react";
import { Pagination } from "components/Pagination";
import _ from "lodash";

interface Props {
    fileView: "half" | "wide";
    files?: any[];
    isPullingFiles: boolean;
    handleChooseFile?: (file: any) => void;
}

const FILES_PER_PAGE = 168;

class Files extends React.PureComponent<Props, any> {
    constructor(props: Props) {
        super(props);

        this.state = {
            search: "",
            file: {},
            pageFiles: []
        };
    }

    componentDidUpdate(prevProps: any) {
        const { files = [] } = this.props;
        const { files: prevFiles = [] } = prevProps;

        if (files.length !== prevFiles.length) {
            if (files && this.state.file) {
                const updatedFile = files.find((file: any) => file.hash == this.state.file.hash);
                this.setState({ file: updatedFile });
            }
        }
    }

    onPageChange = (page: number, pageFiles: unknown[]) => {
        this.setState({ pageFiles });
    };

    render() {
        const { files, fileView, isPullingFiles, handleChooseFile } = this.props;
        const { pageFiles } = this.state;

        return (
            <Fragment>
                {!_.isEmpty(pageFiles) || !isPullingFiles ? (
                    <div>
                        <div className={styles.grid}>
                            {pageFiles &&
                                pageFiles.map((file: any) => {
                                    return (
                                        <File
                                            file={file}
                                            fileView={fileView}
                                            handleChooseFile={handleChooseFile}
                                            key={file.hash}
                                        />
                                    );
                                })}
                        </div>
                        <div className={styles.pagination}>
                            <Pagination
                                onPageChange={this.onPageChange}
                                items={files || []}
                                itemsPerPage={FILES_PER_PAGE}
                            />
                        </div>
                    </div>
                ) : (
                    <LoadingIndicator />
                )}
            </Fragment>
        );
    }
}

export default Files;
