import * as React from "react";
import Button from "components/Button";
import browserHistory from "browserHistory";
import styles from "./styles.scss";
import { addUpsell } from "../Flow/components/Upsells/components/UpsellContainer/actions";
import { appStateType } from "reducers";
import { upsellsFlow } from "types/upsellsFlow";
import { useDispatch, useSelector } from "react-redux";

interface Params {
    elementId: string;
    languageId: string;
    setId: string;
    upsellContainerId: string;
    upsellsFlowId: string;
    versionId: string;
}

type Props = {
    elements: any[];
    nodeRender?: (index: string) => void;
    match: {
        params: Params;
    };
    location: any;
};

const UpsellEditor: React.FC<Props> = ({ match, elements, nodeRender, location }) => {
    const { state: { backlink } = { backlink: "/" } } = location;
    const [editMode, setEditMode] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const {
        params: { upsellsFlowId, elementId, upsellContainerId }
    } = match;
    const upsellReducer = useSelector((state: appStateType) => state.upsell);
    const { upsellContainer, upsellsFlow } = upsellReducer;

    const flow = upsellsFlow.find((upsellsFlow: upsellsFlow) => upsellsFlow._id === upsellsFlowId);
    const containers = upsellContainer.filter(
        upsellContainer => flow && flow.upsells.includes(upsellContainer._id)
    );

    const upsell = containers
        .flatMap(container => container.variants)
        .find(variant => variant._id === elementId);

    React.useEffect(() => {
        if (upsell) {
            setTitle(upsell.title);
        }
    }, [upsell]);

    const dispatch = useDispatch();

    const handleClick = () => {
        if (editMode && upsell) {
            setEditMode(false);

            dispatch(
                addUpsell({
                    _id: upsell._id,
                    title,
                    upsellContainerId
                })
            );
        } else {
            setEditMode(true);
        }
    };

    const handleClickBack = () => {
        if (backlink) {
            browserHistory.push(backlink);
        }
    };

    if (upsell) {
        const index = elements.findIndex(el => el._elementId === upsell.elementId);

        return (
            <div className={styles.main}>
                <>
                    <header className={styles.header}>
                        <div className={styles.headlineWrapper}>
                            {editMode ? (
                                <input value={title} onChange={e => setTitle(e.target.value)} />
                            ) : (
                                <h2 className={styles.headline}>{title}</h2>
                            )}
                            <Button
                                onClick={handleClick}
                                design="icon"
                                icon="editName"
                                marginLeft
                            />
                        </div>
                        <div>
                            {backlink && (
                                <Button onClick={handleClickBack} flat marginRight>
                                    Back
                                </Button>
                            )}
                        </div>
                    </header>
                    {nodeRender && index > -1 ? (
                        nodeRender(String(index))
                    ) : (
                        <div className={styles.alert}>Upsell element not found.</div>
                    )}
                </>
            </div>
        );
    } else {
        return null;
    }
};

export default UpsellEditor;
