import * as React from "react";
import styles from "./styles.scss";

type Props = {
    name: string;
    description: string;
    children: React.ReactChild;
    forceShow?: boolean;
    isGroup?: boolean;
    isArray?: boolean;
    isHigh?: boolean;
    isParent?: boolean;
};

const Headline: React.FC<Props> = ({
    name,
    description = "",
    children,
    forceShow = false,
    isGroup = false,
    isArray = false,
    isHigh = false,
    isParent = false
}) => {
    const isNoName = name === "" || forceShow;

    return (
        <div className={(isGroup ? styles.container : "") + (isParent ? " " + styles.parent : "")}>
            {(!isNoName || forceShow) && (
                <h4
                    className={
                        (isGroup ? styles.group : styles.headline) +
                        (isHigh ? " " + styles.high : "")
                    }
                >
                    {!isGroup ? (
                        <div
                            className={`${styles.name} ${
                                description !== "" && styles.withDescription
                            }`}
                        >
                            {name}
                            {description !== "" && (
                                <div className={styles.description}>{description}</div>
                            )}
                        </div>
                    ) : (
                        <div className={styles.name}>{name}</div>
                    )}
                </h4>
            )}
            <div
                className={isGroup ? styles.body : isArray ? styles.nestedArray : styles.nested}
                style={{ display: "block" }}
            >
                {children}
            </div>
        </div>
    );
};

export default Headline;
