import LoadingIndicator from "components/LoadingIndicator";
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { appStateType } from "reducers";
import getSchemaByKey from "services/getSchemaByKey";
import * as actions from "../../actions";
import ArrayEditor from "../../scenes/ArrayEditor";
import FlatEditor from "../../scenes/FlatEditor";
import ObjectEditor from "../../scenes/ObjectEditor";
import UpsellsEditor from "../../scenes/UpsellsEditor";
import BundleContentEditor from "../../scenes/BundleContentEditor";
import FlavourContentEditor from "../../scenes/FlavourContentEditor";
import ProductContentEditor from "../../scenes/ProductContentEditor";
import { getSetDetails } from "services/getSetDetails";
import { set } from "types/set";
import { Version } from "types/version";

type node = {
    _type: string;
    _static?: boolean;
    _file?: boolean;
    _display?: string;
    _description?: string;
    enum?: any[];
};

export type schemaType = {
    [key: string]: node | schemaType;
};

export type nodeRenderInput = {
    key: string;
    noName?: boolean;
    node: node | schemaType;
    path?: string;
    isChildren?: boolean;
};

type Props = {
    createArrayNode: any;
    createTreeNode: any;
    deleteElement: any;
    deleteVariant: any;
    elements: any;
    isNarrow?: boolean;
    languageId: string;
    match: any;
    saveVariant: any;
    schemaKey: string;
    setCurrentSchema: (versionId: string) => void;
    setId: string;
    setSchemaKey: any;
    singleArrayElement?: string;
    validateContent: any;
    versionId: string;
    version: Version;
};

class NodeComponent extends React.PureComponent<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        const { schemaKey } = this.props;

        if (schemaKey) {
            this.props.setSchemaKey(schemaKey);
        }
    }

    componentDidUpdate(prevProps: any) {
        const { schemaKey } = this.props;

        if (prevProps.schemaKey !== schemaKey) {
            this.props.setSchemaKey(schemaKey);
        }
    }

    nodeComponent(schema: node | schemaType, path: string = "") {
        const output: any = [];

        Object.entries(schema).map(([key, node]) => {
            output.push(this.nodeRender({ key, node, path }));
        });

        return output;
    }

    nodeRender({ key, node, path = "", noName = false, isChildren = false }: nodeRenderInput) {
        if (node._file) {
            node._type = node._type === "OBJECT[]" || node._type === "FILE[]" ? "FILE[]" : "FILE";
        }
        path += "." + key;
        if (path[0] === ".") path = path.slice(1);

        const { isNarrow, languageId, schemaKey, setId, versionId, match, version } = this.props;
        const setDetail: { crm: set["crm"] } = getSetDetails(setId);

        const { currentInvalidPaths } = this.props.elements;
        const schema =
            version && version._id === versionId && version._schema ? version._schema : {};

        if (node.hasOwnProperty("_type")) {
            const { elementsTree } = this.props.elements;
            let elementsNode = _.get(elementsTree, path);
            let add = this.props.saveVariant;

            if (elementsNode == undefined) {
                elementsNode = {
                    _id: "NOT_EXIST",
                    variants: [],
                    _type: (node._type as string).includes("[]") ? "ARRAY" : node._type
                };

                type AddProps = {
                    value: string | number | boolean;
                };

                add = ({ value }: AddProps) =>
                    this.props.createTreeNode({
                        schema,
                        setId,
                        languageId,
                        path,
                        value
                    });
            }

            let name = (node._display || key) as string;
            if (noName) name = "";
            const remove = this.props.deleteVariant;

            const addArrayElement = (path: string, e: React.SyntheticEvent) => {
                if (e) e.preventDefault();
                this.props.createArrayNode(path);
            };

            const forceInvalid = currentInvalidPaths.includes(path);
            const isUpsell = ["upsells", "newUpsells"].includes(schemaKey);

            switch (node._type) {
                case "TEXT":
                case "NUMBER":
                case "SWITCH":
                case "FILE":
                case "KONNEKTIVE_PRODUCT":
                case "ODOO_PRODUCT":
                    if (Array.isArray(elementsNode)) {
                        elementsNode = elementsNode[0];
                    }

                    return (
                        <FlatEditor
                            key={`text-editor-${key}`}
                            name={name}
                            variants={elementsNode.variants}
                            multivariant={!node._static}
                            brickId={elementsNode._id}
                            enums={node.enum as any[]}
                            add={add}
                            remove={remove}
                            path={path}
                            type={node._type}
                            description={node._description as string}
                            crm={setDetail.crm}
                            isChildren={isChildren}
                            isUpsell={isUpsell}
                        />
                    );
                case "UPSELLS":
                    return (
                        <UpsellsEditor
                            elements={Array.isArray(elementsNode) ? elementsNode : [elementsNode]}
                            key={`upsell-editor-${key}`}
                            languageId={languageId}
                            nodeRender={(index: string) => {
                                const newNode = this.nodeRender({
                                    key: index,
                                    node: {
                                        ...node,
                                        _type: "OBJECT"
                                    },
                                    path,
                                    noName: true
                                });

                                return newNode;
                            }}
                            setId={setId}
                            crm={setDetail.crm}
                            schemaKey={schemaKey}
                            versionId={versionId}
                        />
                    );
                case "FLAVOUR_PRODUCTS":
                    return (
                        <FlavourContentEditor
                            elements={Array.isArray(elementsNode) ? elementsNode : [elementsNode]}
                            key={`flavour-products-editor-${key}`}
                            languageId={languageId}
                            nodeRender={(index: string) => {
                                const newNode = this.nodeRender({
                                    key: index,
                                    node: {
                                        ...node,
                                        _type: "OBJECT"
                                    },
                                    path,
                                    noName: true
                                });

                                return newNode;
                            }}
                            setId={setId}
                            crm={setDetail.crm}
                            schemaKey={schemaKey}
                            versionId={versionId}
                            elementId={match.params.elementId}
                        />
                    );
                case "BUNDLES":
                    return (
                        <BundleContentEditor
                            elements={Array.isArray(elementsNode) ? elementsNode : [elementsNode]}
                            key={`bundles-editor-${key}`}
                            languageId={languageId}
                            nodeRender={(index: string) => {
                                const newNode = this.nodeRender({
                                    key: index,
                                    node: {
                                        ...node,
                                        _type: "OBJECT"
                                    },
                                    path,
                                    noName: true
                                });

                                return newNode;
                            }}
                            setId={setId}
                            crm={setDetail.crm}
                            schemaKey={schemaKey}
                            versionId={versionId}
                            elementId={match.params.elementId}
                        />
                    );
                case "PRODUCTS":
                    return (
                        <ProductContentEditor
                            elements={Array.isArray(elementsNode) ? elementsNode : [elementsNode]}
                            key={`products-editor-${key}`}
                            languageId={languageId}
                            nodeRender={(index: string) => {
                                const newNode = this.nodeRender({
                                    key: index,
                                    node: {
                                        ...node,
                                        _type: "OBJECT"
                                    },
                                    path,
                                    noName: true
                                });

                                return newNode;
                            }}
                            setId={setId}
                            crm={setDetail.crm}
                            schemaKey={schemaKey}
                            versionId={versionId}
                            elementId={match.params.elementId}
                        />
                    );
                case "TEXT[]":
                case "NUMBER[]":
                case "SWITCH[]":
                case "FILE[]":
                case "OBJECT[]":
                    return (
                        <ArrayEditor
                            key={`array-editor-${key}`}
                            name={name}
                            node={node[key]}
                            elements={Array.isArray(elementsNode) ? elementsNode : [elementsNode]}
                            nodeRender={(index: string) => {
                                const newNode = this.nodeRender({
                                    key: index,
                                    node: {
                                        ...node,
                                        _type: (node._type as string).replace("[]", "")
                                    },
                                    path,
                                    noName: true,
                                    isChildren: true
                                });

                                return newNode;
                            }}
                            addArrayElement={addArrayElement.bind(null, path)}
                            removeArrayElement={this.props.deleteElement}
                            path={path}
                            forceInvalid={forceInvalid}
                            description={node._description as string}
                            isNarrow={isNarrow}
                        />
                    );
                case "OBJECT":
                    return (
                        <ObjectEditor
                            description={node._description as string}
                            isNarrow={isNarrow}
                            key={`object-editor-${key}`}
                            name={name}
                            path={path}
                            isChildren={isChildren}
                        >
                            {this.nodeComponent(node, path)}
                        </ObjectEditor>
                    );
                default:
                    return null;
            }
        } else return null;
    }

    render() {
        const { schemaKey, version } = this.props;
        const schema = version && version._schema ? version._schema : {};
        const renderConditional = Object.keys(schema).length > 0;

        if (renderConditional) {
            const currentSchema = schemaKey ? getSchemaByKey(schema, schemaKey) : schema;
            return this.nodeComponent(currentSchema);
        } else {
            return <LoadingIndicator />;
        }
    }
}

const mapStateToProps = (state: appStateType) => ({
    elements: state.elements,
    version: state.deployment.version
});

export default withRouter(connect(mapStateToProps, { ...actions })(NodeComponent));
