import { upsell } from "types/upsell";
import store from "store";

export const defaultUpsellReportValues = {
    upsellViews: 0,
    upsellSales: 0,
    grossProfit: 0,
    revenue: 0,
    cog: 0,
    cos: 0
};

export const sumUpsellVariants = ({ _id, variants }: { _id: string; variants: upsell[] }) => {
    let result = { ...defaultUpsellReportValues };

    const state = store.getState();

    const { upsellReports } = state.upsell;

    variants.forEach(variant => {
        const { elementId } = variant;

        if (elementId in upsellReports) {
            const { cog, cos, grossProfit, revenue, upsellSales, upsellViews } =
                upsellReports[elementId].report;

            result.cog += cog;
            result.cos += cos;
            result.grossProfit += grossProfit;
            result.revenue += revenue;
            result.upsellSales += upsellSales;
            result.upsellViews += upsellViews;
        }
    });
    return { ...result, _id };
};
