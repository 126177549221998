import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { appStateType } from "reducers";
import { Action, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";

export function pullFiles(settings: any) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.START_PULL_FILES
        });

        api()
            .get("file", settings)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_FILES,
                    payload: { files: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ERROR_PULL_FILES,
                    payload: { error: err.response }
                });
            });
    };
}

function uploadFile(file: File) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPLOAD_FILE_START,
            payload: { file }
        });
        const data = new FormData();
        data.append("file", file);

        api()
            .post("file/upload", data, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.UPLOAD_FILE,
                    payload: { file: res.data.image }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPLOAD_FILE_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function uploadFiles(files: any[]) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        Array.from(files).forEach((file: File) => {
            dispatch(uploadFile(file));
        });
    };
}

export function saveVideo(video: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SAVE_VIDEO_START
        });

        api()
            .post("file/saveVideo", { video })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.SAVE_VIDEO,
                    payload: { video: res.data }
                })
            )
            .catch((err: AxiosError) => {
                const error = err.response ? err.response.data || "" : err.response;
                dispatch({
                    type: actionTypes.SAVE_VIDEO_ERROR,
                    payload: { error }
                });
            });
    };
}
