import * as React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import * as actions from "./actions";
import { appStateType } from "reducers";
import LoadingIndicator from "components/LoadingIndicator";
import getDollars from "services/getDollars";
import _ from "lodash";
import { Link } from "react-router-dom";
import * as moment from "moment-timezone";

class Orders extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.pullOrders();
    }

    createDate(date: Date) {
        return moment(date).fromNow();
    }

    render() {
        const { orders, isPullingOrders } = this.props.orders;
        const { filter } = this.props;
        const filteredOrders = [...orders].filter(
            (order: any) =>
                order.orderStatus.toUpperCase() === filter.toUpperCase()
        );
        const groupedOrders = _(filteredOrders)
            .orderBy((order: any) => order.updatedAt, "desc")
            .filter((order: any) => order.brand)
            .groupBy((order: any) => order.brand)
            .value();
        const filterName = _.startCase(_.toLower(filter));
        const isPendingOrComplete =
            filter === "PENDING" || filter === "COMPLETE";

        if (isPullingOrders) return <LoadingIndicator />;

        return (
            <>
                <header>
                    <h1 className={styles.h1}>{filterName} Orders:</h1>
                </header>
                <main>
                    {groupedOrders &&
                        Object.entries(groupedOrders).map(
                            ([key, order]: any, index) => {
                                return (
                                    <div
                                        className={styles.panel_wide}
                                        key={`${key}${index}`}
                                    >
                                        <h2>{key}:</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>Order:</td>
                                                    <td>Date:</td>
                                                    <td>Customer:</td>
                                                    {isPendingOrComplete && (
                                                        <td>Email:</td>
                                                    )}
                                                    {isPendingOrComplete && (
                                                        <td>Payment type:</td>
                                                    )}
                                                    {/* {isPendingOrComplete && <td>Payment status:</td>} */}
                                                    {isPendingOrComplete && (
                                                        <td>Total:</td>
                                                    )}
                                                    {filter === "ABANDONED" && (
                                                        <td>Error:</td>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {[...order].map(
                                                    (
                                                        order: any,
                                                        index: number
                                                    ) =>
                                                        order.customer && (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Link
                                                                        to={`/dashboard/orders/single/${order._id}`}
                                                                    >
                                                                        #
                                                                        {index +
                                                                            1}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {order.updatedAt
                                                                        ? this.createDate(
                                                                              order.updatedAt
                                                                          )
                                                                        : this.createDate(
                                                                              order.createdAt
                                                                          )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        order
                                                                            .customer
                                                                            .billingAddress
                                                                            .firstName
                                                                    }{" "}
                                                                    {
                                                                        order
                                                                            .customer
                                                                            .billingAddress
                                                                            .lastName
                                                                    }
                                                                </td>
                                                                {isPendingOrComplete && (
                                                                    <td>
                                                                        {
                                                                            order
                                                                                .customer
                                                                                .emailAddress
                                                                        }
                                                                    </td>
                                                                )}

                                                                {isPendingOrComplete && (
                                                                    <td>
                                                                        {
                                                                            order.paymentType
                                                                        }
                                                                    </td>
                                                                )}
                                                                {/* {isPendingOrComplete && <td>{paymentStatus}</td>} */}
                                                                {isPendingOrComplete && (
                                                                    <td>
                                                                        {getDollars(
                                                                            order.total +
                                                                                order.shippingPrice
                                                                        )}
                                                                    </td>
                                                                )}
                                                                {filter ===
                                                                    "ABANDONED" && (
                                                                    <td>
                                                                        {
                                                                            order.error
                                                                        }
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                );
                            }
                        )}
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    orders: state.orders
});

export default connect(
    mapStateToProps,
    actions
)(Orders);
