import * as React from "react";
import Wildcard from "./scenes/Wildcard";
import Session from "./scenes/Session";
import Replaceproducts from "./scenes/Replaceproducts";
import { Route, Switch, Redirect } from "react-router-dom";
import browserHistory from "browserHistory";
import HorizontalMenu from "components/HorizontalMenu";

class HackerTools extends React.PureComponent<any, any> {
    componentDidMount() {
        if (window.location.pathname == "/dashboard/hackertools/") {
            browserHistory.replace("/dashboard/hackertools/wildcard");
        }
    }

    render() {
        return (
            <>
                <header>
                    <h1>Hacker Tools</h1>
                    <HorizontalMenu
                        basePath="/dashboard/hackertools/"
                        links={["wildcard", "session", "replace-products"]}
                        withIcons={false}
                    />
                </header>
                <main>
                    <Switch>
                        <Route path="/dashboard/hackertools/wildcard" component={Wildcard} />
                        <Route path="/dashboard/hackertools/session" component={Session} />
                        <Route
                            path="/dashboard/hackertools/replace-products"
                            component={Replaceproducts}
                        />
                        <Redirect to="/dashboard/hackertools/wildcard" />
                    </Switch>
                </main>
            </>
        );
    }
}

export default HackerTools;
