import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { appStateType } from "reducers";
import { Dispatch } from "redux";
import { language } from "types/language";
import { set } from "types/set";

export function addLanguage(setId: set["_id"]) {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: actionTypes.ADD_LANGUAGE_START
        });

        api()
            .post("language", { setId })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_LANGUAGE,
                    payload: { language: res.data.language }
                });
                dispatch({
                    type: actionTypes.GET_BRICKS,
                    payload: { bricks: res.data.bricks }
                });
                dispatch({
                    type: actionTypes.GET_ELEMENTS,
                    payload: { elements: res.data.elements }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_LANGUAGE_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function deleteLanguage(id: string, languageId: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: actionTypes.DELETE_LANGUAGE_START
        });

        api()
            .delete("language", { data: { id } })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DELETE_LANGUAGE,
                    payload: { language: res.data.language }
                });
                dispatch({
                    type: actionTypes.DELETE_BRICKS,
                    payload: { bricks: res.data.bricks }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.DELETE_LANGUAGE_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function editLanguage(id: string, language: language) {
    return (dispatch: Dispatch<any>, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.EDIT_LANGUAGE_START
        });

        const state = getState();
        const map = state.languages.languagesMap;
        const language = map.get(id);

        api()
            .put("language", { id, language })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.EDIT_LANGUAGE,
                    payload: { language: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.EDIT_LANGUAGE_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function updateSetLanguageField(id: string, name: string, value: string) {
    return {
        type: actionTypes.UPDATE_LANGUAGE_FIELD,
        payload: { id, name, value }
    };
}

export function updateContentSet(set: set) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_SET_START,
            payload: {
                id: set._id
            }
        });

        const { _id: id, ...fields } = set;
        return api()
            .put("set", { id, fields })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.EDIT_SET,
                    payload: { set: res.data }
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                console.log("edit set failed!", err);
                dispatch({
                    type: actionTypes.EDIT_SET_ERROR,
                    payload: {
                        id: set._id
                    }
                });
                return Promise.reject();
            });
    };
}
