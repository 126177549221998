import * as React from "react";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import * as isoCountries from "assets/isoCountries.js";
import * as _ from "lodash";

export interface Props {
    name: string;
    data: any[];
}

const convertName = (tableName: string, name: string) => {
    if (name === "null") {
        return "-";
    }

    if (
        tableName.includes("basename") ||
        tableName.includes("url") ||
        tableName.includes("deployment") ||
        tableName.includes("template")
    ) {
        return name;
    }

    if (tableName.includes("country")) {
        const country = isoCountries[name.toUpperCase()];

        return country ? country : name;
    }

    return _.startCase(name) || "-";
};

const TotalsTable: React.FC<Props> = ({ data, name: tableName }) => {
    if (data === undefined) {
        return null;
    } else {
        return (
            <table>
                <thead>
                    <tr>
                        <td>Type:</td>
                        <td>Checkout Views:</td>
                        <td>Checkout Conversions:</td>
                        <td>Checkout CVR:</td>
                        <td>Checkout Revenue:</td>
                        <td>Checkout EPC:</td>
                        <td>Checkout AOV:</td>
                        <td>Upsells Views:</td>
                        <td>Upsells Conversions:</td>
                        <td>Upsells CVR:</td>
                        <td>Upsells Revenue:</td>
                        <td>Upsells EPC:</td>
                        <td>Upsells AOV:</td>
                        <td>Partial Sales:</td>
                        <td>SMS Consent Rate:</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any) => (
                        <tr key={item._id}>
                            <td>{convertName(tableName.toLowerCase(), String(item._id || "-"))}</td>
                            <td>{item.views}</td>
                            <td>{item.sales}</td>
                            <td>{getPercentage(item.sales / item.views)}</td>
                            <td>{getDollars(item.revenue)}</td>
                            <td>{getDollars(item.revenue / item.views)}</td>
                            <td>{getDollars(item.revenue / item.sales)}</td>
                            <td>{item.upsellViews}</td>
                            <td>{item.upsellSales}</td>
                            <td>{getPercentage(item.upsellSales / item.upsellViews)}</td>
                            <td>{getDollars(item.upsellRevenue)}</td>
                            <td>{getDollars(item.upsellRevenue / item.upsellViews)}</td>
                            <td>{getDollars(item.upsellRevenue / item.upsellSales)}</td>
                            <td>{item.partialSales}</td>
                            <td>{getPercentage(item.contactOptIns / item.views)}</td>
                            <td>{getPercentage(item.smsConsents / item.views)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
};

export default TotalsTable;
