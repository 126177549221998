import Button from "components/Button";
import CDNModal from "components/Modal/scenes/CDN";
import SearchList from "components/SearchList";
import SwitchButton from "components/SwitchButton";
import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import { useSelector } from "react-redux";
import { getFileObject } from "services/getFileObject";
import { primeValue } from "types/primeValue";
import { odooProduct } from "types/odooProduct";
import File from "../../../CDNCloud/components/File";
import Headline from "../../components/Headline";
import styles from "./styles.scss";
import addIcon from "assets/icons/add.svg";
import SVG from "react-inlinesvg";
const { useState, useRef } = React;
import { set } from "types/set";
import { appStateType } from "reducers";

type SupportedType = "TEXT" | "NUMBER" | "SWITCH" | "FILE" | "KONNEKTIVE_PRODUCT" | "ODOO_PRODUCT";
type SupportedValueType = string | number | boolean;

type GetInputFieldParams = {
    type: SupportedType;
    value: SupportedValueType;
    editMode: boolean;
    cdnRef?: any;
    name?: string;
    onChange?: (
        e: React.SyntheticEvent,
        newValue?: SupportedValueType,
        showModal?: boolean
    ) => void;
    handleAddVariant?: (e: React.SyntheticEvent | null, forceValue: SupportedValueType) => void;
    multivariant?: boolean;
    options?: any;
    enums?: any[];
    variants?: primeValue[];
};

const getInputField = ({
    type,
    value,
    editMode,
    name,
    cdnRef,
    onChange = (e: React.SyntheticEvent) => {
        if (e) {
            e.preventDefault();
        }
    },
    handleAddVariant,
    multivariant = false,
    options,
    enums,
    variants = []
}: GetInputFieldParams) => {
    if (enums && enums.length) {
        let variantVals = Object.fromEntries(
            variants.map(variant => [variant.value, variant.value])
        );
        return (
            <select
                value={value as string}
                disabled={!editMode}
                onChange={onChange}
                className={styles.value}
            >
                <option>Select a value</option>
                {enums.map(val => {
                    return (
                        <option key={`value-option-${val}`} value={val} disabled={variantVals[val]}>
                            {val}
                        </option>
                    );
                })}
            </select>
        );
    }
    switch (type) {
        case "TEXT":
            return (
                <input
                    className={styles.value}
                    type="text"
                    value={value as string}
                    disabled={!editMode}
                    readOnly={!editMode}
                    onChange={onChange}
                    placeholder={name}
                />
            );
        case "NUMBER":
            return (
                <input
                    className={styles.value}
                    type="number"
                    value={value as number}
                    disabled={!editMode}
                    readOnly={!editMode}
                    onChange={onChange}
                    placeholder={name}
                />
            );
        case "SWITCH":
            return (
                <SwitchButton
                    className={editMode && styles.switchButton}
                    isActive={value as boolean}
                    onSwitch={editMode ? onChange : undefined}
                    onLoad={onChange}
                    disabled={!editMode}
                    color="default"
                />
            );
        case "FILE":
            return editMode ? (
                <CDNModal
                    ref={cdnRef}
                    handleChooseFile={(file: any) => {
                        const { hash, extensions, path, preloadImagePath, name } = file;
                        const size = "";
                        const encodedName = encodeURIComponent(name)
                            .replace(/'/g, "%27")
                            .replace(/"/g, "%22");

                        const newVariant = `hash=${hash}#extensions=${extensions.join(
                            "|"
                        )}#size=${size}#path=${path}#preload=${preloadImagePath}#name=${encodedName}`;

                        if (handleAddVariant) handleAddVariant(null, newVariant);
                    }}
                />
            ) : (
                <div className={`${styles.fileValue} ${multivariant && styles.multivariant}`}>
                    {value !== "" && <File fileView="wide" file={getFileObject(value as string)} />}
                </div>
            );
        case "KONNEKTIVE_PRODUCT":
        case "ODOO_PRODUCT":
            return options ? (
                <SearchList
                    options={options}
                    onChange={onChange}
                    initialValue={value as string}
                    isDisabled={!editMode}
                />
            ) : (
                <div>NO OPTIONS GIVEN</div>
            );
        default:
            return <div>WRONG INPUT FIELD TYPE</div>;
    }
};

type Props = {
    name: string;
    variants: primeValue[];
    multivariant?: boolean;
    brickId: string;
    path: string;
    add: any;
    remove: any;
    type: SupportedType;
    description?: string;
    enums?: any[];
    crm?: set["crm"];
    isChildren?: boolean;
    isUpsell?: boolean;
};

const FlatEditor: React.FC<Props> = ({
    name,
    variants = [],
    multivariant = false,
    brickId,
    path,
    add,
    remove,
    type,
    description = "",
    enums = [],
    crm = "KONNEKTIVE",
    isChildren = false,
    isUpsell = false
}) => {
    const [inputValue, setInputValue] = useState<SupportedValueType>("");
    const inputRef = useRef<HTMLInputElement>(null);
    const cdnRef = useRef<React.ReactElement<any, any>>(null);
    const [editMode, setEditMode] = useState<boolean>(false);

    const handleInputChange = (
        e: React.SyntheticEvent,
        newValue: SupportedValueType,
        showModal: boolean = false
    ) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (newValue === undefined) {
            newValue = (e.target as HTMLInputElement).value;
        }

        if (!showModal) {
            setInputValue(newValue);
        }
    };

    const handleOpenModal = (e: React.SyntheticEvent) => {
        e.preventDefault();
        (cdnRef.current as any).openModal();
    };

    const handleAddVariant = (
        e: React.SyntheticEvent | null,
        forceValue: SupportedValueType = ""
    ) => {
        if (e) e.preventDefault();

        if (inputValue !== "") {
            forceValue = inputValue;
        }

        const isAlreadyExist = variants.find(variant => variant.value == forceValue);

        if (forceValue !== "" && !isAlreadyExist) {
            setInputValue("");

            if (inputRef.current !== null) {
                inputRef.current.focus();
            }

            if (multivariant === false) {
                if (variants[0]) {
                    remove({
                        brickId,
                        variantId: variants[0]._id,
                        type,
                        path
                    });
                }
            }

            add({
                brickId,
                value: forceValue,
                type,
                path
            });
        }

        setEditMode(false);
    };

    let options: any = [];

    if (crm === "KONNEKTIVE") {
        const { uniqueKonnektiveOfferProducts, uniqueKonnektiveUpsellProducts } = useSelector(
            (state: appStateType) => state.products
        );
        const uniqueProducts = isUpsell
            ? uniqueKonnektiveUpsellProducts
            : uniqueKonnektiveOfferProducts;

        options = uniqueProducts
            .sort((a: any, b: any) => Number(a.productId) - Number(b.productId))
            .map((product: any) => {
                const { productId, productDescription } = product;
                return {
                    value: productId,
                    name: `(${productId}) ${productDescription}`
                };
            })
            .filter((option: any) => option.value);
    } else {
        const {
            odooProducts = [],
            odooProductsProd = [],
            odooProductsDev = []
        } = useSelector((state: any) => state.products);

        let finalOdooProducts: odooProduct[] = [];

        switch (crm) {
            case "ODOO":
                finalOdooProducts = odooProducts;
                break;
            case "ODOO_DEV":
                finalOdooProducts = odooProductsDev;
                break;
            case "ODOO_PROD":
            default:
                finalOdooProducts = odooProductsProd;
                break;
        }

        options = finalOdooProducts
            .sort((a: odooProduct, b: odooProduct) => a.sid - b.sid)
            .map((item: odooProduct) => ({
                value: item.sid,
                name: `(${item.sid}) ${item.name}`
            }));
    }

    const PlusButton = (
        <SVG
            src={addIcon}
            className={styles.plus}
            onClick={(e: any) => (type === "FILE" ? handleOpenModal(e) : setEditMode(true))}
        />
    );

    return (
        <div
            className={`${styles.editor} ${isChildren && styles.isChildren}`}
            id={variants.length === 0 ? "invalid-element" : ""}
        >
            <Headline
                name={name}
                description={description}
                isArray={isChildren}
                isGroup={!path.includes(".")}
                isParent={!path.includes(".")}
            >
                <>
                    {variants.length === 0 && (
                        <div className={styles.noVariants}>
                            {PlusButton}
                            {type === "FILE" && (
                                <div style={{ width: "100%", textAlign: "right" }}>
                                    {getInputField({
                                        type,
                                        value: inputValue,
                                        editMode: true,
                                        onChange: handleInputChange,
                                        cdnRef,
                                        handleAddVariant,
                                        name,
                                        options,
                                        enums,
                                        variants
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    {variants.map((variant, index) => (
                        <div
                            key={variant._id}
                            className={`${styles.variantRow} ${type === "FILE" && styles.image}`}
                        >
                            {multivariant &&
                                (variant._id.includes("pre-variant") ? (
                                    <LoadingIndicator
                                        size={20}
                                        className={
                                            type === "FILE"
                                                ? styles.loadingPreVariantImage
                                                : styles.loadingPreVariant
                                        }
                                    />
                                ) : (
                                    <Button
                                        className={`${styles.delete} ${
                                            type === "FILE" && styles.image
                                        }`}
                                        onClick={() =>
                                            remove({
                                                brickId,
                                                variantId: variant._id,
                                                type,
                                                path
                                            })
                                        }
                                        title="Remove"
                                        icon="trash"
                                        design="icon"
                                        marginRight
                                    />
                                ))}
                            {getInputField({
                                type,
                                value: variant.value,
                                editMode: false,
                                multivariant,
                                name,
                                options
                            })}
                            {index === variants.length - 1 && !editMode && (
                                <>
                                    {PlusButton}
                                    {type === "FILE" && (
                                        <div style={{ width: "100%", textAlign: "right" }}>
                                            {getInputField({
                                                type,
                                                value: inputValue,
                                                editMode: true,
                                                onChange: handleInputChange,
                                                cdnRef,
                                                handleAddVariant,
                                                name,
                                                options,
                                                enums,
                                                variants
                                            })}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                    {editMode && (
                        <form
                            onSubmit={type === "FILE" ? handleOpenModal : handleAddVariant}
                            className={`${styles.inputBox} ${
                                type !== "KONNEKTIVE_PRODUCT" && styles.flex
                            } ${!path.includes(".") && styles.highestLevel}`}
                        >
                            <div style={{ width: "100%", textAlign: "right" }}>
                                {getInputField({
                                    type,
                                    value: inputValue,
                                    editMode: true,
                                    onChange: handleInputChange,
                                    cdnRef,
                                    handleAddVariant,
                                    name,
                                    options,
                                    enums,
                                    variants
                                })}
                            </div>
                            <div className={styles.addVariantContainer}>
                                <Button type="submit">
                                    {multivariant ? "ADD NEW VARIANT" : "REPLACE VARIANT"}
                                </Button>
                                <SVG
                                    src={addIcon}
                                    className={styles.close}
                                    onClick={() => setEditMode(false)}
                                />
                            </div>
                        </form>
                    )}
                </>
            </Headline>
        </div>
    );
};

export default FlatEditor;
