import Button from "components/Button";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { appStateType } from "reducers";
import { deleteTrigger } from "./actions";
import styles from "./styles.scss";

type Props = {
    upsellsFlowId: string;
};

const Triggers: React.FC<Props> = ({ upsellsFlowId }) => {
    const { upsellFlows } = useSelector((state: appStateType) => ({
        upsellFlows: state.upsell.upsellsFlow
    }));
    const dispatch = useDispatch();

    const flow = upsellFlows.find(flow => flow._id == upsellsFlowId);

    const handleDeleteTrigger = (
        triggerId: string,
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        e.preventDefault();
        dispatch(deleteTrigger(upsellsFlowId, triggerId));
    };

    return (
        <div className={styles.triggers}>
            <h3 className={styles.headline}>Triggers</h3>
            <div className={styles.list}>
                {flow &&
                    flow.triggers.map(trigger => {
                        let displayValue = trigger.compareValue;

                        if (
                            displayValue === "true" ||
                            displayValue === "false"
                        ) {
                            displayValue = String(displayValue).toUpperCase();
                        }

                        return (
                            <div key={trigger._id} className={styles.trigger}>
                                {trigger.variableName} {trigger.operator}{" "}
                                {displayValue}
                                <Button
                                    design="circle"
                                    icon="substract"
                                    size="tiny"
                                    background="darkPurple"
                                    onClick={handleDeleteTrigger.bind(
                                        null,
                                        trigger._id
                                    )}
                                    marginLeft
                                />
                            </div>
                        );
                    })}
                <Link to={`${upsellsFlowId}/triggers`}>
                    <Button flat>Edit</Button>
                </Link>
            </div>
        </div>
    );
};

export default Triggers;
