import * as actionTypes from "actionTypes";
import api from "api";
import Axios, { AxiosResponse, AxiosError, CancelTokenSource } from "axios";
import axios from "axios";
import { Dispatch, Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { appStateType } from "reducers";
import { DeploymentFilters } from "reducers/deploymentReducer";

let source: CancelTokenSource;

export function resetDeployments() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.RESET_DEPLOYMENTS
        });
    };
}

export function pullDeployments() {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.PULL_DEPLOYMENTS_START
        });

        if (source) {
            source.cancel();
        }

        const {
            deployment: { deployments = [], filters }
        } = getState();

        const skip: string = String(deployments.length);
        const queryParams = new URLSearchParams({ ...filters, skip } as any);
        source = axios.CancelToken.source();

        api()
            .get(`deployment?${queryParams.toString()}`, {
                cancelToken: source.token
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_DEPLOYMENTS,
                    payload: {
                        deployments: res.data,
                        isDrained: res.data.length < 10
                    }
                });
            })
            .catch((err: AxiosError) => {
                if (!(err instanceof Axios.Cancel)) {
                    dispatch({
                        type: actionTypes.PULL_DEPLOYMENTS_ERROR,
                        payload: { error: err.response }
                    });
                }
            });
    };
}

export function removeDeployments(serverId: string, basename: string) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        api()
            .delete("deployment", { data: { serverId, basename } })
            .then(() => {
                dispatch({
                    type: actionTypes.REMOVE_DEPLOYMENT,
                    payload: { basename }
                });
            })
            .catch((err: AxiosError) => {
                console.log("DEPLOYMENT REMOVE ERROR:", err.message);
            });
    };
}

export function resetDeploymentFilters() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.RESET_DEPLOYMENT_FILTERS
        });
    };
}

export function setDeploymentFilters({
    key,
    value
}: {
    key: keyof DeploymentFilters;
    value: string;
}) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_DEPLOYMENT_FILTERS,
            payload: {
                key,
                value
            }
        });
    };
}
