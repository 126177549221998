import "moment-duration-format";
import * as React from "react";
import * as moment from "moment-timezone";
import Link from "../Link";
import Stripe from "../Stripe";
import Timer from "components/Timer";
import clock from "assets/icons/clock.svg";
import styles from "./styles.scss";

type Status = {
    message?: string;
    messages?: any[];
    loader?: boolean;
    icon?: string;
    link?: boolean;
};

type Props = {
    liveDeploymentLink: string;
    estimatedTime: number;
    status: Status;
    created_on: string;
};

type State = {
    index: number;
    isAnimationPlaying: boolean;
};

class EstimatedTime extends React.Component<Props, State> {
    refToStripe: React.RefObject<HTMLDivElement> = React.createRef();
    interval: number;

    state = {
        index: 0,
        isAnimationPlaying: false
    };

    componentDidMount() {
        this.setupAnimation();
        this.setupIndex();

        this.interval = window.setInterval(() => {
            this.setupIndex();
            this.setupAnimation();
        }, 1000);
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    setupIndex() {
        const { status, created_on, estimatedTime } = this.props;

        if (status && status.hasOwnProperty("messages")) {
            const { messages = [] } = status;

            const remaining = this.calculateRemaining(created_on, estimatedTime);
            const ratio = this.asRatio(estimatedTime, remaining);
            const messagesCount = messages.length - 1;
            const index = messagesCount - Math.floor(messages.length * ratio);

            if (index <= messagesCount) {
                this.setState({ index });
            }
        }
    }

    setupAnimation() {
        const { estimatedTime, created_on } = this.props;

        const remainingTime = this.calculateRemaining(created_on, estimatedTime);
        const progress = this.asPercentage(estimatedTime, remainingTime);

        if (!!this.refToStripe) {
            if (progress >= 0 && progress <= 100 && remainingTime > 0) {
                (this.refToStripe as any).style.width = `${progress}%`;
            }
        }
    }

    calculateRemaining(createdOn: string, estimatedTime: number) {
        const timeBehind = moment.duration(moment().diff(createdOn)).asSeconds();
        const remainingTime = estimatedTime > 0 ? estimatedTime - timeBehind : estimatedTime;
        return remainingTime;
    }

    asRatio(estimatedTime: number, remaining: number) {
        const ratio = remaining / estimatedTime;
        const safeRatio = !Number.isNaN(ratio) ? ratio : 0;
        return safeRatio;
    }

    asPercentage(estimatedTime: number, remaining: number) {
        const ratio = this.asRatio(estimatedTime, remaining);
        const percentage = 100 - ratio * 100;

        if (percentage > 100) {
            return 100;
        } else {
            return percentage;
        }
    }

    render() {
        const { liveDeploymentLink, estimatedTime, status, created_on } = this.props;

        const { link, loader, messages = [] } = status;
        const { index } = this.state;
        const timeBehind = moment.duration(moment().diff(created_on)).asSeconds();
        const isProgress = Array.isArray(messages) && messages[index];
        const statusToRender = isProgress ? messages[index] : status;

        return (
            <>
                <div className={styles.status}>
                    <>
                        <img src={statusToRender.icon} />
                        {statusToRender.message}
                        {loader && "..."} {link && <Link to={liveDeploymentLink} type="LINK" />}
                    </>
                </div>
                {estimatedTime > 0 && (
                    <>
                        <div className={styles.estimatedTime}>
                            <img src={clock} /> <Timer timeBehind={timeBehind} /> &nbsp;
                            {"/ "}
                            {moment.duration(estimatedTime, "s").format("mm:ss", {
                                trim: false
                            })}{" "}
                            (estimated)
                        </div>
                        <Stripe ref={(el: any) => (this.refToStripe = el)} />
                    </>
                )}
            </>
        );
    }
}

export default EstimatedTime;
