import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import HorizontalMenu from "components/HorizontalMenu";
import AdAccounts from "./Scenes/AdAccounts";
import CMSAnalytics from "./Scenes/CMSAnalytics";
import Impressions from "./Scenes/Impressions";

const Analytics: React.FC<any> = ({ match }) => {
    const { path } = match;

    return (
        <>
            <header>
                <HorizontalMenu
                    basePath={`${path}/`}
                    links={["cms", "impressions", "adaccounts"]}
                />
            </header>
            <main>
                <Switch>
                    <Route exact path={`${path}/cms`} component={CMSAnalytics} />
                    <Route exact path={`${path}/cms/:setId`} component={CMSAnalytics} />
                    <Route exact path={`${path}/adaccounts`} component={AdAccounts} />
                    <Route exact path={`${path}/impressions`} component={Impressions} />
                    <Redirect to={`${path}/cms`} />
                </Switch>
            </main>
        </>
    );
};

export default Analytics;
