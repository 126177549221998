import React, { useState, useEffect, useMemo } from "react";
import styles from "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { appStateType } from "reducers";
import SearchList from "components/SearchList";
import { addFunnel, pullGoogleCampaigns } from "./actions";
import SetsList from "../../components/SetsList";
import debounce from "lodash/debounce";
import JsonFormatter from "components/JsonFormatter";
import LoadingIndicator from "components/LoadingIndicator";
import { FFFunnel } from "types/FFFunnel";

type FunnelForm = {
    _id: null | string;
    ffId: string;
    name: string;
    selectedSet: string;
    sets: string[];
    googleAdAccountId: string;
    selectedConversionAction: string;
};

type Props = {
    setActiveFunnelTabId: (tab: string) => void;
};

const emptyFunnelForm: FunnelForm = {
    _id: null,
    ffId: "",
    name: "",
    selectedSet: "",
    sets: [],
    googleAdAccountId: "",
    selectedConversionAction: ""
};

const CreateFunnel: React.FC<Props> = ({ setActiveFunnelTabId }) => {
    const dispatch = useDispatch();
    const { sets } = useSelector((state: appStateType) => state.sets);
    const {
        editFunnelId,
        funnels,
        googleCampaigns,
        googleConversionActions,
        isLoadingGoogleCampaigns,
        googleCampaignsError,
        isLoadingAddFunnel
    } = useSelector((state: appStateType) => state.conversionbalancer);
    let initialForm = emptyFunnelForm;

    let funnel: FFFunnel | null = null;

    if (editFunnelId) {
        funnel = funnels.find(funnel => funnel._id === editFunnelId) || null;

        if (funnel) {
            initialForm = {
                _id: funnel._id,
                ffId: funnel.ffId,
                name: funnel.name,
                selectedSet: "",
                sets: funnel.sets,
                googleAdAccountId: funnel.googleAdAccountId,
                selectedConversionAction: funnel.selectedConversionAction
            };
        }
    }

    const [selectedOptions, setSelectedOptions] = useState<string[]>(
        funnel?.selectedCampaigns || []
    );

    useEffect(() => {
        if (editFunnelId && initialForm.googleAdAccountId != "") {
            debouncedPullGoogleCampaigns(initialForm.googleAdAccountId);
        }
    }, [editFunnelId]);

    const [funnelForm, setFunnelForm] = useState<FunnelForm>(initialForm);
    const debouncedPullGoogleCampaigns = useMemo(
        () => debounce((value: string) => dispatch(pullGoogleCampaigns(value)), 500),
        [dispatch]
    );

    useEffect(() => {
        setFunnelForm(oldForm => ({ ...oldForm, selectedSet: sets[0]._id }));
    }, [sets]);

    const handleFunnelFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value = "", name = "" } = e.target;
        setFunnelForm(prevFunnelForm => ({ ...prevFunnelForm, [name]: value }));

        if (name === "googleAdAccountId") {
            debouncedPullGoogleCampaigns(value);
        }
    };

    const handleAddFunnel = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        dispatch(addFunnel({ ...funnelForm, selectedCampaigns: selectedOptions }));
        setFunnelForm(emptyFunnelForm);
        setSelectedOptions([]);
        setActiveFunnelTabId("SHOW_FUNNELS");
    };

    const handleClickDeleteSet = (set: string) => (e: React.SyntheticEvent<HTMLDivElement>) => {
        setFunnelForm(oldForm => ({
            ...oldForm,
            sets: oldForm.sets.filter(formSet => formSet != set)
        }));
    };

    const setOptions = sets.map(set => ({ name: set.name, value: set._id }));
    const campaignsOptions =
        googleCampaigns && googleCampaigns.length > 0 && !isLoadingGoogleCampaigns
            ? googleCampaigns.map((row: any) => ({
                  name: row.campaign.name,
                  value: String(row.campaign.id)
              }))
            : [];
    const conversionOptions =
        googleConversionActions && googleConversionActions.length > 0 && !isLoadingGoogleCampaigns
            ? googleConversionActions.map((row: any) => ({
                  name: row.conversion_action.name,
                  value: String(row.conversion_action.resource_name),
                  isDisabled: Number(row.conversion_action.type) !== 7
              }))
            : [];

    return (
        <>
            <div className={styles.form}>
                <input
                    name="ffId"
                    placeholder="FF Funnel ID"
                    value={funnelForm.ffId}
                    onChange={handleFunnelFormChange}
                    className={styles.inputField}
                />
                <input
                    name="name"
                    placeholder="FF Funnel Name"
                    value={funnelForm.name}
                    onChange={handleFunnelFormChange}
                    className={styles.inputField}
                />
                <div className={styles.setsSelection}>
                    <div className={styles.searchContainer}>
                        <SearchList
                            options={setOptions}
                            onChange={(e: any, value: string) => {
                                setFunnelForm(oldForm => ({
                                    ...oldForm,
                                    sets: [...new Set([...oldForm.sets, value])]
                                }));
                            }}
                            placeholder="Select content set..."
                            height={40}
                            fontSize={14}
                            width={400}
                            resetOnSelect={true}
                            zIndex={3}
                        />
                    </div>
                </div>
                {funnelForm.sets.length > 0 && (
                    <div className={styles.setsList}>
                        <span className={styles.title}>Selected content sets:</span>
                        <SetsList
                            setsList={funnelForm.sets}
                            handleClickDeleteSet={handleClickDeleteSet}
                            marginTop={10}
                        />
                    </div>
                )}
                <input
                    name="googleAdAccountId"
                    placeholder="Google Ad Account Id"
                    value={funnelForm.googleAdAccountId}
                    onChange={handleFunnelFormChange}
                    className={styles.inputField}
                />
                {!googleCampaignsError ? (
                    <div className={styles.setsSelection}>
                        <div className={styles.searchContainer}>
                            <SearchList
                                isLoading={isLoadingGoogleCampaigns}
                                options={campaignsOptions}
                                placeholder="Select google campaigns..."
                                height={40}
                                fontSize={14}
                                width={400}
                                resetOnSelect={true}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                zIndex={2}
                                isDisabled={!campaignsOptions || campaignsOptions.length === 0}
                            />
                        </div>
                    </div>
                ) : (
                    <JsonFormatter
                        headline="Pulling Google Ads Campaigns Error"
                        body={googleCampaignsError}
                    />
                )}
                <div className={styles.setsSelection}>
                    <div className={styles.searchContainer}>
                        <SearchList
                            isLoading={isLoadingGoogleCampaigns}
                            options={conversionOptions}
                            onChange={(e: any, value: string) => {
                                setFunnelForm(oldForm => ({
                                    ...oldForm,
                                    selectedConversionAction: value
                                }));
                            }}
                            placeholder="Select google conversion action..."
                            height={40}
                            fontSize={14}
                            width={400}
                            resetOnSelect={true}
                            zIndex={1}
                            isDisabled={!conversionOptions || conversionOptions.length === 0}
                            forceValue={
                                Array.isArray(funnelForm.selectedConversionAction)
                                    ? funnelForm.selectedConversionAction[0]
                                    : funnelForm.selectedConversionAction
                            }
                        />
                    </div>
                </div>
                <div className={styles.info}>
                    ^Deactive conversion actions are deactivated due to incorect conversion action
                    type which is required to be "UPLOAD_CLICKS". Try to change conversion action
                    into "UPLOAD_CLICKS" or create new one due to google docs:{" "}
                    <a href="https://support.google.com/google-ads/answer/7012522">
                        https://support.google.com/google-ads/answer/7012522
                    </a>
                </div>
                {isLoadingAddFunnel ? (
                    <LoadingIndicator />
                ) : (
                    <button
                        onClick={handleAddFunnel}
                        disabled={!!googleCampaignsError || isLoadingGoogleCampaigns}
                    >
                        {editFunnelId ? "EDIT FUNNEL" : "CREATE NEW FUNNEL"}
                    </button>
                )}
            </div>
        </>
    );
};

export default CreateFunnel;
