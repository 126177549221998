import * as React from "react";
import styles from "./styles.scss";

interface Props {
    title: string;
}

const Headline: React.FC<Props> = ({ title }) => (
    <span className={styles.headline}>{title}</span>
);

export default Headline;
