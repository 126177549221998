import * as React from "react";
import Button from "components/Button";
import DynamicActivePricing from "./scenes/DynamicActivePricing";
import PricingInit from "./scenes/PricingInit";
import ModelList from "./scenes/ModelList";
import styles from "./styles.scss";
import { OptionsType } from "components/SearchList";
import { PricingContainer, PricingModel, Currency } from "types/pricing";
import { Route, Switch } from "react-router-dom";
import { appStateType } from "reducers";
import {
    getPricing,
    addPricingContainerDraft,
    getPricingGraphsChunks,
    getOldPricingGraphsChunks
} from "./actions";
import { product } from "types/product";
import { odooProduct } from "types/odooProduct";
import { useDispatch, useSelector } from "react-redux";
import { getSetDetails } from "services/getSetDetails";
import { set } from "types/set";
import NodeComponent from "../../components/NodeComponent";
import LoadingIndicator from "components/LoadingIndicator";
import { getSetData, setSchemaKey } from "../../actions";

type Props = {
    languageId?: string;
    setId?: string;
    versionId?: string;
    match?: any;
    isSdk?: boolean;
};

interface Params {
    languageId: string;
    setId: string;
    versionId: string;
}

interface PricingContextType extends Params {
    pricingContainer: PricingContainer | undefined;
    pricingModelDraft: PricingModel | undefined;
    products: product[] | undefined;
    odooProducts: odooProduct[] | undefined;
    productListOptions: OptionsType | undefined;
    currencies: Currency[];
    odooProductListOptions: OptionsType | undefined;
    crmMode: set["crm"];
    isEditing: boolean;
    schema?: any;
    containerDraft: PricingContainer | undefined;
}

export const PricingContext = React.createContext<PricingContextType>({
    languageId: "",
    pricingContainer: undefined,
    pricingModelDraft: undefined,
    productListOptions: undefined,
    odooProductListOptions: undefined,
    products: undefined,
    odooProducts: undefined,
    setId: "",
    versionId: "",
    currencies: [],
    crmMode: "KONNEKTIVE",
    isEditing: false,
    schema: {},
    containerDraft: undefined
});

const PricingEditor: React.FC<Props> = ({
    setId = "",
    isSdk = false,
    languageId = "",
    versionId = "",
    match = null
}) => {
    setId = setId === "" ? match.params.setId : setId;
    const dispatch = useDispatch();
    const [productListOptions, setProductListOptions] = React.useState<OptionsType>([]);
    const [odooProductListOptions, setOdooProductListOptions] = React.useState<OptionsType>([]);
    const pricingReducer = useSelector((state: appStateType) => state.pricing);
    const {
        konnektiveOfferProducts,
        uniqueKonnektiveOfferProducts,
        odooProducts,
        odooProductsProd,
        odooProductsDev
    } = useSelector((state: appStateType) => state.products);

    const version = useSelector((state: appStateType) => state.deployment.version);
    const schema = version && version._id === versionId && version._schema ? version._schema : {};

    const crmMode = getSetDetails(setId)?.crm;
    const {
        loadingPricingError,
        pricingContainers,
        pricingModelDraft,
        isContainerEditing,
        containerDraft,
        currencies,
        isLoading
    } = pricingReducer;
    const pricingContainer = pricingContainers.find(
        (pricingContainer: PricingContainer) => pricingContainer && pricingContainer.set === setId
    );

    let finalOdooProducts: odooProduct[] = [];

    switch (crmMode) {
        case "ODOO":
            finalOdooProducts = odooProducts;
            break;
        case "ODOO_DEV":
            finalOdooProducts = odooProductsDev;
            break;
        case "ODOO_PROD":
        default:
            finalOdooProducts = odooProductsProd;
            break;
    }

    React.useEffect(() => {
        dispatch(getPricing(setId));
        dispatch(getOldPricingGraphsChunks(setId));
        dispatch(getPricingGraphsChunks(setId));
        dispatch(setSchemaKey("pricing"));
        if (isSdk) {
            dispatch(getSetData(setId));
        }
    }, [setId, isSdk]);

    const handeAddContainer = () => {
        const path = isSdk
            ? `/dashboard/deployment/sdklive/${setId}/initialize`
            : `/dashboard/cms/${setId}/${languageId}/${versionId}/pricing/initialize`;
        dispatch(addPricingContainerDraft(setId, path));
    };

    React.useEffect(() => {
        if (uniqueKonnektiveOfferProducts && Array.isArray(uniqueKonnektiveOfferProducts)) {
            const options = uniqueKonnektiveOfferProducts
                .sort((a, b) => Number(a.productId) - Number(b.productId))
                .map(product => {
                    const { productId, productDescription } = product;
                    return {
                        value: productId,
                        name: `(${productId}) ${productDescription}`
                    };
                })
                .filter((option: any) => option.value);

            if (options.length) {
                setProductListOptions(options);
            }
        }
    }, [uniqueKonnektiveOfferProducts]);

    React.useEffect(() => {
        if (finalOdooProducts && Array.isArray(finalOdooProducts)) {
            const options = finalOdooProducts
                .sort((a, b) => a.sid - b.sid)
                .map(item => {
                    return {
                        name: `(${item.sid}) ${item.name}`,
                        value: item.sid.toString()
                    };
                });

            setOdooProductListOptions(options);
        }
    }, [finalOdooProducts]);

    const path = isSdk
        ? `/dashboard/deployment/sdklive/:setId`
        : `/dashboard/cms/:setId/:languageId/:versionId/pricing`;

    if (loadingPricingError) {
        return (
            <div className={styles.main}>
                <div className={styles.panel}>{loadingPricingError}</div>
            </div>
        );
    }

    if (isLoading && !pricingContainer) {
        return <LoadingIndicator />;
    }

    if (
        (!pricingContainer || !pricingContainer._id) &&
        !window.location.href.includes("/initialize")
    ) {
        return (
            <div className={styles.main}>
                <div className={styles.panel}>
                    <Button onClick={handeAddContainer} flat>
                        Initialize pricing
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <PricingContext.Provider
            value={{
                languageId,
                pricingContainer,
                pricingModelDraft,
                productListOptions,
                odooProductListOptions,
                products: konnektiveOfferProducts,
                odooProducts: finalOdooProducts,
                setId,
                versionId,
                currencies,
                crmMode,
                isEditing: isContainerEditing,
                schema,
                containerDraft
            }}
        >
            <div className={styles.main}>
                <Switch>
                    <Route path={path} render={(props: any) => <ModelList {...props} />} exact />
                    <Route
                        path={`${path}/initialize`}
                        render={(props: any) => <PricingInit />}
                        exact
                    />
                    <Route
                        path={`${path}/flavourproducts/:elementId`}
                        render={(props: any) => (
                            <NodeComponent
                                key={"__FLAVOUR_PRODUCTS"}
                                languageId={languageId}
                                setId={setId}
                                versionId={versionId}
                                {...props}
                                schemaKey={"__FLAVOUR_PRODUCTS"}
                            />
                        )}
                        exact
                    />
                    <Route
                        path={`${path}/dynamic`}
                        render={(props: any) => <DynamicActivePricing {...props} />}
                        exact
                    />
                    <Route
                        path={`${path}/products/:elementId`}
                        render={(props: any) => (
                            <NodeComponent
                                key={"__PRODUCTS"}
                                languageId={languageId}
                                setId={setId}
                                versionId={versionId}
                                {...props}
                                schemaKey={"__PRODUCTS"}
                            />
                        )}
                        exact
                    />
                    <Route
                        path={`${path}/bundles/:elementId`}
                        render={(props: any) => (
                            <NodeComponent
                                key={"__BUNDLES"}
                                languageId={languageId}
                                setId={setId}
                                versionId={versionId}
                                {...props}
                                schemaKey={"__BUNDLES"}
                            />
                        )}
                        exact
                    />
                </Switch>
            </div>
        </PricingContext.Provider>
    );
};

export default PricingEditor;
