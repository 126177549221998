import * as React from "react";
import styles from "./styles.scss";
import { set } from "types/set";
import SwitchButton from "components/SwitchButton";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ActionMeta } from "react-select";
import { brand } from "types/brand";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

const EditSetDetails: React.FC<{ brands: brand[]; set: set; onUpdate: Function }> = props => {
    const [contentSet, setContentSet] = React.useState<set>({
        ...props.set
    });
    const [isLoading, setLoading] = React.useState(false);
    const [selectedBrand, setSelectedBrand] = React.useState<null | SelectOption>(null);

    React.useEffect(() => {
        setContentSet({ ...props.set });

        if (props.brands && props.set) {
            const setBrand = props.brands.find(el => el._id === props.set.brand);

            if (setBrand) {
                setSelectedBrand({ value: setBrand._id as string, label: setBrand.name });
            }
        }
    }, [props.set, props.brands]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setContentSet({
            ...contentSet,
            [name]: value
        });
    };

    const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>, isActive: boolean) => {
        const { name } = e.target;

        setContentSet({
            ...contentSet,
            [name]: isActive
        });
    };

    const handleSelect = (
        selectedOption: SelectOption,
        config: ActionMeta<{ name: string; action: string }>
    ) => {
        switch (config.name) {
            case "brand":
                setSelectedBrand(selectedOption);
                setContentSet({ ...contentSet, [config.name]: selectedOption.value });
                break;
            case "crm":
                setContentSet({ ...contentSet, [config.name]: selectedOption.value as set["crm"] });
                break;
            case "konnektiveCrm":
                setContentSet({
                    ...contentSet,
                    [config.name]: selectedOption.value as set["konnektiveCrm"]
                });
                break;
            default:
                break;
        }
    };

    const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        props
            .onUpdate({ ...contentSet })
            .then(() => {
                setLoading(false);
            })
            .catch((err: any) => {
                console.log(err);
                setLoading(false);
            });
    };

    const { brands } = props;
    const { crm, konnektiveCrm, isSdk = false, isActive, name, campaignId } = contentSet;

    return (
        <form className={styles.form} onSubmit={handleUpdate}>
            <div>
                <label className={styles.label}>Name:</label>
                <input
                    className={styles.field}
                    value={name || ""}
                    onChange={handleChange}
                    placeholder={"Content Set Name"}
                    name="name"
                />
            </div>
            <div>
                <label className={styles.label}>CampaignId:</label>
                <input
                    className={styles.field}
                    value={campaignId || ""}
                    onChange={handleChange}
                    placeholder={"CampaignId"}
                    name="campaignId"
                />
            </div>
            <div className={styles.customSelect}>
                <label className={styles.label}>CRM:</label>
                <Select
                    options={[
                        { value: "KONNEKTIVE", label: "KONNEKTIVE" },
                        { value: "ODOO_PROD", label: "ODOO_PROD" },
                        { value: "ODOO", label: "ODOO" },
                        { value: "ODOO_DEV", label: "ODOO_DEV" }
                    ]}
                    components={animatedComponents}
                    name="crm"
                    placeholder="Select CRM"
                    onChange={handleSelect}
                    value={{ value: crm, label: crm }}
                    isClearable
                    isSearchable
                    styles={{
                        menu: () => ({
                            maxWidth: "100%",
                            border: "1px solid gray",
                            borderRadius: "4px",
                            overflowX: "hidden"
                        })
                    }}
                />
            </div>
            {crm === "KONNEKTIVE" && (
                <div className={styles.customSelect}>
                    <label className={styles.label}>Konnektive Instance:</label>
                    <Select
                        options={[
                            { value: "EJAM", label: "EJAM" },
                            { value: "SUTERA", label: "SUTERA" }
                        ]}
                        components={animatedComponents}
                        name="konnektiveCrm"
                        placeholder="Select Konnektive Instance"
                        onChange={handleSelect}
                        value={{ value: konnektiveCrm, label: konnektiveCrm }}
                        isClearable
                        isSearchable
                        styles={{
                            menu: () => ({
                                maxWidth: "100%",
                                border: "1px solid gray",
                                borderRadius: "4px",
                                overflowX: "hidden"
                            })
                        }}
                    />
                </div>
            )}

            <div className={styles.customSelect}>
                <label className={styles.label}>Brand:</label>
                <Select
                    options={brands.map((el: brand) => ({ value: el._id, label: el.name }))}
                    components={animatedComponents}
                    name="brand"
                    placeholder="Select Content Set Brand"
                    onChange={handleSelect}
                    value={selectedBrand}
                    isClearable
                    isSearchable
                    styles={{
                        menu: () => ({
                            maxWidth: "100%",
                            border: "1px solid gray",
                            borderRadius: "4px",
                            overflowX: "hidden"
                        })
                    }}
                />
            </div>
            <div className={styles.customSwitch}>
                <p>Is SDK Content Set?</p>
                <SwitchButton
                    isActive={isSdk || false}
                    onSwitch={handleSwitch}
                    disabled={false}
                    className={styles.sdkSwitch}
                />
            </div>
            <div className={styles.customSwitch}>
                <p>Is Content Set Active?</p>
                <SwitchButton
                    isActive={isActive || false}
                    onSwitch={handleSwitch}
                    disabled={false}
                    className={styles.sdkSwitch}
                />
            </div>
            <div>
                <button className={styles.submitButton} type="submit">
                    {isLoading ? "Updating..." : "Update Content set"}
                </button>
            </div>
        </form>
    );
};

export default EditSetDetails;
