import * as actionTypes from "actionTypes";
import _ from "lodash";
import { tagItems } from "services/tagItems";
import { product } from "types/product";
import { odooProduct } from "types/odooProduct";

export type productsStateType = {
    konnektiveOfferProducts: product[];
    konnektiveUpsellProducts: product[];
    uniqueKonnektiveOfferProducts: product[];
    uniqueKonnektiveUpsellProducts: product[];
    isLoading: boolean;
    error: object;
    odooProducts: odooProduct[];
    odooProductsProd: odooProduct[];
    odooProductsDev: odooProduct[];
    isLoadingOdoo: boolean;
    isLoadingOdooProd: boolean;
    isLoadingOdooDev: boolean;
    odooError: object;
    soldOutProducts: any;
    isLoadingSoldOutProducts: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: productsStateType = {
    konnektiveOfferProducts: [],
    konnektiveUpsellProducts: [],
    uniqueKonnektiveOfferProducts: [],
    uniqueKonnektiveUpsellProducts: [],
    isLoading: false,
    error: {},
    odooProducts: [],
    odooProductsProd: [],
    odooProductsDev: [],
    isLoadingOdoo: false,
    isLoadingOdooProd: false,
    isLoadingOdooDev: false,
    odooError: {},
    soldOutProducts: [],
    isLoadingSoldOutProducts: false
};

export const productsStorage: { [key: string]: boolean } = {
    uniqueKonnektiveOfferProducts: true,
    uniqueKonnektiveUpsellProducts: true
};

export default (state: productsStateType = initialState, action: IAction): productsStateType => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_PRODUCTS:
            const products: product[] = action.payload.products;
            const konnektiveOfferProducts = products.filter(el => el.productType === "OFFER");
            const konnektiveUpsellProducts = products.filter(el => el.productType === "UPSALE");
            const uniqueKonnektiveOfferProducts = _.uniqBy(konnektiveOfferProducts, "productId");
            const uniqueKonnektiveUpsellProducts = _.uniqBy(konnektiveUpsellProducts, "productId");
            return {
                ...state,
                konnektiveOfferProducts,
                konnektiveUpsellProducts,
                uniqueKonnektiveOfferProducts,
                uniqueKonnektiveUpsellProducts,
                isLoading: false
            };
        case actionTypes.GET_PRODUCTS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case actionTypes.GET_ODOO_PRODUCTS_START:
            return {
                ...state,
                isLoadingOdoo: true
            };
        case actionTypes.GET_ODOO_PRODUCTS:
            const { odooProducts = [] } = action.payload;

            return {
                ...state,
                odooProducts,
                isLoadingOdoo: false
            };
        case actionTypes.GET_ODOO_PRODUCTS_ERROR:
            return {
                ...state,
                isLoadingOdoo: false,
                odooError: action.payload.error
            };
        case actionTypes.GET_ODOO_PRODUCTS_PROD_START:
            return {
                ...state,
                isLoadingOdooProd: true
            };
        case actionTypes.GET_ODOO_PRODUCTS_PROD:
            const { odooProductsProd = [] } = action.payload;

            return {
                ...state,
                odooProductsProd,
                isLoadingOdooProd: false
            };
        case actionTypes.GET_ODOO_PRODUCTS_PROD_ERROR:
            return {
                ...state,
                isLoadingOdooProd: false,
                odooError: action.payload.error
            };
        case actionTypes.GET_ODOO_PRODUCTS_DEV_START:
            return {
                ...state,
                isLoadingOdooDev: true
            };
        case actionTypes.GET_ODOO_PRODUCTS_DEV:
            const { odooProductsDev = [] } = action.payload;

            return {
                ...state,
                odooProductsDev,
                isLoadingOdooDev: false
            };
        case actionTypes.GET_ODOO_PRODUCTS_DEV_ERROR:
            return {
                ...state,
                isLoadingOdooDev: false,
                odooError: action.payload.error
            };
        case actionTypes.GET_SOLD_OUT_PRODUCTS_START:
            return {
                ...state,
                isLoadingSoldOutProducts: true
            };
        case actionTypes.GET_SOLD_OUT_PRODUCTS:
            return {
                ...state,
                soldOutProducts: action.payload,
                isLoadingSoldOutProducts: false
            };
        case actionTypes.GET_SOLD_OUT_PRODUCTS_ERROR:
            return {
                ...state,
                isLoadingSoldOutProducts: false,
                odooError: action.payload.error
            };
        case actionTypes.SAVE_SOLD_OUT_PRODUCTS_START:
            return {
                ...state,
                isLoadingSoldOutProducts: true
            };
        case actionTypes.SAVE_SOLD_OUT_PRODUCTS: {
            const oldSoldOutProducts = state.soldOutProducts;
            const product = action.payload;
            const isUpdate = oldSoldOutProducts.findIndex(
                oldSoldOutProduct => oldSoldOutProduct._id === product._id
            );
            let newSoldOutProducts;

            if (isUpdate === -1) {
                newSoldOutProducts = [...oldSoldOutProducts, product];
            } else {
                newSoldOutProducts = oldSoldOutProducts[isUpdate] = product;
            }

            return {
                ...state,
                soldOutProducts: newSoldOutProducts,
                isLoadingSoldOutProducts: false
            };
        }
        case actionTypes.SAVE_SOLD_OUT_PRODUCTS_ERROR:
            return {
                ...state,
                isLoadingSoldOutProducts: false,
                odooError: action.payload.error
            };
        case actionTypes.DELETE_SOLD_OUT_PRODUCTS_START: {
            const newSoldOutProducts = state.soldOutProducts.filter(
                soldOutProduct => String(soldOutProduct._id) !== String(action.payload)
            );

            return {
                ...state,
                soldOutProducts: newSoldOutProducts
            };
        }
        default:
            return state;
    }
};
