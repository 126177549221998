import analyticsReducer, { analyticsStorage, analyticsStateType } from "./analyticsReducer";
import authReducer, { authStorage, authStateType } from "./authReducer";
import brandReducer, { brandStorage, brandStateType } from "./brandReducer";
import uiReducer, { uiStorage, uiStateType } from "./uiReducer";
import bricksReducer, { bricksStorage, bricksStateType } from "./bricksReducer";
import deploymentReducer, { deploymentStorage, deploymentStateType } from "./deploymentReducer";
import dripReducer, { dripStorage, dripStateType } from "./dripReducer";
import elementsReducer, { elementsStorage, elementsStateType } from "./elementsReducer";
import fileReducer, { fileStorage, fileStateType } from "./fileReducer";
import hackertoolsReducer, { hackertoolsStorage, hackertoolsStateType } from "./hackertoolsReducer";
import languagesReducer, { languagesStorage, languagesStateType } from "./languagesReducer";
import ordersReducer, { ordersStorage, ordersStateType } from "./ordersReducer";
import pricingReducer, { pricingStorage, pricingStateType } from "./pricingReducer";
import productsReducer, { productsStorage, productsStateType } from "./productsReducer";
import setsReducer, { setsStorage, setsStateType } from "./setsReducer";
import spendReducer, { spendStorage, spendStateType } from "./spendReducer";
import systemReducer, { systemStorage, systemStateType } from "./systemReducer";
import tokensReducer, { tokensStorage, tokensStateType } from "./tokensReducer";
import treeReducer, { treeStorage, treeStateType } from "./treeReducer";
import upsellReducer, { upsellStorage, upsellStateType } from "./upsellReducer";
import whitelistReducer, { whitelistStorage, whitelistStateType } from "./whitelistReducer";
import conversionbalancerReducer, {
    conversionbalancerStorage,
    conversionbalancerStateType
} from "./conversionbalancerReducer";
import subscriptionsReducer, {
    subscriptionsStorage,
    subscriptionsStateType
} from "./subscriptionsReducer";
import productGroupsReducer, {
    productGroupStorage,
    productGroupStateType
} from "./productGroupsReducer";
import { combineReducers } from "redux";

export type appStateType = {
    analytics: analyticsStateType;
    auth: authStateType;
    brand: brandStateType;
    ui: uiStateType;
    bricks: bricksStateType;
    deployment: deploymentStateType;
    drip: dripStateType;
    elements: elementsStateType;
    file: fileStateType;
    hackertools: hackertoolsStateType;
    languages: languagesStateType;
    orders: ordersStateType;
    pricing: pricingStateType;
    products: productsStateType;
    sets: setsStateType;
    spend: spendStateType;
    system: systemStateType;
    tokens: tokensStateType;
    tree: treeStateType;
    upsell: upsellStateType;
    whitelist: whitelistStateType;
    conversionbalancer: conversionbalancerStateType;
    subscription: subscriptionsStateType;
    productGroup: productGroupStateType;
};

export const storageState: { [key: string]: { [key: string]: boolean } } = {
    analytics: analyticsStorage,
    auth: authStorage,
    brand: brandStorage,
    ui: uiStorage,
    bricks: bricksStorage,
    deployment: deploymentStorage,
    drip: dripStorage,
    elements: elementsStorage,
    file: fileStorage,
    hackertools: hackertoolsStorage,
    languages: languagesStorage,
    orders: ordersStorage,
    pricing: pricingStorage,
    products: productsStorage,
    sets: setsStorage,
    spend: spendStorage,
    system: systemStorage,
    tokens: tokensStorage,
    tree: treeStorage,
    upsell: upsellStorage,
    whitelist: whitelistStorage,
    conversionbalancer: conversionbalancerStorage,
    subscription: subscriptionsStorage,
    productGroup: productGroupStorage
};

const appReducer = combineReducers<appStateType>({
    analytics: analyticsReducer,
    auth: authReducer,
    brand: brandReducer,
    ui: uiReducer,
    bricks: bricksReducer,
    deployment: deploymentReducer,
    drip: dripReducer,
    elements: elementsReducer,
    file: fileReducer,
    hackertools: hackertoolsReducer,
    languages: languagesReducer,
    orders: ordersReducer,
    pricing: pricingReducer,
    products: productsReducer,
    sets: setsReducer,
    spend: spendReducer,
    system: systemReducer,
    tokens: tokensReducer,
    tree: treeReducer,
    upsell: upsellReducer,
    whitelist: whitelistReducer,
    conversionbalancer: conversionbalancerReducer,
    subscription: subscriptionsReducer,
    productGroup: productGroupsReducer
});

export const reducers = (state: any, action: { type: string; [key: string]: any }) => {
    if (action.type === "SIGN_OUT_USER") {
        localStorage.clear();
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};
