import * as actionTypes from "actionTypes";
import api from "api";
import socket from "../../websocket";
import store from "store";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import { appStateType } from "reducers";

socket.on("connect", () => {
    console.log("WebSocket connected");
});

socket.on("status", (data: any) => {
    store.dispatch({
        type: actionTypes.SET_DEPLOYMENT_STATUS,
        payload: data
    });
});

export function getLanguages() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_LANGUAGES_START
        });

        api()
            .get(`language`)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_LANGUAGES,
                    payload: { languages: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_LANGUAGES_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getLanguage(languageId: string) {
    return (dispatch: Dispatch) => {
        if (!languageId) {
            console.log("languageId is required");
            return;
        }

        api()
            .get(`language/set/${languageId}`)
            .then((res: AxiosResponse) => {
                const { language } = res.data;

                dispatch({
                    type: actionTypes.GET_CMS_LANGUAGE,
                    payload: { language }
                });
            })
            .catch((err: AxiosError) => console.log("GET CMS LANGUAGE ERROR: ", err.message));
    };
}

export function getSets() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SETS_START
        });

        api()
            .get("set")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_SETS,
                    payload: { sets: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_SETS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getSystem() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SYSTEM_START
        });

        api()
            .get("system")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_SYSTEM,
                    payload: { crons: res.data.system.crons }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_SYSTEM_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getCurriencies() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_CURRIENCIES_START
        });

        api()
            .get("pricing/currency")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_CURRIENCIES,
                    payload: { currencies: res.data.currencies }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_CURRIENCIES_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

interface GetKonnektiveProductsParams {
    campaignId?: number;
    konnektiveCrm?: string;
}

export function getKonnektiveProducts({
    campaignId,
    konnektiveCrm
}: GetKonnektiveProductsParams | undefined = {}) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_PRODUCTS_START
        });

        api()
            .get("product", {
                params: {
                    campaignId,
                    konnektiveCrm
                }
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_PRODUCTS,
                    payload: { products: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_PRODUCTS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getOdooProducts(isForced?: boolean) {
    return (dispatch: Dispatch, getState: any) => {
        const {
            products: { odooProducts }
        } = getState();

        if (odooProducts.length && !isForced) {
            return;
        }

        dispatch({
            type: actionTypes.GET_ODOO_PRODUCTS_START
        });

        api()
            .get("product/odoo/staging")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_ODOO_PRODUCTS,
                    payload: { odooProducts: res.data.allProducts || [] }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_ODOO_PRODUCTS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function getOdooProductsProd(isForced?: boolean) {
    return (dispatch: Dispatch, getState: any) => {
        const {
            products: { odooProductsProd }
        } = getState();

        if (odooProductsProd.length && !isForced) {
            return;
        }

        dispatch({
            type: actionTypes.GET_ODOO_PRODUCTS_PROD_START
        });

        api()
            .get("product/odoo/production")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_ODOO_PRODUCTS_PROD,
                    payload: { odooProductsProd: res.data.allProducts || [] }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_ODOO_PRODUCTS_PROD_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function getOdooProductsDev(isForced?: boolean) {
    return (dispatch: Dispatch, getState: any) => {
        const {
            products: { odooProductsDev }
        } = getState();

        if (odooProductsDev.length && !isForced) {
            return;
        }

        dispatch({
            type: actionTypes.GET_ODOO_PRODUCTS_DEV_START
        });

        api()
            .get("product/odoo/development")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_ODOO_PRODUCTS_DEV,
                    payload: { odooProductsDev: res.data.allProducts || [] }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_ODOO_PRODUCTS_DEV_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}
