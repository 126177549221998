import * as actionTypes from "actionTypes";
import api from "api";
import { Action, Dispatch } from "redux";
import { AxiosResponse, AxiosError } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { appStateType } from "reducers";

export function saveVersion({ ...data }) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.SAVE_VERSION_START,
            payload: { id: data._id }
        });

        api()
            .put("version", data)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.SAVE_VERSION,
                    payload: { version: res.data }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.SAVE_VERSION_ERROR,
                    payload: { error: err.response, id: data._id }
                })
            );
    };
}

export function removeVersion(versionId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_VERSION_START,
            payload: {
                id: versionId
            }
        });
        api()
            .delete("version", { data: { versionId } })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REMOVE_VERSION,
                    payload: { version: res.data.version }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REMOVE_VERSION_ERROR,
                    payload: { error: err.response, id: versionId }
                });
            });
    };
}

export function archieveVersion(versionId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_VERSION_START,
            payload: {
                id: versionId
            }
        });
        api()
            .put("version/status", { id: versionId, isRemoved: true })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REMOVE_VERSION,
                    payload: { version: res.data.version }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REMOVE_VERSION_ERROR,
                    payload: { error: err.response, id: versionId }
                });
            });
    };
}
