import * as React from "react";
import ReactDatePicker from "react-datepicker";
import styles from "./styles.scss";

type Props = {
    onChange: any;
    value: Date | null;
};

type ExampleCustomInputType = {
    value?: string;
    onClick?: () => void;
};

export const DatePicker: React.FC<Props> = ({ value, onChange }) => {
    const ExampleCustomInput = ({ value, onClick }: ExampleCustomInputType) => (
        <button className={styles.input} onClick={onClick}>
            {value ? value : "SELECT DATE..."}
        </button>
    );

    return (
        <ReactDatePicker
            customInput={<ExampleCustomInput />}
            selected={value}
            onChange={onChange}
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeSelect
        />
    );
};
