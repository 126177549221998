import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError } from "axios";
import { appStateType } from "reducers";
import { Dispatch } from "redux";

export function addTrigger(upsellsFlowId: string, variable: any) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_UPSELL_TRIGGER,
            payload: { upsellsFlowId, variable }
        });
    };
}

interface UpdateTriggerProps {
    upsellsFlowId: string;
    triggerId: string;
    key: string;
    value: string | boolean;
}

export function updateTrigger({ upsellsFlowId, triggerId, key, value }: UpdateTriggerProps) {
    return {
        type: actionTypes.UPDATE_UPSELL_TRIGGER,
        payload: { upsellsFlowId, triggerId, key, value }
    };
}

export function saveTriggers(upsellsFlowId: string) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const flow = getState().upsell.upsellsFlow.find(flow => flow._id === upsellsFlowId);

        dispatch({
            type: actionTypes.SAVE_TRIGGERS_START
        });

        api()
            .post("upsell/triggers", {
                upsellsFlowId,
                triggers: flow ? flow.triggers : []
            })
            .then(() => {
                dispatch({
                    type: actionTypes.SAVE_TRIGGERS_DONE
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.SAVE_TRIGGERS_DONE
                });

                console.error(err);
            });
    };
}
