import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import styles from "./styles.scss";
import ReactPaginate from "react-paginate";
import _ from "lodash";

export interface Props {
    onPageChange: (page: number, pageItems: unknown[]) => void;
    items: unknown[];
    itemsPerPage: number;
}

export const Pagination: React.FC<Props> = ({ onPageChange, items, itemsPerPage }) => {
    const [activePage, setActivePage] = useState(0);

    const onPageChangeHandler = (page: number) => {
        const pages = _.chunk(items, itemsPerPage);
        const pageItems = _.isEmpty(pages) ? [] : pages[page];

        onPageChange(page, pageItems);
        setActivePage(page);
    };

    useEffect(() => {
        onPageChangeHandler(0);
    }, [items]);

    const pageCount = Math.ceil(_.size(items) / itemsPerPage);
    if (pageCount <= 1) return <Fragment />;

    return (
        <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            forcePage={activePage}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={styles.pagination}
            activeLinkClassName={styles.activePage}
            onPageChange={({ selected }) => onPageChangeHandler(selected)}
        />
    );
};
