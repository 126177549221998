import axios, { AxiosInstance } from "axios";
import store from "store";
import { SIGN_OUT_USER } from "actionTypes";

const api = () => {
    let instance: AxiosInstance;
    const token = store.getState().auth.token;

    const headers = {};

    if (token != "") {
        headers["Authorization"] = token;
    }

    if (process.env.NODE_ENV == "development") {
        instance = axios.create({
            baseURL: process.env.baseURL,
            headers
        });
    } else {
        instance = axios.create({
            baseURL: "/api",
            headers
        });
    }

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                store.dispatch({
                    type: SIGN_OUT_USER
                });
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export default api;
