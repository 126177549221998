import * as React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import * as actions from "./actions";
import { appStateType } from "reducers";
import LoadingIndicator from "components/LoadingIndicator";
import _ from "lodash";
class Orders extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.pullOrders();
    }

    render() {
        const { orders, isPullingOrders } = this.props.orders;
        const groupedOrders = _(orders)
            .orderBy((order: any) => order.createdAt, "desc")
            .groupBy((order: any) => order.orderStatus)
            .value();

        if (isPullingOrders) return <LoadingIndicator />;
        return (
            <>
                <header>
                    <h1 className={styles.h1}>Summary</h1>
                </header>
                <main className={styles.grid}>
                    {Object.entries(groupedOrders).map(
                        ([key, value]: any, index: number) => {
                            const filterName = _.startCase(_.toLower(key));

                            return (
                                <div key={index} className={styles.order}>
                                    <p>{filterName} orders:</p>
                                    <p className={styles.order_length}>
                                        {value.length}
                                    </p>
                                </div>
                            );
                        }
                    )}
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    orders: state.orders
});

export default connect(
    mapStateToProps,
    actions
)(Orders);
