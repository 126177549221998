import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { appStateType } from "reducers";
import { upsellsFlow } from "types/upsellsFlow";
import {
    getUpsellContainer,
    getUpsellReport,
    getUpsellsFlow,
    updateUpsellsFlow
} from "./components/actions";
import Flow from "./components/Flow";
import FlowMenu from "./components/FlowMenu";
import TriggersEditor from "./components/TriggersEditor";
import UpsellEditor from "./components/UpsellEditor";
import styles from "./styles.scss";
import SwitchButton from "components/SwitchButton";
import { set } from "types/set";
import { DatePicker } from "components/DatePicker";

type Props = {
    elements: any[];
    languageId: string;
    nodeRender: (index: string) => void;
    schemaKey: string;
    setId: string;
    versionId: string;
    crm: set["crm"];
};

const UpsellsEditor: React.FC<Props> = ({
    elements,
    languageId,
    nodeRender,
    schemaKey,
    setId,
    versionId,
    crm
}) => {
    const dispatch = useDispatch();
    const upsellReducer = useSelector((state: appStateType) => state.upsell);
    const konnektiveUpsellProducts = useSelector(
        (state: appStateType) => state.products.konnektiveUpsellProducts
    );
    const odooProducts = useSelector((state: appStateType) => state.products.odooProducts);
    const odooProductsProd = useSelector((state: appStateType) => state.products.odooProductsProd);
    const odooProductsDev = useSelector((state: appStateType) => state.products.odooProductsDev);
    const [analyticsRange, setAnalyticsRange] = React.useState<{
        from: null | Date;
        to: null | Date;
        isDateChanged: Boolean;
    }>({
        from: null,
        to: null,
        isDateChanged: false
    });

    const { upsellsFlow, upsellContainer, isLoading, addUpsellsFlowLoading, isUpdating } =
        upsellReducer;

    let flowDisabled: boolean = false;
    const flows = upsellsFlow
        .filter((upsellsFlow: upsellsFlow) => {
            if (upsellsFlow.set === setId && upsellsFlow.language === languageId) {
                flowDisabled = flowDisabled || Boolean(upsellsFlow.disabled);
                return !upsellsFlow.isRemoved;
            }
            return false;
        })
        .sort((a, b) => Number(a.order) - Number(b.order));

    React.useEffect(() => {
        dispatch(getUpsellsFlow(setId));
        dispatch(getUpsellContainer(setId, languageId, crm));
    }, []);

    const basePath = `/dashboard/cms/${setId}/${languageId}/${versionId}/${schemaKey}`;
    const path = `/dashboard/cms/:setId/:languageId/:versionId/:schemaKey/:upsellsFlowId`;

    const handleAnalyticsRange = (name: string, date: null | Date) => {
        setAnalyticsRange({ ...analyticsRange, [name]: date, isDateChanged: true });
    };

    const switchUpsell = () => {
        dispatch(updateUpsellsFlow(setId, !flowDisabled));
    };

    const updateUpsellReport = () => {
        const { from, to, isDateChanged } = analyticsRange;
        if (!isDateChanged) {
            return;
        }

        dispatch(getUpsellReport({ setId, languageId, crm, from, to }));
        setAnalyticsRange({ ...analyticsRange, isDateChanged: false });
    };

    return (
        <div>
            <div className={styles.headerAction}>
                <div className={styles.disableBtn}>
                    <p>Disable All Upsells: </p>
                    <SwitchButton
                        isActive={flowDisabled}
                        onSwitch={switchUpsell}
                        className={styles.disableBtn}
                    />
                </div>
                <div className={styles.dateRange}>
                    <div className={styles.text}>From:</div>
                    <DatePicker
                        value={analyticsRange.from}
                        onChange={(date: Date | null) => handleAnalyticsRange("from", date)}
                    />
                    <div className={styles.text}>To:</div>
                    <DatePicker
                        value={analyticsRange.to}
                        onChange={(date: Date | null) => handleAnalyticsRange("to", date)}
                    />
                    <div className={styles.text}>
                        <button onClick={updateUpsellReport} type="button">
                            Get Report
                        </button>
                    </div>
                </div>
            </div>
            {isUpdating && (
                <div className={styles.fetchingChanges}>
                    <small>updating flows: </small>
                    <LoadingIndicator size={18} />
                </div>
            )}
            {!flowDisabled ? (
                <div className={classnames(styles.main, { [styles.disabledUpsell]: flowDisabled })}>
                    <div>
                        <FlowMenu
                            addUpsellsFlowLoading={addUpsellsFlowLoading}
                            basePath={basePath}
                            flows={flows}
                            languageId={languageId}
                            setId={setId}
                            upsellContainer={upsellContainer}
                            crm={crm}
                        />
                        {isLoading && (
                            <div className={styles.fetchingChanges}>
                                <small>Fetching flows: </small>
                                <LoadingIndicator size={18} />
                            </div>
                        )}
                    </div>
                    <Switch>
                        <Route
                            path={path}
                            render={(props: any) => (
                                <Flow
                                    {...props}
                                    elements={elements}
                                    products={konnektiveUpsellProducts}
                                    odooProducts={odooProducts}
                                    odooProductsProd={odooProductsProd}
                                    odooProductsDev={odooProductsDev}
                                    crm={crm}
                                />
                            )}
                            exact
                        />
                        <Route
                            path={`${path}/triggers`}
                            render={(props: any) => <TriggersEditor {...props} />}
                            exact
                        />
                        <Route
                            path={`${path}/:upsellContainerId/:elementId`}
                            render={(props: any) => (
                                <UpsellEditor
                                    {...props}
                                    elements={elements}
                                    nodeRender={nodeRender}
                                />
                            )}
                            exact
                        />
                    </Switch>
                </div>
            ) : null}
        </div>
    );
};

export default UpsellsEditor;
