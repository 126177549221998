import * as React from "react";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { addUpsellContainer } from "../UpsellContainer/actions";
import { useDispatch } from "react-redux";
import cx from "classnames";

type Props = {
    addUpsellContainerLoading: boolean;
    upsellsFlowId: string;
    marginTop?: boolean;
    totalContainers: number;
};

const AddUpsellContainer: React.FC<Props> = ({
    addUpsellContainerLoading,
    upsellsFlowId,
    marginTop = false,
    totalContainers
}) => {
    const dispatch = useDispatch();

    return (
        <div
            className={cx(styles.addContainer, {
                [styles.marginTop]: marginTop
            })}
        >
            {addUpsellContainerLoading ? (
                <LoadingIndicator />
            ) : (
                <Button
                    design="circle"
                    size="medium"
                    icon="add"
                    onClick={() =>
                        dispatch(
                            addUpsellContainer({
                                isDownsell: false,
                                upsellsFlowId,
                                title: "New Upsell",
                                index: totalContainers
                            })
                        )
                    }
                ></Button>
            )}
        </div>
    );
};

export default AddUpsellContainer;
