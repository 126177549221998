import * as React from "react";
import Clone from "assets/icons/clone.svg";
import EditName from "assets/icons/edit-name.svg";
import Plus from "assets/icons/plus.svg";
import SVG from "react-inlinesvg";
import Trash from "assets/icons/trash.svg";
import Currency from "assets/icons/currency.svg";
import cx from "classnames";
import styles from "./styles.scss";

export type Props = {
    [key: string]: any;
    background?: "green" | "purple" | "darkPurple" | "pinkGradient";
    className?: string;
    children?: React.ReactNode;
    design?: "button" | "circle" | "icon";
    flat?: boolean;
    icon?: "add" | "substract" | "editName" | "trash" | "clone" | "currency";
    marginLeft?: boolean;
    marginRight?: boolean;
    marginTop?: boolean;
    size?: "tiny" | "small" | "medium";
};

const Button: React.FC<Props> = ({
    background,
    children,
    className,
    design = "button",
    flat = false,
    icon = "add",
    marginLeft = false,
    marginRight = false,
    marginTop = false,
    size,
    ...props
}) => {
    const marginClasses = {
        [styles.marginRight]: marginRight,
        [styles.marginLeft]: marginLeft,
        [styles.marginTop]: marginTop
    };

    const sizeClasses = {
        [styles.tiny]: size === "tiny",
        [styles.small]: size === "small",
        [styles.medium]: size === "medium"
    };

    let colorClasses = {
        [styles.green]: background === "green",
        [styles.pinkGradient]: background === "pinkGradient",
        [styles.purple]: background === "purple",
        [styles.darkPurple]: background === "darkPurple"
    };

    const renderIcon = (icon: string) => {
        switch (icon) {
            case "add":
            case "substract":
                return <SVG src={Plus} />;
            case "editName":
                return <SVG src={EditName} />;
            case "trash":
                return <SVG src={Trash} />;
            case "clone":
                return <SVG src={Clone} />;
            case "currency":
                return <SVG src={Currency} />;
            default:
                return null;
        }
    };

    switch (design) {
        case "button":
            return (
                <button
                    className={cx(styles.button, className, {
                        ...marginClasses,
                        ...colorClasses,
                        [styles.flat]: flat,
                        ...sizeClasses
                    })}
                    {...props}
                >
                    {children}
                </button>
            );
        case "circle":
            return (
                <div
                    {...props}
                    className={cx(className, {
                        [styles.circleButton]: design === "circle",
                        [styles.add]: icon === "add",
                        [styles.substract]: icon === "substract",
                        [styles.circleBorder]: icon === "clone",
                        ...marginClasses,
                        ...sizeClasses
                    })}
                >
                    <div className={cx(styles.circle, colorClasses)}>{renderIcon(icon)}</div>
                    {children && <div className={styles.children}>{children}</div>}
                </div>
            );
        case "icon":
            return (
                <div
                    {...props}
                    className={cx(styles.greyIcon, className, {
                        ...marginClasses,
                        ...sizeClasses,
                        ...colorClasses
                    })}
                >
                    <div>{renderIcon(icon)}</div>
                    {children && <div className={styles.children}>{children}</div>}
                </div>
            );
        default:
            return <></>;
    }
};

export default Button;
