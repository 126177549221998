import * as types from "actionTypes";
import { language } from "types/language";

export type languagesStateType = {
    languagesMap: Map<string, object>;
    language: language | null;
    isLoading: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: languagesStateType = {
    languagesMap: new Map(),
    language: null,
    isLoading: false
};

export const languagesStorage: { [key: string]: boolean } = {
    languagesMap: true
};

export default (state: languagesStateType = initialState, action: IAction): languagesStateType => {
    let language: language, newLanguages: languagesStateType["languagesMap"];
    switch (action.type) {
        case types.GET_LANGUAGES_START:
            return {
                ...state,
                languagesMap: new Map(),
                isLoading: true
            };
        case types.GET_LANGUAGES:
            newLanguages = new Map(state.languagesMap);
            action.payload.languages.forEach((language: language) =>
                newLanguages.set(language._id, language)
            );

            return {
                ...state,
                languagesMap: newLanguages,
                isLoading: false
            };
        case types.GET_LANGUAGES_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case types.ADD_LANGUAGE_START:
            return {
                ...state,
                isLoading: true
            };
        case types.ADD_LANGUAGE:
            newLanguages = new Map(state.languagesMap);
            language = action.payload.language;
            newLanguages.set(language._id, language);
            return {
                ...state,
                languagesMap: newLanguages,
                isLoading: false
            };
        case types.ADD_LANGUAGE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case types.DELETE_LANGUAGE_START:
            return {
                ...state,
                isLoading: true
            };
        case types.DELETE_LANGUAGE:
            newLanguages = new Map(state.languagesMap);
            language = action.payload.language;
            newLanguages.delete(language._id);
            return {
                ...state,
                languagesMap: newLanguages,
                isLoading: false
            };
        case types.DELETE_LANGUAGE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case types.EDIT_LANGUAGE_START:
            return {
                ...state,
                isLoading: true
            };
        case types.EDIT_LANGUAGE:
            newLanguages = new Map(state.languagesMap);
            language = action.payload.language;
            newLanguages.set(language._id, language);
            return {
                ...state,
                languagesMap: newLanguages,
                isLoading: false
            };
        case types.EDIT_LANGUAGE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case types.UPDATE_LANGUAGE_FIELD:
            const { id, name, value } = action.payload;
            newLanguages = new Map(state.languagesMap);
            language = {
                ...(newLanguages.get(id) as language),
                [name]: value
            };
            newLanguages.set(id, language);
            return {
                ...state,
                languagesMap: newLanguages
            };
        case types.GET_CMS_LANGUAGE: {
            const { language } = action.payload;

            return {
                ...state,
                language
            };
        }
        default:
            return state;
    }
};
