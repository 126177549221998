import * as React from "react";

class ServerFields extends React.PureComponent<any, any> {
    render() {
        const {
            mode,
            host,
            username,
            password,
            domain,
            handleOnChange,
            whitelist
        } = this.props;

        return (
            <>
                <h2>{mode === "edit" ? "Edit" : "Add"} Server:</h2>
                <input
                    name="host"
                    onChange={handleOnChange}
                    placeholder="Host"
                    type="text"
                    value={host}
                />
                <input
                    name="username"
                    onChange={handleOnChange}
                    placeholder="Username"
                    type="text"
                    value={username}
                />
                <input
                    autoComplete="new-password"
                    name="password"
                    onChange={handleOnChange}
                    placeholder="Password"
                    type="password"
                    value={password}
                />
                <select name="domain" onChange={handleOnChange} value={domain}>
                    <option value="disabledDomain" disabled>
                        Domain
                    </option>
                    {whitelist.map((domain: any) => (
                        <option value={domain._id} key={domain._id}>
                            {domain.name}
                        </option>
                    ))}
                </select>
            </>
        );
    }
}

export default ServerFields;
