import React from "react";
import styles from "./styles.scss";
import clsx from "classnames";

const Tabs: React.FC<any> = props => {
    const { clickHandler, active, tabList } = props;
    return (
        <div className={clsx(styles.tabbed, styles.round)}>
            <ul>
                <li
                    key={"new"}
                    data-tab={"new"}
                    onClick={clickHandler}
                    className={clsx({ [styles.active]: active === "new" })}
                >
                    new
                </li>
                {tabList.map((el: string) => (
                    <li
                        key={el}
                        data-tab={el}
                        onClick={clickHandler}
                        className={clsx({ [styles.active]: active === el })}
                    >
                        {el}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Tabs;
