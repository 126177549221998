import * as React from "react";
const { useEffect } = React;
import styles from "./styles.scss";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";
import { useDispatch } from "react-redux";
import { getKonnektiveProducts } from "../actions";
import { getSoldOutProducts, addSoldOutProduct, deleteSoldOutProduct } from "./actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

type SelectOption = {
    value: string;
    label: string;
};

const SoldOutProducts: React.FC = () => {
    const dispatch = useDispatch();
    const {
        uniqueKonnektiveOfferProducts,
        uniqueKonnektiveUpsellProducts,
        soldOutProducts,
        isLoading
    } = useSelector((state: appStateType) => state.products);
    const [selectedOption, setSelectedOption] = React.useState<SelectOption | null>();
    const allKonnektiveProducts = [
        ...uniqueKonnektiveOfferProducts,
        ...uniqueKonnektiveUpsellProducts
    ];

    useEffect(() => {
        dispatch(getKonnektiveProducts());
        dispatch(getSoldOutProducts());
    }, []);

    const options: SelectOption[] = allKonnektiveProducts.map(product => ({
        label: `(${product.productId}) ${product.productName}`,
        value: product.productId
    }));

    const handleSelect = selectedOption => {
        setSelectedOption(selectedOption);
    };

    const handleAddSoldOutProduct = e => {
        e.preventDefault();

        if (selectedOption) {
            dispatch(addSoldOutProduct({ productId: selectedOption.value }));
        }

        setSelectedOption(null);
    };

    const handleDeleteSoldOutProduct = id => e => {
        e.preventDefault();
        dispatch(deleteSoldOutProduct(id));
    };

    return (
        <>
            <header className={styles.header}>
                <h1>Sold Out Products</h1>
            </header>
            <main>
                {soldOutProducts.map(soldOutProduct => {
                    const konnektiveProduct = allKonnektiveProducts.find(
                        konnektiveProduct =>
                            String(soldOutProduct.productId) === String(konnektiveProduct.productId)
                    );

                    return (
                        <div className={styles.product}>
                            <button
                                className={styles.delete}
                                onClick={handleDeleteSoldOutProduct(soldOutProduct._id)}
                            >
                                X
                            </button>
                            ({soldOutProduct.productId}){" "}
                            {konnektiveProduct?.productName ||
                                (isLoading
                                    ? "Loading konnektive product name..."
                                    : "PRODUCT NOT FOUND!")}
                        </div>
                    );
                })}
                <div className={styles.newspace}>
                    <Select
                        isDisabled={isLoading}
                        className={styles.select}
                        options={options}
                        components={animatedComponents}
                        placeholder={
                            isLoading ? "Loading all possible products..." : "Select Product"
                        }
                        onChange={handleSelect}
                        value={selectedOption}
                    />
                    <div className={styles.button} onClick={handleAddSoldOutProduct}>
                        ADD NEW SOLD OUT PRODUCT
                    </div>
                </div>
            </main>
        </>
    );
};

export default SoldOutProducts;
