import * as actionTypes from "actionTypes";

export type ordersStateType = {
    orders: object[];
    isPullingOrders: boolean;
    pullingErrors: null | Error;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: ordersStateType = {
    orders: [],
    isPullingOrders: false,
    pullingErrors: null
};

export const ordersStorage: { [key: string]: boolean } = {};

export default (state: ordersStateType = initialState, action: IAction): ordersStateType => {
    switch (action.type) {
        case actionTypes.START_PULL_ORDERS:
            return {
                ...state,
                isPullingOrders: true
            };
        case actionTypes.PULL_ORDERS:
            return {
                ...state,
                isPullingOrders: false,
                orders: action.payload.orders
            };
        case actionTypes.ERROR_PULL_ORDERS:
            return {
                ...state,
                isPullingOrders: false,
                pullingErrors: action.payload.error
            };
        default:
            return state;
    }
};
