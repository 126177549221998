import * as React from "react";
import {
    buildChartTheme,
    AnimatedAxis,
    AnimatedGrid,
    AnimatedLineSeries,
    XYChart,
    Tooltip
} from "@visx/xychart";
import { scaleOrdinal } from "@visx/scale";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";

const accessors = {
    xAccessor: d => d.x,
    yAccessor: d => d.y
};

const LineChart = ({ data }) => {
    const customTheme = buildChartTheme({
        backgroundColor: "#ffffff",
        colors: data.map(({ color }) => color),
        gridColor: "#111111",
        gridColorDark: "#222222",
        tickLength: 1
    });

    return (
        <XYChart
            theme={customTheme}
            height={400}
            xScale={{ type: "band" }}
            yScale={{ type: "linear" }}
        >
            <AnimatedAxis orientation="bottom" />
            <AnimatedAxis orientation="left" />
            <AnimatedGrid columns={false} numTicks={4} />
            {data.map(({ title, lineData }) => (
                <AnimatedLineSeries dataKey={title} data={lineData} {...accessors} />
            ))}
            <Tooltip
                snapTooltipToDatumX
                showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }) => {
                    if (!tooltipData || !colorScale) return null;

                    return (
                        <div>
                            <div>Day: {(tooltipData as any).nearestDatum.datum.x}</div>
                            {Object.entries(tooltipData.datumByKey).map(([key, val]) => (
                                <div>
                                    <span style={{ color: colorScale(key) }}>{key}: </span>
                                    <span>{accessors.yAccessor(val.datum)}</span>
                                </div>
                            ))}
                        </div>
                    );
                }}
            />
        </XYChart>
    );
};

export const CCDripChart = ({ data }) => {
    const ordinalColorScale = scaleOrdinal({
        domain: data.map(({ title }) => title),
        range: data.map(({ color }) => color)
    }) as any;

    return (
        <div className="App">
            <LineChart data={data} />
            <LegendOrdinal
                scale={ordinalColorScale}
                labelFormat={label => `${(label as any).toUpperCase()}`}
            >
                {labels => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {labels.map((label, i) => (
                            <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                                <svg width={20} height={20}>
                                    <rect fill={label.value} width={20} height={20} />
                                </svg>
                                <LegendLabel align="left" margin="0 0 0 4px">
                                    {label.text}
                                </LegendLabel>
                            </LegendItem>
                        ))}
                    </div>
                )}
            </LegendOrdinal>
        </div>
    );
};
