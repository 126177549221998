import cx from "classnames";
import * as React from "react";
import { brick } from "types/brick";
import Headline from "../../components/Headline";
import styles from "./styles.scss";
import Button from "components/Button";
import arrowIcon from "assets/icons/chevron-up.svg";
import SVG from "react-inlinesvg";
const { useState, useEffect } = React;

type Props = {
    key: string;
    name: string;
    node: any;
    elements: any[];
    nodeRender: (index: string) => void;
    addArrayElement: () => void;
    removeArrayElement: any;
    path: string;
    forceInvalid: string;
    description?: string;
    isNarrow?: boolean;
};

const ArrayEditor: React.FC<Props> = ({
    name,
    elements = [],
    nodeRender,
    addArrayElement,
    removeArrayElement,
    path,
    forceInvalid,
    description = "",
    isNarrow
}) => {
    const [selectedItem, setSelectedItem] = useState<number>(0);

    useEffect(() => {
        setSelectedItem(0);
    }, []);

    let selectedBrick = elements[selectedItem];

    if (Array.isArray(selectedBrick)) {
        selectedBrick = selectedBrick[0];
    }

    const handleRemoveClick = (e: React.MouseEvent) => {
        e.preventDefault();

        let elementId;
        let deleteParent;

        if (selectedBrick) {
            if (selectedBrick.hasOwnProperty("_id")) {
                elementId = selectedBrick.element;
                deleteParent = false;
            } else {
                const values = Object.values(selectedBrick) as brick[];
                let tryId = 0;

                do {
                    if (!!values[tryId]) {
                        elementId = values[tryId].hasOwnProperty("element")
                            ? values[tryId].element
                            : null;
                    }

                    tryId++;
                } while (elementId === null);

                deleteParent = true;
            }
        }

        removeArrayElement(path + "." + selectedItem, elementId, deleteParent);

        setSelectedItem(prevSelectedItem => {
            let newIndex = prevSelectedItem - 1;
            if (newIndex < 0) newIndex = 0;

            return newIndex;
        });
    };

    const handleAddClick = () => {
        if (elements[elements.length - 1] != null) {
            setSelectedItem(elements.length);
            addArrayElement();
        }
    };

    const menuElements = [...elements];
    if (menuElements.length === 0) menuElements.push(null);

    const menuLenght = menuElements.length;

    const isGroup = !path.includes(".");

    return (
        <div
            className={styles.editor}
            id={elements.length === 0 || forceInvalid ? "invalid-element" : ""}
        >
            <Headline name={name} description={description} isGroup={isGroup} isHigh={isGroup}>
                <>
                    <div className={`${styles.menu} ${isGroup && styles.noMargin}`}>
                        <div className={`${styles.option} ${styles.active}`}>
                            {selectedItem !== 0 && (
                                <SVG
                                    src={arrowIcon}
                                    className={styles.up}
                                    onClick={() => setSelectedItem(selectedItem - 1)}
                                />
                            )}
                            {selectedItem + 1} / {menuElements.length}
                            {selectedItem + 1 < menuLenght && (
                                <SVG
                                    src={arrowIcon}
                                    className={styles.down}
                                    onClick={() => setSelectedItem(selectedItem + 1)}
                                />
                            )}
                        </div>
                        <div className={styles.add} onClick={handleAddClick}>
                            +
                        </div>
                        <Button
                            className={styles.delete}
                            onClick={handleRemoveClick}
                            title="Remove"
                            icon="trash"
                            design="icon"
                            marginRight
                        />
                    </div>
                    <div
                        className={cx({
                            [styles.children]: !isNarrow
                        })}
                    >
                        <>
                            <div />
                            {nodeRender(String(selectedItem))}
                        </>
                    </div>
                </>
            </Headline>
        </div>
    );
};
export default ArrayEditor;
