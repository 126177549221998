import * as React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";

type LinkProps = {
    to: string;
    type: "LINK" | "EDIT" | "REMOVE";
    children?: React.ReactNode;
};

const StyledLink: React.FC<LinkProps> = props => {
    const { to, type, children } = props;

    return (
        <Link to={to} className={styles.link}>
            {children}
        </Link>
    );
};

export default StyledLink;
