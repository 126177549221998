import * as types from "actionTypes";
import { brick } from "types/brick";

export type bricksStateType = {
    bricksMap: Map<string, object>;
    isLoading: boolean;
    isLoadingVariants: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: bricksStateType = {
    bricksMap: new Map(),
    isLoading: false,
    isLoadingVariants: false
};

export const bricksStorage: { [key: string]: boolean } = {
    bricksMap: true
};

export default (state: bricksStateType = initialState, action: IAction): bricksStateType => {
    let brick: brick, bricks: brick[], newBricks: bricksStateType["bricksMap"];
    switch (action.type) {
        case types.GET_BRICKS_START:
            return {
                ...state,
                bricksMap: new Map(),
                isLoading: true
            };
        case types.GET_BRICKS:
            newBricks = new Map(state.bricksMap);
            action.payload.bricks.forEach((brick: brick) => newBricks.set(brick._id, brick));

            return {
                ...state,
                bricksMap: newBricks,
                isLoading: false
            };
        case types.GET_BRICKS_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case types.DELETE_BRICKS:
            newBricks = new Map(state.bricksMap);
            action.payload.bricks.forEach((brick: brick) => newBricks.delete(brick._id));
            return {
                ...state,
                bricksMap: newBricks,
                isLoading: false
            };
        case types.ADD_BRICK_VARIANT_START:
            return {
                ...state,
                isLoadingVariants: true
            };
        case types.ADD_BRICK_VARIANT:
            newBricks = new Map(state.bricksMap);
            const { brick } = action.payload;
            newBricks.set(brick._id, brick);

            return {
                ...state,
                bricksMap: newBricks,
                isLoadingVariants: false
            };
        case types.ADD_BRICK_VARIANT_ERROR:
            return {
                ...state,
                isLoadingVariants: false
            };
        case types.UPDATE_VARIANT_FIELD:
            let { id, variantIndex, value } = action.payload;
            newBricks = new Map(state.bricksMap);

            let oldBrick = newBricks.get(id) as brick;
            const newVariants = [...oldBrick.variants];
            newVariants[variantIndex] = value;

            let newBrick = {
                ...oldBrick,
                variants: newVariants
            };

            newBricks.set(id, newBrick);

            return {
                ...state,
                bricksMap: newBricks
            };
        case types.DELETE_VARIANTS:
            let { id: elementId, order } = action.payload;

            newBricks = new Map(state.bricksMap);
            newBricks.forEach((value: brick) => {
                if (value._id == order) {
                    oldBrick = newBricks.get(order) as brick;

                    newBrick = {
                        ...oldBrick,
                        variants: []
                    };

                    newBricks.set(order, newBrick);
                }
            });

            return {
                ...state,
                bricksMap: newBricks
            };
        default:
            return state;
    }
};
