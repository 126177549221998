import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";
import { appStateType } from "reducers";
import socket from "../../../../../websocket";
import * as moment from "moment-timezone";

socket.on("wildcard", () => {
    console.log("new wildcard created");
});

export function createOrRenew() {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.ADD_NEW_WILDCARD_START
        });

        api()
            .get("hackertools/wildcard")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_NEW_WILDCARD,
                    payload: {
                        ip: res.data.ip,
                        validTo: moment()
                            .add(res.data.time, "seconds")
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")
                            .toString()
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ADD_NEW_WILDCARD_ERROR,
                    payload: {
                        error: err.response ? err.response.data || "" : err.response
                    }
                });
            });
    };
}
