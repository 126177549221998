import * as React from "react";
import Button, { Props as ButtonProps } from "components/Button";
import styles from "./styles.scss";

interface Props {
    children: React.ReactChild;
}

const InsetButton: React.FC<ButtonProps> = props => {
    const { children, ...rest } = props;

    return (
        <Button {...rest}>
            <div className={styles.insetButton}>{props.children}</div>
        </Button>
    );
};

export const Inset: React.FC<Props> = props => {
    const { children, ...rest } = props;

    return (
        <div {...rest} className={styles.inset}>
            {props.children}
        </div>
    );
};

export default InsetButton;
