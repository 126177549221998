import * as React from "react";
import * as ReactDOM from "react-dom";
import styles from "./styles.scss";

const modalRoot: HTMLElement | null = document.getElementById("modal-root");

type ModalProps = {
    children?: any;
    className?: string;
    onClose: () => void;
    title?: string;
};

class Modal extends React.PureComponent<ModalProps, any> {
    el: HTMLElement;

    constructor(props: any) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount() {
        modalRoot!.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot!.removeChild(this.el);
    }

    render() {
        const { children, className, onClose, title } = this.props;

        return ReactDOM.createPortal(
            <div className={styles.modal}>
                <div className={`${styles.content} ${className}`}>
                    <header>
                        {title && <p className={styles.title}>{title}</p>}
                        <div className={styles.closeButton}>
                            <img
                                onClick={onClose}
                                src="/img/svg/close.svg"
                                alt="X"
                            />
                        </div>
                    </header>
                    {children}
                </div>
                <div className={styles.bg} onClick={onClose} />
            </div>,
            this.el
        );
    }
}

export default Modal;
