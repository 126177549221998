import React from "react";
import styles from "./styles.scss";
import JsonFormatter from "components/JsonFormatter";
import { FFFunnel } from "types/FFFunnel";
import { useDispatch } from "react-redux";
import { balanceConversions } from "./actions";

type Props = {
    id: string;
    createdAt: string;
    createdBy: string;
    funnel: FFFunnel;
    funnelCopy: FFFunnel;
    fromDate: string;
    toDate: string;
    googleAdsCampaigns: { [k: string]: any } | null;
    getFunnelError: { [k: string]: any } | null;
    creatingGoogleAdsClientError: { [k: string]: any } | null;
    pullingGoogleAdsCampaignsError: { [k: string]: any } | null;
    bigQueryError: { [k: string]: any } | null;
    bigQueryRows: { [k: string]: any } | null;
    totalBigQueryRows: { [k: string]: any } | null;
    totalBigQueryError: { [k: string]: any } | null;
    googleConversionsError: { [k: string]: any } | null;
    googleConversions: { [k: string]: any } | null;
    googleConversionsOriginalResponse: { [k: string]: any } | null;
    otherError: { [k: string]: any } | null;
    conversionsDifference: number;
    bqSessions: { [k: string]: any } | null;
    bqSessionsError: { [k: string]: any } | null;
    conversionsDraft: { [k: string]: any } | null;
    conversionsDraftError: { [k: string]: any } | null;
    setActiveReportTabId?: (tab: string) => void;
};

const Report: React.FC<Props> = ({
    id,
    createdAt,
    createdBy,
    funnel,
    funnelCopy,
    fromDate,
    toDate,
    googleAdsCampaigns,
    getFunnelError,
    creatingGoogleAdsClientError,
    pullingGoogleAdsCampaignsError,
    bigQueryRows,
    bigQueryError,
    totalBigQueryRows,
    totalBigQueryError,
    otherError,
    googleConversionsError,
    googleConversions,
    googleConversionsOriginalResponse,
    conversionsDifference,
    bqSessions,
    bqSessionsError,
    conversionsDraft,
    conversionsDraftError,
    setActiveReportTabId
}) => {
    const dispatch = useDispatch();
    const decorateHeadline = (headline: string, value: any) => `${headline} ${value ? "❌" : "✅"}`;

    const handleClickBalance = (reportId: string) => (e: React.SyntheticEvent<HTMLDivElement>) => {
        dispatch(balanceConversions(reportId));
        setActiveReportTabId && setActiveReportTabId("SHOW_DRAFTS");
    };

    return (
        <>
            <div className={styles.report}>
                <div>Created At: {createdAt}</div>
                <div>Created by: {createdBy}</div>
                <div>Funnel: {funnelCopy?.name || funnel?.name || "N/A"}</div>
                <div>From date: {fromDate}</div>
                <div>To date: {toDate}</div>
                <br />
                <div>ADDITIONAL DATA:</div>
                <div>
                    Google Ads Account Id:{" "}
                    {funnelCopy?.googleAdAccountId || funnel?.googleAdAccountId || "N/A"}
                </div>
                <JsonFormatter headline="Funnel Copy:" body={funnelCopy} />
                <JsonFormatter headline="Google Ads Campaigns:" body={googleAdsCampaigns} />
                <JsonFormatter
                    headline="Google Ads Conversions Query Response:"
                    body={googleConversionsOriginalResponse}
                />
                <br />
                <div>ERRORS:</div>
                <JsonFormatter
                    headline={decorateHeadline("Quering Google Big Query:", totalBigQueryError)}
                    body={totalBigQueryError}
                />
                <JsonFormatter
                    headline={decorateHeadline(
                        "Quering Google Big Query (with utm_campaign filter):",
                        bigQueryError
                    )}
                    body={bigQueryError}
                />
                <JsonFormatter
                    headline={decorateHeadline("Pulling funnels:", getFunnelError)}
                    body={getFunnelError}
                />
                <JsonFormatter
                    headline={decorateHeadline(
                        "Creating google ads client:",
                        creatingGoogleAdsClientError
                    )}
                    body={creatingGoogleAdsClientError}
                />
                <JsonFormatter
                    headline={decorateHeadline(
                        "Pulling google ads campaigns:",
                        pullingGoogleAdsCampaignsError
                    )}
                    body={pullingGoogleAdsCampaignsError}
                />
                <JsonFormatter
                    headline={decorateHeadline(
                        "Google Ads Views & Convertions:",
                        googleConversionsError
                    )}
                    body={googleConversionsError}
                />
                <JsonFormatter
                    headline={decorateHeadline("Other:", otherError)}
                    body={otherError}
                />
                <JsonFormatter
                    headline={decorateHeadline(
                        "Pulling BQ session objects with conversion:",
                        bqSessionsError
                    )}
                    body={bqSessionsError}
                />
                <JsonFormatter
                    headline={decorateHeadline("Draft conversions:", conversionsDraftError)}
                    body={conversionsDraftError}
                />
                <br />
                <div>REPORT:</div>
                {totalBigQueryRows && (
                    <>
                        <div>MPE Views: {totalBigQueryRows[0].views}</div>
                        <div>MPE Conversions: {totalBigQueryRows[0].conversions}</div>
                    </>
                )}
                {bigQueryRows && (
                    <>
                        <div>MPE Views (with utm_campaign filter): {bigQueryRows[0].views}</div>
                        <div>
                            MPE Conversions (with utm_campaign filter):{" "}
                            {bigQueryRows[0].conversions}
                        </div>
                    </>
                )}
                <div>
                    Google Ads Conversions:{" "}
                    {googleConversions ? googleConversions.conversions : "N/A"}
                </div>
                <br />
                {conversionsDifference && conversionsDifference > 0 ? (
                    <>
                        <div>BALANCER DRAFT:</div>
                        <div>Conversions difference: {conversionsDifference}</div>
                        <JsonFormatter
                            headline={"BQ session objects with conversion:"}
                            body={bqSessions}
                        />
                        <JsonFormatter
                            headline={"This balancing process will run following conversions:"}
                            body={conversionsDraft}
                        />
                    </>
                ) : (
                    <div>No balancer draft for ths report!</div>
                )}
                <div className={styles.button} onClick={handleClickBalance(id)}>
                    BALANCE {conversionsDifference} CONVERSIONS
                </div>
            </div>
        </>
    );
};

export default Report;
