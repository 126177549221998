import * as React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import * as orderActions from "../../actions";
import { appStateType } from "reducers";
import LoadingIndicator from "components/LoadingIndicator";
import getDollars from "services/getDollars";
import _ from "lodash";
import Address from "./components/Address";

interface Props {
    match: {
        params: {
            id: String;
        };
    };
}
class SingleOrder extends React.PureComponent<any, Props> {
    constructor(props = {}) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.pullOrders();
    }
    onChange(id: string, event: React.SyntheticEvent) {
        event.preventDefault();
        const { value } = event.target as HTMLInputElement;
        this.props.updateNote(id, value);
    }

    createDate(date: Date) {
        const dateString = new Date(date).toLocaleDateString("en-US");
        const timeString = new Date(date).toLocaleTimeString(
            new Intl.DateTimeFormat().resolvedOptions().locale
        );
        return `${dateString} ${timeString}`;
    }

    render() {
        const { order } = this.props;
        if (!order) return <LoadingIndicator />;

        return (
            <>
                <header>
                    <h1>Order</h1>
                </header>
                <main>
                    <div className={styles.panel_wrapper}>
                        <div className={styles.order_panel}>
                            <div className={styles.order_summary}>
                                <p>Brand: {order.brand}</p>
                                {order.paymentType && (
                                    <p>Payment: {order.paymentType}</p>
                                )}
                                <p>
                                    Total:{" "}
                                    {getDollars(
                                        order.total + order.shippingPrice
                                    )}
                                </p>
                                <p>Template: v{order.templateVersion}</p>
                                <p>Date: {this.createDate(order.createdAt)}</p>
                            </div>
                        </div>
                    </div>
                    {order.hasOwnProperty("lineItems") > 0 &&
                        order.lineItems.map((lineItem: any, index: number) => {
                            return (
                                <div
                                    className={styles.order_single}
                                    key={index}
                                >
                                    <p>{index + 1}</p>
                                    <p>Product: {lineItem.title}</p>
                                    <p>Quantity: {lineItem.quantity}</p>
                                    <p>Price: {getDollars(lineItem.price)}</p>
                                </div>
                            );
                        })}
                    {order.customer && (
                        <div className={styles.panel_wrapper}>
                            <div className={styles.order_address}>
                                <Address
                                    headline="Shipping address"
                                    data={order.customer.shippingAddress}
                                />
                            </div>
                            <div className={styles.order_address}>
                                <Address
                                    headline="Billing address"
                                    data={order.customer.billingAddress}
                                />
                            </div>
                        </div>
                    )}
                </main>
            </>
        );
    }
}

const getOrder = (state: { orders: any }, id: String = "") => {
    const { orders = [] } = state;
    const order = orders.find((order: any) =>
        order ? order._id == id : false
    );
    return order ? order : {};
};

const mapStateToProps = (state: appStateType, props: Props) => ({
    orders: state.orders,
    order: getOrder(state.orders, props.match.params.id)
});

export default connect(
    mapStateToProps,
    { ...orderActions }
)(SingleOrder);
