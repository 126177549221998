import * as actionTypes from "actionTypes";
import { IImpressionReport } from "types/report";
import { Filter } from "../scenes/Dashboard/Analytics/Scenes/CMSAnalytics/actions";

export type analyticsStateType = {
    report: any;
    impressionReport: IImpressionReport[];
    isLoading: boolean;
    errors: object[];
    adAccounts: object[];
    filters: { [key in Filter]: string[] };
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: analyticsStateType = {
    report: [],
    impressionReport: [],
    isLoading: false,
    errors: [],
    adAccounts: [],
    filters: {
        paymentMethod: []
    }
};

export const analyticsStorage: { [key: string]: boolean } = {};

export default (state: analyticsStateType = initialState, action: IAction): analyticsStateType => {
    switch (action.type) {
        case actionTypes.GET_REPORT_START:
            return {
                ...state,
                report: [],
                errors: [],
                isLoading: true
            };
        case actionTypes.GET_REPORT:
            return {
                ...state,
                report: action.payload.report,
                isLoading: false
            };
        case actionTypes.GET_REPORT_ERROR:
            return {
                ...state,
                report: [],
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.GET_IMPRESSION_REPORT_START:
            return {
                ...state,
                report: [],
                errors: [],
                isLoading: true
            };
        case actionTypes.GET_IMPRESSION_REPORT:
            return {
                ...state,
                impressionReport: action.payload.report,
                isLoading: false
            };
        case actionTypes.GET_IMPRESSION_REPORT_ERROR:
            return {
                ...state,
                report: [],
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.GET_ADACCOUNTS:
            return {
                ...state,
                adAccounts: action.payload.adaccounts
            };
        case actionTypes.ASSIGN_ADACCOUNT:
            const adAccounts = state.adAccounts.map((account: any) => {
                return account.account_id == action.payload.account.account_id
                    ? { ...action.payload.account }
                    : account;
            });
            return {
                ...state,
                adAccounts
            };
        case actionTypes.GET_DISTINCT_FIELD:
            const newFilters = {
                ...state.filters,
                ...action.payload.filter
            };

            return {
                ...state,
                filters: newFilters
            };
        case actionTypes.GET_DISTINCT_FIELD_START:
            return { ...state };
        case actionTypes.GET_DISTINCT_FIELD_ERROR:
            return { ...state };
        default:
            return state;
    }
};
