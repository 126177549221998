import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

export const balanceConversions = (googleBalancerReportId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.BALANCE_CONVERSIONS_START
        });

        api()
            .post("conversionbalancer/balance", { googleBalancerReportId })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.BALANCE_CONVERSIONS,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.BALANCE_CONVERSIONS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};
