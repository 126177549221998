import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";
import { appStateType } from "reducers";
import { element } from "types/element";

export function getElements(setId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ELEMENTS_START
        });

        api()
            .get(`element/noparent/${setId}`)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_ELEMENTS,
                    payload: { elements: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_ELEMENTS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getBricks(setId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BRICKS_START
        });

        api()
            .get(`brick/${setId}`)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_BRICKS,
                    payload: { bricks: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_BRICKS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function deleteElement(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_ELEMENT_START
        });

        api()
            .delete("element", { data: { id } })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DELETE_ELEMENT,
                    payload: { id }
                });
                dispatch({
                    type: actionTypes.DELETE_BRICKS,
                    payload: { bricks: res.data }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.DELETE_ELEMENT_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function updateNameField(id: string, value: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_ELEMENT_FIELD,
            payload: { id, value }
        });
    };
}

export function saveElement(id: string) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.UPDATE_ELEMENT_START
        });

        const state = getState();
        const el = state.elements.elements.find(
            (element: element) => element._id === id
        ) as element;
        const name = el.name;

        api()
            .put("element/name", { id, name })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.UPDATE_ELEMENT,
                    payload: { element: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPDATE_ELEMENT_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export const refreshContent = (setId: string) => {
    return (dispatch: Dispatch) => {
        dispatch<any>(getElements(setId));
        dispatch<any>(getBricks(setId));
    };
};
