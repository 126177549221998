import * as React from "react";
import styles from "./styles.scss";
import logo from "assets/logo.svg";

import SignInForm from "./SignInForm";

const SignIn = () => {
  return (
    <div className={styles.page}>
      <div className={styles.loginPanel}>
        <img className={styles.logo} src={logo} alt="Ejam.com logo" />
        <SignInForm />
      </div>
    </div>
  );
};

export default SignIn;
