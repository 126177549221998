import * as actionTypes from "actionTypes";
import _ from "lodash";
import getOperators from "services/getOperators";
import uuid from "uuid";
import { tagItems } from "services/tagItems";
import { upsell } from "types/upsell";
import { upsellContainer } from "types/upsellContainer";
import { upsellsFlow } from "types/upsellsFlow";
import { LogicalOperator } from "types/flowTrigger";

export type upsellStateType = {
    addUpsellContainerLoading: boolean;
    addUpsellsFlowLoading: boolean;
    editUpsellsFlowLoading: boolean;
    isLoading: boolean;
    removeUpsellContainerLoading: boolean;
    upsellContainer: upsellContainer[];
    upsellsFlow: upsellsFlow[];
    saveTriggersLoading: boolean;
    isUpdating: boolean;
    upsellReports: object;
    errors: null | Error;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: upsellStateType = {
    addUpsellContainerLoading: false,
    addUpsellsFlowLoading: false,
    editUpsellsFlowLoading: false,
    isLoading: false,
    removeUpsellContainerLoading: false,
    upsellContainer: [],
    upsellsFlow: [],
    saveTriggersLoading: false,
    isUpdating: false,
    upsellReports: {},
    errors: null
};

export const upsellStorage: { [key: string]: boolean } = {
    upsellsFlow: true
};

export default (state: upsellStateType = initialState, action: IAction): upsellStateType => {
    switch (action.type) {
        case actionTypes.ADD_UPSELLS_FLOW_START:
            return {
                ...state,
                addUpsellsFlowLoading: action.payload.id ? false : true,
                editUpsellsFlowLoading: action.payload.id ? true : false
            };
        case actionTypes.ADD_UPSELLS_FLOW:
            const { upsellsFlow } = action.payload;
            const upsellsFlowMap = new Map();
            state.upsellsFlow.forEach((upsell: any) => upsellsFlowMap.set(upsell._id, upsell));
            const oldUpsellsFlow = state.upsellsFlow.find(flow => flow._id === upsellsFlow._id);

            if (oldUpsellsFlow) {
                const updatedFlow = _.assign(oldUpsellsFlow, upsellsFlow);
                upsellsFlowMap.set(upsellsFlow._id, updatedFlow);
            } else {
                upsellsFlowMap.set(upsellsFlow._id, upsellsFlow);
            }

            return {
                ...state,
                upsellsFlow: Array.from(upsellsFlowMap.values()),
                addUpsellsFlowLoading: false,
                editUpsellsFlowLoading: false
            };
        case actionTypes.ADD_UPSELLS_FLOW_ERROR:
            return {
                ...state,
                addUpsellsFlowLoading: false,
                editUpsellsFlowLoading: false
            };
        case actionTypes.GET_UPSELLS_FLOW_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_UPSELLS_FLOW:
            return {
                ...state,
                upsellsFlow: action.payload.upsellsFlow,
                isLoading: false
            };
        case actionTypes.GET_UPSELLS_FLOW_ERROR:
            return {
                ...state,
                upsellsFlow: [],
                errors: action.payload.error,
                isLoading: false
            };

        case actionTypes.UPDATE_UPSELLS_FLOW_START:
            return {
                ...state,
                isUpdating: true
            };
        case actionTypes.UPDATE_UPSELLS_FLOW:
            let { setId, disabled } = action.payload;
            return {
                ...state,
                upsellsFlow: state.upsellsFlow.map(flow => {
                    if (flow.set === setId) {
                        return { ...flow, disabled };
                    }
                    return flow;
                }),
                isUpdating: false
            };
        case actionTypes.UPDATE_UPSELLS_FLOW_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isUpdating: false
            };
        case actionTypes.ADD_UPSELL_CONTAINER_START:
            return {
                ...state,
                addUpsellContainerLoading: true
            };
        case actionTypes.ADD_UPSELL_CONTAINER:
            const { upsellContainer } = action.payload;
            const upsellContainerMap = new Map();
            state.upsellContainer.forEach((upsell: any) =>
                upsellContainerMap.set(upsell._id, upsell)
            );
            const oldContainer = state.upsellContainer.find(
                container => container._id === upsellContainer._id
            );

            if (oldContainer) {
                const oldVariants = oldContainer.variants;
                const newVariants = upsellContainer.variants;
                const updatedVariants = _.merge(oldVariants, newVariants);

                upsellContainerMap.set(upsellContainer._id, {
                    ...upsellContainer,
                    variants: updatedVariants
                });
            } else {
                upsellContainerMap.set(upsellContainer._id, upsellContainer);
            }

            const sortedUpsellContainer = _.sortBy(Array.from(upsellContainerMap.values()), [
                (o: any) => o.index
            ]);

            return {
                ...state,
                upsellContainer: sortedUpsellContainer,
                upsellsFlow: state.upsellsFlow.map(flow =>
                    flow._id === action.payload.upsellsFlowId
                        ? {
                              ...flow,
                              upsells: _.sortBy(
                                  Array.from(new Set([...flow.upsells, upsellContainer._id])),
                                  [(o: any) => o.index]
                              )
                          }
                        : flow
                ),
                addUpsellContainerLoading: false
            };
        case actionTypes.ADD_UPSELL_CONTAINER_ERROR:
            return {
                ...state,
                addUpsellContainerLoading: false
            };
        case actionTypes.REMOVE_UPSELL_CONTAINER_START:
            return {
                ...state,
                removeUpsellContainerLoading: true
            };
        case actionTypes.REMOVE_UPSELL_CONTAINER:
            return {
                ...state,
                upsellContainer: state.upsellContainer.filter(
                    (container: any) => container._id !== action.payload.upsellContainer._id
                ),
                removeUpsellContainerLoading: false,
                upsellsFlow: state.upsellsFlow.map(flow =>
                    flow._id === action.payload.upsellsFlowId
                        ? {
                              ...flow,
                              upsells: flow.upsells.filter(
                                  (container: any) =>
                                      container._id !== action.payload.upsellContainer._id
                              )
                          }
                        : flow
                )
            };
        case actionTypes.REMOVE_UPSELL_CONTAINER_ERROR:
            return {
                ...state,
                removeUpsellContainerLoading: true
            };
        case actionTypes.GET_UPSELL_CONTAINER_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_UPSELL_CONTAINER:
            return {
                ...state,
                upsellContainer: action.payload.upsellContainer,
                isLoading: false
            };
        case actionTypes.GET_UPSELL_CONTAINER_ERROR:
            return {
                ...state,
                upsellContainer: [],
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.ADD_UPSELL_START:
            return {
                ...state,
                upsellContainer: tagItems(state.upsellContainer, action.payload.upsellContainerId, {
                    addUpsellLoading: true
                })
            };
        case actionTypes.ADD_UPSELL:
            const newContainer = state.upsellContainer.find(
                (container: upsellContainer) => container._id === action.payload.upsellContainerId
            );

            if (newContainer) {
                const hasUpsell = newContainer.variants.find(
                    (upsell: upsell) => upsell._id === action.payload.upsell._id
                );

                if (hasUpsell) {
                    newContainer.variants = newContainer.variants.map((upsell: upsell) =>
                        upsell._id === action.payload.upsell._id
                            ? _.assign(upsell, action.payload.upsell)
                            : upsell
                    );
                } else {
                    newContainer.variants = [...newContainer.variants, action.payload.upsell];
                }

                const newContainers = state.upsellContainer.map((container: upsellContainer) =>
                    container._id === action.payload.upsellContainerId
                        ? { ...newContainer, addUpsellLoading: false }
                        : container
                );

                return {
                    ...state,
                    upsellContainer: newContainers
                };
            }

            return {
                ...state
            };
        case actionTypes.ADD_UPSELL_ERROR:
            return {
                ...state,
                upsellContainer: tagItems(state.upsellContainer, action.payload.upsellContainerId, {
                    addUpsellLoading: false
                })
            };
        case actionTypes.GET_UPSELL_REPORT_START:
            return {
                ...state
            };
        case actionTypes.GET_UPSELL_REPORT:
            const { report = { upsellsFlow: [], upsells: [] } } = action.payload;
            const upsellFlowReport = report.upsellsFlow;

            const newUpsellsFlows = state.upsellsFlow.map(upsellsFlow => {
                const flowReport = upsellFlowReport.filter(
                    (flow: any) => flow._id === upsellsFlow._id
                );

                if (flowReport) {
                    return {
                        ...upsellsFlow,
                        report: flowReport
                    };
                }

                return upsellsFlow;
            });

            return {
                ...state,
                upsellsFlow: newUpsellsFlows
            };
        case actionTypes.GET_UPSELL_REPORT_ERROR:
            return {
                ...state
            };
        case actionTypes.ADD_UPSELL_TRIGGER:
            const { upsellsFlowId, variable } = action.payload;

            const newUpsellsFlow = state.upsellsFlow.map(flow => {
                if (flow._id == upsellsFlowId) {
                    return {
                        ...flow,
                        triggers: [
                            ...flow.triggers,
                            {
                                _id: uuid.v4(),
                                logicalOperator: LogicalOperator.AND,
                                variableType: variable.type,
                                variableKey: variable.key,
                                variableName: variable.name,
                                operator: getOperators(variable.type)[0],
                                compareValue: ""
                            }
                        ]
                    };
                }

                return flow;
            });

            return {
                ...state,
                upsellsFlow: newUpsellsFlow
            };
        case actionTypes.UPDATE_UPSELL_TRIGGER: {
            const { upsellsFlowId, triggerId, key, value } = action.payload;

            const newUpsellsFlow = state.upsellsFlow.map(flow => {
                if (flow._id == upsellsFlowId) {
                    return {
                        ...flow,
                        triggers: flow.triggers.map(trigger => {
                            if (trigger._id == triggerId) {
                                return {
                                    ...trigger,
                                    [key]: value
                                };
                            }

                            return trigger;
                        })
                    };
                }

                return flow;
            });

            return {
                ...state,
                upsellsFlow: newUpsellsFlow
            };
        }
        case actionTypes.SAVE_TRIGGERS_START:
            return {
                ...state,
                saveTriggersLoading: true
            };
        case actionTypes.SAVE_TRIGGERS_DONE:
            return {
                ...state,
                saveTriggersLoading: false
            };
        case actionTypes.DELETE_UPSELL_TRIGGER: {
            const { upsellsFlowId, triggerId } = action.payload;

            const newUpsellsFlow = state.upsellsFlow.map(flow => {
                if (flow._id == upsellsFlowId) {
                    return {
                        ...flow,
                        triggers: flow.triggers.filter(trigger => trigger._id != triggerId)
                    };
                }

                return flow;
            });

            return {
                ...state,
                upsellsFlow: newUpsellsFlow
            };
        }
        case actionTypes.ADD_UPSELL_REPORT: {
            const { elementId, report } = action.payload;
            const newUpsellReports = { ...state.upsellReports };
            newUpsellReports[elementId] = { createdAt: Date.now(), report };

            return {
                ...state,
                upsellReports: newUpsellReports
            };
        }
        case actionTypes.ARCHIEVE_UPSELL_FLOW_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.ARCHIEVE_UPSELL_FLOW: {
            const newUpsellFlows = state.upsellsFlow.filter(el => el._id !== action.payload.flowId);
            return {
                ...state,
                isLoading: false,
                upsellsFlow: newUpsellFlows
            };
        }
        case actionTypes.ARCHIEVE_UPSELL_FLOW_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.REORDER_UPSELL_FLOW_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.REORDER_UPSELL_FLOW: {
            return {
                ...state,
                isLoading: false
            };
        }
        case actionTypes.TEMP_REORDER_UPSELL_FLOW: {
            const reorderedFlowsObj = action.payload.flows.reduce(
                (acc: { [key: string]: upsellsFlow }, next: upsellsFlow) => ({
                    ...acc,
                    [next._id]: next.order
                }),
                {}
            );

            const newUpsellFlows = state.upsellsFlow.map(el =>
                reorderedFlowsObj[el._id] !== undefined
                    ? { ...el, order: reorderedFlowsObj[el._id] }
                    : el
            );

            return {
                ...state,
                isLoading: false,
                upsellsFlow: newUpsellFlows
            };
        }
        case actionTypes.REORDER_UPSELL_FLOW_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.COPY_UPSELL_FLOW_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.COPY_UPSELL_FLOW: {
            const { upsellFLows, upsellContainers } = action.payload;

            return {
                ...state,
                isLoading: false,
                upsellsFlow: [...state.upsellsFlow, ...upsellFLows],
                upsellContainer: [...state.upsellContainer, ...upsellContainers]
            };
        }
        case actionTypes.COPY_UPSELL_FLOW_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isLoading: false
            };

        case actionTypes.ARCHIEVE_UPSELL_CONTAINER_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.ARCHIEVE_UPSELL_CONTAINER: {
            const { flowId, containerId } = action.payload;
            const newUpsellFlows = state.upsellsFlow.map(el =>
                el._id === flowId
                    ? {
                          ...el,
                          upsells: el.upsells.filter(upsell => upsell !== containerId)
                      }
                    : el
            );
            const newContainers = state.upsellContainer.filter(el => el._id !== containerId);
            return {
                ...state,
                isLoading: false,
                upsellsFlow: newUpsellFlows,
                upsellContainer: newContainers
            };
        }
        case actionTypes.ARCHIEVE_UPSELL_CONTAINER_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.REORDER_UPSELL_CONTAINER_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.REORDER_UPSELL_CONTAINER: {
            return {
                ...state,
                isLoading: false
            };
        }
        case actionTypes.TEMP_REORDER_UPSELL_CONTAINER: {
            const reorderedContainersObj = action.payload.containers.reduce(
                (acc: { [key: string]: upsellContainer }, next: upsellContainer) => ({
                    ...acc,
                    [next._id]: next.index
                }),
                {}
            );

            const newUpsellContainers = state.upsellContainer.map(el =>
                reorderedContainersObj[el._id] !== undefined
                    ? { ...el, index: reorderedContainersObj[el._id] }
                    : el
            );

            return {
                ...state,
                isLoading: false,
                upsellContainer: newUpsellContainers
            };
        }
        case actionTypes.REORDER_UPSELL_CONTAINER_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isLoading: false
            };

        case actionTypes.COPY_UPSELL_CONTAINER_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.COPY_UPSELL_CONTAINER: {
            const { container, flowId } = action.payload;
            const newFlows = state.upsellsFlow.map(el =>
                el._id === flowId ? { ...el, upsells: [...el.upsells, container._id] } : el
            );

            return {
                ...state,
                isLoading: false,
                upsellsFlow: newFlows,
                upsellContainer: [...state.upsellContainer, container]
            };
        }
        case actionTypes.COPY_UPSELL_CONTAINER_ERROR:
            return {
                ...state,
                errors: action.payload.error,
                isLoading: false
            };

        default:
            return state;
    }
};
