import * as React from "react";
const { useEffect } = React;
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { useSelector, useDispatch } from "react-redux";
import { getHotDeployments } from "./actions";

type Props = {};

const HotDeployments: React.FC<Props> = () => {
    const { hotDeployments, isGettingHotDeployments } = useSelector(
        (state: appStateType) => state.deployment
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHotDeployments());
    }, []);

    return (
        <div>
            <h2>🔥Hot Deployments</h2>
            {isGettingHotDeployments || !hotDeployments ? (
                <LoadingIndicator />
            ) : (
                <div className={styles.hotDeployments}>
                    <table>
                        <thead>
                            <tr>
                                <th>Views (last 1h)</th>
                                <th>Basename</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hotDeployments.map(hotDeployment => (
                                <tr>
                                    <td>{hotDeployment.views}</td>
                                    <td>{hotDeployment.basename}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default HotDeployments;
