import * as React from "react";
const { useState, useRef, useMemo, useEffect } = React;
import scss from "./styles.scss";
import subscriptionIcon from "assets/icons/subscription.svg";
import SVG from "react-inlinesvg";
import {
    useTransition,
    useSpring,
    useChain,
    config as configs,
    easings,
    animated,
    useSpringRef
} from "@react-spring/web";
import debounce from "lodash/debounce";
import parentContains from "services/parentContains";
import SwitchButton from "components/SwitchButton";
import { useDispatch, useSelector } from "react-redux";
import { appStateType } from "reducers";
import { SubscriptionForm } from "types/Subscription";
import { getSubscriptions, addSubscription } from "./actions";
import LoadingIndicator from "components/LoadingIndicator";

interface ButtonSizes {
    top: number | string;
    left: number | string;
    width: number | string;
    height: number | string;
}

const emptySubscriptionForm: SubscriptionForm = {
    name: "",
    recurringInterval: 0,
    discountType: "PERCENTAGE",
    discount: 0,
    isPopular: false,
    isSelected: false
};

const KonnektiveSubscriptions: React.FC = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean | null>(null);
    const [showButtonBody, setShowButtonBody] = useState<boolean>(true);
    const [sizes, setSizes] = useState<ButtonSizes | null>(null);
    const springApi = useSpringRef();
    const transApi = useSpringRef();
    const buttonRef = useRef<any>();
    const buttonCloneRef = useRef<any>();
    const panelRef = useRef<any>();
    const buttonSizes = useRef<ButtonSizes>({
        top: "initial",
        left: "initial",
        width: "initial",
        height: "initial"
    });
    const [subscriptionForm, setSubscriptionForm] =
        useState<SubscriptionForm>(emptySubscriptionForm);
    const { subscriptions, isPullingSubscriptions } = useSelector(
        (state: appStateType) => state.subscription
    );

    const { top, left, width, height } = buttonSizes.current;

    const initialStyle = {
        position: "absolute",
        top,
        left,
        width,
        height,
        background: "linear-gradient(30deg, #d6116d 10%, #ff7171)",
        boxShadow: "rgba(214, 17, 109, 0.4) 0 6px 30px",
        borderRadius: 6
    };

    let springBody;

    const xSize = 700;
    const ySize = 450;

    if (sizes !== null) {
        springBody = {
            ref: springApi,
            config: {},
            from: initialStyle,
            to: open
                ? {
                      position: "absolute",
                      top: (window.innerHeight - ySize) / 2 - 22,
                      left: (window.innerWidth - xSize) / 2,
                      width: xSize,
                      height: ySize,
                      background: "linear-gradient(30deg, #ffffff 10%, #ffffff)",
                      boxShadow: "rgba(0, 0, 0, 0.4) 0 6px 80px",
                      borderRadius: 16
                  }
                : initialStyle,
            onStart: () => {
                if (open) {
                    setShowButtonBody(false);
                } else {
                    setShowButtonBody(true);
                }
            }
        };
    } else {
        springBody = {
            ref: springApi
        };
    }

    const styles = useSpring(springBody);

    const form = useMemo(
        () => [
            { tag: "input", type: "text", name: "name", label: "Name:" },
            { tag: "input", type: "number", name: "recurringInterval", label: "Interval Days:" },
            {
                tag: "select",
                name: "discountType",
                label: "Discount Type:",
                options: ["AMOUNT", "PERCENTAGE"]
            },
            { tag: "input", type: "number", label: "Discount:", name: "discount" },
            { tag: "switch", name: "isPopular", label: "Popular:" },
            { tag: "switch", name: "isSelected", label: "Pre Selected:" },
            { tag: "submit" }
        ],
        []
    );

    const transition = useTransition(open ? form : [], {
        ref: transApi,
        trail: 400 / form.length,
        from: { opacity: 0, scale: 0 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0 }
    });

    useEffect(() => {
        dispatch(getSubscriptions());

        const resize = () => {
            const panel = panelRef.current;
            const button = buttonRef.current;

            button.style = null;

            const cs = getComputedStyle(button);

            const marginX = parseFloat(cs.marginLeft) + parseFloat(cs.marginRight);
            const marginY = parseFloat(cs.marginTop) + parseFloat(cs.marginBottom);

            const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
            const paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

            const borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
            const borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

            const { x, y } = button.getBoundingClientRect();

            const width = button.offsetWidth - paddingX - borderX;
            const height = button.offsetHeight - paddingY - borderY;

            buttonSizes.current = { top: y - marginY, left: x - marginX, width, height };

            if (buttonCloneRef.current && typeof buttonCloneRef.current.remove === "function") {
                buttonCloneRef.current.remove();
            }

            const buttonClone = button.cloneNode(true);
            buttonClone.style.opacity = 0;
            buttonClone.setAttribute("id", Date.now());
            buttonCloneRef.current = buttonClone;
            panel.insertBefore(buttonClone, button.nextSibling);
            setSizes(buttonSizes.current);
        };

        setTimeout(() => {
            if (buttonRef && buttonRef.current && panelRef && panelRef.current) {
                resize();
            }
        }, 100);

        const debouncedResize = debounce(resize, 100);

        window.addEventListener("resize", debouncedResize);

        return () => window.removeEventListener("resize", debouncedResize);
    }, []);

    useChain(open ? [springApi, transApi] : [transApi, springApi], [0, open ? 0.3 : 0.9]);

    const finalStyles = sizes !== null ? styles : {};

    useEffect(() => {
        const handleWindowClick = (e: MouseEvent) => {
            if (buttonRef.current) {
                const isParentConatins = parentContains(buttonRef.current, e.target as Node);

                setOpen(open => {
                    if (!isParentConatins && open) {
                        return false;
                    }

                    if (isParentConatins && !open) {
                        return true;
                    }

                    return open;
                });
            }
        };

        window.addEventListener("click", handleWindowClick);

        return () => window.removeEventListener("click", handleWindowClick);
    }, []);

    const updateFormField = (name: string, value: string | number | boolean) => {
        setSubscriptionForm(oldForm => ({ ...oldForm, [name]: value }));
    };

    const handleDynamicFormChange = (e: React.SyntheticEvent) => {
        const { name, value, type } = e.target as HTMLInputElement;
        updateFormField(name, type === "number" ? Number(value) : value);
    };

    const submitForm = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(addSubscription(subscriptionForm));
        setOpen(false);
        setSubscriptionForm(emptySubscriptionForm);
    };

    return (
        <>
            <header>
                <h1>Konnektive Subscriptions</h1>
            </header>
            <main>
                <div ref={panelRef} className={scss.panel_wide}>
                    <div className={scss.icon}>
                        <SVG className={scss.icon} src={subscriptionIcon} />
                    </div>
                    <animated.div ref={buttonRef} style={finalStyles} className={scss.button}>
                        {showButtonBody && "+ ADD NEW SUBSCRIPTION"}
                        <div className={scss.form}>
                            {transition((style, item) => {
                                const renderField = () => {
                                    switch (item.tag) {
                                        case "input":
                                            return (
                                                <input
                                                    type={item.type}
                                                    name={item.name}
                                                    value={item.name && subscriptionForm[item.name]}
                                                    onChange={handleDynamicFormChange}
                                                />
                                            );
                                        case "select":
                                            return (
                                                <select
                                                    name={item.name}
                                                    value={item.name && subscriptionForm[item.name]}
                                                    onChange={handleDynamicFormChange}
                                                >
                                                    {item.options?.map(option => (
                                                        <option>{option}</option>
                                                    ))}
                                                </select>
                                            );
                                        case "switch":
                                            return (
                                                <SwitchButton
                                                    isActive={
                                                        item.name && subscriptionForm[item.name]
                                                    }
                                                    onSwitch={(e, isActive) => {
                                                        updateFormField(item?.name || "", isActive);
                                                    }}
                                                />
                                            );
                                        case "submit":
                                            return (
                                                <div className={scss.submit} onClick={submitForm}>
                                                    ADD NEW SUBSCRIPTION
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }
                                };
                                return (
                                    <animated.div className={scss.label} style={{ ...style }}>
                                        {item.label && (
                                            <div className={scss.text}>{item.label}</div>
                                        )}
                                        <div className={scss.item}>{renderField()}</div>
                                    </animated.div>
                                );
                            })}
                        </div>
                    </animated.div>
                    {isPullingSubscriptions ? (
                        <LoadingIndicator className={scss.loader} size={70} />
                    ) : (
                        <div className={scss.subscriptionsContainer}>
                            {subscriptions.map(subscription => (
                                <div className={scss.subscription} key={subscription._id}>
                                    <div className={scss.label}>
                                        <b>Model ID:</b> {subscription._id}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Subscription ID:</b> {subscription.subId}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Name:</b> {subscription.name}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Interval Days:</b> {subscription.recurringInterval}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Discount Type:</b> {subscription.discountType}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Discount:</b> {subscription.discount}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Popular:</b> {String(subscription.isPopular)}
                                    </div>
                                    <div className={scss.label}>
                                        <b>Selected:</b> {String(subscription.isSelected)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </>
    );
};

export default KonnektiveSubscriptions;
