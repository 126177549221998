import cx from "classnames";
import * as React from "react";
import Headline from "../../components/Headline";
import styles from "./styles.scss";

type Props = {
    children: React.ReactChild;
    name: string;
    description?: string;
    isNarrow?: boolean;
    path: string;
    isChildren?: boolean;
};

const ObjectEditor: React.FC<Props> = ({
    children,
    description = "",
    isNarrow,
    name,
    path,
    isChildren = false
}) => {
    const split = path.split(".");
    const isChildrenOfHighestLevelArray =
        Array.isArray(split) && split.length === 2 && !isNaN(Number(split[1]));

    return (
        <div
            className={cx(styles.editor, {
                [styles.inArray]: isChildren,
                [styles.paddingRight]: isChildrenOfHighestLevelArray
            })}
        >
            <Headline
                name={name}
                description={description}
                isGroup={!path.includes(".")}
                isArray={true}
            >
                <div className={styles.children}>{children}</div>
            </Headline>
        </div>
    );
};

export default ObjectEditor;
