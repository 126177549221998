import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { user } from "types/user";
import { UserRole } from "types/userRole";

export function getUsers() {
    return api()
        .get("users")
        .then(({ data }: AxiosResponse) => Promise.resolve(data.users))
        .catch((err: AxiosError) => Promise.reject(err));
}

export const addUser = (user: user) => {
    return api()
        .post("users", user)
        .then(({ data }: AxiosResponse) => Promise.resolve(data.user))
        .catch((err: AxiosError) => Promise.reject(err));
};

export const updateUserPassword = (userId: string, password: string) => {
    return api()
        .put("users/password", { userId, password })
        .then(({ data }: AxiosResponse) => Promise.resolve(data.user))
        .catch((err: AxiosError) => Promise.reject(err));
};

type UserPayload = {
    roles: UserRole[];
    allowedBrands: string[];
};

export const updateUserRoles = (userId: string, userPayload: UserPayload) => {
    return api()
        .put("users/edit", { userId, ...userPayload })
        .then(({ data }: AxiosResponse) => Promise.resolve(data.user))
        .catch((err: AxiosError) => Promise.reject(err));
};

export function removeUser(userId: string) {
    return api()
        .delete(`users`, { data: { userId } })
        .then(({ data }: AxiosResponse) => Promise.resolve(data.user))
        .catch((err: AxiosError) => Promise.reject(err));
}

export function getUserRoles() {
    return api()
        .get("users/roles")
        .then(({ data }: AxiosResponse) => Promise.resolve(data.roles))
        .catch((err: AxiosError) => Promise.reject(err));
}
