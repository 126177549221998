import { SET_CURRENT_USER, SET_AUTH_ERRORS, START_AUTH, SIGN_OUT_USER } from "actionTypes";
import { apiSignedInUser } from "types/user";
import isEmpty from "services/isEmpty";

export type authStateType = {
    isAuthenticated: boolean;
    user: apiSignedInUser | {};
    errors: string[];
    token: string;
    isLoading: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: authStateType = {
    isAuthenticated: false,
    user: {},
    errors: [],
    token: "",
    isLoading: false
};

export const authStorage: { [key: string]: boolean } = {
    isAuthenticated: true,
    token: true,
    user: true
};

export default (state: authStateType = initialState, action: IAction): authStateType => {
    switch (action.type) {
        case START_AUTH:
            return {
                ...state,
                isLoading: true,
                errors: []
            };
        case SET_CURRENT_USER:
            const { token, user } = action.payload;
            return {
                ...state,
                isLoading: false,
                isAuthenticated: !isEmpty(user),
                token,
                user
            };
        case SET_AUTH_ERRORS:
            return {
                ...state,
                isLoading: false,
                errors: [action.payload.message]
            };
        case SIGN_OUT_USER:
            return {
                ...state,
                isAuthenticated: false,
                token: "",
                user: {}
            };
        default:
            return state;
    }
};
