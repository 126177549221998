import cx from "classnames";
import { linkSync } from "fs";
import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import IconLink from "./compontents/IconLink";
import styles from "./styles.scss";

type Props = {
    action?: (...args: any[]) => void;
    activeLink?: string;
    basePath?: string;
    links: string[];
    withIcons?: boolean;
    enrichLinks?: any;
    search?: any;
    searchValue?: string;
    clearSearch?: any;
    showSearch?: boolean;
};

const HorizontalMenu: React.FC<Props> = ({
    activeLink,
    basePath,
    enrichLinks,
    links,
    withIcons = false,
    search = () => null,
    clearSearch = () => null,
    searchValue = "",
    showSearch = false
}) => {
    const isLinkActive = (url: string, exact: boolean) => {
        const path = window.location.pathname;
        return exact ? path === url : path.includes(url);
    };

    return (
        <nav className={styles.horizontalMenu}>
            {links.map(link => {
                const [name, search] = link.split("?");
                let enrichLink = "";

                if (enrichLinks && enrichLinks[name]) {
                    enrichLink = enrichLinks[name];
                }

                return (
                    <div key={link}>
                        {basePath ? (
                            <NavLink
                                activeClassName={styles.active}
                                isActive={() => isLinkActive(`${basePath}${name}`, false)}
                                to={`${basePath}${name}${enrichLink}${search ? `?${search}` : ""}`}
                            >
                                <IconLink link={name} withIcons={withIcons} />
                            </NavLink>
                        ) : (
                            <Link
                                className={cx({
                                    [styles.active]: activeLink === link
                                })}
                                to={`#${link}`}
                            >
                                <IconLink link={name} withIcons={withIcons} />
                            </Link>
                        )}
                    </div>
                );
            })}
            {showSearch && (
                <div className={styles.inputWrapper}>
                    <input
                        type="text"
                        onChange={search}
                        placeholder="Search ..."
                        value={searchValue}
                    />
                    <span onClick={clearSearch}>&#x2715;</span>
                </div>
            )}
        </nav>
    );
};

export default HorizontalMenu;
