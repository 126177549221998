import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

export const pullBalancerJobs = () => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.PULL_BALANCER_JOBS_START
        });

        api()
            .get("conversionbalancer/jobs")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.PULL_BALANCER_JOBS,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.PULL_BALANCER_JOBS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};
