import { pickBy } from "lodash";
import { appStateType } from "reducers";

function replacer(this: any, key: any, value: any) {
    const originalObject = this[key];
    if (originalObject instanceof Map) {
        return {
            dataType: "Map",
            value: Array.from(originalObject.entries())
        };
    } else {
        return value;
    }
}

function reviver(key: any, value: any) {
    if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
            return new Map(value.value);
        }
    }
    return value;
}

export const loadState = (): object | undefined => {
    try {
        const serializedState = localStorage.getItem("state");

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState, reviver);
    } catch (error) {
        return undefined;
    }
};

export const getStorageState = (
    state: appStateType,
    schema: { [key: string]: { [key: string]: boolean } }
): object => {
    const storageState = {};

    Object.keys(state).forEach(key => {
        storageState[key] = pickBy(
            state[key],
            (value: any, index: string) => schema[key][index]
        );
    });

    return storageState;
};

export const saveState = (state: object): void | undefined => {
    try {
        const serializedState = JSON.stringify(state, replacer);
        localStorage.setItem("state", serializedState);
    } catch (error) {
        console.log(error, "error");
    }
};
