import * as React from "react";
import * as actions from "../../../../scenes/Dashboard/CDNCloud/actions";
import LoadingIndicator from "components/LoadingIndicator";
import Modal from "../../index";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";

class UploadModal extends React.PureComponent<any, any> {
    el: HTMLElement;

    constructor(props: any) {
        super(props);

        this.state = {
            files: [],
            video: ""
        };
    }

    processSelectedFiles(fileInput: any) {
        const files = fileInput.target.files;
        this.props.uploadFiles(files);
        this.setState({ files: Array.from(files) });
    }

    handleInputChange(e: React.SyntheticEvent) {
        const { value, name } = e.target as HTMLInputElement;
        this.setState({ [name]: value });
    }

    saveVideo() {
        const video = this.state.video;
        this.props.saveVideo(video);
        this.setState({ video: "" });
    }

    render() {
        const {
            onClose,
            uploading,
            savingVideoError,
            isSavingVideo
        } = this.props;

        return (
            <Modal onClose={onClose.bind(this)}>
                <div className={styles.flex}>
                    <div className={styles.half}>
                        <img
                            className={styles.cloud}
                            src="/img/svg/cloud.svg"
                        />
                        <p>Upload image:</p>
                        <p>GIF, JPG, PNG, SVG,</p>
                        <input
                            name="file"
                            type="file"
                            multiple
                            onChange={this.processSelectedFiles.bind(this)}
                        />
                        <p className={styles.or}>or</p>
                        <input
                            className={styles.input}
                            name="video"
                            onChange={e => this.handleInputChange(e)}
                            placeholder="add wistia hash"
                            type="text"
                            value={this.state.video}
                        />
                        {isSavingVideo ? (
                            <LoadingIndicator />
                        ) : (
                            <button
                                className={styles.button}
                                onClick={this.saveVideo.bind(this)}
                            >
                                Add video
                            </button>
                        )}
                        {savingVideoError && (
                            <p className={styles.error}>{savingVideoError}</p>
                        )}
                    </div>
                    <div className={styles.half}>
                        {this.state.files.map((file: any) => {
                            const isUploading = uploading.find(
                                (uploadingFile: any) =>
                                    file.name == uploadingFile.name
                            );
                            return (
                                <div key={file.name} className={styles.file}>
                                    <span>{file.name}</span>
                                    {isUploading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <img src="/img/svg/checkmark.svg" />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    uploading: state.file.uploading,
    savingVideoError: state.file.savingVideoError,
    isSavingVideo: state.file.isSavingVideo
});

export default connect(
    mapStateToProps,
    { ...actions }
)(UploadModal);
