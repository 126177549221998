import * as _ from "lodash";
import { filter } from "lodash";
import { noop } from "lodash";
import { indexOf } from "lodash";

export const filterSchame = (schema: object, elementTree: any, searchString: string) => {
    const regEx = new RegExp(searchString, "ig");
    const filtered: any[] = [];
    const appplyFilter = (pastKeys: [], [key, value]: [string, any]) => {
        switch (value._type) {
            case "OBJECT":
            case "OBJECT[]":
                Object.entries(value).map(appplyFilter.bind(null, [...pastKeys, key]));
                return null;
            case "TEXT[]":
                const a = _.get(elementTree, [...pastKeys, key]);
                if (Array.isArray(a)) {
                    a.forEach((item: any) => {
                        if (item && item.variants) {
                            const contentValue = Array.isArray(item.variants)
                                ? item.variants[0].value
                                : item.variants;
                            if (regEx.test(contentValue)) {
                                pastKeys.reduce((c: any, n: any) => {
                                    c.push(n);
                                    if (filtered.indexOf([...c, "_type"]) === -1) {
                                        filtered.push([...c, "_type"]);
                                        filtered.push([...c, "_display"]);
                                        filtered.push([...c, "_description"]);
                                    }
                                    return c;
                                }, []);
                                filtered.push([...pastKeys, key]);
                            }
                        }
                    });
                }
                return null;
            case "TEXT":
            case "NUMBER":
                const temp = _.get(elementTree, [...pastKeys, key]);
                if (temp && temp.variants) {
                    const contentValue = Array.isArray(temp.variants)
                        ? temp.variants[0].value
                        : temp.variants;
                    if (regEx.test(contentValue)) {
                        pastKeys.reduce((c: any, n: any) => {
                            c.push(n);
                            if (filtered.indexOf([...c, "_type"]) === -1) {
                                filtered.push([...c, "_type"]);
                                filtered.push([...c, "_display"]);
                                filtered.push([...c, "_description"]);
                            }
                            return c;
                        }, []);
                        filtered.push([...pastKeys, key]);
                    }
                }
                return null;
            default:
                return null;
        }
    };
    Object.entries(schema).map(appplyFilter.bind(null, []));

    return _.pick(schema, filtered);
};
