import * as React from "react";
import styles from "./styles.scss";

export type DiodeStatus = "SUCCESS" | "FAILURE" | "PENDING";

type Props = {
    status: DiodeStatus;
    onClick?: () => void;
};

export const Diode: React.FC<Props> = ({ status, onClick }) => {
    const handleClick = onClick || (() => {});

    return (
        <div className={getDiodeClassName(status, !!onClick)} onClick={handleClick}>
            <div className={styles.border} />
        </div>
    );
};

function getDiodeClassName(status: DiodeStatus, shouldHandleClick: boolean) {
    let className = `${styles.diode} `;
    if (shouldHandleClick) {
        className += `${styles.btn} `;
    }

    switch (status) {
        case "SUCCESS":
            return className + styles.success;
        case "FAILURE":
            return className + styles.failure;
        case "PENDING":
            return className + styles.pending;
    }
}
