import { SIGN_OUT_USER, SWITCH_SIDE_MENU } from "actionTypes";

export function logout() {
    return {
        type: SIGN_OUT_USER
    };
}
export function switchSideMenu() {
    return {
        type: SWITCH_SIDE_MENU
    };
}
