import * as React from "react";
import styles from "./styles.scss";
import cx from "classnames";

type Props = {
    className?: string;
    [x: string]: any;
};

const DragButton: React.FC<Props> = ({ children, className = "", ...rest }) => {
    return (
        <div className={cx(styles.dragButton, className)} {...rest}>
            <div className={styles.lines} />
        </div>
    );
};

export default DragButton;
