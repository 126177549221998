import * as React from "react";
import Modal from "../../index";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";

type Props = {
    action?: (args: any) => void;
    buttonText: string;
    confirmKey?: string;
    description?: string;
};

type State = {
    isModalOpen: boolean;
    confirmKey: string;
};

class ConfirmRemoving extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isModalOpen: false,
            confirmKey: ""
        };
    }

    handleOpenModal() {
        this.setState({ isModalOpen: true });
    }

    handleCloseModal() {
        this.setState({ isModalOpen: false, confirmKey: "" });
    }

    handleInputChange(e: React.SyntheticEvent) {
        const { value } = e.target as HTMLInputElement;
        this.setState({ confirmKey: value });
    }

    isDisabled(confirmKey: string) {
        if (confirmKey === this.state.confirmKey) {
            return false;
        }
        return true;
    }

    remove({ ...args }) {
        this.handleCloseModal();
        const { action } = this.props;

        if (typeof action == "function") {
            action(args);
        }
    }

    render() {
        const { isModalOpen } = this.state;
        const { buttonText, confirmKey, description } = this.props;

        return (
            <>
                <button onClick={this.handleOpenModal.bind(this)}>
                    {buttonText}
                </button>
                {isModalOpen && (
                    <Modal
                        className={styles.smallModal}
                        onClose={this.handleCloseModal.bind(this)}
                        title="Remove"
                    >
                        <main className={styles.main}>
                            {description && <p>{description}</p>}
                            {confirmKey && (
                                <>
                                    <p className={styles.confirm}>
                                        Type <code>{confirmKey}</code> to
                                        confirm.
                                    </p>
                                    <input
                                        placeholder={`confirm key`}
                                        onChange={this.handleInputChange.bind(
                                            this
                                        )}
                                        name="confirmKey"
                                    />
                                </>
                            )}
                            <div className={styles.buttons}>
                                <button
                                    onClick={this.handleCloseModal.bind(this)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={styles.primary}
                                    disabled={
                                        confirmKey
                                            ? this.isDisabled(confirmKey)
                                            : false
                                    }
                                    onClick={this.remove.bind(this)}
                                >
                                    Remove
                                </button>
                            </div>
                        </main>
                    </Modal>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({});

export default connect(mapStateToProps, null)(ConfirmRemoving);
