import * as React from "react";
import AddUpsellContainer from "./components/Upsells/components/AddUpsellContainer";
import Button from "components/Button";
import Triggers from "./components/Triggers";
import Upsells from "./components/Upsells";
import styles from "./styles.scss";
import { addUpsellsFlow } from "../actions";
import { appStateType } from "reducers";
import { product } from "types/product";
import { odooProduct } from "types/odooProduct";
import { schemaType } from "../../../../components/NodeComponent";
import { upsellsFlow } from "types/upsellsFlow";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "components/LoadingIndicator";
import { set } from "types/set";
import { reorderUpsellContainer } from "./components/Upsells/components/UpsellContainer/actions";

interface Params {
    languageId: string;
    setId: string;
    upsellsFlowId: string;
    versionId: string;
}

interface CMSContextType extends Params {
    elements: any[];
    products: product[];
    schema: schemaType;
    odooProducts: odooProduct[];
    odooProductsProd: odooProduct[];
    odooProductsDev: odooProduct[];
    crm: set["crm"];
}

type Props = {
    elements: any[];
    products: product[];
    odooProducts: odooProduct[];
    odooProductsProd: odooProduct[];
    odooProductsDev: odooProduct[];
    crm: set["crm"];
    match: {
        params: Params;
    };
};

export const CMSContext = React.createContext<CMSContextType>({
    elements: [],
    languageId: "",
    products: [],
    odooProducts: [],
    odooProductsProd: [],
    odooProductsDev: [],
    schema: {},
    setId: "",
    upsellsFlowId: "",
    versionId: "",
    crm: "KONNEKTIVE"
});

const Flow: React.FC<Props> = ({
    match,
    elements,
    products,
    odooProducts = [],
    odooProductsProd = [],
    odooProductsDev = [],
    crm = "KONNEKTIVE"
}) => {
    const {
        params: { upsellsFlowId, setId, languageId, versionId }
    } = match;

    const [title, setTitle] = React.useState("");
    const [editMode, setEditMode] = React.useState(false);
    const dispatch = useDispatch();
    const version = useSelector((state: appStateType) => state.deployment.version);
    const upsellReducer = useSelector((state: appStateType) => state.upsell);
    const {
        addUpsellContainerLoading,
        isLoading,
        removeUpsellContainerLoading,
        upsellContainer,
        upsellsFlow
    } = upsellReducer;

    const flow = upsellsFlow.find((upsellsFlow: upsellsFlow) => upsellsFlow._id === upsellsFlowId);
    const containers = upsellContainer.filter(
        upsellContainer => flow && flow.upsells.includes(upsellContainer._id)
    );

    const _schema = version && version._id === versionId && version._schema ? version._schema : {};

    React.useEffect(() => {
        if (flow) {
            setTitle(flow.title);
        }
    }, [flow]);

    const handleClick = () => {
        if (editMode) {
            setEditMode(false);

            if (flow) {
                dispatch(
                    addUpsellsFlow({ languageId, setId, title, _id: flow._id, order: flow.order })
                );
            }
        } else {
            setEditMode(true);
        }
    };

    const handleContainerReorder = (result: any) => {
        const { destination, source, draggableId } = result;

        if (!destination || !source) {
            return;
        }

        if (destination.droppableId !== source.droppableId) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        dispatch(reorderUpsellContainer(source.index, destination.index, draggableId, containers));
    };

    const isDuplicating = flow && flow.duplication && !flow.duplication.isCompleted;

    return flow ? (
        <CMSContext.Provider
            value={{
                elements,
                languageId,
                products,
                odooProducts,
                odooProductsProd,
                odooProductsDev,
                schema: _schema,
                setId,
                upsellsFlowId,
                versionId,
                crm
            }}
        >
            <div>
                <div className={styles.panel}>
                    <section>
                        <header className={styles.header}>
                            {editMode ? (
                                <input value={title} onChange={e => setTitle(e.target.value)} />
                            ) : (
                                <h2 className={styles.title}>{flow.title}</h2>
                            )}
                            <Button
                                onClick={handleClick}
                                icon="editName"
                                design="icon"
                                marginLeft
                            />
                        </header>
                        <Triggers upsellsFlowId={upsellsFlowId} />
                        {isDuplicating ? (
                            <div>
                                Upsell Flow is being duplicated. please come back later or refresh
                                the page
                            </div>
                        ) : null}
                        <Upsells
                            addUpsellContainerLoading={addUpsellContainerLoading}
                            removeUpsellContainerLoading={removeUpsellContainerLoading}
                            containers={containers}
                            upsellsFlowId={flow._id}
                            handleContainerReorder={handleContainerReorder}
                        />
                    </section>
                </div>
                {isLoading === false ? (
                    <AddUpsellContainer
                        addUpsellContainerLoading={addUpsellContainerLoading}
                        upsellsFlowId={flow._id}
                        totalContainers={containers.length}
                    />
                ) : (
                    <LoadingIndicator />
                )}
            </div>
        </CMSContext.Provider>
    ) : null;
};

export default Flow;
