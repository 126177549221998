import * as React from "react";
import * as actions from "../ServerDeployments/actions";
import Button from "components/Button";
import InfiniteScroll from "react-infinite-scroll-component";
import DeploymentCard from "../../components/DeploymentCard";
import LoadingIndicator from "components/LoadingIndicator";
import { sortBy, throttle } from "lodash";
import filterIcon from "assets/icons/filter.svg";
import { brand } from "types/brand";
import styles from "./styles.scss";
import { Version } from "types/version";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { getLiveDeployment } from "services/getLiveDeployment";
import { getBrands } from "scenes/Dashboard/Brand/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ActionMeta } from "react-select";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

class LiveDeployments extends React.PureComponent<any, any> {
    handleTrottle: any;

    constructor(props: any) {
        super(props);
        this.handleTrottle = throttle(async () => {
            await this.props.pullDeployments();
        }, 1000);
    }

    static defaultProps = {
        deployments: [],
        liveDeployments: [],
        servers: [],
        sets: [],
        templates: [],
        versions: [],
        brands: []
    };

    crm = [
        {
            label: "Odoo Staging",
            value: "ODOO"
        },
        {
            label: "Odoo Production",
            value: "ODOO_PROD"
        },
        {
            label: "Odoo Development",
            value: "ODOO_DEV"
        },
        {
            label: "Konnektive",
            value: "KONNEKTIVE"
        }
    ];

    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleTrottle();
        this.props.getBrands();
    }

    async componentDidUpdate(prevProps: any) {
        const { filters } = this.props;
        const { filters: prevFilters } = prevProps;
        const hasFiltersChanged = Object.keys(filters).some(
            filter => prevFilters[filter] !== filters[filter]
        );

        if (hasFiltersChanged) {
            this.props.resetDeployments();
            this.handleTrottle();
        }
    }

    setFilter(name: string, value: string) {
        this.props.setDeploymentFilters({ key: name, value });
        this.props.resetDeployments();
    }

    handleChange(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
        const { name, value } = e.target;
        this.setFilter(name, value);
    }

    handleSelectChange(
        selectedOption: SelectOption,
        config: ActionMeta<{ name: string; action: string }>
    ) {
        const value = selectedOption && selectedOption.value ? selectedOption.value : "";
        this.setFilter(config.name as string, value);
    }

    clearFilter() {
        const hasFilters = Object.values(this.props.filters).some(Boolean);
        if (hasFilters) {
            this.props.resetDeploymentFilters();
        }
    }

    getSortedVersions(versions: Version[]) {
        const { template: templateId } = this.props.filters;

        const filteredVersions = versions.filter((version: Version) =>
            templateId ? version.template._id === templateId : true
        );

        const splitedTags = filteredVersions.map((version: Version) => ({
            ...version,
            tag: version.tag.split("-")
        }));

        const sortedVersions = splitedTags.sort((a, b) =>
            b.tag[0]
                .replace(/\d+/g, n => (+n + 100000) as any)
                .localeCompare(a.tag[0].replace(/\d+/g, n => (+n + 100000) as any))
        );

        const mergedTags = sortedVersions.map((version: Version & { tag: string[] }) => ({
            ...version,
            tag: version.tag.join("-")
        }));

        return mergedTags;
    }

    render() {
        const {
            brands,
            languages,
            liveDeployments,
            sets,
            templates,
            versions,
            isPullingDeployments,
            isDrained,
            deployments
        } = this.props;

        const {
            set: setId = "",
            template: templateId = "",
            version: versionId = "",
            brand: brandId = "",
            crm = "",
            basename = ""
        } = this.props.filters;

        const setOptions = sortBy(sets, (set: any) => set.name).map((set: any) => ({
            label: set.name,
            value: set._id
        }));

        const templateOptions = templates.map((template: any) => ({
            label: `${template.name} (${template.shortcut})`,
            value: template._id
        }));

        const versionOptions = this.getSortedVersions(versions).map((version: any) => ({
            label: `${version.tag} (${version.template.shortcut})`,
            value: version._id
        }));

        const brandOptions = brands.map((brand: brand) => ({
            label: brand.name,
            value: brand._id
        }));

        const selectedTemplate = templateOptions.find(
            (el: SelectOption) => el.value === templateId
        );
        const selectedSet = setOptions.find((el: SelectOption) => el.value === setId);
        const selectedVersion = versionOptions.find((el: SelectOption) => el.value === versionId);
        const selectedBrand = brandOptions.find((el: SelectOption) => el.value === brandId);
        const selectedCrm = this.crm.find((el: SelectOption) => el.value === crm);

        return (
            <>
                <div className={styles.panel}>
                    <div className={styles.search}>
                        <input
                            type="text"
                            placeholder="Search for basename ..."
                            autoFocus
                            value={basename}
                            name="basename"
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
                    <div className={styles.filterContainer}>
                        <img src={filterIcon} className={styles.filterIcon} alt="filters" />
                        <div className={styles.filter}>
                            <Select
                                options={templateOptions}
                                components={animatedComponents}
                                name="template"
                                placeholder="Select Template"
                                onChange={this.handleSelectChange.bind(this)}
                                value={selectedTemplate}
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        maxWidth: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        background: "white"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.filter}>
                            <Select
                                options={setOptions}
                                components={animatedComponents}
                                name="set"
                                placeholder="Select CMS Set"
                                onChange={this.handleSelectChange.bind(this)}
                                value={selectedSet}
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        maxWidth: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        background: "white"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.filter}>
                            <Select
                                options={versionOptions}
                                components={animatedComponents}
                                name="version"
                                placeholder="Select Version"
                                onChange={this.handleSelectChange.bind(this)}
                                value={selectedVersion}
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        maxWidth: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        background: "white"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.filter}>
                            <Select
                                options={brandOptions}
                                components={animatedComponents}
                                name="brand"
                                placeholder="Select Brand"
                                onChange={this.handleSelectChange.bind(this)}
                                value={selectedBrand}
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        maxWidth: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        background: "white"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.filter}>
                            <Select
                                options={this.crm}
                                components={animatedComponents}
                                name="crm"
                                placeholder="Select CRM"
                                onChange={this.handleSelectChange.bind(this)}
                                value={selectedCrm}
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        maxWidth: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        background: "white"
                                    })
                                }}
                            />
                        </div>
                        <Button onClick={this.clearFilter.bind(this)} flat>
                            Clear Filters
                        </Button>
                    </div>
                </div>
                <div className={styles.deployments}>
                    <InfiniteScroll
                        className={styles.deployments_grid}
                        style={{ overflow: "initial" }}
                        dataLength={deployments.length}
                        next={() => this.props.pullDeployments()}
                        hasMore={!isDrained}
                        loader={
                            <div
                                style={{
                                    marginTop: 64
                                }}
                            >
                                <LoadingIndicator size={100} />
                            </div>
                        }
                    >
                        {deployments.map((deployment: any) => {
                            const liveDeployment = getLiveDeployment(
                                [],
                                deployment.pipelineResponse || {}
                            );

                            return (
                                <DeploymentCard
                                    deployment={deployment}
                                    key={deployment._id}
                                    languages={languages}
                                    liveDeployment={liveDeployment}
                                />
                            );
                        })}
                    </InfiniteScroll>
                    {!isPullingDeployments && !deployments.length && isDrained ? (
                        <div className={styles.errMsg}>
                            <span>No deployments matching this filter</span>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ brand, deployment, sets, languages }: appStateType) => ({
    brands: brand.brands,
    deployments: deployment.deployments,
    filters: deployment.filters,
    isPullingDeployments: deployment.isPullingDeployments,
    isDrained: deployment.isDrained,
    languages: Array.from(languages.languagesMap.values()),
    liveDeployments: deployment.liveDeployments,
    servers: deployment.servers,
    sets: sets.sets,
    templates: deployment.templates,
    versions: deployment.versions
});

export default connect(mapStateToProps, {
    ...actions,
    getBrands
})(LiveDeployments);
