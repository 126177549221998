import * as actionTypes from "actionTypes";
import api from "api";
import { appStateType } from "reducers";
import { Dispatch } from "redux";
import { brick } from "types/brick";
import { element } from "types/element";

function findChildrens(elements: {}[], parent: string) {
    return elements.filter((element: element) => element.parent !== "" && element.parent == parent);
}

function findBrick(bricks: any, element: any) {
    return bricks.find((brick: brick) => brick.element == element._id);
}

export function buildTree(elementId: string, languageId: string) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const {
            elements: { elements },
            bricks: { bricksMap }
        } = getState();
        const bricks = Array.from(bricksMap.values()).filter(
            (brick: brick) => brick.language == languageId
        );
        const parent = elements.find((element: element) => element._id === elementId) as element;

        const tree: any = [];
        let elementsCount = 0;
        let bricksCount = 0;
        let variantsCount = 0;
        dispatch({
            type: actionTypes.GET_TREE_START
        });
        const recoursiveBuildTree = (elements: any, parent: any, ref = []) => {
            if (ref.length == 0) {
                tree.push({ ...parent, childrens: [] });
            }
            const childrens = findChildrens(elements, parent._id);
            if (childrens.length) {
                childrens.forEach((children: any) => {
                    elementsCount++;
                    let brick;
                    if (ref.length == 0) {
                        ref = tree[0];
                    }
                    if (
                        [
                            "TEXT",
                            "NUMBER",
                            "KONNEKTIVE_PRODUCT",
                            "ODOO_PRODUCT",
                            "SWITCH",
                            "FILE"
                        ].includes(children.type)
                    ) {
                        brick = findBrick(bricks, children);
                        if (brick) bricksCount++;
                        if (brick && brick.hasOwnProperty("variants"))
                            variantsCount += brick["variants"].length;
                    }
                    ref["childrens"] = [...ref["childrens"], { ...children, childrens: [], brick }];
                    return recoursiveBuildTree(
                        elements,
                        children,
                        ref["childrens"].find((children2: any) => children2._id == children._id)
                    );
                });
            }
            return tree;
        };

        dispatch({
            type: actionTypes.GET_TREE,
            payload: {
                tree: recoursiveBuildTree(elements, parent),
                count: {
                    elements: elementsCount,
                    bricks: bricksCount,
                    variants: variantsCount,
                    sum: elementsCount + bricksCount + variantsCount
                }
            }
        });
    };
}

async function asyncForEach(array: [], callback: any) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

async function copyElement(element: any, parent: string, set: string, language: string) {
    const { name, type, childrens } = element;
    const res = await api().post("element", {
        setId: set,
        type,
        parent,
        languageId: language,
        name
    });
    const {
        data: { elements, bricks }
    } = res;

    return {
        ...elements[0],
        brick: { ...bricks[0] },
        childrens,
        elements,
        bricks
    };
}

async function copyVariant(variant: any, brick: string) {
    if (variant) {
        const { value } = variant;
        const res = await api().put("brick", { id: brick, value });
        const { data } = res;
        return { ...data };
    } else {
        return null;
    }
}

type copyTreeConfig = {
    language?: string;
    parent?: string;
    set?: string;
    copyVariants?: boolean;
};

export function copyTree(config: copyTreeConfig = {}) {
    return async (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.COPY_TREE_START
        });
        const { tree = [] } = getState().tree as any;

        if (Array.isArray(tree) && tree.length > 0) {
            const language = config.language;
            const set = config.set;
            const firstParent = config.parent;
            const firstObject = tree[0];

            if (firstObject == undefined) {
                const firstChildrens = firstObject["childrens"];
                const copyVariants = config.copyVariants ? true : true;

                const processTree = async (tree: any, nextParent: string | null = null) => {
                    if (tree.length > 0 && set && language) {
                        await asyncForEach(tree, async (element: any) => {
                            let newParent: any;

                            if (
                                [...firstChildrens].find(
                                    (children: any) => children._id == element._id
                                )
                            ) {
                                newParent = firstParent;
                            } else {
                                newParent = nextParent;
                            }

                            const newElement = await copyElement(element, newParent, set, language);

                            if (newElement) {
                                dispatch({
                                    type: actionTypes.INCREMENT_PROGRESS
                                });
                            }

                            if (newElement.brick.hasOwnProperty("variants")) {
                                dispatch({
                                    type: actionTypes.INCREMENT_PROGRESS
                                });
                            }

                            if (
                                copyVariants &&
                                element.brick &&
                                element.brick.hasOwnProperty("variants") &&
                                element.brick.variants.length > 0
                            ) {
                                await asyncForEach(element.brick.variants, async (variant: any) => {
                                    const newVariant = await copyVariant(
                                        variant,
                                        newElement.brick._id
                                    );

                                    if (newVariant) {
                                        dispatch({
                                            type: actionTypes.INCREMENT_PROGRESS
                                        });
                                    }
                                });
                            }

                            if (newElement.childrens && newElement.childrens.length > 0) {
                                newParent = newElement._id;
                                processTree(newElement.childrens, newParent);
                            }
                        });
                    }

                    return tree;
                };

                await processTree(tree);
            }
        }
    };
}

export function copyComplete() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.COPY_COMPLETE
        });
    };
}
