import * as actionTypes from "actionTypes";
import api from "api";
import browserHistory from "browserHistory";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import { appStateType } from "reducers";

type Server = {
    _id: string;
    campaignKeys: {};
    domain: string;
    host: string;
    otherSettings: {};
    password: string;
    pixels: {};
    username: string;
};

export function saveServer({
    _id,
    campaignKeys,
    domain,
    host,
    otherSettings,
    password,
    pixels,
    username
}: Server) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.ADD_SERVER_START
        });

        api()
            .put("server", {
                _id,
                domain,
                host,
                password,
                username,
                campaignKeys,
                otherSettings,
                pixels
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_SERVER,
                    payload: { server: res.data }
                });
                browserHistory.push("/dashboard/deployment/servers");
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_SERVER_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}
