import * as React from "react";
import * as actions from "./actions";
import * as dashboardActions from "../../../actions";
import * as moment from "moment-timezone";
import LoadingIndicator from "components/LoadingIndicator";
import _ from "lodash";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { IImpressionReport, IImpressionReportQuery } from "types/report";
import { set } from "types/set";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ActionMeta } from "react-select";
import { Version } from "types/version";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

interface State {
    selectedSet: set | undefined;
    selectedVersion: string;
    basename: string;
    from: string;
    to: string;
}

type dynamicTotal = [string, any[]];

class Impressions extends React.PureComponent<any, State> {
    constructor(props: object) {
        super(props);

        this.state = {
            selectedSet: undefined,
            selectedVersion: "",
            from: this.getTodaysDate(),
            to: this.getTodaysDate(),
            basename: ""
        };
    }

    getTodaysDate(now = new Date()) {
        return moment(now).format("YYYY-MM-DD");
    }

    handleReportClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const { selectedSet, selectedVersion, from, to: toInput, basename } = this.state;

        const toDate = new Date(toInput);
        const to = this.getTodaysDate(toDate);

        const query: IImpressionReportQuery = {
            selectedSet: selectedSet?._id,
            selectedVersion: selectedVersion,
            from,
            to,
            basename
        };

        this.props.getImpressionsReport(query);
    }

    handleSelectSet(
        selectedOption: SelectOption,
        config: ActionMeta<{ name: string; action: string }>
    ) {
        if (!selectedOption) {
            this.setState(state => ({ ...state, selectedSet: undefined }));
        }

        const { value: selectedSetId } = selectedOption;
        const { sets } = this.props.sets;
        const selectedSet = sets.find((set: set) => set._id == selectedSetId);

        this.setState({ selectedSet });
    }

    handleSelectVersion(
        selectedOption: SelectOption,
        config: ActionMeta<{ name: string; action: string }>
    ) {
        if (!selectedOption) {
            this.setState(state => ({ ...state, selectedVersion: "" }));
        }

        const { value: selectedVersionTag } = selectedOption;

        this.setState({ selectedVersion: selectedVersionTag });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        const { value, name } = e.target;

        // @ts-ignore
        this.setState({ [name]: value });
    }

    handleSelectFilter(
        selectedOption: SelectOption[],
        config: ActionMeta<{ name: string; action: string }>
    ) {
        let value: undefined | string[];

        if (selectedOption && selectedOption.length) {
            value = selectedOption.map(opt => opt.value);
        }

        this.setState(state => ({
            ...state,
            [config.name as string]: value
        }));
    }

    render() {
        const { impressionReport, isLoading } = this.props.analytics;
        const { sets } = this.props.sets;
        const { versions } = this.props;
        const { from, to, selectedVersion, selectedSet, basename } = this.state;

        return (
            <>
                <div className={`${styles.panel_wide}`}>
                    <div className={`${styles.form_wide}`}>
                        <div className={styles.input_group}>
                            <label>Set:</label>
                            <Select
                                options={sets.map((el: set) => ({
                                    value: el._id,
                                    label: el.name
                                }))}
                                components={animatedComponents}
                                name="selectedSet"
                                placeholder="Select ContentSet"
                                onChange={this.handleSelectSet.bind(this)}
                                value={
                                    selectedSet
                                        ? { value: selectedSet._id, label: selectedSet.name }
                                        : null
                                }
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        position: "absolute",
                                        background: "#f5f5f5",
                                        marginTop: "2px",
                                        zIndex: 9999,
                                        minWidth: "500px"
                                    }),
                                    container: () => ({
                                        width: "100%",
                                        minWidth: "500px"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>Versions:</label>
                            <Select
                                options={versions.map((el: Version) => ({
                                    value: el.tag,
                                    label: `${el.tag} (${el.template.shortcut})`
                                }))}
                                components={animatedComponents}
                                name="selectedVersion"
                                placeholder="Select Version"
                                onChange={this.handleSelectVersion.bind(this)}
                                value={
                                    selectedVersion
                                        ? { value: selectedVersion, label: selectedVersion }
                                        : null
                                }
                                isClearable
                                isSearchable
                                styles={{
                                    menu: () => ({
                                        border: "1px solid gray",
                                        borderRadius: "4px",
                                        overflowX: "hidden",
                                        position: "absolute",
                                        background: "#f5f5f5",
                                        marginTop: "2px",
                                        zIndex: 9999,
                                        minWidth: "500px"
                                    }),
                                    container: () => ({
                                        width: "100%",
                                        minWidth: "500px"
                                    })
                                }}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>From:</label>
                            <input
                                type="date"
                                value={from}
                                name="from"
                                onChange={this.handleChange.bind(this)}
                            />
                        </div>
                        <div className={styles.input_group}>
                            <label>To:</label>
                            <input
                                type="date"
                                value={to}
                                name="to"
                                onChange={this.handleChange.bind(this)}
                            />
                        </div>
                    </div>
                    <div className={`${styles.form_wide}`}>
                        <div className={styles.input_group}>
                            <label>Basename:</label>
                            <input
                                type="text"
                                value={basename}
                                name="basename"
                                onChange={this.handleChange.bind(this)}
                                placeholder="filter by basename"
                            />
                        </div>
                    </div>
                    <div className={`${styles.form_wide}`}>
                        {isLoading ? (
                            <div className={styles.input_group}>
                                <LoadingIndicator className={styles.loader} />
                            </div>
                        ) : (
                            <div className={styles.input_group}>
                                <button
                                    onClick={this.handleReportClick.bind(this)}
                                    className={styles.primary}
                                >
                                    REPORT
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {impressionReport && impressionReport.length ? (
                    <div className={styles.panel_wide} style={{ overflowX: "scroll" }}>
                        <h2>Impressions Report:</h2>
                        <table>
                            <thead>
                                <tr>
                                    <td>Content Set:</td>
                                    <td>Version:</td>
                                    <td>Basename:</td>
                                    <td>Views:</td>
                                    <td>Conversions:</td>
                                    <td>CVR:</td>
                                    <td>Revenue:</td>
                                    <td>AOV:</td>
                                </tr>
                            </thead>
                            <tbody>
                                {impressionReport.map((impression: IImpressionReport) => {
                                    const set = sets.find((el: set) => el._id === impression.set);

                                    return (
                                        <tr
                                            key={`${impression.set}-${impression.version}-${impression.basename}`}
                                        >
                                            <td>{set ? set.name : impression.set}</td>
                                            <td>{impression.version || "-"}</td>
                                            <td>{impression.basename || "-"}</td>
                                            <td>{impression.views}</td>
                                            <td>{impression.sales}</td>
                                            <td>
                                                {getPercentage(impression.sales / impression.views)}
                                            </td>
                                            <td>{getDollars(impression.revenue)}</td>
                                            <td>
                                                {getDollars(impression.revenue / impression.sales)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    sets: state.sets,
    versions: state.deployment.versions || [],
    analytics: state.analytics
});

export default connect(mapStateToProps, { ...actions, ...dashboardActions })(Impressions);
