import "./WebFont";
import * as React from "react";
import Dashboard from "./scenes/Dashboard";
import PrivateRoute from "components/PrivateRoute";
import SignIn from "./scenes/SignIn";
import history from "browserHistory";
import store from "./store";
import { Provider } from "react-redux";
import { Switch, Router, Route, Redirect } from "react-router-dom";

class App extends React.PureComponent<any, any> {
    public render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        <Route path="/signin" component={SignIn} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <Redirect to="/signin" />
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;
