export enum LogicalOperator {
    AND = "AND",
    OR = "OR"
}

export type FlowTrigger = {
    _id: string;
    logicalOperator: LogicalOperator;
    variableType: string;
    variableKey: string;
    variableName: string;
    operator: string;
    compareValue: string | boolean;
};
