import cx from "classnames";
import * as React from "react";
import styles from "./styles.scss";

type Props = {
    [x: string]: any;
    bold?: "label" | "value";
    data?: any;
    direction?: "row" | "column";
    fontSize?: number;
    label: string;
    pointer?: boolean;
    primaryColor?: string | "green";
    reverse?: boolean;
    value: string | number | JSX.Element;
    info?: string;
    className?: string;
};

const DataPiece: React.FC<Props> = ({
    bold = "value",
    data,
    direction = "row",
    fontSize = "14px",
    label,
    pointer = false,
    primaryColor = "black",
    reverse = false,
    value,
    info,
    clasName,
    ...rest
}) => {
    if (primaryColor === "green") {
        primaryColor = "#46d440";
    }

    return (
        <div
            className={cx(styles.dataPiece, clasName, {
                [styles.column]: direction === "column",
                [styles.columnReverse]: direction === "column" && reverse,
                [styles.rowReverse]: direction === "row" && reverse,
                [styles.pointer]: pointer
            })}
            style={{ fontSize }}
            {...rest}
        >
            <span className={cx({ [styles.bold]: bold === "label" })} title={info}>
                {label}
            </span>
            <span
                className={cx({ [styles.bold]: bold === "value" })}
                style={{ color: primaryColor }}
            >
                {value}
            </span>
        </div>
    );
};

export default DataPiece;
