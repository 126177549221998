import React, { useEffect, useState } from "react";
import styles from "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { appStateType } from "reducers";
import { getReport } from "./actions";
import Report from "../ReportsList/components/Report";

type Props = {};

type ReportForm = {
    funnelId: string;
    fromDate: string;
    toDate: string;
};

const CreateReport: React.FC<Props> = () => {
    const emptyReportForm: ReportForm = { funnelId: "", fromDate: "", toDate: "" };
    const [reportForm, setReportForm] = useState<ReportForm>(emptyReportForm);
    const {
        funnels,
        isLoadingReport,
        report,
        reportGeneratingProgress,
        reportGeneratingMessages
    } = useSelector((state: appStateType) => state.conversionbalancer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (funnels.length > 0) {
            setReportForm(prevReportForm => ({ ...prevReportForm, funnelId: funnels[0]._id }));
        }
    }, [funnels.length]);

    const handleReportFormChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { value = "", name = "" } = e.target;
        setReportForm(prevReportForm => ({ ...prevReportForm, [name]: value }));
    };

    const handleGetReport = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        dispatch(getReport(reportForm));
    };

    return (
        <>
            <h2>Reporting</h2>
            <div className={styles.reportParams}>
                <div className={styles.reportParam}>
                    Funnel:
                    <select
                        name="funnelId"
                        value={reportForm.funnelId}
                        onChange={handleReportFormChange}
                    >
                        {funnels.map(funnel => (
                            <option key={funnel._id} value={funnel._id}>
                                {funnel.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.reportParam}>
                    From:{" "}
                    <input
                        name="fromDate"
                        type="date"
                        value={reportForm.fromDate}
                        onChange={handleReportFormChange}
                    />
                </div>
                <div className={styles.reportParam}>
                    To:{" "}
                    <input
                        name="toDate"
                        type="date"
                        value={reportForm.toDate}
                        onChange={handleReportFormChange}
                    />
                </div>
            </div>
            {isLoadingReport ? (
                <>
                    <div className={styles.percentageProgress}>
                        {reportGeneratingProgress === 0 ? (
                            <>Initializing...</>
                        ) : (
                            <>{reportGeneratingProgress}%</>
                        )}
                    </div>
                    <div className={styles.stripeBox}>
                        <div
                            className={styles.stripe}
                            style={{ width: reportGeneratingProgress + "%" }}
                        >
                            <div className={styles.animation} />
                        </div>
                    </div>
                    <div className={styles.messagesBox}>
                        {reportGeneratingMessages.map(message => (
                            <div className={styles.message}>✅ {message}...</div>
                        ))}
                    </div>
                </>
            ) : (
                <button
                    className={styles.getReport}
                    onClick={handleGetReport}
                    disabled={isLoadingReport}
                >
                    REPORT
                </button>
            )}
            {report && Object.keys(report).length > 0 && (
                <Report
                    id={report._id}
                    createdAt={report.createdAt}
                    createdBy={report.createdBy?.login || ""}
                    funnel={report.funnelId}
                    funnelCopy={report.funnelCopy}
                    fromDate={report.fromDate}
                    toDate={report.toDate}
                    googleAdsCampaigns={report.googleAdsCampaigns}
                    getFunnelError={report.getFunnelError}
                    creatingGoogleAdsClientError={report.creatingGoogleAdsClientError}
                    pullingGoogleAdsCampaignsError={report.pullingGoogleAdsCampaignsError}
                    bigQueryError={report.bigQueryError}
                    bigQueryRows={report.bigQueryRows}
                    totalBigQueryRows={report.totalBigQueryRows}
                    totalBigQueryError={report.totalBigQueryError}
                    googleConversionsError={report.googleConversionsError}
                    googleConversions={report.googleConversions}
                    googleConversionsOriginalResponse={report.googleConversionsOriginalResponse}
                    otherError={report.error}
                    conversionsDifference={report.conversionsDifference}
                    bqSessions={report.bqSessions}
                    bqSessionsError={report.bqSessionsError}
                    conversionsDraft={report.conversionsDraft}
                    conversionsDraftError={report.conversionsDraftError}
                />
            )}
        </>
    );
};

export default CreateReport;
