export const getFileObject = (filePath: string) => {
    const values = filePath.split("#");
    const data = values.map((partOfData: string) => partOfData.split("="));

    return data.reduce((prev: {}, [key, value]: [string, string]) => {
        let updatedValue: any = null;
        switch (key) {
            case "name":
                updatedValue = decodeURIComponent(value.replace(/\+/g, " "));
                break;
            case "extensions":
                updatedValue = value && value.split("|");
                break;
            default:
                updatedValue = value;
                break;
        }

        return {
            ...prev,
            [key]: updatedValue
        };
    }, {});
};

export const isFile = (variantValue: string) => {
    const stringValue = String(variantValue);

    if (
        stringValue.includes("hash") &&
        stringValue.includes("extensions") &&
        stringValue.includes("path")
    ) {
        return true;
    }

    return false;
};
