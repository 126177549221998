import * as React from "react";
import * as actions from "./actions";
import * as moment from "moment-timezone";
import LoadingIndicator from "components/LoadingIndicator";
import Version from "./components/Version";
import _ from "lodash";
import styles from "./styles.scss";
import { connect } from "react-redux";

type Props = {
    isOpen: boolean;
    list: any[];
    mode?: "tooltip";
    versions: any[];
};

class Versions extends React.PureComponent<Props, any> {
    static defaultProps = {
        isOpen: true,
        versions: []
    };

    render() {
        const { isOpen, list, mode, versions = [] } = this.props;
        let filteredVersions = versions;

        if (mode === "tooltip") {
            filteredVersions = list;
        }
        filteredVersions = _(filteredVersions).orderBy("createdAt", "desc").value();

        return (
            isOpen &&
            (versions.length > 0 ? (
                <div className={this.props.mode === "tooltip" ? styles.tooltip : ""}>
                    {filteredVersions.map(({ _id, createdAt, ...version }: any) => (
                        <Version
                            _id={_id}
                            createdAt={moment(createdAt).format("YYYY-MM-DD").toString()}
                            key={_id}
                            mode={mode}
                            {...version}
                        />
                    ))}
                </div>
            ) : (
                <LoadingIndicator />
            ))
        );
    }
}

const mapStateToProps = (state: any) => ({
    deployment: state.deployment,
    versions: state.deployment.versions
});

export default connect(mapStateToProps, actions)(Versions);
