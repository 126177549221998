import * as actionTypes from "actionTypes";

export type systemStateType = {
    crons: {
        [key: string]: {
            timestamp: Date;
        };
    };
    isLoading: boolean;
    errors: null | Error;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: systemStateType = {
    crons: {},
    isLoading: false,
    errors: null
};

export const systemStorage: { [key: string]: boolean } = {};

export default (state: systemStateType = initialState, action: IAction): systemStateType => {
    switch (action.type) {
        case actionTypes.GET_SYSTEM_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_SYSTEM:
            return {
                ...state,
                crons: action.payload.crons,
                isLoading: false
            };
        case actionTypes.GET_SYSTEM_ERROR:
            return {
                ...state,
                crons: {},
                errors: action.payload.error,
                isLoading: false
            };

        default:
            return state;
    }
};
