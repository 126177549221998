import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appStateType } from "reducers";
import { addTrigger, saveTriggers, updateTrigger } from "./actions";
import Trigger from "./components/Trigger";
import styles from "./styles.scss";
import triggerVariables from "assets/triggerVariables";

type Props = {
    match: {
        params: {
            upsellsFlowId: string;
        };
    };
};

interface FlowUpdateTriggerProps {
    triggerId: string;
    key: string;
    value: string | boolean;
}

const TriggersEditor: React.FC<Props> = ({ ...rest }) => {
    const { upsellsFlowId } = rest.match.params;
    const { upsellFlows, saveTriggersLoading } = useSelector((state: appStateType) => ({
        version: state.deployment.version,
        upsellFlows: state.upsell.upsellsFlow,
        saveTriggersLoading: state.upsell.saveTriggersLoading
    }));
    const dispatch = useDispatch();

    const flow = upsellFlows.find(flow => flow._id == upsellsFlowId);

    const handleAddTrigger = () => {
        dispatch(addTrigger(upsellsFlowId, triggerVariables[0]));
    };

    const flowUpdateTrigger = ({ triggerId, key, value }: FlowUpdateTriggerProps) => {
        dispatch(
            updateTrigger({
                upsellsFlowId,
                triggerId,
                key,
                value
            })
        );
    };

    const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(saveTriggers(upsellsFlowId));
    };

    return (
        <div className={styles.editor}>
            <div className={styles.triggers}>
                <header className={styles.header}>
                    {flow && <span className={styles.title}>{flow.title}</span>}
                    {saveTriggersLoading ? (
                        <LoadingIndicator />
                    ) : (
                        <Button onClick={handleSave} flat background="green">
                            Save
                        </Button>
                    )}
                </header>
                <section className={styles.section}>
                    {flow &&
                        flow.triggers.map((trigger, index) => {
                            const updateTriggerMock = (key: string, value: string | boolean) => {
                                flowUpdateTrigger({
                                    triggerId: trigger._id || "",
                                    key,
                                    value
                                });
                            };

                            return (
                                <Trigger
                                    trigger={trigger}
                                    key={trigger._id}
                                    variables={triggerVariables}
                                    updateTrigger={updateTriggerMock}
                                    hideLogicalOperator={index === 0}
                                />
                            );
                        })}
                </section>
            </div>
            <Button design="circle" icon="add" marginTop onClick={handleAddTrigger} size="medium" />
        </div>
    );
};

export default TriggersEditor;
