import api from "api";
import axios, { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function pullOrders(settings: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.START_PULL_ORDERS
    });

    api()
      .get("orders", settings)
      .then((res: AxiosResponse) => {
        dispatch({
          type: actionTypes.PULL_ORDERS,
          payload: { orders: res.data }
        });
      })
      .catch((err: AxiosError) => {
        dispatch({
          type: actionTypes.ERROR_PULL_ORDERS,
          payload: { error: err.response }
        });
      });
  };
}
