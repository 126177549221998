import * as React from "react";
import Dropzone from "react-dropzone";
import styles from "./styles.scss";

const AddFile = (props: any) => {
    return (
        <Dropzone
            onDrop={(acceptedFiles: any) => props.uploadFiles(acceptedFiles)}
        >
            {({ getRootProps, getInputProps, isDragActive }: any) => (
                <section
                    className={`${styles.add} ${isDragActive &&
                        styles.active} ${
                        props.mode === "normal" ? "" : styles.popup
                    }`}
                >
                    <div
                        {...getRootProps({
                            onClick: (event: any) => {
                                event.stopPropagation();
                                props.onClick();
                            }
                        })}
                    >
                        <input {...getInputProps()} />
                        <img src="/img/svg/add.svg" />
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default AddFile;
