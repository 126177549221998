import cx from "classnames";
import SearchList from "components/SearchList";
import _ from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import DataPiece from "scenes/Dashboard/SchemaCMSEditor/components/DataPiece";
import { getOdooProduct } from "services/getOdooProduct";
import parentContains from "services/parentContains";
import { FlavourProduct as FlavourProductType } from "types/pricing";
import { odooProduct } from "types/odooProduct";
import { updateFlavourProductDraft, redirectToPath } from "../../../../actions";
import headerStyles from "../Header/styles.scss";
import productsStyles from "../FlavourProducts/styles.scss";
import styles from "./styles.scss";
import Select from "react-select";
import isEqual from "lodash/isEqual";
import EditIcon from "assets/icons/edit-name.svg";
import { PricingContext } from "../../../..";

const { useState, useRef, useMemo } = React;

interface Props {
    product: FlavourProductType;
    isDraft: boolean;
    crmMode: "ODOO" | "ODOO_PROD" | "KONNEKTIVE" | "ODOO_DEV";
    odooProductListOptions: { name: string; value: string }[] | undefined;
}

type EditorTypes = "SEARCH_PRODUCT" | "OPST" | null;

const Product: React.FC<Props> = ({ product, isDraft, crmMode, odooProductListOptions }) => {
    const dispatch = useDispatch();

    const [currentProduct, setCurrentProduct] = useState<odooProduct | null>(null);
    const [activeField, setActiveFieldValue] = useState<EditorTypes>();
    const [isProductHover, setProductHover] = useState<number>(0);
    const editorRef = useRef<HTMLDivElement>(null);
    const { setId, languageId, versionId } = React.useContext(PricingContext);

    const handleChangeProduct = (product: FlavourProductType, field: "productId" | "opst") => {
        if (field === "productId") {
            const odooProduct = getOdooProduct(
                Number(product.productId),
                crmMode as "ODOO" | "ODOO_PROD" | "ODOO_DEV"
            );
            if (odooProduct) {
                dispatch(
                    updateFlavourProductDraft(product._id, {
                        ...product,
                        subscriptions: []
                    })
                );
                setActiveFieldValue(null);
                setCurrentProduct(odooProduct);
            }
        } else {
            dispatch(updateFlavourProductDraft(product._id, product));
        }
    };

    const setActiveField = (newActiveField: EditorTypes, e: MouseEvent) => {
        e.stopPropagation();

        if (isDraft) {
            setActiveFieldValue(prevActiveField => {
                if (prevActiveField !== newActiveField) {
                    const handleWindowClick = (e: MouseEvent) => {
                        if (editorRef.current) {
                            if (!parentContains(editorRef.current, e.target as Node)) {
                                setActiveFieldValue(null);
                                window.removeEventListener("click", handleWindowClick, false);
                            }
                        }
                    };

                    if (newActiveField !== null) {
                        window.addEventListener("click", handleWindowClick, false);
                    }
                }

                return newActiveField;
            });
        }
    };

    const getEditor = () => {
        switch (activeField) {
            case "SEARCH_PRODUCT":
                return (
                    <SearchList
                        options={odooProductListOptions || []}
                        onChange={(e: any, value: string) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    productId: value
                                },
                                "productId"
                            )
                        }
                    />
                );
            case "OPST":
                return (
                    <Select
                        className={styles.select}
                        options={
                            currentProduct
                                ? currentProduct.subscriptions.map(el => ({
                                      value: String(el.sub_id),
                                      label: el.name
                                  }))
                                : []
                        }
                        isMulti
                        isSearchable
                        placeholder="Select Subscriptions"
                        onChange={selectedOptions => {
                            const subsObj = (currentProduct as odooProduct).subscriptions.reduce(
                                (acc, next) => ({ ...acc, [next.sub_id]: next }),
                                {}
                            );
                            const subs = selectedOptions
                                ? (selectedOptions as { value: string; label: string }[]).map(
                                      el => {
                                          const selectedSub = subsObj[el.value];
                                          return {
                                              subId: el.value,
                                              name: el.label,
                                              recurringInterval: selectedSub.sub_validity
                                          };
                                      }
                                  )
                                : [];
                            handleChangeProduct(
                                {
                                    ...product,
                                    subscriptions: subs
                                },
                                "opst"
                            );
                        }}
                        value={
                            product.subscriptions && product.subscriptions.length
                                ? product.subscriptions.map(el => ({
                                      value: el.subId,
                                      label: el.name
                                  }))
                                : []
                        }
                    />
                );

            default:
                return null;
        }
    };

    let editor = getEditor();

    const getValueForField = (field: string): any => {
        switch (field) {
            case "pid":
                return (
                    <span title={currentProduct ? (currentProduct as any).name : null}>
                        {product.productId || "NA"}
                    </span>
                );

            case "opst":
                const sub =
                    product.subscriptions && product.subscriptions.length
                        ? product.subscriptions.map(el => el.name).join(", ")
                        : null;

                return <span title={sub || "NA"}>{sub?.substring(0, 3) || "NA"}</span>;

            default:
                return "NA";
        }
    };

    return (
        <div
            className={cx(styles.product, {
                [headerStyles.borderBottom]: isDraft
            })}
            onMouseEnter={() => {
                if (isDraft) setProductHover(0.5);
            }}
            onMouseLeave={() => {
                if (isDraft) setProductHover(0);
            }}
        >
            <div
                className={productsStyles.row}
                style={{ gridTemplateColumns: isDraft ? "1fr 60px" : "1fr" }}
            >
                <div className={productsStyles.data}>
                    <DataPiece
                        direction="column"
                        label="PID"
                        info={"Odoo Product ID"}
                        onClick={setActiveField.bind(null, "SEARCH_PRODUCT")}
                        pointer={isDraft}
                        value={getValueForField("pid")}
                    />
                    <DataPiece
                        direction="column"
                        label="OPST"
                        info="Odoo product subscription type"
                        pointer={isDraft}
                        value={getValueForField("opst")}
                        onClick={setActiveField.bind(null, "OPST")}
                    />
                    {!isDraft ? (
                        <DataPiece
                            direction="column"
                            label=""
                            info="Edit Flavour Product content"
                            pointer={true}
                            value={
                                <span className={styles.edit}>
                                    <img src={EditIcon} alt={"edit content"} />
                                </span>
                            }
                            onClick={() => {
                                dispatch(
                                    redirectToPath(
                                        `/dashboard/cms/${setId}/${languageId}/${versionId}/pricing/flavourproducts/${product?.elementId}`
                                    )
                                );
                            }}
                        />
                    ) : null}
                </div>
            </div>
            <div ref={editorRef} className={styles.absoluteSearchList}>
                {editor}
            </div>
        </div>
    );
};

export default React.memo(Product, (prevProps: Props, nextProps: Props) => {
    const areEqual = isEqual(prevProps, nextProps);
    return areEqual;
});
