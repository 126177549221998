import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";
import { appStateType } from "reducers";
import { set } from "types/set";

export function addSet() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_SET_START
        });

        api()
            .post("set")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_SET,
                    payload: { set: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_SET_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function archieveSet(id: string, isActive: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_SET_START,
            payload: { id }
        });

        api()
            .put("set/status", {
                id,
                isActive
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.DELETE_SET,
                    payload: { set: res.data.set }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.DELETE_SET_ERROR,
                    payload: { error: err.response, id }
                })
            );
    };
}

export function updateName(id: string) {
    return (dispatch: Dispatch<any>, getState: () => appStateType) => {
        const state = getState();
        const name = (state.sets.sets.find((set: set) => set._id === id) as set).name;
        dispatch(updateSet(id, { name }));
    };
}

export function duplicateSet(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DUPLICATE_SET_START,
            payload: { id }
        });

        api()
            .post("set/duplicate", { id })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DUPLICATE_SET,
                    payload: { set: res.data.set }
                });
                res.data.languages.forEach((language: object) => {
                    dispatch({ type: actionTypes.ADD_LANGUAGE, payload: { language } });
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.DUPLICATE_SET_ERROR,
                    payload: { error: err.response, id }
                })
            );
    };
}

export function updateSet(id: string, fields: any) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_SET_START,
            payload: { id }
        });

        api()
            .put("set", { id, fields })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.EDIT_SET,
                    payload: { set: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.EDIT_SET_ERROR,
                    payload: { error: err.response, id }
                })
            );
    };
}

export function updateSetField(id: string, name: string) {
    return {
        type: actionTypes.UPDATE_SET_FIELD,
        payload: { id, name }
    };
}
