import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";
import { appStateType } from "reducers";
import browserHistory from "browserHistory";

type Deployment = {
    basename: string;
    campaignKeys: string;
    server: string;
    set: string;
    template: string;
    version: string;
    settings: {
        campaignKeys: {};
        otherSettings: {};
        pixels: {};
    };
};

export function saveDeployment({
    basename,
    campaignKeys,
    server,
    set,
    settings,
    template,
    version
}: Deployment) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SAVE_DEPLOYMENT_START
        });
        api()
            .post("deployment", {
                basename,
                campaignKeys,
                server,
                set,
                settings,
                template,
                version
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.SAVE_DEPLOYMENT,
                    payload: { deployment: res.data.deployment }
                });
                browserHistory.push("/dashboard/deployment/live");
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.SAVE_DEPLOYMENT_ERROR,
                    payload: {
                        error: err.response ? err.response.data || "" : err.response
                    }
                });
            });
    };
}

export function getDeployment(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_DEPLOYMENT_START
        });
        api()
            .get("deployment/single", {
                params: {
                    id
                }
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_DEPLOYMENT,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                console.log("GET DEPLOYMENT ERROR", err);
                dispatch({
                    type: actionTypes.GET_DEPLOYMENT_ERROR
                });
            });
    };
}

export function editDeployment(details: any) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const state = getState();
        const {
            deployment: { editedDeployment }
        } = state;

        const newEditedDeployment = { ...editedDeployment, details };

        dispatch({
            type: actionTypes.GET_DEPLOYMENT,
            payload: newEditedDeployment
        });
    };
}
