import * as React from "react";
import Header from "./components/Header";
import FlavourProducts from "./components/FlavourProducts";
import styles from "./styles.scss";

const FlavourBox: React.FC<{}> = ({}) => {
    return (
        <div className={styles.modelBox}>
            <Header />
            <FlavourProducts />
        </div>
    );
};

export default FlavourBox;
