import * as React from "react";
import styles from "./styles.scss";
import { withRouter } from "react-router-dom";

class GridItem extends React.PureComponent<any, any> {
    handleOpen(e: React.SyntheticEvent, id: string) {
        e.stopPropagation();
        this.props.history.push(`/dashboard/deployment/servers/${id}`);
    }

    handleEdit(e: React.SyntheticEvent, id: string) {
        e.stopPropagation();
        this.props.history.push(`/dashboard/deployment/servers/${id}/edit`);
    }

    render() {
        const { domain, id } = this.props;

        return (
            <div
                className={styles.server}
                onClick={e => this.handleOpen(e, id)}
            >
                <div>{domain.name}</div>
                <div>
                    <button onClick={e => this.handleEdit(e, id)}>
                        <img src="/img/svg/edit.svg" alt="edit" />
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(GridItem);
