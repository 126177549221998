import * as types from "actionTypes";
import { token } from "types/token";

export type tokensStateType = {
    tokens: token[];
    form: {
        [key: string]: string;
    };
    accounts: string[];
    addTokenLoading: boolean;
    getTokensLoading: boolean;
    deleteTokenLoading: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: tokensStateType = {
    tokens: [],
    accounts: [],
    form: {
        name: "",
        tokenkey: "",
        accounts: ""
    },
    addTokenLoading: false,
    getTokensLoading: false,
    deleteTokenLoading: false
};

export const tokensStorage: { [key: string]: boolean } = {
    form: true
};

export default (state: tokensStateType = initialState, action: IAction): tokensStateType => {
    switch (action.type) {
        case types.UPDATE_FIELD:
            const { name, value } = action.payload;
            return {
                ...state,
                form: {
                    ...state.form,
                    [name]: value
                }
            };
        case types.ADD_TOKEN_START:
            return {
                ...state,
                addTokenLoading: true
            };
        case types.ADD_TOKEN:
            return {
                ...state,
                form: initialState.form,
                tokens: [...state.tokens, action.payload.token],
                addTokenLoading: false
            };
        case types.ADD_TOKEN_ERROR:
            return {
                ...state,
                form: initialState.form,
                addTokenLoading: false
            };
        case types.GET_TOKENS_START:
            return {
                ...state,
                tokens: [],
                getTokensLoading: true
            };
        case types.GET_TOKENS:
            const { tokens } = action.payload;
            const accountsArray = tokens
                .map((token: { accounts: {} }) => token.accounts)
                .reduce((prev: [], next: []) => [...prev, ...next], []);
            const accountsSet = new Set<string>(accountsArray);
            return {
                ...state,
                tokens: [...state.tokens, ...action.payload.tokens],
                accounts: Array.from(accountsSet),
                getTokensLoading: false
            };
        case types.GET_TOKENS_ERROR:
            return {
                ...state,
                getTokensLoading: false
            };
        case types.DELETE_TOKEN_START:
            return {
                ...state,
                deleteTokenLoading: true
            };
        case types.DELETE_TOKEN:
            return {
                ...state,
                tokens: state.tokens.filter((token: any) => token._id != action.payload.id),
                deleteTokenLoading: false
            };
        case types.DELETE_TOKEN_ERROR:
            return {
                ...state,
                deleteTokenLoading: false
            };
        default:
            return state;
    }
};
