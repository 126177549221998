import * as React from "react";
import styles from "./styles.scss";
import { set } from "types/set";
import Button from "components/Button";
import { redirectToPath } from "../PricingEditor/actions";
import { useDispatch } from "react-redux";

type Props = {
    elements: any[];
    languageId: string;
    nodeRender: (index: string) => void;
    schemaKey: string;
    setId: string;
    versionId: string;
    crm: set["crm"];
    elementId: string;
};

const BundleContentEditor: React.FC<Props> = ({
    elements,
    languageId,
    nodeRender,
    schemaKey,
    setId,
    versionId,
    crm,
    elementId
}) => {
    const dispatch = useDispatch();

    const handleClickBack = () => {
        const path = `/dashboard/cms/${setId}/${languageId}/${versionId}/pricing`;
        dispatch(redirectToPath(path));
    };

    const index = elements.findIndex(el => el._elementId === elementId);

    return (
        <div className={styles.main}>
            <>
                <header className={styles.header}>
                    <div>Bundle Content Editor</div>
                    <div>
                        <Button onClick={handleClickBack} flat marginRight>
                            Back To Pricing
                        </Button>
                    </div>
                </header>
                {index > -1 ? (
                    nodeRender(String(index))
                ) : (
                    <div className={styles.alert}>Bundle Content element not found.</div>
                )}
            </>
        </div>
    );
};

export default BundleContentEditor;
