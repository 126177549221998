import * as actionTypes from "actionTypes";
import { PricingContainer, PricingModel, PricingProduct, Currency, Bundle } from "types/pricing";

export type pricingStateType = {
    pricingContainers: PricingContainer[];
    isLoading: boolean;
    loadingPricingError: string;
    confirmingPricingModelError: string;
    addingPricingContainerError: string;
    editingPricingModelError: string;
    updatingMinViewsError: string;
    pricingModelDraft: PricingModel | undefined;
    currencies: Currency[];
    isContainerEditing: boolean;
    containerDraft: PricingContainer | undefined;
    loadingPricingGraphs: boolean;
    loadingPricingGraphsError: string;
    pricingGraphsData: any;
    currentSet: string;
    currentPricingModels: PricingModel[];
    analyticsFromDate: Date | null;
    analyticsToDate: Date | null;
    populatedPricingModels: PricingModel[];
    isSourcesLoading: boolean;
    updateSourceError: string;
    isOldGraphsReport: boolean;
    graphsReportCreatedAt: string;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: pricingStateType = {
    pricingContainers: [],
    isLoading: false,
    loadingPricingError: "",
    confirmingPricingModelError: "",
    addingPricingContainerError: "",
    editingPricingModelError: "",
    pricingModelDraft: undefined,
    currencies: [],
    updatingMinViewsError: "",
    isContainerEditing: false,
    containerDraft: undefined,
    loadingPricingGraphs: false,
    loadingPricingGraphsError: "",
    pricingGraphsData: [],
    currentSet: "",
    currentPricingModels: [],
    analyticsFromDate: null,
    analyticsToDate: null,
    populatedPricingModels: [],
    isSourcesLoading: false,
    updateSourceError: "",
    isOldGraphsReport: false,
    graphsReportCreatedAt: ""
};

export const pricingStorage: { [key: string]: boolean } = {};

export default (state: pricingStateType = initialState, action: IAction): pricingStateType => {
    let pricingContainers: PricingContainer[],
        pricingContainer: PricingContainer,
        variants: PricingModel[];

    switch (action.type) {
        case actionTypes.ADD_PRICING_CONTAINER:
            ({ pricingContainer } = action.payload);

            return {
                ...state,
                isLoading: false,
                containerDraft: pricingContainer,
                isContainerEditing: true
            };
        case actionTypes.EDIT_PRICING_CONTAINER:
            ({ pricingContainer } = action.payload);
            return {
                ...state,
                isLoading: false,
                containerDraft: { ...state.containerDraft, ...pricingContainer },
                isContainerEditing: true
            };
        case actionTypes.ADD_PRICING_FLAVOUR_PRODUCT: {
            const { containerDraft } = state;
            let { id, elementId } = action.payload;
            let flavourProduct = { _id: id, elementId, subscriptions: [], productId: "" };
            const flavourProducts = [...(containerDraft?.flavourProducts || []), flavourProduct];
            return {
                ...state,
                isLoading: false,
                containerDraft: { ...(containerDraft as PricingContainer), flavourProducts }
            };
        }
        case actionTypes.EDIT_PRICING_FLAVOUR_PRODUCT: {
            const { containerDraft } = state;
            let { id, product } = action.payload;
            const flavourProducts = [...(containerDraft?.flavourProducts || [])].map(el =>
                el._id === id ? product : el
            );
            return {
                ...state,
                isLoading: false,
                containerDraft: { ...(containerDraft as PricingContainer), flavourProducts }
            };
        }
        case actionTypes.SAVE_PRICING_CONTAINER_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SAVE_PRICING_CONTAINER:
            return {
                ...state,
                isLoading: false,
                pricingContainers: [...state.pricingContainers, action.payload.pricingContainer],
                isContainerEditing: false,
                containerDraft: undefined
            };
        case actionTypes.UPDATE_PRICING_CONTAINER: {
            const { pricingContainer } = action.payload;
            return {
                ...state,
                isLoading: false,
                pricingContainers: state.pricingContainers.map(el =>
                    el._id === pricingContainer._id ? pricingContainer : el
                ),
                isContainerEditing: false,
                containerDraft: undefined
            };
        }
        case actionTypes.SAVE_PRICING_CONTAINER_ERROR:
        case actionTypes.UPDATE_PRICING_CONTAINER_ERROR:
            return {
                ...state,
                isLoading: false,
                addingPricingContainerError: action.payload.error
            };
        case actionTypes.UPDATE_PRICING_CONTAINER_START:
            return { ...state, isLoading: true };
        case actionTypes.GET_PRICING_START:
            return { ...state, isLoading: true, loadingPricingError: "" };
        case actionTypes.GET_PRICING:
            return {
                ...state,
                isLoading: false,
                pricingContainers: action.payload.pricingContainers
            };
        case actionTypes.GET_PRICING_ERROR:
            return {
                ...state,
                isLoading: false,
                loadingPricingError: action.payload.error
            };
        case actionTypes.CONFIRM_PRICING_MODEL_START:
            return {
                ...state,
                isLoading: true,
                confirmingPricingModelError: ""
            };
        case actionTypes.CONFIRM_PRICING_MODEL:
            pricingContainers = state.pricingContainers.map((pricingContainer: PricingContainer) =>
                pricingContainer._id === action.payload.pricingContainerId
                    ? {
                          ...pricingContainer,
                          variants: [...pricingContainer.variants, action.payload.pricingModel]
                      }
                    : pricingContainer
            );

            return {
                ...state,
                isLoading: false,
                pricingContainers,
                pricingModelDraft: undefined
            };
        case actionTypes.CONFIRM_PRICING_MODEL_ERROR:
            return {
                ...state,
                isLoading: false,
                confirmingPricingModelError: action.payload.error
            };
        case actionTypes.EDIT_PRICING_MODEL_START:
            return {
                ...state,
                editingPricingModelError: ""
            };
        case actionTypes.EDIT_PRICING_MODEL:
            pricingContainer = state.pricingContainers.find(
                (pricingContainer: PricingContainer) =>
                    pricingContainer._id === action.payload.pricingContainerId
            ) as any;

            if (pricingContainer) {
                variants = pricingContainer.variants.map((variant: PricingModel) =>
                    variant._id === action.payload.pricingModel._id
                        ? action.payload.pricingModel
                        : variant
                );

                pricingContainer.variants = variants;

                pricingContainers = state.pricingContainers.map(
                    (oldPricingContainer: PricingContainer) =>
                        oldPricingContainer._id === action.payload.pricingContainerId
                            ? pricingContainer
                            : oldPricingContainer
                );

                return {
                    ...state,
                    pricingContainers
                };
            }

            return {
                ...state
            };

        case actionTypes.EDIT_PRICING_MODEL_ERROR:
            return {
                ...state,
                editingPricingModelError: action.payload.error
            };
        case actionTypes.ADD_PRICING_MODEL_DRAFT:
            return {
                ...state,
                pricingModelDraft: {
                    title: "",
                    isActive: false,
                    products: [],
                    bundles: [],
                    ...action.payload
                }
            };
        case actionTypes.EDIT_PRICING_MODEL_DRAFT:
            return {
                ...state,
                pricingModelDraft: {
                    ...state.pricingModelDraft,
                    ...action.payload
                }
            };
        case actionTypes.COPY_PRICING_MODEL:
            return {
                ...state,
                pricingModelDraft: {
                    ...action.payload
                }
            };
        case actionTypes.ADD_PRICING_MODEL_PRODUCT_DRAFT:
            if (state.pricingModelDraft) {
                return {
                    ...state,
                    pricingModelDraft: {
                        ...state.pricingModelDraft,
                        products: [
                            ...state.pricingModelDraft.products,
                            {
                                _id: action.payload.id,
                                productId: "",
                                giftId: "",
                                isBestseller: false,
                                amazonLink: "",
                                strikethroughPrice: "",
                                isSelected: false,
                                price: "0",
                                image: {},
                                title: "",
                                elementId: action.payload.elementId,
                                shippingPrice: "0",
                                subscriptions: [],
                                konnektiveSubscriptions: [],
                                subsReplacePrice: "",
                                subsReplaceProduct: "",
                                units: 1,
                                cog: 0,
                                cos: 0,
                                takeRate: 0,
                                unitsSold: 0,
                                upgradeTakeRate: 0
                            }
                        ]
                    }
                };
            }
            return state;
        case actionTypes.REORDER_PRICING_MODEL_PRODUCT_DRAFT:
            if (state.pricingModelDraft) {
                const { sourceIndex, destinationIndex, draggableId } = action.payload;
                const newProducts = Array.from(state.pricingModelDraft.products);
                const product = newProducts.find(
                    (pricingProduct: PricingProduct) => pricingProduct._id === draggableId
                );

                if (product) {
                    newProducts.splice(sourceIndex, 1);
                    newProducts.splice(destinationIndex, 0, product);
                }

                return {
                    ...state,
                    pricingModelDraft: {
                        ...state.pricingModelDraft,
                        products: newProducts
                    }
                };
            }
            return state;
        case actionTypes.EDIT_PRICING_MODEL_PRODUCT_DRAFT:
            if (state.pricingModelDraft) {
                return {
                    ...state,
                    pricingModelDraft: {
                        ...state.pricingModelDraft,
                        products: state.pricingModelDraft.products.map((product: PricingProduct) =>
                            product._id == action.payload.id ? action.payload.product : product
                        )
                    }
                };
            }
            return state;

        case actionTypes.ADD_PRICING_BUNDLE_DRAFT:
            if (state.pricingModelDraft) {
                return {
                    ...state,
                    pricingModelDraft: {
                        ...state.pricingModelDraft,
                        bundles: [
                            ...state.pricingModelDraft.bundles,
                            {
                                _id: action.payload.id,
                                id: "",
                                unitPrice: 0,
                                shippingPrice: 0,
                                size: 0,
                                elementId: action.payload.elementId,
                                productId: "",
                                giftId: "",
                                price: 0,
                                cog: 0,
                                cos: 0,
                                takeRate: 0,
                                unitsSold: 0
                            }
                        ]
                    }
                };
            }
            return state;
        case actionTypes.REORDER_PRICING_BUNDLE_DRAFT:
            if (state.pricingModelDraft) {
                const { sourceIndex, destinationIndex, draggableId } = action.payload;
                const newBundles = Array.from(state.pricingModelDraft.bundles);
                const bundle = newBundles.find((bundle: Bundle) => bundle._id === draggableId);

                if (bundle) {
                    newBundles.splice(sourceIndex, 1);
                    newBundles.splice(destinationIndex, 0, bundle);
                }

                return {
                    ...state,
                    pricingModelDraft: {
                        ...state.pricingModelDraft,
                        bundles: newBundles
                    }
                };
            }
            return state;
        case actionTypes.EDIT_PRICING_BUNDLE_DRAFT:
            if (state.pricingModelDraft) {
                return {
                    ...state,
                    pricingModelDraft: {
                        ...state.pricingModelDraft,
                        bundles: state.pricingModelDraft.bundles.map((bundle: Bundle) =>
                            bundle._id == action.payload.id ? action.payload.bundle : bundle
                        )
                    }
                };
            }
            return state;

        case actionTypes.GET_CURRIENCIES_START:
            return {
                ...state
            };
        case actionTypes.GET_CURRIENCIES:
            const { currencies } = action.payload;
            return {
                ...state,
                currencies
            };
        case actionTypes.GET_CURRIENCIES_ERROR:
            return {
                ...state
            };
        case actionTypes.UPDATE_MIN_VIEWS_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.UPDATE_MIN_VIEWS:
            const { pricingContainerId, minViews: singleTrustAmount } = action.payload;

            return {
                ...state,
                isLoading: false,
                pricingContainers: state.pricingContainers.map(el =>
                    el._id === pricingContainerId
                        ? { ...el, midasState: { ...el.midasState, singleTrustAmount } }
                        : el
                )
            };
        case actionTypes.UPDATE_MIN_VIEWS_ERROR:
            let { error } = action.payload;
            return {
                ...state,
                isLoading: false,
                updatingMinViewsError: error
            };
        case actionTypes.UPDATE_CONTAINER_SOURCES_START:
            return {
                ...state,
                isSourcesLoading: true
            };
        case actionTypes.UPDATE_CONTAINER_SOURCES: {
            const { pricingContainerId, trafficSources } = action.payload;

            return {
                ...state,
                isSourcesLoading: false,
                pricingContainers: state.pricingContainers.map(el =>
                    el._id === pricingContainerId ? { ...el, trafficSources } : el
                )
            };
        }
        case actionTypes.UPDATE_CONTAINER_SOURCES_ERROR: {
            let { error } = action.payload;
            return {
                ...state,
                isSourcesLoading: false,
                updateSourceError: error
            };
        }
        case actionTypes.GET_PRICING_GRAPHS_START:
            return {
                ...state,
                loadingPricingGraphs: true
            };
        case actionTypes.GET_PRICING_GRAPHS: {
            const {
                createdAt,
                isOldReport = false,
                report: { graphs, pricingModels, setId }
            } = action.payload;

            if (isOldReport && state.pricingGraphsData.length > 0) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                loadingPricingGraphs: false,
                pricingGraphsData: graphs,
                currentPricingModels: pricingModels,
                currentSet: setId,
                isOldGraphsReport: isOldReport,
                graphsReportCreatedAt: createdAt
            };
        }
        case actionTypes.GET_PRICING_GRAPHS_START:
            return {
                ...state,
                loadingPricingGraphs: true,
                pricingGraphsData: null
            };
        case actionTypes.SET_PRICING_ANALYTICS_FROM_DATE:
            return {
                ...state,
                analyticsFromDate: action.payload.analyticsFromDate
            };
        case actionTypes.SET_PRICING_ANALYTICS_TO_DATE:
            return {
                ...state,
                analyticsToDate: action.payload.analyticsToDate
            };
        case actionTypes.GET_PRICING_GRAPHS_ERROR: {
            const { error } = action.payload;

            return {
                ...state,
                loadingPricingGraphs: false,
                loadingPricingGraphsError: error
            };
        }
        case actionTypes.SET_POPULATED_PRICING_MODELS:
            const { populatedPricingModels } = action.payload;

            return {
                ...state,
                populatedPricingModels
            };
        default:
            return state;
    }
};
