import * as React from "react";
import styles from "./styles.scss";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";

type HandleClickDeleteSetType = (
    setId: string
) => (e: React.SyntheticEvent<HTMLDivElement>) => void;

type Props = {
    setsList: string[];
    handleClickDeleteSet?: HandleClickDeleteSetType;
    marginTop?: number;
};

const SetsList = ({ setsList, handleClickDeleteSet, marginTop = 0 }: Props) => {
    const { sets } = useSelector((state: appStateType) => state.sets);

    return (
        <>
            {setsList.map(set => (
                <div key={set} className={styles.set} style={{ marginTop }}>
                    <div className={styles.text}>
                        {sets.find(setObject => setObject._id === set)?.name || "NOT FOUND"}
                    </div>
                    {handleClickDeleteSet && (
                        <div className={styles.delete} onClick={handleClickDeleteSet(set)}>
                            X
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default SetsList;
