import background from "assets/backgrounds/square_bg.png";
import * as moment from "moment-timezone";
import * as React from "react";
import styles from "./styles.scss";
import Timer from "../Timer";

class Card extends React.Component<any, any> {
    timeout: number;

    state = {
        isAfter: true
    };

    componentDidMount() {
        this.cardSetup();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.validTo !== prevProps.validTo) {
            this.cardSetup();
        }
    }

    cardSetup() {
        const TIME = this.getTimeDiff();

        this.setState({ isAfter: this.isAfter() });

        if (TIME > 0) {
            this.timeout = window.setTimeout(() => {
                this.setState({ isAfter: this.isAfter() });
            }, TIME);
        }
    }

    isAfter() {
        const { validTo } = this.props;

        if (validTo) {
            const date = moment(validTo, "dddd, MMMM Do YYYY, h:mm:ss a");
            const isAfter = moment().isAfter(date);
            return isAfter;
        }
        return true;
    }

    getTimeDiff() {
        const { validTo } = this.props;

        if (validTo) {
            const date = moment(validTo, "dddd, MMMM Do YYYY, h:mm:ss a");
            const duration = moment.duration(date.diff(moment()));
            const ms = duration.asMilliseconds();
            return ms;
        }
        return 0;
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout);
    }

    render() {
        const { ip, validTo, createOrRenew } = this.props;
        const { isAfter } = this.state;

        return !isAfter ? (
            <div
                className={styles.card}
                style={{ backgroundImage: `url(${background})` }}
            >
                <p>
                    <strong>Wildcard {ip}</strong>
                </p>

                <Timer validTo={validTo} />
            </div>
        ) : (
            <button
                onClick={createOrRenew.bind(this)}
                className={styles.button}
            >
                Create wildcard
            </button>
        );
    }
}

export default Card;
