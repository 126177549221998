import * as actionTypes from "actionTypes";
import { productGroup } from "types/productGroup";
import { tagItems } from "services/tagItems";

export type productGroupStateType = {
    addProductGroupLoading: boolean;
    productGroups: productGroup[];
    isLoading: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: productGroupStateType = {
    addProductGroupLoading: false,
    productGroups: [],
    isLoading: false
};

export const productGroupStorage: { [key: string]: boolean } = {
    addProductGroupLoading: false,
    productGroups: true,
    isLoading: false
};

export default (
    state: productGroupStateType = initialState,
    action: IAction
): productGroupStateType => {
    switch (action.type) {
        case actionTypes.ADD_PRODUCT_GROUP_START:
        case actionTypes.UPDATE_PRODUCT_GROUP_START:
            return {
                ...state,
                addProductGroupLoading: true
            };
        case actionTypes.ADD_PRODUCT_GROUP: {
            const { productGroup } = action.payload;

            return {
                ...state,
                productGroups: [...state.productGroups, productGroup],
                addProductGroupLoading: false
            };
        }
        case actionTypes.UPDATE_PRODUCT_GROUP: {
            const { productGroup } = action.payload;

            return {
                ...state,
                productGroups: state.productGroups.map(el =>
                    el._id === productGroup._id ? productGroup : el
                ),
                addProductGroupLoading: false
            };
        }
        case actionTypes.ADD_PRODUCT_GROUP_ERROR:
        case actionTypes.UPDATE_PRODUCT_GROUP_ERROR:
            return {
                ...state,
                addProductGroupLoading: false
            };
        case actionTypes.GET_PRODUCT_GROUPS_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_PRODUCT_GROUPS:
            return {
                ...state,
                productGroups: action.payload.productGroups,
                isLoading: false
            };
        case actionTypes.GET_PRODUCT_GROUPS_ERROR:
            return {
                ...state,
                productGroups: [],
                isLoading: false
            };
        case actionTypes.REMOVE_PRODUCT_GROUP_START:
            return {
                ...state,
                productGroups: tagItems(state.productGroups, action.payload.id, {
                    error: "",
                    isRemoving: true
                })
            };
        case actionTypes.REMOVE_PRODUCT_GROUP:
            return {
                ...state,
                productGroups: state.productGroups.filter(
                    productGroup => productGroup._id !== action.payload.productGroup._id
                )
            };
        case actionTypes.REMOVE_PRODUCT_GROUP_ERROR:
            const { error, id } = action.payload;

            return {
                ...state,
                productGroups: tagItems(state.productGroups, id, {
                    error: error.data.error,
                    isRemoving: false
                })
            };
        default:
            return state;
    }
};
