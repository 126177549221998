import React from "react";
import styles from "./styles.scss";
import cx from "classnames";
import SVG from "react-inlinesvg";

type Props = {
    tabs: {
        id: string;
        text: string;
        icon: any;
    }[];
    activeOptionId: string;
    onClick: (newActiveTabId: string) => (e: React.SyntheticEvent<HTMLDivElement>) => void;
};

const TabsMenu: React.FC<Props> = ({ tabs, activeOptionId, onClick }) => {
    return (
        <div className={styles.tabs}>
            {tabs.map(option => (
                <div
                    key={option.id}
                    className={cx(styles.tab, { [styles.active]: activeOptionId === option.id })}
                    onClick={onClick(option.id)}
                >
                    <div className={styles.icon}>
                        <SVG src={option.icon} />
                    </div>
                    <div className={styles.text}>{option.text}</div>
                </div>
            ))}
            <div className={styles.highlightBar} />
        </div>
    );
};

export default TabsMenu;
