import * as React from "react";
const { useRef, useLayoutEffect } = React;
import SVG from "react-inlinesvg";
import styles from "./styles.scss";
import refreshIcon from "assets/icons/pagePreview/sync-alt.svg";
import mobileIcon from "assets/icons/pagePreview/mobile-alt.svg";
import desktopIcon from "assets/icons/pagePreview/desktop.svg";
import fullscreenIcon from "assets/icons/pagePreview/expand-wide.svg";
import ContentEditable from "react-contenteditable";
import useForceRerender from "services/useForceRerender";

type Props = {
    urlBase: string;
    initialUrl: string;
    browserBarRef: React.RefObject<HTMLDivElement>;
    refreshPreview: () => void;
    setPreviewMode: (key: string) => void;
    updatePreviewUrl: (previewUrl: string) => void;
};

const BrowserBar: React.FC<Props> = ({
    urlBase,
    initialUrl,
    browserBarRef,
    refreshPreview,
    setPreviewMode,
    updatePreviewUrl
}) => {
    const forceRerender = useForceRerender();
    const inputEl = useRef<HTMLDivElement>(null);
    const urlBaseEl = useRef<HTMLDivElement>(null);
    const url = useRef<string>(initialUrl);

    const setUrl = (newUrl: string) => {
        url.current = newUrl;
        forceRerender();
    };

    const handleUrlFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value }
        } = e;

        setUrl(value.replace(/[^?&=A-Za-z0-9]/gi, ""));
    };

    const handleSubmit = (e: any = null) => {
        if (e) {
            e.preventDefault();
        }

        updatePreviewUrl(url.current);
    };

    const handleBarClick = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.focus();
        }
    };

    const handleBlur = () => {
        const tempUrl = url.current;
        setUrl("");
        setTimeout(() => {
            setUrl(tempUrl);
        }, 0);
    };

    useLayoutEffect(() => {
        if (urlBaseEl && urlBaseEl.current && inputEl && inputEl.current) {
            const urlBase = urlBaseEl.current;
            const input = inputEl.current;
            const urlBaseWitdh = urlBase.getBoundingClientRect().width;
            input.style.left = urlBaseWitdh + "px";
        }
    }, []);

    const handleKeyDown = (e: any) => {
        const { which } = e;

        if (which === 13) {
            e.preventDefault();
        }
    };

    const handleKeyUp = (e: any) => {
        const { which } = e;

        if (which === 13) {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className={styles.browserBar} ref={browserBarRef}>
            <div title="Refresh preview page." className={styles.icon} onClick={refreshPreview}>
                <SVG src={refreshIcon} />
            </div>
            <div className={styles.line} />
            <div
                title="Preview page for mobile."
                className={styles.icon}
                onClick={() => setPreviewMode("MOBILE")}
            >
                <SVG src={mobileIcon} />
            </div>
            <div
                title="Preview page for desktop."
                className={styles.icon}
                onClick={() => setPreviewMode("DESKTOP")}
            >
                <SVG src={desktopIcon} />
            </div>
            <div
                title="Preview page in fullscreen mode."
                className={styles.icon}
                onClick={() => setPreviewMode("FULLSCREEN")}
            >
                <SVG src={fullscreenIcon} />
            </div>
            <div className={styles.line} />
            <div className={styles.inputContainer} onClick={() => handleBarClick()}>
                <div className={styles.innerContent}>
                    <div ref={urlBaseEl} className={styles.urlBase}>
                        {urlBase}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ContentEditable
                            html={url.current}
                            innerRef={inputEl}
                            className={styles.inputUrl}
                            onChange={handleUrlFieldChange}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            onKeyUp={handleKeyUp}
                        />
                    </form>
                </div>{" "}
            </div>
        </div>
    );
};

export default BrowserBar;
