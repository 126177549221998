import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { appStateType } from "reducers";

type Props = {
    component: React.ComponentType;
    auth: {
        isAuthenticated: boolean;
    };
    [key: string]: any;
};

const PrivateRoute = ({ component: Component, auth, ...rest }: Props) => (
    <Route
        {...rest}
        render={(props: object) =>
            auth.isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to="/signin" />
            )
        }
    />
);

const mapStateToProps = (state: appStateType) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
