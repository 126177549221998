import * as React from "react";
import * as actions from "./actions";
import Languages from "./Languages";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { set } from "types/set";

class SchemaSetPage extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
    }

    componentDidMount() {
        this.props.setPullBlock();
    }

    render() {
        const { sets } = this.props.sets;
        const setId = this.props.match.params.setId;
        const set = sets.find((set: set) => set._id === setId);

        if (set === undefined) {
            return null;
        }

        const { duplication: { started = false, completed = false, error = "" } = {} } = set;

        if (error != "") {
            return (
                <div>
                    <p>Failure while duplicating the Set.Please contact with a developer.</p>
                </div>
            );
        }

        return (
            <>
                <header>
                    <h1>Run Schema Content Editor:</h1>
                </header>
                <main>
                    <h4 className={styles.headline}>
                        <span className={styles.gray}>CMS Content Set: </span>
                        {set.name}
                    </h4>
                    {started && !completed ? (
                        <div className={styles.panel_wide}>
                            <p>your copy of the Set.It will take some time. 🤪</p>
                        </div>
                    ) : (
                        <>{set && <Languages set={set} icons={this.props.icons} />}</>
                    )}
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    sets: state.sets,
    elements: state.elements
});

export default connect(mapStateToProps, actions)(SchemaSetPage);
