import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import { Dispatch } from "redux";
import { signInUser, apiSignedInUser } from "types/user";
import { SET_CURRENT_USER, SET_AUTH_ERRORS, START_AUTH } from "actionTypes";

export function setCurrentUser(user: apiSignedInUser, token: string) {
    return {
        type: SET_CURRENT_USER,
        payload: {
            token,
            user
        }
    };
}

export function setAuthErrors(errors: object) {
    return {
        type: SET_AUTH_ERRORS,
        payload: errors
    };
}

export function startAuth() {
    return {
        type: START_AUTH
    };
}

export function loginUser(user: signInUser) {
    return (dispatch: Dispatch) => {
        dispatch(startAuth());

        setTimeout(() => {
            api()
                .post("user/login", user)
                .then((res: AxiosResponse) => {
                    const token = res.data.token;
                    const decoded = jwtDecode(token);
                    dispatch(setCurrentUser(decoded, token));
                })
                .catch((err: AxiosError) => {
                    if (err.response) {
                        dispatch(setAuthErrors(err.response.data || {}));
                    }
                });
        }, 1000);
    };
}
