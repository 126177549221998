import * as actionTypes from "actionTypes";
import { Version } from "types/version";
import { tagItems } from "services/tagItems";

export type liveDeployment = {
    status: {
        estimatedTime?: string;
        icon?: string;
        link?: boolean;
        loader?: boolean;
        message?: string;
    };
    buildNumber: number;
    testsStarted: boolean;
    testsFinished: boolean;
    testsResult: {
        buildNumber: string;
        buildLog: string;
        status: "failed" | "done" | "running";
    };
    estimatedTime?: string;
};

interface IAction {
    type: string;
    payload: any;
}

export type DeploymentFilters = {
    basename?: string;
    template?: string;
    version?: string;
    set?: string;
    crm?: string;
    brand?: string;
    server?: string;
};

export type deploymentStateType = {
    deployments: object[];
    isPullingDeployments: boolean;
    isPullingServers: boolean;
    isPullingTemplates: boolean;
    isPullingVersions: boolean;
    isSavingDeployment: boolean;
    isSavingServer: boolean;
    isSavingTemplate: boolean;
    liveDeployments: liveDeployment[];
    pullingDeploymentsError: string;
    pullingServersError: string;
    pullingTemplatesError: string;
    pullingVersionsError: string;
    savingDeploymentError: string;
    savingServerError: string;
    servers: object[];
    templates: object[];
    versions: Version[];
    isDrained?: boolean;
    filters: DeploymentFilters;
    version?: Version | null;
    isGettingHotDeployments: boolean;
    hotDeployments: any;
    editedDeployment: any;
    isGettingDeployment: boolean;
};

const defaultDeploymentFilters = {
    basename: "",
    template: "",
    version: "",
    set: "",
    crm: "",
    server: "",
    brand: ""
};

const initialState: deploymentStateType = {
    deployments: [],
    isPullingDeployments: false,
    isPullingServers: false,
    isPullingTemplates: false,
    isPullingVersions: false,
    isSavingDeployment: false,
    isSavingServer: false,
    isSavingTemplate: false,
    liveDeployments: [],
    pullingDeploymentsError: "",
    pullingServersError: "",
    pullingTemplatesError: "",
    pullingVersionsError: "",
    savingDeploymentError: "",
    savingServerError: "",
    servers: [],
    templates: [],
    versions: [],
    isDrained: false,
    filters: defaultDeploymentFilters,
    version: null,
    isGettingHotDeployments: false,
    hotDeployments: null,
    editedDeployment: null,
    isGettingDeployment: false
};

export const deploymentStorage: { [key: string]: boolean } = {
    deployments: false,
    servers: true,
    templates: true,
    versions: true
};

export default (
    state: deploymentStateType = initialState,
    action: IAction
): deploymentStateType => {
    switch (action.type) {
        case actionTypes.PULL_VERSIONS_START:
            return {
                ...state,
                isPullingVersions: true
            };
        case actionTypes.PULL_VERSIONS:
            const { versions } = action.payload;

            return {
                ...state,
                isPullingVersions: false,
                versions
            };
        case actionTypes.PULL_VERSIONS_ERROR:
            return {
                ...state,
                isPullingVersions: false,
                pullingVersionsError: action.payload.error
            };
        case actionTypes.SAVE_VERSION_START:
            return {
                ...state,
                versions: tagItems(state.versions, action.payload.id, {
                    isSaving: true
                })
            };
        case actionTypes.SAVE_VERSION:
            return {
                ...state,
                versions: tagItems(state.versions, action.payload.version._id, {
                    isSaving: false
                })
            };
        case actionTypes.SAVE_VERSION_ERROR:
            return {
                ...state,
                versions: tagItems(state.versions, action.payload.id, {
                    isSaving: false
                })
            };
        case actionTypes.REMOVE_VERSION_START:
            return {
                ...state,
                versions: tagItems(state.versions, action.payload.id, {
                    error: "",
                    isRemoving: true
                })
            };
        case actionTypes.REMOVE_VERSION:
            return {
                ...state,
                versions: state.versions.filter(
                    version => version._id !== action.payload.version._id
                )
            };
        case actionTypes.REMOVE_VERSION_ERROR:
            const { error, id } = action.payload;

            return {
                ...state,
                versions: tagItems(state.versions, id, {
                    error: error.data.error,
                    isRemoving: false
                })
            };
        case actionTypes.PULL_SERVERS_START:
            return {
                ...state,
                isPullingServers: true
            };
        case actionTypes.PULL_SERVERS:
            return {
                ...state,
                isPullingServers: false,
                servers: action.payload.servers
            };
        case actionTypes.PULL_SERVERS_ERROR:
            return {
                ...state,
                isPullingServers: false,
                pullingServersError: action.payload.error
            };
        case actionTypes.ADD_SERVER_START:
            return {
                ...state,
                isSavingServer: true,
                savingServerError: ""
            };
        case actionTypes.ADD_SERVER:
            return {
                ...state,
                isSavingServer: false
            };
        case actionTypes.ADD_SERVER_ERROR:
            return {
                ...state,
                isSavingServer: false,
                savingServerError: action.payload.error.data.message
            };
        case actionTypes.PULL_DEPLOYMENTS_START:
            return {
                ...state,
                isPullingDeployments: true
            };
        case actionTypes.PULL_DEPLOYMENTS:
            return {
                ...state,
                isPullingDeployments: false,
                isDrained: action.payload.isDrained,
                deployments: [...state.deployments, ...action.payload.deployments]
            };
        case actionTypes.PULL_DEPLOYMENTS_ERROR:
            return {
                ...state,
                isPullingDeployments: false,
                pullingTemplatesError: action.payload.error
            };
        case actionTypes.RESET_DEPLOYMENTS:
            return {
                ...state,
                isPullingDeployments: true,
                isDrained: false,
                deployments: []
            };
        case actionTypes.SAVE_DEPLOYMENT_START:
            return {
                ...state,
                isSavingDeployment: true,
                savingDeploymentError: ""
            };
        case actionTypes.SAVE_DEPLOYMENT:
            let newDeployment = action.payload.deployment as any;
            let newDeployments = state.deployments.filter((dl: any) => {
                if (dl.server !== newDeployment.server) {
                    return true;
                } else if (
                    dl.server === newDeployment.server &&
                    dl.basename !== newDeployment.basename
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            return {
                ...state,
                deployments: [newDeployment, ...newDeployments],
                isSavingDeployment: false
            };
        case actionTypes.SAVE_DEPLOYMENT_ERROR:
            return {
                ...state,
                isSavingDeployment: false,
                savingDeploymentError: action.payload.error
            };
        case actionTypes.SET_DEPLOYMENT_STATUS:
            let { buildNumber } = action.payload;

            if (!buildNumber) {
                return {
                    ...state
                };
            }

            const oldDeployment =
                state.liveDeployments.length > 0 &&
                state.liveDeployments.find((liveDeployment: liveDeployment) => {
                    if (liveDeployment) {
                        return Number(liveDeployment.buildNumber) === Number(buildNumber);
                    } else {
                        return false;
                    }
                });

            if (oldDeployment) {
                return {
                    ...state,
                    liveDeployments: state.liveDeployments.map((liveDeployment: liveDeployment) => {
                        return Number(liveDeployment.buildNumber) === Number(buildNumber)
                            ? getLiveDeploymentInfo(action.payload)
                            : liveDeployment;
                    })
                };
            } else {
                return {
                    ...state,
                    liveDeployments: [
                        ...state.liveDeployments,
                        getLiveDeploymentInfo(action.payload)
                    ]
                };
            }
        case actionTypes.PULL_TEMPLATES_START:
            return {
                ...state,
                isPullingTemplates: true
            };
        case actionTypes.PULL_TEMPLATES:
            return {
                ...state,
                isPullingTemplates: false,
                templates: action.payload.templates
            };
        case actionTypes.PULL_TEMPLATES_ERROR:
            return {
                ...state,
                isPullingTemplates: false,
                pullingTemplatesError: action.payload.error
            };
        case actionTypes.ADD_TEMPLATE_START:
            return {
                ...state,
                isSavingTemplate: true
            };
        case actionTypes.ADD_TEMPLATE:
            return {
                ...state,
                isSavingTemplate: false
            };
        case actionTypes.ADD_TEMPLATE_ERROR:
            return {
                ...state,
                isSavingTemplate: false
            };
        case actionTypes.REMOVE_DEPLOYMENT: {
            const { basename } = action.payload;
            const newDeployments = state.deployments.filter((el: any) => el.basename !== basename);
            return {
                ...state,
                deployments: newDeployments
            };
        }
        case actionTypes.SET_DEPLOYMENT_FILTERS: {
            const { key, value } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [key]: value
                }
            };
        }
        case actionTypes.RESET_DEPLOYMENT_FILTERS: {
            return {
                ...state,
                filters: defaultDeploymentFilters
            };
        }
        case actionTypes.PULL_CMS_VERSION: {
            const { version = {} } = action.payload;
            return {
                ...state,
                version
            };
        }
        case actionTypes.GET_HOT_DEPLOYMENTS_START:
            return {
                ...state,
                isGettingHotDeployments: true
            };
        case actionTypes.GET_HOT_DEPLOYMENTS:
            return {
                ...state,
                isGettingHotDeployments: false,
                hotDeployments: action.payload
            };
        case actionTypes.GET_HOT_DEPLOYMENTS_ERROR:
            return {
                ...state,
                isGettingHotDeployments: false
            };
        case actionTypes.GET_DEPLOYMENT_START:
            return {
                ...state,
                isGettingDeployment: true,
                editedDeployment: null
            };
        case actionTypes.GET_DEPLOYMENT:
            return {
                ...state,
                editedDeployment: action.payload,
                isGettingDeployment: false
            };
        case actionTypes.GET_DEPLOYMENT_ERROR:
            return {
                ...state,
                isGettingDeployment: false
            };
        default:
            return state;
    }
};

function getLiveDeploymentInfo(liveDeployment: liveDeployment): liveDeployment {
    let { buildNumber, status, estimatedTime, testsStarted, testsFinished, testsResult } =
        liveDeployment;

    return {
        buildNumber: Number(buildNumber),
        status,
        estimatedTime,
        testsStarted,
        testsFinished,
        testsResult
    };
}
