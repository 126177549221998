import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import getDollars from "services/getDollars";
import styles from "./styles.scss";

import maxBy from "lodash/maxBy";
import throttle from "lodash/throttle";

interface graphProps {
    chartsData: any[];
    labels: {
        [id: string]: string;
    };
    tab: string;
}

const Graph: React.FC<graphProps> = ({ chartsData, labels, tab = "" }) => {
    const chartsDataRef = useRef(chartsData);
    chartsDataRef.current = chartsData;
    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef(null);

    const draw = (svg: d3.Selection<null, unknown, null, undefined>, data: any) => {
        const containerWidth = containerRef?.current?.offsetWidth || 0;
        const height = window.innerHeight * 0.7;
        const width = containerWidth - 90;
        const margin = { top: 20, right: 30, bottom: 70, left: 100 };

        svg.attr("width", width)
            .attr("height", height)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", `0 0 ${width} ${height}`);

        let totalMinRevenuePerView = Number.POSITIVE_INFINITY;

        const lineData = Object.entries(data)
            .map(([key, value]) => {
                let viewsAcc = 0,
                    revenueAcc = 0;

                return {
                    modelId: key,
                    data: (value as any).map((entry: any) => {
                        viewsAcc += Number(entry.views);
                        revenueAcc += Number(entry.gp);

                        const revenuePerView = revenueAcc / viewsAcc;

                        if (revenuePerView && revenuePerView < totalMinRevenuePerView) {
                            totalMinRevenuePerView = revenuePerView;
                        }

                        return {
                            views: viewsAcc,
                            revenue: revenuePerView
                        } as any;
                    })
                };
            })
            .filter(chart => chart.modelId != "null");

        const ids = lineData.map(chart => chart.modelId);

        const max = lineData.reduce((c, n) => {
            const currentMax = maxBy(n.data, "views") as any;

            if (c > currentMax.views) {
                return c;
            }

            return currentMax.views;
        }, 0);

        let maxGP = lineData.reduce((c, n) => {
            const currentMax = maxBy(n.data, "revenue") as any;

            if (c > currentMax.revenue) {
                return c;
            }

            return currentMax.revenue;
        }, 0);

        const x = d3
            .scaleLinear()
            .domain([0, max])
            .range([margin.left, width - margin.right]);

        const y = d3
            .scaleLinear()
            .domain([0, maxGP])
            .range([height - margin.bottom, margin.top]);

        const palette = [
            "rgba(2, 173, 185, 0.75)",
            "rgba(212, 83, 83, 0.75)",
            "rgba(201, 212, 83, 0.75)",
            "rgba(54, 222, 111, 0.75)",
            "rgba(241, 100, 47, 0.75)",
            "rgba(179, 96, 226, 0.75)",
            "rgba(77, 97, 212, 0.75)",
            "rgba(185, 2, 103, 0.75)",
            "rgba(255, 255, 255, 0.75)"
        ];

        const colors = {};

        Object.keys(data).map((k: string, i: number) => {
            colors[k] = palette[i];
        }) as any;

        const xAxis = (g: any) =>
            g.attr("transform", `translate(0,${height - margin.bottom})`).call(
                d3
                    .axisBottom(x)
                    .tickSize(-height + margin.top * 2 + 10)
                    .tickFormat((d: any) => {
                        return d;
                    })
                    .ticks(10)
            );

        const yAxis = (g: any) =>
            g.attr("transform", `translate(${margin.left},0)`).call(
                d3
                    .axisLeft(y)
                    .tickSize(-width + 90)
                    .tickFormat((d: number) => {
                        return getDollars(d);
                    })
            );

        const container = svg
            .append("g")
            .attr("class", "wth")
            .attr("width", width)
            .attr("height", height)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", `0 0 ${width} ${height}`);

        var line = d3
            .line()
            .x(function (d: any) {
                return x(d.views);
            })
            .y(function (d: any) {
                return y(d.revenue);
            })
            .curve(d3.curveBasis);

        container
            .selectAll("g")
            .data(lineData)
            .enter()
            .append("g")
            .attr("fill", "none")
            .attr("stroke-width", "1.5px")
            .attr("stroke", (d: any, i: number) => {
                return palette[i];
            })
            .datum(d => {
                return d.data;
            })
            .append("path")
            .attr("class", styles.line)
            .attr("d", line);

        const xAxisGroup = svg.append("g").call(xAxis);
        const yAxisGroup = svg.append("g").call(yAxis);

        yAxisGroup.select(".tick text").remove();

        svg.selectAll(`.${styles.grid} .domain`).remove();

        const defs = svg.append("defs");

        const filter = defs.append("filter").attr("id", "glow");
        filter.append("feGaussianBlur").attr("stdDeviation", "3.5").attr("result", "coloredBlur");

        const feComponentTransfer = filter.append("feComponentTransfer");
        feComponentTransfer.append("feFuncA").attr("type", "linear").attr("slope", "0.3");

        const feMerge = filter.append("feMerge");
        feMerge.append("feMergeNode").attr("in", "coloredBlur");
        feMerge.append("feMergeNode").attr("in", "SourceGraphic");

        const xLabelText = "VIEWS";
        const yLabelText = "GP / VIEW";
        const labelsPositioning = 5;

        svg.append("text")
            .attr("class", "x-label")
            .attr("text-anchor", "end")
            .attr("x", width / 2 + labelsPositioning * xLabelText.length)
            .attr("y", height - 9)
            .text(xLabelText);

        svg.append("text")
            .attr("class", "y-label")
            .attr("text-anchor", "end")
            .attr("x", height / -2 + labelsPositioning * yLabelText.length)
            .attr("y", 11)
            .attr("dy", ".75em")
            .attr("transform", "rotate(-90)")
            .text(yLabelText);

        const NO_NAME = "NO NAME";

        const keys = ids.map(id => {
            const MAX_LENGTH = 30;
            let name = labels[id] || NO_NAME;

            if (name.length > MAX_LENGTH) {
                name = name.slice(0, MAX_LENGTH) + "...";
            }

            return name;
        });

        svg.selectAll("mydots")
            .data(keys)
            .enter()
            .append("circle")
            .attr("cx", width - 310)
            .attr("cy", function (d, i) {
                return 60 + i * 25;
            })
            .attr("r", 7)
            .style("fill", function (d, i) {
                return palette[i];
            });

        svg.selectAll("mylabels")
            .data(keys)
            .enter()
            .append("text")
            .attr("x", width - 290)
            .attr("y", function (d, i) {
                return 60 + i * 25 + 1;
            })
            .style("fill", function (d, i) {
                return palette[i];
            })
            .text(function (d) {
                return d;
            })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle");

        const updateChart = ({ transform }: any) => {
            container.attr("transform", transform);
            const zx = transform.rescaleX(x).interpolate(d3.interpolateRound);
            const zy = transform.rescaleY(y).interpolate(d3.interpolateRound);

            xAxisGroup.call(xAxis, zx);
            yAxisGroup.call(yAxis, zy);

            xAxisGroup.selectAll(".tick line").attr("y1", "5");
            xAxisGroup.selectAll(".tick text").attr("y", "16");
            yAxisGroup.selectAll(".tick line").attr("x1", "-5");
            yAxisGroup.selectAll(".tick text").attr("x", "-16");

            container.selectAll("path").style("filter", "url(#glow)");
        };

        const zoom = d3.zoom().scaleExtent([0.2, 5]).on("zoom", updateChart);
        container.call(zoom).call(zoom.transform, d3.zoomIdentity);
    };

    useEffect(() => {
        const resize = () => {
            const d3SVG = d3.select(svgRef.current);
            if (svgRef.current) {
                d3SVG.selectAll("*").remove();
                draw(d3SVG, chartsDataRef.current);
            }
        };

        const throttledResize = throttle(resize, 500);
        window.addEventListener("resize", throttledResize);

        resize();

        return () => {
            window.removeEventListener("resize", throttledResize);
        };
    }, [tab]);

    return (
        <div ref={containerRef} className={styles.container}>
            <svg ref={svgRef} className={styles.svg} />
        </div>
    );
};

export default Graph;
