import { DefaultRoutesType } from "types/defaultRoutesType";

const deploymentsRoute = "/dashboard/deployment/live";
const crmsRoute = "/dashboard/crms";
const conversionsbalancerRoute = "/dashboard/conversionsbalancer";
const sdkRoute = "/dashboard/deployment/sdklive";

const defaultRoutes: DefaultRoutesType = {
    ED_ADMIN: deploymentsRoute,
    ED_SUPER_ADMIN: deploymentsRoute,
    ED_DEVELOPER: deploymentsRoute,
    ED_CMS_EDITOR: deploymentsRoute,
    ED_GOOGLE_MEDIA_BUYER: conversionsbalancerRoute,
    CRMS_ADMIN: crmsRoute,
    CRMS_VIDEO_CREATOR: crmsRoute,
    CRMS_IMAGE_CREATOR: crmsRoute,
    CRMS_COPYWRITER: crmsRoute,
    CRMS_UX_DESIGNER: crmsRoute,
    CRMS_MEDIA_BUYER: crmsRoute,
    CRMS_MANAGER: crmsRoute,
    CRMS_ANALYST: crmsRoute,
    SDK: "/dashboard/deployment/sdklive",
    CRMS_GOOGLE_VIDEO_CONTENT_CREATOR: crmsRoute,
    CRMS_GOOGLE_IMAGE_CONTENT_CREATOR: crmsRoute,
    CRMS_GOOGLE_COPYWRITER: crmsRoute,
    CRMS_GOOGLE_VIDEO_ADMIN: crmsRoute,
    CRMS_GOOGLE_IMAGE_ADMIN: crmsRoute,
    CRMS_GOOGLE_COPY_ADMIN: crmsRoute,
    CRMS_GOOGLE_ANALYST: crmsRoute,
    CRMS_GOOGLE_MANAGER: crmsRoute,
    SDK_ADMIN: sdkRoute,
    SDK_EDITOR: sdkRoute
};

export default defaultRoutes;
