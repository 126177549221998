import * as React from "react";
import Axios from "axios";

class Thumbnail extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            thumbnail: ""
        };
    }

    componentDidMount() {
        const { id } = this.props;
        this.loadThumbnail(id);
    }

    loadThumbnail(id: string) {
        Axios.get(
            `https://fast.wistia.com/oembed?url=https://home.wistia.com/medias/${id}`
        ).then(video => {
            const {
                data: { thumbnail_url: thumbnail }
            } = video;
            this.setState({ thumbnail });
        });
    }

    render() {
        const { thumbnail } = this.state;

        return <img alt="Wistia thumbnail" src={thumbnail} />;
    }
}

export default Thumbnail;
