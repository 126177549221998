import cx from "classnames";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import { sumUpsellVariants } from "services/getReport";
import { upsellContainer } from "types/upsellContainer";
import { upsellsFlow } from "types/upsellsFlow";
import styles from "./styles.scss";
import { set } from "types/set";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    addUpsellsFlow,
    duplicateUpsellsFlow,
    archieveUpsellsFlow,
    reorderUpsellsFlow
} from "../actions";
import { flow } from "lodash";

type Props = {
    addUpsellsFlowLoading: boolean;
    basePath: string;
    flows: upsellsFlow[];
    languageId: string;
    setId: string;
    upsellContainer: upsellContainer[];
    crm: set["crm"];
};

type Report = {
    _id: string;
    upsellViews: number;
    upsellSales: number;
    grossProfit: number;
    revenue: number;
};

const FlowMenu: React.FC<Props> = ({
    addUpsellsFlowLoading,
    basePath,
    flows,
    languageId,
    setId,
    upsellContainer
}) => {
    const dispatch = useDispatch();
    const [reports, setReports] = React.useState<{ [key: string]: Report }>({});

    React.useEffect(() => {
        let newReports = {};
        if (upsellContainer.length) {
            flows.map(flow => {
                const { _id, upsells } = flow;
                const containers = upsellContainer.filter(c => upsells.includes(c._id));
                const variants = containers.flatMap(c => c.variants);

                const report = sumUpsellVariants({
                    _id,
                    variants
                });

                newReports[_id] = report;
            });
        }
        setReports(newReports);
    }, [setId, flows, upsellContainer.length]);

    const handleFlowCopy = (flowId: string) => {
        dispatch(duplicateUpsellsFlow(setId, flowId));
    };

    const handleFlowArchieve = (flowId: string) => {
        dispatch(archieveUpsellsFlow(flowId));
    };

    const handleFlowReorder = (result: any) => {
        const { destination, source, draggableId } = result;

        if (!destination || !source) {
            return;
        }

        if (destination.droppableId !== source.droppableId) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        dispatch(reorderUpsellsFlow(source.index, destination.index, draggableId, flows));
    };

    return (
        <aside className={styles.flowMenu}>
            <DragDropContext onDragEnd={handleFlowReorder}>
                <Droppable droppableId="flow-menu">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {flows.map((flow, flowIdx) => {
                                const { _id, title } = flow;
                                const menuReport = reports[_id] || {};
                                const navLink = `${basePath}/${_id}`;

                                return (
                                    <Draggable
                                        index={flow.order === undefined ? flowIdx : flow.order}
                                        draggableId={String(flow._id)}
                                        key={flow._id}
                                    >
                                        {provided => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={cx(styles.flowContainer, {
                                                    [styles.activeContainer]:
                                                        navLink === window.location.pathname
                                                })}
                                            >
                                                <NavLink
                                                    key={flow._id}
                                                    to={navLink}
                                                    className={styles.flowItem}
                                                    activeClassName={styles.flowItemActive}
                                                >
                                                    <div className={cx(styles.row, styles.title)}>
                                                        <div className={styles.text}>{title}</div>
                                                        <div className={styles.actionBox}>
                                                            <Button
                                                                design="circle"
                                                                icon="clone"
                                                                size="small"
                                                                onClick={(
                                                                    e: React.SyntheticEvent
                                                                ) => {
                                                                    e.preventDefault();
                                                                    handleFlowCopy(flow._id);
                                                                }}
                                                            />
                                                            <Button
                                                                design="icon"
                                                                icon="trash"
                                                                size="small"
                                                                marginLeft
                                                                onClick={(
                                                                    e: React.SyntheticEvent
                                                                ) => {
                                                                    e.preventDefault();
                                                                    handleFlowArchieve(flow._id);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={styles.row}>
                                                        {getDollars(menuReport.grossProfit)}{" "}
                                                        <span>GP</span>
                                                    </div>
                                                    <hr></hr>
                                                    <div className={styles.row}>
                                                        {menuReport.upsellViews || 0}{" "}
                                                        <span>Views</span>
                                                    </div>
                                                    <hr></hr>
                                                    <div className={styles.row}>
                                                        {menuReport.upsellSales || 0}{" "}
                                                        <span>Conversions</span>
                                                    </div>
                                                    <hr></hr>
                                                    <div className={styles.row}>
                                                        {getPercentage(
                                                            menuReport.upsellSales /
                                                                menuReport.upsellViews
                                                        )}{" "}
                                                        <span>CVR</span>
                                                    </div>
                                                    <hr></hr>
                                                    <div className={styles.row}>
                                                        {getDollars(
                                                            menuReport.grossProfit /
                                                                menuReport.upsellViews
                                                        )}{" "}
                                                        <span>EPV</span>
                                                    </div>
                                                    <hr></hr>
                                                    <div className={styles.row}>
                                                        {getDollars(
                                                            menuReport.revenue /
                                                                menuReport.upsellSales
                                                        )}{" "}
                                                        <span>EPO</span>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Button
                onClick={() =>
                    dispatch(
                        addUpsellsFlow({
                            languageId,
                            setId,
                            title: "Upsell Flow " + (flows.length + 1),
                            order: flow.length
                        })
                    )
                }
                flat
            >
                {addUpsellsFlowLoading ? <LoadingIndicator size={18} /> : "Create new flow"}
            </Button>
        </aside>
    );
};

export default React.memo(FlowMenu);
