import * as actionTypes from "actionTypes";

export type uiStateType = {
    isSideMenuHidden: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: uiStateType = {
    isSideMenuHidden: false
};

export const uiStorage: { [key: string]: boolean } = {};

export default (state: uiStateType = initialState, action: IAction): uiStateType => {
    switch (action.type) {
        case actionTypes.SWITCH_SIDE_MENU:
            return {
                ...state,
                isSideMenuHidden: !state.isSideMenuHidden
            };
        default:
            return state;
    }
};
