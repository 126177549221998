import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

export function getSets() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SETS_START
        });

        api()
            .get("set")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_SETS,
                    payload: { sets: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_SETS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function setPullBlock(flag: boolean = false) {
    return {
        type: actionTypes.SET_PULLBLOCK,
        payload: { flag }
    };
}
