import * as React from "react";
import * as _ from "lodash";
import Button from "components/Button";
import Bundle from "../Bundle";
import styles from "./styles.scss";
import { PricingModelContext } from "../..";
import { ExtendedBundle } from "types/pricing";
import { useDispatch } from "react-redux";
import { addPricingBundleDraft, reorderPricingBundleDraft } from "../../../../actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PricingContext } from "../../../..";

interface Props {
    bundles: ExtendedBundle[];
}

const Bundles: React.FC<Props> = ({ bundles = [] }) => {
    const { isDraft } = React.useContext(PricingModelContext);
    const { languageId, setId, versionId, schema } = React.useContext(PricingContext);
    const dispatch = useDispatch();

    const handleAddBundle = () => {
        dispatch(addPricingBundleDraft(languageId, versionId, setId, schema));
    };

    const onDragEnd = (result: any) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        dispatch(reorderPricingBundleDraft(source.index, destination.index, draggableId));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={isDraft === false}>
                {provided => (
                    <main ref={provided.innerRef} {...provided.droppableProps}>
                        {bundles.map((bundle, index: number) => (
                            <Draggable
                                index={index}
                                draggableId={String(bundle._id)}
                                isDragDisabled={isDraft === false}
                                key={bundle._id}
                            >
                                {provided => <Bundle bundle={bundle} provided={provided} />}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {isDraft && (
                            <div className={styles.row}>
                                <span />
                                <Button
                                    design="circle"
                                    icon="add"
                                    size="small"
                                    background="purple"
                                    onClick={handleAddBundle}
                                />
                            </div>
                        )}
                    </main>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default Bundles;
