import * as React from "react";
import CampaignKeys from "../../../../scenes/Dashboard/CPDS/scenes/Server/components/CampaignKeys";
import DynamicForm from "../../../../scenes/Dashboard/CPDS/scenes/Server/components/DynamicForm";
import InsetButton, {
    Inset
} from "scenes/Dashboard/CPDS/components/DeploymentCard/components/InsetButton";
import Modal from "../../index";
import details from "assets/icons/deploymentButtons/details.svg";
import { campaignKeys, pixels, otherSettings } from "types/server";

interface Props {
    buttonText: string;
    campaignKeys: campaignKeys;
    otherSettings: otherSettings;
    pixels: pixels;
}

class DeploymentDetails extends React.PureComponent<Props, any> {
    state = {
        isModalOpen: false
    };

    handleOpenModal() {
        this.setState({ isModalOpen: true });
    }

    handleCloseModal() {
        this.setState({ isModalOpen: false });
    }

    render() {
        const { buttonText, pixels, campaignKeys, otherSettings = {} } = this.props;
        const { isModalOpen } = this.state;

        return (
            <>
                <InsetButton onClick={this.handleOpenModal.bind(this)} flat marginRight>
                    {buttonText}
                    <Inset>
                        <img src={details} />
                    </Inset>
                </InsetButton>
                {isModalOpen && (
                    <Modal onClose={this.handleCloseModal.bind(this)}>
                        <CampaignKeys campaignKeys={campaignKeys} isPanel={false} />
                        <DynamicForm formData={pixels} formName="Pixels" isPanel={false} />
                        <DynamicForm
                            formData={otherSettings}
                            formName="Other Settings"
                            isPanel={false}
                        />
                    </Modal>
                )}
            </>
        );
    }
}

export default DeploymentDetails;
