import * as React from "react";
import AnalyticsBar from "scenes/Dashboard/SchemaCMSEditor/components/AnalyticsBar";
import DataPiece from "scenes/Dashboard/SchemaCMSEditor/components/DataPiece";
import getTimeAgo from "services/getTimeAgo";
import styles from "./styles.scss";

interface Props {
    createdAt?: string;
    updatedAt?: string;
}

const Bar: React.FC<Props> = ({ createdAt, updatedAt }) => {
    return (
        <>
            {createdAt && updatedAt && (
                <AnalyticsBar className={styles.analyticsBar}>
                    <DataPiece
                        label="Running"
                        value={getTimeAgo(createdAt)}
                        reverse
                    />
                    <DataPiece
                        label="Last Update"
                        value={getTimeAgo(updatedAt)}
                        reverse
                    />
                </AnalyticsBar>
            )}
        </>
    );
};

export default Bar;
