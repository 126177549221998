import * as actionTypes from "actionTypes";
import api from "api";
import { Action, Dispatch } from "redux";
import { AxiosResponse, AxiosError } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { appStateType } from "reducers";

export function pullTemplates() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.PULL_TEMPLATES_START
        });

        api()
            .get("template")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.PULL_TEMPLATES,
                    payload: { templates: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.PULL_TEMPLATES_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function addTemplate(
    name: string,
    repoOwner: string,
    repoName: string,
    redirect: string
) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.ADD_TEMPLATE_START
        });

        api()
            .post("template", { name, repoOwner, repoName, redirect })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_TEMPLATE,
                    payload: { template: res.data }
                });
                dispatch(pullTemplates());
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_TEMPLATE_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}
