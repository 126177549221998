import * as React from "react";
import Modal from "components/Modal/scenes/File";
import Thumbnail from "../Thumbnail";
import cx from "classnames";
import styles from "./styles.scss";

type Props = {
    file: any;
    fileView: "half" | "wide";
    handleChooseFile?: (file: any) => void;
};

type State = {
    isModalOpen: boolean;
};

class File extends React.PureComponent<Props, State> {
    state = {
        isModalOpen: false
    };

    handleClickModal(isModalOpen: boolean) {
        this.setState({ isModalOpen });
    }

    render() {
        const { file, fileView, handleChooseFile } = this.props;
        const { isModalOpen } = this.state;

        const {
            bytes,
            extensions = [],
            hash,
            isUploaded,
            name,
            path,
            sizes = [],
            thumbnail: { url } = { url: "" }
        } = file;

        if (extensions.length === 0) {
            return <></>;
        }

        const cloudfront = "https://d3hlrrbqydii6y.cloudfront.net";
        const [extension] = extensions;
        const isVideo = extensions.includes("wistia");
        const isMini = `${sizes.length > 0 ? "_150x150" : ""}`;
        const imageUrl = `${cloudfront}/${path}/${hash}${isMini}.${extension}`;

        return (
            <>
                <div
                    className={cx(styles.box, {
                        [styles.active]: isUploaded === true
                    })}
                    key={name}
                >
                    {bytes !== undefined && bytes !== null && (
                        <span className={styles.bytes}>{bytes}kb</span>
                    )}
                    <span className={styles.ext}>{extension}</span>
                    <div
                        className={styles.box_image}
                        onClick={this.handleClickModal.bind(this, true)}
                    >
                        {isVideo ? (
                            url ? (
                                <img src={url} />
                            ) : (
                                <Thumbnail id={hash} />
                            )
                        ) : (
                            <img src={imageUrl} />
                        )}
                    </div>
                    {name && (
                        <div
                            className={styles.box_title}
                            onClick={() => (handleChooseFile ? handleChooseFile(file) : null)}
                        >
                            {name.includes(".") ? name.split(".")[0] : name}
                        </div>
                    )}
                </div>
                {isModalOpen && (
                    <Modal
                        className={styles.modal}
                        file={file}
                        onClose={this.handleClickModal.bind(this, false)}
                        view={fileView}
                    />
                )}
            </>
        );
    }
}

export default File;
