import amulet from "assets/icons/deployment/amulet.svg";
import cave from "assets/icons/deployment/cave.svg";
import chest from "assets/icons/deployment/chest.svg";
import compass from "assets/icons/deployment/compass.svg";
import crown from "assets/icons/deployment/crown.svg";
import magicHat from "assets/icons/deployment/magic-hat.svg";
import map from "assets/icons/deployment/map.svg";
import ritual from "assets/icons/deployment/ritual.svg";
import shoe from "assets/icons/deployment/shoe.svg";
import skull from "assets/icons/deployment/skull.svg";
import spellBook from "assets/icons/deployment/spell-book.svg";
import sword from "assets/icons/deployment/sword.svg";

const deploymentMessages = {
    created: {
        message: "Planning the greatest template mission ever",
        icon: map,
        loader: true
    },
    inprogress: {
        loader: true,
        messages: [
            {
                message: "Learning coding mystery",
                icon: spellBook
            },
            {
                message: "Conjuring up new template with a local wizard",
                icon: magicHat
            },
            {
                message: "Using gold vision artifact to find missing products pictures",
                icon: amulet
            },
            {
                message: "Searching for warranty component",
                icon: cave
            },
            {
                message: "Fighting bugs and monsters",
                icon: sword
            },
            {
                message: "Preparing template resources",
                icon: chest
            },
            {
                message: "Exploring a queen",
                icon: shoe
            },
            {
                message: "Taking back to dashboard kingdom",
                icon: compass
            }
        ]
    },
    successful: {
        message: "Success! My lord, a new page, is growing our economy",
        icon: crown,
        link: true
    },
    failed: {
        message: "This time your mission failed. We will never give up!",
        icon: skull
    },
    stopped: {
        message: "Unknown dark forces stopped the deployment",
        icon: ritual
    }
};

export default deploymentMessages;
