import * as React from "react";
import Headline from "../Headline";
import SpaceBetween from "scenes/Dashboard/SchemaCMSEditor/components/SpaceBetween";
import cx from "classnames";
import styles from "./styles.scss";
import Button from "components/Button";
import { PricingContext } from "../../../..";
import { useDispatch } from "react-redux";
import { startPricingContainerEditing, updatePricingContainer } from "../../../../actions";

const Header: React.FC = () => {
    const { pricingContainer, isEditing, setId } = React.useContext(PricingContext);
    const dispatch = useDispatch();
    const containerId = pricingContainer?._id;

    return (
        <div className={styles.modelBox}>
            <header className={cx(styles.header)}>
                <SpaceBetween>
                    <Headline title={"Flavour Products"} />
                    <div className={styles.rightColumn}>
                        {containerId ? (
                            <Button
                                size="tiny"
                                onClick={() => {
                                    isEditing
                                        ? dispatch(updatePricingContainer(containerId))
                                        : dispatch(
                                              startPricingContainerEditing(setId, containerId)
                                          );
                                }}
                                flat
                            >
                                {isEditing ? "Submit" : "Edit"}
                            </Button>
                        ) : null}
                    </div>
                </SpaceBetween>
            </header>
        </div>
    );
};

export default Header;
