import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function getSoldOutProducts() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SOLD_OUT_PRODUCTS_START
        });

        api()
            .get("product/soldoutproduct")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_SOLD_OUT_PRODUCTS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_SOLD_OUT_PRODUCTS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

type AddSoldOutProductProps = {
    productId: string;
};

export function addSoldOutProduct({ productId }: AddSoldOutProductProps) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SAVE_SOLD_OUT_PRODUCTS_START
        });

        api()
            .post("product/soldoutproduct", { id: null, productId, isSoldOut: true })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.SAVE_SOLD_OUT_PRODUCTS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.SAVE_SOLD_OUT_PRODUCTS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function deleteSoldOutProduct(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_SOLD_OUT_PRODUCTS_START,
            payload: id
        });

        api()
            .delete("product/soldoutproduct", { data: { id } })
            .then()
            .catch((err: AxiosError) => {
                console.log("delete sold out product error", err.response);
            });
    };
}
