import * as types from "actionTypes";
import { Dispatch } from "redux";
import { appStateType } from "reducers";
import api from "api";
import { AxiosResponse, AxiosError } from "axios";

export function updateField(name: string, value: string) {
  return {
    type: types.UPDATE_FIELD,
    payload: { name, value }
  };
}

export function addToken() {
  return (dispatch: Dispatch, getState: () => appStateType) => {
    dispatch({ type: types.ADD_TOKEN_START });

    const state = getState();
    const { form } = state.tokens;

    api()
      .post("tokens", form)
      .then((res: AxiosResponse) => {
        dispatch({
          type: types.ADD_TOKEN,
          payload: {
            token: res.data
          }
        });
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          dispatch({ type: types.ADD_TOKEN_ERROR, payload: err.response.data });
        }
      });
  };
}

export function getTokens() {
  return (dispatch: Dispatch, getState: () => appStateType) => {
    dispatch({ type: types.GET_TOKENS_START });

    api()
      .get("tokens")
      .then((res: AxiosResponse) => {
        dispatch({
          type: types.GET_TOKENS,
          payload: {
            tokens: res.data
          }
        });
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          dispatch({
            type: types.GET_TOKENS_ERROR,
            payload: err.response.data
          });
        }
      });
  };
}

export function deleteToken(id: string) {
  return (dispatch: Dispatch, getState: () => appStateType) => {
    dispatch({ type: types.DELETE_TOKEN_START });

    const state = getState();
    const { form } = state.tokens;

    api()
      .delete("tokens", { data: { id } })
      .then((res: AxiosResponse) => {
        dispatch({
          type: types.DELETE_TOKEN,
          payload: {
            id: res.data._id
          }
        });
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          dispatch({
            type: types.DELETE_TOKEN_ERROR,
            payload: err.response.data
          });
        }
      });
  };
}
