import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

type AddFunnelProps = {
    _id: string | null;
    ffId: string;
    name: string;
    sets: string[];
    googleAdAccountId: string;
    selectedCampaigns: string[];
    selectedConversionAction: string;
};

export const addFunnel = ({
    _id,
    ffId,
    name,
    sets,
    googleAdAccountId,
    selectedCampaigns,
    selectedConversionAction
}: AddFunnelProps) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_FF_FUNNEL_START
        });

        api()
            .post("conversionbalancer/fffunnel", {
                _id,
                ffId,
                name,
                sets,
                googleAdAccountId,
                selectedCampaigns,
                selectedConversionAction
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_FF_FUNNEL,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.ADD_FF_FUNNEL_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const pullGoogleCampaigns = (googleAdAccountId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_GOOGLE_CAMPAIGNS_START
        });

        api()
            .post("conversionbalancer/googlecampaigns", {
                googleAdAccountId
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.UPDATE_GOOGLE_CAMPAIGNS,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPDATE_GOOGLE_CAMPAIGNS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};
