export const SET_PULLBLOCK = "SET_PULLBLOCK";

export const START_AUTH = "START_AUTH";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_AUTH_ERRORS = "SET_AUTH_ERRORS";
export const SIGN_OUT_USER = "SIGN_OUT_USER";

export const UPDATE_FIELD = "UPDATE_FIELD";
export const ADD_TOKEN_START = "ADD_TOKEN_START";
export const ADD_TOKEN = "ADD_TOKEN";
export const ADD_TOKEN_ERROR = "ADD_TOKEN_ERROR";
export const GET_TOKENS_START = "GET_TOKENS_START";
export const GET_TOKENS = "GET_TOKENS";
export const GET_TOKENS_ERROR = "GET_TOKENS_ERROR";
export const DELETE_TOKEN_START = "DELETE_TOKEN_START";
export const DELETE_TOKEN = "DELETE_TOKEN";
export const DELETE_TOKEN_ERROR = "DELETE_TOKEN_ERROR";

export const ADD_SET_START = "ADD_SET_START";
export const ADD_SET = "ADD_SET";
export const ADD_SET_ERROR = "ADD_SET_ERROR";
export const GET_SETS_START = "GET_SET_START";
export const GET_SETS = "GET_SET";
export const GET_SETS_ERROR = "GET_SET_ERROR";
export const DELETE_SET_START = "DELETE_SET_START";
export const DELETE_SET = "DELETE_SET";
export const DELETE_SET_ERROR = "DELETE_SET_ERROR";
export const EDIT_SET_START = "EDIT_SET_START";
export const EDIT_SET = "EDIT_SET";
export const EDIT_SET_ERROR = "EDIT_SET_ERROR";
export const UPDATE_SET_FIELD = "UPDATE_SET_FIELD";
export const DUPLICATE_SET_START = "DUPLICATE_SET_START";
export const DUPLICATE_SET = "DUPLICATE_SET";
export const DUPLICATE_SET_ERROR = "DUPLICATE_SET_ERROR";
export const CHOOSE_CRM = "CHOOSE_CRM";
export const SET_IS_SDK = "SET_IS_SDK";

export const GET_ELEMENTS_START = "GET_ELEMENTS_START";
export const GET_ELEMENTS = "GET_ELEMENTS";
export const GET_ELEMENTS_ERROR = "GET_ELEMENTS_ERROR";
export const ADD_ELEMENT_START = "ADD_ELEMENT_START";
export const ADD_ELEMENT = "ADD_ELEMENT";
export const ADD_ELEMENT_ERROR = "ADD_ELEMENT_ERROR";
export const DELETE_ELEMENT_START = "DELETE_ELEMENT_START";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const DELETE_ELEMENT_ERROR = "DELETE_ELEMENT_ERROR";
export const UPDATE_ELEMENT_FIELD = "UPDATE_ELEMENT_FIELD";
export const UPDATE_ELEMENT_START = "UPDATE_ELEMENT_START";
export const UPDATE_ELEMENT = "UPDATE_ELEMENT";
export const UPDATE_ELEMENT_ERROR = "UPDATE_ELEMENT_ERROR";
export const UPDATE_ELEMENT_CHOOSEONE_START = "UPDATE_ELEMENT_CHOOSEONE_START";
export const UPDATE_ELEMENT_CHOOSEONE = "UPDATE_ELEMENT_CHOOSEONE";
export const UPDATE_ELEMENT_CHOOSEONE_ERROR = "UPDATE_ELEMENT_CHOOSEONE_ERROR";
export const SET_ELEMENTS_TREE = "SET_ELEMENTS_TREE";
export const ADD_ELEMENTS_TREE_VARIANT = "ADD_ELEMENTS_TREE_VARIANT";
export const REMOVE_ELEMENTS_TREE_VARIANT = "REMOVE_ELEMENTS_TREE_VARIANT";
export const ADD_ELEMENTS_TREE_VARIANT_START = "ADD_ELEMENTS_TREE_VARIANT_START";
export const ADD_ARRAY_NODE = "ADD_ARRAY_NODE";
export const DELETE_ARRAY_NODE = "DELETE_ARRAY_NODE";
export const SET_SCHEMA_KEY = "SET_SCHEMA_KEY";
export const ELEMENTS_SET_SCHEMA = "ELEMENTS_SET_SCHEMA";

export const GET_LANGUAGES_START = "GET_LANGUAGES_START";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_ERROR = "GET_LANGUAGES_ERROR";
export const ADD_LANGUAGE_START = "ADD_LANGUAGE_START";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const ADD_LANGUAGE_ERROR = "ADD_LANGUAGE_ERROR";
export const DELETE_LANGUAGE_START = "DELETE_LANGUAGE_START";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const DELETE_LANGUAGE_ERROR = "DELETE_LANGUAGE_ERROR";
export const EDIT_LANGUAGE_START = "EDIT_LANGUAGE_START";
export const EDIT_LANGUAGE = "EDIT_LANGUAGE";
export const EDIT_LANGUAGE_ERROR = "EDIT_LANGUAGE_ERROR";
export const UPDATE_LANGUAGE_FIELD = "UPDATE_LANGUAGE_FIELD";
export const GET_CMS_LANGUAGE = "GET_CMS_LANGUAGE";

export const GET_BRICKS_START = "GET_BRICKS_START";
export const GET_BRICKS = "GET_BRICKS";
export const GET_BRICKS_ERROR = "GET_BRICKS_ERROR";
export const DELETE_BRICKS = "DELETE_BRICKS";
export const ADD_BRICK_VARIANT_START = "ADD_BRICK_VARIANT_START";
export const ADD_BRICK_VARIANT = "ADD_BRICK_VARIANT";
export const ADD_BRICK_VARIANT_ERROR = "ADD_BRICK_VARIANT_ERROR";
export const UPDATE_VARIANT_FIELD = "UPDATE_VARIANT_FIELD";

export const GET_REPORT_START = "GET_REPORT_START";
export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";
export const GET_IMPRESSION_REPORT_START = "GET_IMPRESSION_REPORT_START";
export const GET_IMPRESSION_REPORT = "GET_IMPRESSION_REPORT";
export const GET_IMPRESSION_REPORT_ERROR = "GET_IMPRESSION_REPORT_ERROR";

export const START_PULL_DRIP_SETTINGS = "START_PULL_DRIP_SETTINGS";
export const PULL_DRIP_SETTINGS = "PULL_DRIP_SETTINGS";
export const ERROR_PULL_DRIP_SETTINGS = "ERROR_PULL_DRIP_SETTINGS";
export const START_SAVE_DRIP_SETTINGS = "START_SAVE_DRIP_SETTINGS";
export const SAVE_DRIP_SETTINGS = "SAVE_DRIP_SETTINGS";
export const ERROR_SAVE_DRIP_SETTINGS = "ERROR_SAVE_DRIP_SETTINGS";
export const UPDATE_DRIP_SETTING = "UPDATE_DRIP_SETTING";
export const DRIP_GET_FILE = "DRIP_GET_FILE";
export const START_DRIP_UPLOAD_CARDS = "START_DRIP_UPLOAD_CARDS";
export const DRIP_UPLOAD_CARDS = "DRIP_UPLOAD_CARDS";
export const ERROR_DRIP_UPLOAD_CARDS = "ERROR_DRIP_UPLOAD_CARDS";
export const START_PULL_DRIP_CARDS = "START_PULL_DRIP_CARDS";
export const PULL_DRIP_CARDS = "PULL_DRIP_CARDS";
export const ERROR_PULL_DRIP_CARDS = "ERROR_PULL_DRIP_CARDS";

export const GET_WHITELIST_START = "GET_WHITELIST_START";
export const GET_WHITELIST = "GET_WHITELIST";
export const GET_WHITELIST_ERROR = "GET_WHITELIST_ERROR";
export const ADD_NEW_DOMAIN_START = "ADD_NEW_DOMAIN_START";
export const ADD_NEW_DOMAIN = "ADD_NEW_DOMAIN";
export const ADD_NEW_DOMAIN_ERROR = "ADD_NEW_DOMAIN_ERROR";
export const REMOVE_NEW_DOMAIN_START = "REMOVE_NEW_DOMAIN_START";
export const REMOVE_NEW_DOMAIN = "REMOVE_NEW_DOMAIN";
export const REMOVE_NEW_DOMAIN_ERROR = "REMOVE_NEW_DOMAIN_ERROR";

export const DELETE_VARIANTS = "DELETE_VARIANTS";

export const ADD_SETTINGS_START = "ADD_SETTINGS_START";
export const ADD_SETTINGS = "ADD_SETTINGS";
export const ADD_SETTINGS_ERROR = "ADD_SETTINGS_ERROR";

export const DELETE_SETTINGS_START = "DELETE_SETTINGS_START";
export const DELETE_SETTINGS = "DELETE_SETTINGS";
export const DELETE_SETTINGS_ERROR = "DELETE_SETTINGS_ERROR";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD = "DELETE_CARD";
export const DELETE_CARD_ERROR = "DELETE_CARD_ERROR";

export const TOGGLE_CARD_START = "TOGGLE_CARD_START";
export const TOGGLE_CARD = "TOGGLE_CARD";
export const TOGGLE_CARD_ERROR = "TOGGLE_CARD_ERROR";

export const TOGGLE_DRIP_START = "TOGGLE_DRIP_START";
export const TOGGLE_DRIP = "TOGGLE_DRIP";
export const TOGGLE_DRIP_ERROR = "TOGGLE_DRIP_ERROR";

export const START_PULL_ORDERS = "START_PULL_ORDERS";
export const PULL_ORDERS = "PULL_ORDERS";
export const ERROR_PULL_ORDERS = "ERROR_PULL_ORDERS";

export const GET_SPEND_START = "GET_SPEND_START";
export const GET_SPEND = "GET_SPEND";
export const GET_SPEND_ERROR = "GET_SPEND_ERROR";

export const GET_ADACCOUNTS_START = "GET_ADACCOUNTS_START";
export const GET_ADACCOUNTS = "GET_ADACCOUNTS";
export const GET_ADACCOUNTS_ERROR = "GET_ADACCOUNTS_ERROR";

export const ASSIGN_ADACCOUNT_START = "ASSIGN_ADACCOUNT_START";
export const ASSIGN_ADACCOUNT = "ASSIGN_ADACCOUNT";
export const ASSIGN_ADACCOUNT_ERROR = "ASSIGN_ADACCOUNT_ERROR";

export const GET_TREE_START = "GET_TREE_START";
export const GET_TREE = "GET_TREE";
export const GET_TREE_ERROR = "GET_TREE_ERROR";
export const INCREMENT_PROGRESS = "INCREMENT_PROGRESS";
export const COPY_COMPLETE = "COPY_COMPLETE";

export const COPY_TREE_START = "COPY_TREE_START";
export const COPY_TREE = "COPY_TREE";
export const COPY_TREE_ERROR = "COPY_TREE_ERROR";

export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";
export const UPDATE_FILE_START = "UPDATE_FILE_START";
export const UPDATE_FILE = "UPDATE_FILE";
export const UPDATE_FILE_ERROR = "UPDATE_FILE_ERROR";
export const DELETE_FILE_START = "DELETE_FILE_START";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_ERROR = "DELETE_FILE_ERROR";
export const DELETE_FILE_ERRORS = "DELETE_FILE_ERRORS";
export const START_PULL_FILES = "START_PULL_FILES";
export const PULL_FILES = "PULL_FILES";
export const ERROR_PULL_FILES = "ERROR_PULL_FILES";
export const START_PULL_TAGS = "START_PULL_TAGS";
export const PULL_TAGS = "PULL_TAGS";
export const ERROR_PULL_TAGS = "ERROR_PULL_TAGS";
export const SAVE_VIDEO_START = "SAVE_VIDEO_START";
export const SAVE_VIDEO = "SAVE_VIDEO";
export const SAVE_VIDEO_ERROR = "SAVE_VIDEO_ERROR";

export const SAVE_VERSION_START = "SAVE_VERSION_START";
export const SAVE_VERSION = "SAVE_VERSION";
export const SAVE_VERSION_ERROR = "SAVE_VERSION_ERROR";
export const PULL_VERSIONS_START = "PULL_VERSIONS_START";
export const PULL_VERSIONS = "PULL_VERSIONS";
export const PULL_VERSIONS_ERROR = "PULL_VERSIONS_ERROR";
export const REMOVE_VERSION_START = "REMOVE_VERSION_START";
export const REMOVE_VERSION = "REMOVE_VERSION";
export const REMOVE_VERSION_ERROR = "REMOVE_VERSION_ERROR";
export const PULL_CMS_VERSION = "PULL_CMS_VERSION";

export const ADD_SERVER_START = "ADD_SERVER_START";
export const ADD_SERVER = "ADD_SERVER";
export const ADD_SERVER_ERROR = "ADD_SERVER_ERROR";
export const PULL_SERVERS_START = "PULL_SERVERS_START";
export const PULL_SERVERS = "PULL_SERVERS";
export const PULL_SERVERS_ERROR = "PULL_SERVERS_ERROR";

export const PULL_DEPLOYMENTS_START = "PULL_DEPLOYMENTS_START";
export const PULL_DEPLOYMENTS = "PULL_DEPLOYMENTS";
export const RESET_DEPLOYMENTS = "RESET_DEPLOYMENTS";
export const PULL_DEPLOYMENTS_ERROR = "PULL_DEPLOYMENTS_ERROR";
export const SAVE_DEPLOYMENT_START = "SAVE_DEPLOYMENT_START";
export const SAVE_DEPLOYMENT = "SAVE_DEPLOYMENT";
export const SAVE_DEPLOYMENT_ERROR = "SAVE_DEPLOYMENT_ERROR";
export const GET_DEPLOYMENT_START = "GET_DEPLOYMENT_START";
export const GET_DEPLOYMENT = "GET_DEPLOYMENT";
export const GET_DEPLOYMENT_ERROR = "GET_DEPLOYMENT_ERROR";
export const SET_DEPLOYMENT_STATUS = "SET_DEPLOYMENT_STATUS";
export const REMOVE_DEPLOYMENT = "REMOVE_DEPLOYMENT";
export const SET_DEPLOYMENT_FILTERS = "SET_DEPLOYMENT_FILTERS";
export const RESET_DEPLOYMENT_FILTERS = "RESET_DEPLOYMENT_FILTERS";
export const GET_HOT_DEPLOYMENTS_START = "GET_HOT_DEPLOYMENTS_START";
export const GET_HOT_DEPLOYMENTS = "GET_HOT_DEPLOYMENTS";
export const GET_HOT_DEPLOYMENTS_ERROR = "GET_HOT_DEPLOYMENTS_ERROR";

export const PULL_TEMPLATES_START = "PULL_TEMPLATES_START";
export const PULL_TEMPLATES = "PULL_TEMPLATES";
export const PULL_TEMPLATES_ERROR = "PULL_TEMPLATES_ERROR";
export const ADD_TEMPLATE_START = "ADD_TEMPLATE_START";
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATE_ERROR = "ADD_TEMPLATE_ERROR";

export const ADD_NEW_WILDCARD_START = "ADD_NEW_WILDCARD_START";
export const ADD_NEW_WILDCARD = "ADD_NEW_WILDCARD";
export const ADD_NEW_WILDCARD_ERROR = "ADD_NEW_WILDCARD_ERROR";

export const GET_DISTINCT_FIELD_START = "GET_DISTINCT_FIELD_START";
export const GET_DISTINCT_FIELD = "GET_DISTINCT_FIELD";
export const GET_DISTINCT_FIELD_ERROR = "GET_DISTINCT_FIELD_ERROR";

export const ADD_BRAND_START = "ADD_BRAND_START";
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_ERROR = "ADD_BRAND_ERROR";
export const GET_BRANDS_START = "GET_BRANDS_START";
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_ERROR = "GET_BRANDS_ERROR";
export const REMOVE_BRAND_START = "REMOVE_BRAND_START";
export const REMOVE_BRAND = "REMOVE_BRAND";
export const REMOVE_BRAND_ERROR = "REMOVE_BRAND_ERROR";

export const ADD_PRODUCT_GROUP_START = "ADD_PRODUCT_GROUP_START";
export const ADD_PRODUCT_GROUP = "ADD_PRODUCT_GROUP";
export const ADD_PRODUCT_GROUP_ERROR = "ADD_PRODUCT_GROUP_ERROR";
export const UPDATE_PRODUCT_GROUP_START = "UPDATE_PRODUCT_GROUP_START";
export const UPDATE_PRODUCT_GROUP = "UPDATE_PRODUCT_GROUP";
export const UPDATE_PRODUCT_GROUP_ERROR = "UPDATE_PRODUCT_GROUP_ERROR";
export const GET_PRODUCT_GROUPS_START = "GET_PRODUCT_GROUPS_START";
export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS";
export const GET_PRODUCT_GROUPS_ERROR = "GET_PRODUCT_GROUPS_ERROR";
export const REMOVE_PRODUCT_GROUP_START = "REMOVE_PRODUCT_GROUP_START";
export const REMOVE_PRODUCT_GROUP = "REMOVE_PRODUCT_GROUP";
export const REMOVE_PRODUCT_GROUP_ERROR = "REMOVE_PRODUCT_GROUP_ERROR";

export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_ODOO_PRODUCTS_START = "GET_ODOO_PRODUCTS_START";
export const GET_ODOO_PRODUCTS = "GET_ODOO_PRODUCTS";
export const GET_ODOO_PRODUCTS_ERROR = "GET_ODOO_PRODUCTS_ERROR";
export const GET_ODOO_PRODUCTS_PROD_START = "GET_ODOO_PRODUCTS_PROD_START";
export const GET_ODOO_PRODUCTS_PROD = "GET_ODOO_PRODUCTS_PROD";
export const GET_ODOO_PRODUCTS_PROD_ERROR = "GET_ODOO_PRODUCTS_PROD_ERROR";
export const GET_ODOO_PRODUCTS_DEV_START = "GET_ODOO_PRODUCTS_DEV_START";
export const GET_ODOO_PRODUCTS_DEV = "GET_ODOO_PRODUCTS_DEV";
export const GET_ODOO_PRODUCTS_DEV_ERROR = "GET_ODOO_PRODUCTS_DEV_ERROR";
export const GET_SOLD_OUT_PRODUCTS_START = "GET_SOLD_OUT_PRODUCTS_START";
export const GET_SOLD_OUT_PRODUCTS = "GET_SOLD_OUT_PRODUCTS";
export const GET_SOLD_OUT_PRODUCTS_ERROR = "GET_SOLD_OUT_PRODUCTS_ERROR";
export const SAVE_SOLD_OUT_PRODUCTS_START = "SAVE_SOLD_OUT_PRODUCTS_START";
export const SAVE_SOLD_OUT_PRODUCTS = "SAVE_SOLD_OUT_PRODUCTS";
export const SAVE_SOLD_OUT_PRODUCTS_ERROR = "SAVE_SOLD_OUT_PRODUCTS_ERROR";
export const DELETE_SOLD_OUT_PRODUCTS_START = "DELETE_SOLD_OUT_PRODUCTS_START";

export const GET_SYSTEM_START = "GET_SYSTEM_START";
export const GET_SYSTEM = "GET_SYSTEM";
export const GET_SYSTEM_ERROR = "GET_SYSTEM_ERROR";

export const ADD_UPSELLS_FLOW_START = "ADD_UPSELLS_FLOW_START";
export const ADD_UPSELLS_FLOW = "ADD_UPSELLS_FLOW";
export const ADD_UPSELLS_FLOW_ERROR = "ADD_UPSELLS_FLOW_ERROR";
export const GET_UPSELLS_FLOW_START = "GET_UPSELLS_FLOW_START";
export const GET_UPSELLS_FLOW = "GET_UPSELLS_FLOW";
export const GET_UPSELLS_FLOW_ERROR = "GET_UPSELLS_FLOW_ERROR";
export const UPDATE_UPSELLS_FLOW_START = "UPDATE_UPSELLS_FLOW_START";
export const UPDATE_UPSELLS_FLOW = "UPDATE_UPSELLS_FLOW";
export const UPDATE_UPSELLS_FLOW_ERROR = "UPDATE_UPSELLS_FLOW_ERROR";

export const ADD_UPSELL_TRIGGER = "ADD_UPSELL_TRIGGER";
export const UPDATE_UPSELL_TRIGGER = "UPDATE_UPSELL_TRIGGER";
export const SAVE_TRIGGERS_START = "SAVE_TRIGGERS_START";
export const SAVE_TRIGGERS_DONE = "SAVE_TRIGGERS_DONE";
export const DELETE_UPSELL_TRIGGER = "DELETE_UPSELL_TRIGGER";
export const ADD_UPSELL_REPORT = "ADD_UPSELL_REPORT";

export const ADD_UPSELL_CONTAINER_START = "ADD_UPSELL_CONTAINER_START";
export const ADD_UPSELL_CONTAINER = "ADD_UPSELL_CONTAINER";
export const ADD_UPSELL_CONTAINER_ERROR = "ADD_UPSELL_CONTAINER_ERROR";
export const REMOVE_UPSELL_CONTAINER_START = "REMOVE_UPSELL_CONTAINER_START";
export const REMOVE_UPSELL_CONTAINER = "REMOVE_UPSELL_CONTAINER";
export const REMOVE_UPSELL_CONTAINER_ERROR = "REMOVE_UPSELL_CONTAINER_ERROR";
export const GET_UPSELL_CONTAINER_START = "GET_UPSELL_CONTAINER_START";
export const GET_UPSELL_CONTAINER = "GET_UPSELL_CONTAINER";
export const GET_UPSELL_CONTAINER_ERROR = "GET_UPSELL_CONTAINER_ERROR";

export const ADD_UPSELL_START = "ADD_UPSELL_START";
export const ADD_UPSELL = "ADD_UPSELL";
export const ADD_UPSELL_ERROR = "ADD_UPSELL_ERROR";

export const GET_UPSELL_REPORT_START = "GET_UPSELL_REPORT_START";
export const GET_UPSELL_REPORT = "GET_UPSELL_REPORT";
export const GET_UPSELL_REPORT_ERROR = "GET_UPSELL_REPORT_ERROR";

export const COPY_UPSELL_CONTAINER_START = "COPY_UPSELL_CONTAINER_START";
export const COPY_UPSELL_CONTAINER = "COPY_UPSELL_CONTAINER";
export const COPY_UPSELL_CONTAINER_ERROR = "COPY_UPSELL_CONTAINER_ERROR";

export const ARCHIEVE_UPSELL_CONTAINER_START = "ARCHIEVE_UPSELL_CONTAINER_START";
export const ARCHIEVE_UPSELL_CONTAINER = "ARCHIEVE_UPSELL_CONTAINER";
export const ARCHIEVE_UPSELL_CONTAINER_ERROR = "ARCHIEVE_UPSELL_CONTAINER_ERROR";

export const REORDER_UPSELL_CONTAINER_START = "REORDER_UPSELL_CONTAINER_START";
export const REORDER_UPSELL_CONTAINER = "REORDER_UPSELL_CONTAINER";
export const TEMP_REORDER_UPSELL_CONTAINER = "TEMP_REORDER_UPSELL_CONTAINER";
export const REORDER_UPSELL_CONTAINER_ERROR = "REORDER_UPSELL_CONTAINER_ERROR";

export const REORDER_UPSELL_FLOW_START = "REORDER_UPSELL_FLOW_START";
export const REORDER_UPSELL_FLOW = "REORDER_UPSELL_FLOW";
export const TEMP_REORDER_UPSELL_FLOW = "TEMP_REORDER_UPSELL_FLOW";
export const REORDER_UPSELL_FLOW_ERROR = "REORDER_UPSELL_FLOW_ERROR";

export const ARCHIEVE_UPSELL_FLOW_START = "ARCHIEVE_UPSELL_FLOW_START";
export const ARCHIEVE_UPSELL_FLOW = "ARCHIEVE_UPSELL_FLOW";
export const ARCHIEVE_UPSELL_FLOW_ERROR = "ARCHIEVE_UPSELL_FLOW_ERROR";

export const COPY_UPSELL_FLOW_START = "COPY_UPSELL_FLOW_START";
export const COPY_UPSELL_FLOW = "COPY_UPSELL_FLOW";
export const COPY_UPSELL_FLOW_ERROR = "COPY_UPSELL_FLOW_ERROR";

export const REMOVE_SESSION_START = "REMOVE_SESSION_START";
export const REMOVE_SESSION = "REMOVE_SESSION";
export const REMOVE_SESSION_ERROR = "REMOVE_SESSION_ERROR";
export const GET_CACHED_SETS_START = "GET_CACHED_SETS_START";
export const GET_CACHED_SETS = "GET_CACHED_SETS";
export const GET_CACHED_SETS_ERROR = "GET_CACHED_SETS_ERROR";
export const GET_ODOO_ERRORS_START = "GET_ODOO_ERRORS_START";
export const GET_ODOO_ERRORS = "GET_ODOO_ERRORS";
export const GET_ODOO_ERRORS_ERROR = "GET_ODOO_ERRORS_ERROR";
export const GET_ODOO_ERRORS_REPORT_START = "GET_ODOO_ERRORS_REPORT_START";
export const GET_ODOO_ERRORS_REPORT = "GET_ODOO_ERRORS_REPORT";
export const GET_ODOO_ERRORS_REPORT_ERROR = "GET_ODOO_ERRORS_REPORT_ERROR";

export const SAVE_PRICING_CONTAINER_START = "SAVE_PRICING_CONTAINER_START";
export const ADD_PRICING_CONTAINER = "ADD_PRICING_CONTAINER";
export const SAVE_PRICING_CONTAINER = "SAVE_PRICING_CONTAINER";
export const SAVE_PRICING_CONTAINER_ERROR = "SAVE_PRICING_CONTAINER_ERROR";
export const GET_PRICING_START = "GET_PRICING_START";
export const GET_PRICING = "GET_PRICING";
export const GET_PRICING_ERROR = "GET_PRICING_ERROR";
export const EDIT_PRICING_MODEL_START = "EDIT_PRICING_MODEL_START";
export const EDIT_PRICING_MODEL = "EDIT_PRICING_MODEL";
export const EDIT_PRICING_MODEL_ERROR = "EDIT_PRICING_MODEL_ERROR";
export const CONFIRM_PRICING_MODEL_START = "CONFIRM_PRICING_MODEL_START";
export const CONFIRM_PRICING_MODEL = "CONFIRM_PRICING_MODEL";
export const CONFIRM_PRICING_MODEL_ERROR = "CONFIRM_PRICING_MODEL_ERROR";
export const ADD_PRICING_MODEL_DRAFT = "ADD_PRICING_MODEL_DRAFT";
export const COPY_PRICING_MODEL = "COPY_PRICING_MODEL";
export const EDIT_PRICING_MODEL_DRAFT = "EDIT_PRICING_MODEL_DRAFT";
export const EDIT_PRICING_MODEL_CURRENCY_DRAFT = "EDIT_PRICING_MODEL_CURRENCY_DRAFT";
export const ADD_PRICING_MODEL_PRODUCT_DRAFT = "ADD_PRICING_MODEL_PRODUCT_DRAFT";
export const EDIT_PRICING_MODEL_PRODUCT_DRAFT = "EDIT_PRICING_MODEL_PRODUCT_DRAFT";
export const REORDER_PRICING_MODEL_PRODUCT_DRAFT = "REORDER_PRICING_MODEL_PRODUCT_DRAFT";
export const EDIT_PRICING_CONTAINER = "EDIT_PRICING_CONTAINER";
export const ADD_PRICING_FLAVOUR_PRODUCT = "ADD_PRICING_FLAVOUR_PRODUCT";
export const EDIT_PRICING_FLAVOUR_PRODUCT = "EDIT_PRICING_FLAVOUR_PRODUCT";
export const UPDATE_PRICING_CONTAINER_START = "UPDATE_PRICING_CONTAINER_START";
export const UPDATE_PRICING_CONTAINER = "UPDATE_PRICING_CONTAINER";
export const UPDATE_PRICING_CONTAINER_ERROR = "UPDATE_PRICING_CONTAINER_ERROR";
export const ADD_PRICING_BUNDLE_DRAFT = "ADD_PRICING_BUNDLE_DRAFT";
export const EDIT_PRICING_BUNDLE_DRAFT = "EDIT_PRICING_BUNDLE_DRAFT";
export const REORDER_PRICING_BUNDLE_DRAFT = "REORDER_PRICING_BUNDLE_DRAFT";
export const GET_PRICING_GRAPHS_START = "GET_PRICING_GRAPHS_START";
export const GET_PRICING_GRAPHS = "GET_PRICING_GRAPHS";
export const GET_PRICING_GRAPHS_ERROR = "GET_PRICING_GRAPHS_ERROR";
export const SET_PRICING_ANALYTICS_FROM_DATE = "SET_PRICING_ANALYTICS_FROM_DATE";
export const SET_PRICING_ANALYTICS_TO_DATE = "SET_PRICING_ANALYTICS_TO_DATE";
export const SET_POPULATED_PRICING_MODELS = "SET_POPULATED_PRICING_MODELS";

export const GET_CURRIENCIES_START = "GET_CURRIENCIES_START";
export const GET_CURRIENCIES = "GET_CURRIENCIES";
export const GET_CURRIENCIES_ERROR = "GET_CURRIENCIES_ERROR";
export const UPDATE_MIN_VIEWS_START = "UPDATE_MIN_VIEWS_START";
export const UPDATE_MIN_VIEWS = "UPDATE_MIN_VIEWS";
export const UPDATE_MIN_VIEWS_ERROR = "UPDATE_MIN_VIEWS_ERROR";
export const UPDATE_CONTAINER_SOURCES_START = "UPDATE_CONTAINER_SOURCES_START";
export const UPDATE_CONTAINER_SOURCES = "UPDATE_CONTAINER_SOURCES";
export const UPDATE_CONTAINER_SOURCES_ERROR = "UPDATE_CONTAINER_SOURCES_ERROR";

export const SWITCH_SIDE_MENU = "SWITCH_SIDE_MENU";

export const GET_FF_FUNNELS_START = "GET_FF_FUNNELS_START";
export const GET_FF_FUNNELS = "GET_FF_FUNNELS";
export const GET_FF_FUNNELS_ERROR = "GET_FF_FUNNELS_ERROR";
export const ADD_FF_FUNNEL_START = "ADD_FF_FUNNEL_START";
export const ADD_FF_FUNNEL = "ADD_FF_FUNNEL";
export const ADD_FF_FUNNEL_ERROR = "ADD_FF_FUNNEL_ERROR";
export const DELETE_FF_FUNNEL_START = "DELETE_FF_FUNNEL_START";
export const DELETE_FF_FUNNEL = "DELETE_FF_FUNNEL";
export const DELETE_FF_FUNNEL_ERROR = "DELETE_FF_FUNNEL_ERROR";
export const GET_FF_REPORT_START = "GET_FF_REPORT_START";
export const GET_FF_REPORT = "GET_FF_REPORT";
export const GET_FF_REPORT_DATA = "GET_FF_REPORT_DATA";
export const GET_FF_REPORT_PROGRESS = "GET_FF_REPORT_PROGRESS";
export const GET_FF_REPORT_ERROR = "GET_FF_REPORT_ERROR";
export const GET_GOOGLE_REPORTS_START = "GET_GOOGLE_REPORTS_START";
export const GET_GOOGLE_REPORTS = "GET_GOOGLE_REPORTS";
export const GET_GOOGLE_REPORTS_ERROR = "GET_GOOGLE_REPORTS_ERROR";
export const UPDATE_GOOGLE_CAMPAIGNS_START = "UPDATE_GOOGLE_CAMPAIGNS_START";
export const UPDATE_GOOGLE_CAMPAIGNS = "UPDATE_GOOGLE_CAMPAIGNS";
export const UPDATE_GOOGLE_CAMPAIGNS_ERROR = "UPDATE_GOOGLE_CAMPAIGNS_ERROR";
export const SET_EDIT_FUNNEL_ID = "SET_EDIT_FUNNEL_ID";
export const PULL_BALANCER_JOBS_START = "PULL_BALANCER_JOBS_START";
export const PULL_BALANCER_JOBS = "PULL_BALANCER_JOBS";
export const PULL_BALANCER_JOBS_ERROR = "PULL_BALANCER_JOBS_ERROR";
export const BALANCE_CONVERSIONS_START = "BALANCE_CONVERSIONS_START";
export const BALANCE_CONVERSIONS = "BALANCE_CONVERSIONS";
export const BALANCE_CONVERSIONS_ERROR = "BALANCE_CONVERSIONS_ERROR";

export const GET_SUBSCRIPTIONS_START = "GET_SUBSCRIPTIONS_START";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_ERROR = "GET_SUBSCRIPTIONS_ERROR";
export const ADD_SUBSCRIPTION_START = "ADD_SUBSCRIPTION_START";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_SUBSCRIPTION_ERROR = "ADD_SUBSCRIPTION_ERROR";

export const CC_DRIP_REPORT_START = "CC_DRIP_REPORT_START";
export const CC_DRIP_REPORT = "CC_DRIP_REPORT";
export const CC_DRIP_REPORT_ERROR = "CC_DRIP_REPORT_ERROR";
