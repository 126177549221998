export type server = {
    _id: string;
    host: string;
    domain: string;
    password: string;
    username: string;
    pixels: pixels;
    campaignKeys: campaignKeys;
    otherSettings: otherSettings;
};

export type pixels = {
    dmca: string;
    facebook: string;
    googleAds: string;
    googleAdsConversion: string;
    googleAnalytics: string;
    googleTagManager: string;
    pinterest: string;
    sentry: string;
    snapchat: string;
    twitter: string;
    ejamPixel: string;
    yahoo: string;
    bingPixel: string;
    storeCashId: string;
    segment: string;
    addStoreCashTag: boolean;
};

export type campaignKeys = {
    [key: string]: {
        [country: string]: string;
    };
};

export interface otherSettings {
    paypalBillerId: number;
    chatBotId: number;
    chatAppId: number;
    chatPageId: number;
    xCallyLogin: string;
    xCallyPassword: string;
    xcallyCompanyId: number;
    xCallyPartialsId: number;
    xCallyDeclinedId: number;
    xCallyOrderConfirmationId: number;
    xCallyDncListId: string;
    ebanxMerchantPaymentCode: string;
    shopifyLogin: string;
    shopifyPassword: string;
    shopifyStoreName: string;
    smartlook: string;
    yotpoAppKey: string;
    yotpoProductId: string;
    sezzleMerchantId: number;
    ebanxMerchantId: number;
    sandboxModeSecretKey: string;
    klaviyoApiKey: string;
    klaviyoAccountId: string;
    useStagingApi: boolean;
    useDevMode: boolean;
    productGroupId: number;
    googleMapKey: string;
    gorgiasAppId: string;
    smartyStreets: string;
    mulberry: string;
    useEjamAbstra: boolean;
}

export const validKeys: (keyof otherSettings)[] = [
    "paypalBillerId",
    "chatBotId",
    "chatAppId",
    "chatPageId",
    "xCallyLogin",
    "xCallyPassword",
    "xcallyCompanyId",
    "xCallyPartialsId",
    "xCallyDeclinedId",
    "xCallyOrderConfirmationId",
    "xCallyDncListId",
    "ebanxMerchantPaymentCode",
    "shopifyLogin",
    "shopifyPassword",
    "shopifyStoreName",
    "smartlook",
    "yotpoAppKey",
    "yotpoProductId",
    "sezzleMerchantId",
    "ebanxMerchantId",
    "sandboxModeSecretKey",
    "klaviyoApiKey",
    "klaviyoAccountId",
    "productGroupId",
    "googleMapKey",
    "gorgiasAppId",
    "useStagingApi",
    "useDevMode",
    "smartyStreets",
    "mulberry",
    "useEjamAbstra"
];
