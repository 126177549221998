import * as React from "react";
import * as _ from "lodash";
import Button from "components/Button";
import Product from "../Product";
import styles from "./styles.scss";
import { PricingModelContext } from "../..";
import { PricingContext } from "../../../..";
import { ExtendedPricingProduct } from "types/pricing";
import { useDispatch } from "react-redux";
import { addPricingModelProductDraft, reorderPricingModelProductDraft } from "../../../../actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";

interface Props {
    products: ExtendedPricingProduct[];
    type: "PRODUCT" | "BUNDLE";
}

const Products: React.FC<Props> = ({ products = [], type }) => {
    const { isDraft } = React.useContext(PricingModelContext);
    const { languageId, setId, versionId, schema } = React.useContext(PricingContext);
    const dispatch = useDispatch();

    const handleAddProduct = () => {
        dispatch(addPricingModelProductDraft(languageId, versionId, setId, schema, "PRODUCT"));
    };

    const onDragEnd = (result: any) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        dispatch(reorderPricingModelProductDraft(source.index, destination.index, draggableId));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={isDraft === false}>
                {provided => (
                    <main ref={provided.innerRef} {...provided.droppableProps}>
                        {products.map((product: ExtendedPricingProduct, index: number) => (
                            <Draggable
                                index={index}
                                draggableId={String(product._id)}
                                isDragDisabled={isDraft === false}
                                key={product._id || uuid()}
                            >
                                {provided => (
                                    <Product
                                        product={product}
                                        provided={provided}
                                        isElements={type === "BUNDLE"}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {isDraft && (
                            <div className={styles.row}>
                                <span />
                                <Button
                                    design="circle"
                                    icon="add"
                                    size="small"
                                    background="purple"
                                    onClick={handleAddProduct}
                                />
                            </div>
                        )}
                    </main>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default Products;
