import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

export function getFunnels() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_FF_FUNNELS_START
        });

        api()
            .get("conversionbalancer/fffunnel")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_FF_FUNNELS,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_FF_FUNNELS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getReports() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_GOOGLE_REPORTS_START
        });

        api()
            .get("conversionbalancer/reports")
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_GOOGLE_REPORTS,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_GOOGLE_REPORTS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}
