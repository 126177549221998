import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function getSpend(from: any, to: any, adAccount: string) {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.GET_SPEND_START });
        api()
            .post("fbspend", { adAccount, from, to })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_SPEND,
                    payload: { spend: res.data[0] }
                });
            })
            .catch((err: AxiosError) => {
                const error = err.response ? err.response.data || "" : err.response;
                dispatch({ type: actionTypes.GET_SPEND_ERROR, payload: { error } });
            });
    };
}
