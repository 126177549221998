import * as React from "react";
import CDNCloud from "../../../../scenes/Dashboard/CDNCloud";
import Modal from "../../index";

interface Props {
    handleChooseFile: (file: string) => void;
    onClose?: () => void;
}

class CDNModal extends React.PureComponent<Props, any> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            isModalOpen: false
        };

        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({ isModalOpen: true });
    }

    handleCloseModal() {
        const { onClose } = this.props;

        this.setState({ isModalOpen: false });

        if (onClose) {
            onClose();
        }
    }

    render() {
        const { handleChooseFile } = this.props;
        const { isModalOpen } = this.state;

        if (!isModalOpen) return null;

        return (
            <Modal onClose={this.handleCloseModal.bind(this)} title="Cloud CDN">
                <CDNCloud
                    handleChooseFile={(arg: any) => {
                        handleChooseFile(arg);
                        this.handleCloseModal();
                    }}
                    fileView="wide"
                    mode="popup"
                />
            </Modal>
        );
    }
}

export default CDNModal;
