import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";
import SVG from "react-inlinesvg";
import magnifier from "assets/icons/magnifier.svg";
import LoadingIndicator from "components/LoadingIndicator";
const { useState, useEffect, useRef } = React;

export type OptionType = {
    name: string;
    value: string;
    isDisabled?: boolean;
};

export type OptionsType = OptionType[];

interface Props {
    options?: OptionsType;
    selectedOptions?: string[];
    setSelectedOptions?: React.Dispatch<React.SetStateAction<string[]>>;
    onChange?: any;
    initialValue?: string;
    isDisabled?: boolean;
    placeholder?: string;
    height?: number;
    fontSize?: number;
    width?: number | null;
    forceValue?: string;
    resetOnSelect?: boolean;
    isLoading?: boolean;
    zIndex?: number;
}

const SearchList: React.FC<Props> = ({
    options = [{ name: "No options", value: "" }],
    selectedOptions,
    setSelectedOptions,
    onChange,
    initialValue,
    isDisabled = false,
    placeholder = "Search product...",
    width = null,
    height = 50,
    fontSize = 18,
    forceValue,
    resetOnSelect = false,
    isLoading = false,
    zIndex = 1
}) => {
    let [selected, setSelected] = useState(initialValue);
    if (forceValue !== undefined) {
        selected = forceValue;
    }

    const [searcherValue, setSearcherValue] = useState("");
    let [editMode, setEditMode] = useState(false);
    const editModeRef = useRef<boolean>(editMode);

    const updateEditMode = (newValue: boolean) => {
        editModeRef.current = newValue;
        setEditMode(newValue);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { value } = e.currentTarget;
        setSearcherValue(value.toLowerCase().replace(/  +/g, " "));
    };

    const handleSelect = (value: string, e: React.MouseEvent<HTMLDivElement>) => {
        if (!selectableOptionsEnabled) {
            e.preventDefault();
            updateEditMode(false);

            if (forceValue === undefined) {
                setSelected(value);
            }

            setSearcherValue("");

            if (onChange) {
                onChange(null, value);
            }

            if (resetOnSelect) {
                setSelected(initialValue);
            }
        }
    };

    const handleEditMode = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isDisabled && !editMode) {
            updateEditMode(true);
        }
    };

    useEffect(() => {
        const handleWindowClick = () => {
            updateEditMode(false);
        };

        window.addEventListener("click", handleWindowClick);

        return () => {
            window.removeEventListener("click", handleWindowClick);
        };
    }, []);

    const selectedOption = options.find(option => option.value === selected);
    const selectedName = selectedOption
        ? selectedOption.name
        : (selectedOptions?.length || 0) > 0
        ? `${selectedOptions?.length || 0} options selected`
        : placeholder;

    const filteredOptions = options.filter(option =>
        searcherValue
            .split(" ")
            .reduce(
                (result, keyword) => result && option.name.toLowerCase().includes(keyword),
                true
            )
    );

    const searchBoxStyles: any = {
        zIndex: 10 * zIndex
    };

    if (editMode) {
        searchBoxStyles.height = 51 + Math.min(filteredOptions.length, 5) * 39 + "px";
    } else {
        searchBoxStyles.height = height + "px";
    }

    if (width) {
        searchBoxStyles.width = width + "px";
    }

    const selectableOptionsEnabled = !!selectedOptions;

    const optionsValues = options.map(option => option.value);

    const handleSelectAll = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedOptions &&
            setSelectedOptions(oldSelectedOptions =>
                optionsValues.length === oldSelectedOptions.length ? [] : optionsValues
            );
    };

    const handleAddSelectedOption = (value: string) => (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedOptions &&
            setSelectedOptions(oldSelectedOptions => {
                const shouldAdd = !oldSelectedOptions.find(
                    oldSelectedOption => oldSelectedOption === value
                );

                const uniqueOptions = [...new Set([...oldSelectedOptions, value])];

                return shouldAdd
                    ? uniqueOptions
                    : oldSelectedOptions.filter(option => option !== value);
            });
    };

    return (
        <div
            className={cx(styles.searchBox, {
                [styles.clickable]: !editMode && !isDisabled,
                [styles.disabled]: isDisabled,
                [styles.editMode]: editMode,
                [styles.loading]: isLoading
            })}
            style={searchBoxStyles}
            onClick={handleEditMode}
        >
            {editMode && !isDisabled ? (
                <>
                    <div className={styles.inputBox}>
                        {selectableOptionsEnabled && (
                            <div
                                onClick={handleSelectAll}
                                className={cx(styles.selectBox, {
                                    [styles.active]: selectedOptions?.length === options.length
                                })}
                            >
                                <div className={styles.icon}></div>
                            </div>
                        )}

                        <div className={styles.magnifier}>
                            <SVG src={magnifier} />
                        </div>
                        <input
                            type="text"
                            value={searcherValue}
                            onChange={handleInput}
                            className={styles.searcher}
                            autoFocus={true}
                            placeholder={placeholder}
                            style={{
                                height: 50 + "px",
                                lineHeight: 50 + "px",
                                fontSize: fontSize + "px",
                                padding: `0 ${fontSize}px`
                            }}
                        />
                    </div>
                    {selectableOptionsEnabled && <div className={styles.options}>xxx</div>}
                    <div className={styles.options} style={{ zIndex: 20 * zIndex }}>
                        {filteredOptions.map(option => {
                            const isActive = !option.isDisabled;

                            return (
                                <div
                                    key={`option-${option.value}`}
                                    className={cx(styles.option, {
                                        [styles.active]: isActive
                                    })}
                                    onClick={
                                        isActive ? handleSelect.bind(null, option.value) : () => {}
                                    }
                                    title={option.name}
                                >
                                    {selectableOptionsEnabled && (
                                        <div
                                            className={cx(styles.selectBox, {
                                                [styles.active]: selectedOptions?.includes(
                                                    option.value
                                                )
                                            })}
                                            onClick={
                                                isActive
                                                    ? handleAddSelectedOption(option.value)
                                                    : () => {}
                                            }
                                        >
                                            <div className={styles.icon}></div>
                                        </div>
                                    )}
                                    <div className={styles.name}>{option.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : isLoading ? (
                <LoadingIndicator size={24} />
            ) : (
                <div
                    className={styles.searcher}
                    title={selectedName}
                    style={{
                        height: height + "px",
                        lineHeight: height + "px",
                        fontSize: fontSize + "px",
                        padding: `0 ${fontSize}px`
                    }}
                >
                    {selectedName}
                </div>
            )}
        </div>
    );
};

export default SearchList;
