import * as actionTypes from "actionTypes";

export type whitelistStateType = {
    whitelist: { _id: string; name: string; isSDK: boolean }[];
    isLoading: boolean;
    error: object;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: whitelistStateType = {
    whitelist: [],
    isLoading: false,
    error: {}
};

export const whitelistStorage: { [key: string]: boolean } = {
    whitelist: true,
    error: true
};

export default (state: whitelistStateType = initialState, action: IAction): whitelistStateType => {
    switch (action.type) {
        case actionTypes.GET_WHITELIST_START:
            return {
                ...state,
                whitelist: [],
                error: {},
                isLoading: true
            };
        case actionTypes.GET_WHITELIST:
            return {
                ...state,
                whitelist: action.payload.whitelist,
                isLoading: false
            };
        case actionTypes.GET_WHITELIST_ERROR:
            return {
                ...state,
                whitelist: [],
                error: action.payload.error,
                isLoading: false
            };
        case actionTypes.ADD_NEW_DOMAIN_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.ADD_NEW_DOMAIN:
            return {
                ...state,
                whitelist: [...state.whitelist, action.payload.domain],
                isLoading: false
            };
        case actionTypes.ADD_NEW_DOMAIN_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case actionTypes.REMOVE_NEW_DOMAIN_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.REMOVE_NEW_DOMAIN:
            return {
                ...state,
                whitelist: state.whitelist.filter(
                    (domain: any) => domain._id != action.payload.domain._id
                ),
                isLoading: false
            };
        case actionTypes.REMOVE_NEW_DOMAIN_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        default:
            return state;
    }
};
