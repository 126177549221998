import edit from "assets/icons/edit.svg";
import trash from "assets/icons/trash.svg";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import CDNModal from "components/Modal/scenes/CDN";
import SetSelector from "components/Modal/scenes/SetSelector";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appStateType } from "reducers";
import { getFileObject } from "services/getFileObject";
import { brand } from "types/brand";
import { productGroup } from "types/productGroup";
import { set } from "types/set";
import File from "../CDNCloud/components/File";
import { getProductGroups } from "../ProductGroup/actions";
import { addBrand, getBrands, removeBrand } from "./actions";
import styles from "./styles.scss";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ActionMeta } from "react-select";
import SwitchButton from "components/SwitchButton";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

const defaultBrand: brand = {
    name: "",
    logo: "",
    sets: [],
    yotpoAppKey: "",
    yotpoSecretKey: "",
    fbPixelId: "",
    fbPixelAccessKey: "",
    fbAppSecret: "",
    odooBrandId: "",
    odooBrandIdProd: "",
    odooBrandIdDev: "",
    portalDomain: "",
    portalContentSet: "",
    campaignKeys: [],
    productGroups: [],
    isPortalActive: false,
    type: "PRODUCT_GROUP",
    parentBrand: "",
    klaviyoConsentList: ""
};

interface column {
    columnName: string;
    key: string;
    inputType:
        | "TEXT"
        | "FILE"
        | "SET"
        | "PORTAL_SET"
        | "PORTAL_DOMAIN"
        | "CAMPAIGN_KEYS"
        | "SWITCH"
        | "PRODUCT_GROUP"
        | "TYPE"
        | "PARENT_BRAND";
}

const columns: column[] = [
    {
        columnName: "ID",
        key: "_id",
        inputType: "TEXT"
    },
    {
        columnName: "Name",
        key: "name",
        inputType: "TEXT"
    },
    {
        columnName: "Logo",
        key: "logo",
        inputType: "FILE"
    },
    {
        columnName: "Sets",
        key: "sets",
        inputType: "SET"
    },
    {
        columnName: "Yotpo App Key",
        key: "yotpoAppKey",
        inputType: "TEXT"
    },
    {
        columnName: "Yotpo Secret key",
        key: "yotpoSecretKey",
        inputType: "TEXT"
    },
    {
        columnName: "Facebook Pixel Id",
        key: "fbPixelId",
        inputType: "TEXT"
    },
    {
        columnName: "Facebook Pixel Access Key",
        key: "fbPixelAccessKey",
        inputType: "TEXT"
    },
    {
        columnName: "Facebook App Secret",
        key: "fbAppSecret",
        inputType: "TEXT"
    },
    {
        columnName: "Odoo Brand ID",
        key: "odooBrandId",
        inputType: "TEXT"
    },
    {
        columnName: "Odoo Production Brand ID",
        key: "odooBrandIdProd",
        inputType: "TEXT"
    },
    {
        columnName: "Odoo Development Brand ID",
        key: "odooBrandIdDev",
        inputType: "TEXT"
    },
    {
        columnName: "Product Groups",
        key: "productGroups",
        inputType: "PRODUCT_GROUP"
    },
    {
        columnName: "Enable Customer Portal",
        key: "isPortalActive",
        inputType: "SWITCH"
    },
    {
        columnName: "Portal Domain",
        key: "portalDomain",
        inputType: "PORTAL_DOMAIN"
    },
    {
        columnName: "Portal Content Set",
        key: "portalContentSet",
        inputType: "PORTAL_SET"
    },
    {
        columnName: "Campaign Keys",
        key: "campaignKeys",
        inputType: "CAMPAIGN_KEYS"
    },

    {
        columnName: "Type",
        key: "type",
        inputType: "TYPE"
    },
    {
        columnName: "Parent Brand",
        key: "parentBrand",
        inputType: "PARENT_BRAND"
    },
    {
        columnName: "Klaviyo Consent List",
        key: "klaviyoConsentList",
        inputType: "TEXT"
    }
];

const Brand: React.FC = () => {
    const [brand, setBrand] = React.useState<brand>(defaultBrand);
    const brandReducer = useSelector((state: appStateType) => state.brand);
    const sets = useSelector((state: appStateType) => state.sets.sets);
    const domains = useSelector((state: appStateType) => state.whitelist.whitelist);
    const productGroups = useSelector((state: appStateType) => state.productGroup.productGroups);
    const { addBrandLoading, brands, isLoading } = brandReducer;
    const dispatch = useDispatch();
    const isDev = process.env.NODE_ENV === "development";
    const isBrandId = brand.hasOwnProperty("_id");
    const cdnRef = React.useRef(null);
    const setRef = React.useRef(null);
    const [selectedPortalSet, setPortalSet] = React.useState<null | SelectOption>(null);
    const [selectedPortalDomain, setPortalDomain] = React.useState<null | SelectOption>(null);
    const [selectedProductGroups, setProductGroups] = React.useState<SelectOption[]>([]);
    const [selectedBrandType, setBrandType] = React.useState<null | SelectOption<brand["type"]>>(
        null
    );
    const [selectedParentBrand, setParentBrand] = React.useState<null | SelectOption>(null);

    const parentBrands = brands.filter(el => el.type === "PARENT" && el._id !== brand._id);

    React.useEffect(() => {
        dispatch(getBrands());
        dispatch(getProductGroups());
    }, []);

    React.useEffect(() => {
        if (addBrandLoading === false) {
            setBrand(defaultBrand);
            setProductGroups([]);
            setPortalDomain(null);
            setPortalSet(null);
            setBrandType(null);
            setParentBrand(null);
        }
    }, [addBrandLoading]);

    const handleEdit = (brandId: string) => {
        const brand = brands.find((brand: brand) => brand._id === brandId);

        if (brand) {
            setBrand(brand);
            const portalSet = sets.find(el => el._id === brand.portalContentSet);
            const portalDomain = domains.find(el => el._id === brand.portalDomain);

            const portalSetOption = portalSet
                ? { value: portalSet._id, label: portalSet.name }
                : null;
            const portalDomainOption = portalDomain
                ? { value: portalDomain._id, label: portalDomain.name }
                : null;

            const productGroupOptions = brand.productGroups.map(el => {
                const productGroup = productGroups.find(el2 => el2._id === el);

                return {
                    label: `${productGroup?.id}(${productGroup?.name})`,
                    value: String(productGroup?._id)
                };
            });

            setProductGroups(productGroupOptions);

            setPortalDomain(portalDomainOption);
            setPortalSet(portalSetOption);

            if (brand.type) {
                setBrandType({ value: brand.type, label: brand.type });
            }

            if (brand.parentBrand) {
                const selectedParentBrand = parentBrands.find(el => el._id === brand.parentBrand);

                if (selectedParentBrand) {
                    const { _id, name } = selectedParentBrand;
                    setParentBrand({ value: _id as string, label: name });
                } else {
                    setParentBrand(null);
                    setBrand({ ...brand, parentBrand: null });
                }
            }
            window.scrollTo(0, 0);
        } else {
            setBrand(defaultBrand);
            setPortalDomain(null);
            setProductGroups([]);
            setPortalSet(null);
            setBrandType(null);
            setParentBrand(null);
        }
    };

    const handleChange = (key: string, value: any) => {
        const newBrand = { ...brand };
        newBrand[key] = value;
        setBrand(newBrand);
    };

    const handleOpenModal = (ref: any) => {
        if (ref.current) {
            ref.current.openModal();
        }
    };

    const handleSelect = (
        selectedOption: SelectOption | SelectOption[],
        config: ActionMeta<{ name: string; action: string }>
    ) => {
        switch (config.name) {
            case "portalContentSet":
                setPortalSet(selectedOption as SelectOption);
                setBrand({
                    ...brand,
                    [config.name]: selectedOption ? (selectedOption as SelectOption).value : null
                });
                break;
            case "portalDomain":
                setPortalDomain(selectedOption as SelectOption);
                setBrand({
                    ...brand,
                    [config.name]: selectedOption ? (selectedOption as SelectOption).value : null
                });
                break;
            case "productGroups":
                setProductGroups(selectedOption as SelectOption[]);
                setBrand({
                    ...brand,
                    [config.name]: selectedOption
                        ? (selectedOption as SelectOption[]).map(el => String(el.value))
                        : []
                });
                break;
            case "type":
                setBrandType(selectedOption as SelectOption<brand["type"]>);
                setBrand({
                    ...brand,
                    [config.name]: selectedOption
                        ? (selectedOption as SelectOption<brand["type"]>).value
                        : (null as unknown as brand["type"])
                });
                break;
            case "parentBrand":
                setParentBrand(selectedOption as SelectOption);
                setBrand({
                    ...brand,
                    [config.name]: selectedOption ? (selectedOption as SelectOption).value : null
                });
                break;
            default:
                break;
        }
    };

    const handleCancel = () => {
        setBrand(defaultBrand);
        setProductGroups([]);
        setPortalDomain(null);
        setPortalSet(null);
        setBrandType(null);
        setParentBrand(null);
    };

    const handleSave = () => {
        if (addBrandLoading === true || brand.name === "") return;

        const campaignKeys = brand.campaignKeys.filter(Boolean);

        if (!brand.type || (brand.type === "PRODUCT_GROUP" && !brand.parentBrand)) {
            return;
        }

        if (
            brand.type === "PARENT" &&
            brand.isPortalActive &&
            (!brand.portalDomain || !brand.portalContentSet)
        ) {
            return;
        }

        dispatch(
            addBrand({
                ...brand,
                campaignKeys
            })
        );
    };

    const getInput = (column: column) => {
        switch (column.inputType) {
            case "TEXT":
                return (
                    <input
                        className={styles.field}
                        disabled={column.key === "_id"}
                        value={brand[column.key] || ""}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleChange(column.key, e.currentTarget.value)
                        }
                        placeholder={column.columnName}
                    />
                );
            case "FILE":
                return (
                    <>
                        <CDNModal
                            ref={cdnRef}
                            handleChooseFile={(file: any) => {
                                const { hash, extensions, path } = file;
                                const size = "";

                                const newVariant = `hash=${hash}#extensions=${extensions.join(
                                    "|"
                                )}#size=${size}#path=${path}`;

                                handleChange(column.key, newVariant);
                            }}
                        />
                        <Button onClick={() => handleOpenModal(cdnRef)} flat marginRight>
                            {brand[column.key] ? "Selected" : "Add logo"}
                        </Button>
                    </>
                );
            case "SET":
                return (
                    <>
                        <SetSelector
                            ref={setRef}
                            handleChooseSet={(sets: set[]) => {
                                handleChange(column.key, sets);
                            }}
                            selectedSets={brand[column.key] || []}
                        />
                        <Button onClick={() => handleOpenModal(setRef)} flat marginLeft>
                            {brand[column.key]
                                ? `Selected ${brand[column.key].length} sets`
                                : "Select sets"}
                        </Button>
                    </>
                );
            case "PORTAL_SET":
                return (
                    <div className={styles.customSelect}>
                        <Select
                            options={sets.map(el => ({ value: el._id, label: el.name }))}
                            components={animatedComponents}
                            name="portalContentSet"
                            placeholder="Select Portal ContentSet"
                            onChange={handleSelect}
                            value={selectedPortalSet}
                            isClearable
                            isSearchable
                            styles={{
                                menu: () => ({
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    overflowX: "hidden"
                                })
                            }}
                        />
                    </div>
                );
            case "PORTAL_DOMAIN":
                return (
                    <div className={styles.customSelect}>
                        <Select
                            options={domains.map(el => ({ value: el._id, label: el.name }))}
                            components={animatedComponents}
                            name="portalDomain"
                            placeholder="Select Portal Domain"
                            onChange={handleSelect}
                            value={selectedPortalDomain}
                            isClearable
                            isSearchable
                            styles={{
                                menu: () => ({
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    overflowX: "hidden"
                                })
                            }}
                        />
                    </div>
                );
            case "CAMPAIGN_KEYS":
                return (
                    <input
                        className={styles.field}
                        value={brand[column.key].join(",") || ""}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleChange(column.key, e.currentTarget.value.split(","))
                        }
                        placeholder={column.columnName}
                    />
                );
            case "PRODUCT_GROUP":
                return (
                    <div className={styles.customSelect}>
                        <Select
                            options={productGroups.map(el => ({
                                value: el._id,
                                label: `${el.id}(${el.name})`
                            }))}
                            components={animatedComponents}
                            name="productGroups"
                            placeholder="Select Product Groups"
                            onChange={handleSelect}
                            value={selectedProductGroups}
                            isClearable
                            isSearchable
                            isMulti
                            styles={{
                                menu: () => ({
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    overflowX: "hidden"
                                })
                            }}
                        />
                    </div>
                );
            case "SWITCH":
                return (
                    <div className={styles.customSwitch}>
                        <p>{column.columnName}</p>
                        <SwitchButton
                            isActive={brand[column.key] || false}
                            onSwitch={(e, isActive) => {
                                handleChange(column.key, isActive);
                            }}
                            disabled={false}
                            className={styles.sdkSwitch}
                        />
                    </div>
                );
            case "TYPE":
                return (
                    <div className={styles.customSelect}>
                        <Select
                            options={[
                                { value: "PARENT", label: "PARENT" },
                                { value: "PRODUCT_GROUP", label: "PRODUCT_GROUP" }
                            ]}
                            components={animatedComponents}
                            name="type"
                            placeholder="Select Brand Type"
                            onChange={handleSelect}
                            value={selectedBrandType}
                            isClearable
                            styles={{
                                menu: () => ({
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    overflowX: "hidden"
                                })
                            }}
                        />
                    </div>
                );
            case "PARENT_BRAND":
                return (
                    <div className={styles.customSelect}>
                        <Select
                            options={parentBrands.map(el => ({ value: el._id, label: el.name }))}
                            components={animatedComponents}
                            name="parentBrand"
                            placeholder="Select Parent Brand"
                            onChange={handleSelect}
                            value={selectedParentBrand}
                            isClearable
                            isSearchable
                            styles={{
                                menu: () => ({
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    overflowX: "hidden"
                                })
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <header className={styles.header}>
                <h1>Ejam Brands</h1>
            </header>
            <main>
                <section className={styles.section}>
                    <div className={styles.form}>
                        {columns.map(col => (
                            <React.Fragment key={col.key}>{getInput(col)}</React.Fragment>
                        ))}
                        <Button
                            background="green"
                            className={styles.button}
                            disabled={addBrandLoading}
                            flat
                            onClick={handleSave}
                        >
                            {addBrandLoading ? (
                                <LoadingIndicator size={18} />
                            ) : isBrandId ? (
                                "Save Brand"
                            ) : (
                                "Add New Brand"
                            )}
                        </Button>
                        {isBrandId && (
                            <Button
                                className={styles.button}
                                disabled={addBrandLoading}
                                onClick={handleCancel}
                                flat
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {isDev && <th>Remove</th>}
                                <th>Edit</th>
                                {columns.map(col => (
                                    <th key={col.key}>{col.columnName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {brands.map((brand: brand, index: number) => (
                                <tr key={`${brand.name}-${index}`}>
                                    {isDev && (
                                        <td>
                                            <Button
                                                onClick={() =>
                                                    dispatch(removeBrand(brand._id as string))
                                                }
                                            >
                                                <img src={trash} />
                                            </Button>
                                        </td>
                                    )}
                                    <td>
                                        <Button onClick={() => handleEdit(brand._id || "")}>
                                            <img src={edit} />
                                        </Button>
                                    </td>
                                    {columns.map(col => {
                                        if (col.inputType === "TEXT") {
                                            return <td key={col.key}>{brand[col.key]}</td>;
                                        }

                                        if (col.inputType === "FILE") {
                                            return (
                                                <td key={col.key}>
                                                    <File
                                                        fileView="wide"
                                                        file={getFileObject(brand[col.key] || "")}
                                                    />
                                                </td>
                                            );
                                        }

                                        if (col.inputType === "SET") {
                                            const arr = brand[col.key] || [];
                                            return (
                                                <td key={col.key}>
                                                    <ul>
                                                        {arr.map((setId: string) => {
                                                            const set = sets.find(
                                                                (set: set) => set._id === setId
                                                            );

                                                            if (set) {
                                                                return (
                                                                    <li key={setId}>{set.name}</li>
                                                                );
                                                            }

                                                            return null;
                                                        })}
                                                    </ul>
                                                </td>
                                            );
                                        }

                                        if (col.inputType === "PRODUCT_GROUP") {
                                            const arr = brand[col.key] || [];
                                            return (
                                                <td key={col.key}>
                                                    <ul>
                                                        {arr.map((productGroupId: string) => {
                                                            const productGroup = productGroups.find(
                                                                (productGroup: productGroup) =>
                                                                    productGroup._id ===
                                                                    productGroupId
                                                            );

                                                            if (productGroup) {
                                                                return (
                                                                    <li
                                                                        title={String(
                                                                            productGroup.id
                                                                        )}
                                                                        key={productGroupId}
                                                                    >
                                                                        {productGroup.name}
                                                                    </li>
                                                                );
                                                            }

                                                            return null;
                                                        })}
                                                    </ul>
                                                </td>
                                            );
                                        }

                                        if (col.inputType === "PORTAL_SET") {
                                            const val = brand[col.key] || "";
                                            const set = sets.find(set => set._id === val);

                                            return <td key={col.key}>{set ? set.name : "NA"}</td>;
                                        }

                                        if (col.inputType === "PORTAL_DOMAIN") {
                                            const val = brand[col.key] || "";
                                            const domain = domains.find(
                                                domain => domain._id === val
                                            );

                                            return (
                                                <td key={col.key}>{domain ? domain.name : "NA"}</td>
                                            );
                                        }

                                        if (col.inputType === "CAMPAIGN_KEYS") {
                                            const val = brand[col.key] || [];

                                            return <td key={col.key}>{val.join(",")}</td>;
                                        }

                                        if (col.inputType === "SWITCH") {
                                            const val = brand[col.key] || false;

                                            return <td key={col.key}>{val ? "True" : "False"}</td>;
                                        }

                                        if (col.inputType === "PARENT_BRAND") {
                                            const val = brand[col.key];

                                            const parentBrand = parentBrands.find(
                                                el => el._id === val
                                            );
                                            return (
                                                <td key={col.key}>
                                                    {parentBrand ? parentBrand.name : "NA"}
                                                </td>
                                            );
                                        }

                                        if (col.inputType === "TYPE") {
                                            return <td key={col.key}>{brand[col.key] || "NA"}</td>;
                                        }

                                        return <td key={col.key}></td>;
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {isLoading && brands.length === 0 ? <LoadingIndicator /> : null}
                </section>
            </main>
        </>
    );
};

export default Brand;
