import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { appStateType } from "reducers";
import { Dispatch } from "redux";
import { setCurrentSchema } from "scenes/Dashboard/SchemaCMSEditor/actions";

type Version = {
    _id: string;
    version: string;
    title: string;
    description: string;
};

export const pullVersions = () => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.PULL_VERSIONS_START
        });

        api()
            .get("version")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_VERSIONS,
                    payload: { versions: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.PULL_VERSIONS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
};

export const pullVersion = (versionId: string) => {
    return (dispatch: Dispatch) => {
        if (!versionId) {
            console.log("versionId is required");
            return;
        }

        api()
            .get(`version/${versionId}`)
            .then((res: AxiosResponse) => {
                const { version } = res.data;
                dispatch({
                    type: actionTypes.PULL_CMS_VERSION,
                    payload: { version }
                });

                dispatch<any>(setCurrentSchema(version._id));
            })
            .catch((err: AxiosError) => {
                console.log("GET CMS VERSION ERROR: ", err.message);
            });
    };
};

export function saveVersion({ _id, version, title, description }: Version) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        dispatch({
            type: actionTypes.SAVE_VERSION_START
        });

        api()
            .post("server", { _id, version, title, description })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.SAVE_VERSION,
                    payload: { version: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.SAVE_VERSION_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}
