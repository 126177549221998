import React, { useEffect } from "react";
import styles from "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { appStateType } from "reducers";
import { pullBalancerJobs } from "./actions";
import JsonFormatter from "components/JsonFormatter";
import LoadingIndicator from "components/LoadingIndicator";

type Props = {};

const FunnelsList: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const { isPullingBalancerJobs, archivedBalancerJobs } = useSelector(
        (state: appStateType) => state.conversionbalancer
    );

    useEffect(() => {
        dispatch(pullBalancerJobs());
    }, []);

    return (
        <>
            {isPullingBalancerJobs ? (
                <div className={styles.info}>
                    <LoadingIndicator />
                </div>
            ) : archivedBalancerJobs.length > 0 ? (
                <div className={styles.jobs}>
                    {archivedBalancerJobs.map(balancerJob => (
                        <div className={styles.job} key={balancerJob._id}>
                            <div>Created at: {balancerJob.createdAt}</div>
                            <div>Created by: {balancerJob.user.login}</div>
                            <JsonFormatter
                                headline={"Created conversions:"}
                                body={balancerJob.createdGoogleConversions}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.info}>No balancer jobs created yet.</div>
            )}
        </>
    );
};

export default FunnelsList;
