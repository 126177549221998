import * as types from "actionTypes";
import { set } from "types/set";

export type setsStateType = {
    sets: set[];
    getSetsLoading: boolean;
    addSetLoading: boolean;
    editSetLoadingIds: string[];
    pullBlock: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: setsStateType = {
    sets: [],
    getSetsLoading: false,
    addSetLoading: false,
    editSetLoadingIds: [],
    pullBlock: false
};

export const setsStorage: { [key: string]: boolean } = {
    sets: true
};

export default (state: setsStateType = initialState, action: IAction): setsStateType => {
    let newSets;
    switch (action.type) {
        case types.ADD_SET_START:
            return {
                ...state,
                addSetLoading: true
            };
        case types.ADD_SET:
            return {
                ...state,
                sets: [...state.sets, action.payload.set],
                addSetLoading: false
            };
        case types.ADD_SET_ERROR:
            return {
                ...state,
                addSetLoading: false
            };
        case types.GET_SETS_START:
            return {
                ...state,
                getSetsLoading: true
            };
        case types.GET_SETS:
            return {
                ...state,
                sets: action.payload.sets,
                getSetsLoading: false
            };
        case types.GET_SETS_ERROR:
            return {
                ...state,
                getSetsLoading: false
            };
        case types.DELETE_SET_START:
            return {
                ...state,
                editSetLoadingIds: [...state.editSetLoadingIds, action.payload.id]
            };
        case types.DELETE_SET:
            return {
                ...state,
                sets: state.sets.filter((set: any) => set._id != action.payload.set._id),
                editSetLoadingIds: state.editSetLoadingIds.filter(
                    (id: any) => id != action.payload.set._id
                )
            };
        case types.DELETE_SET_ERROR:
            return {
                ...state,
                editSetLoadingIds: state.editSetLoadingIds.filter(
                    (id: any) => id != action.payload.id
                )
            };
        case types.EDIT_SET_START:
            return {
                ...state,
                editSetLoadingIds: [...state.editSetLoadingIds, action.payload.id]
            };
        case types.EDIT_SET:
            newSets = [...state.sets] as set[];
            newSets[newSets.findIndex((set: set) => set._id == action.payload.set._id)] =
                action.payload.set;

            return {
                ...state,
                sets: newSets,
                editSetLoadingIds: state.editSetLoadingIds.filter(
                    (id: any) => id != action.payload.set._id
                )
            };
        case types.EDIT_SET_ERROR:
            return {
                ...state,
                editSetLoadingIds: state.editSetLoadingIds.filter(
                    (id: any) => id != action.payload.id
                )
            };
        case types.UPDATE_SET_FIELD:
            return {
                ...state,
                sets: state.sets.map((set: set) =>
                    set._id === action.payload.id ? { ...set, name: action.payload.name } : set
                )
            };
        case types.SET_PULLBLOCK:
            return {
                ...state,
                pullBlock: action.payload.flag
            };
        case types.DUPLICATE_SET_START:
            return {
                ...state,
                addSetLoading: true
            };
        case types.DUPLICATE_SET:
            return {
                ...state,
                sets: [...state.sets, action.payload.set],
                addSetLoading: false
            };
        case types.DUPLICATE_SET_ERROR:
            return {
                ...state,
                addSetLoading: false
            };
        case types.CHOOSE_CRM: {
            const { setId, crm } = action.payload;

            return {
                ...state,
                sets: state.sets.map((set: set) =>
                    String(set._id) === String(setId) ? { ...set, crm } : set
                )
            };
        }
        case types.SET_IS_SDK: {
            const { setId, isActive } = action.payload;

            return {
                ...state,
                sets: state.sets.map((set: set) =>
                    String(set._id) === String(setId) ? { ...set, isSdk: isActive } : set
                )
            };
        }
        default:
            return state;
    }
};
