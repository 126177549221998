import * as React from "react";

interface Props {
    hashedId: string;
}

class Wistia extends React.Component<Props, any> {
    swatch = React.createRef<HTMLDivElement>();
    handle: any;

    constructor(props: any) {
        super(props);
        const { hashedId = "", ...embedOptions } = { ...this.props };
        window["_wq"] = window["_wq"] || [];
        window["_wq"].push({
            id: hashedId,
            options: embedOptions
        });
    }

    customLoad() {
        this.swatch.current!.style.opacity = "1";
    }

    render() {
        return (
            <div
                className="wistia_responsive_padding"
                style={{
                    width: "100%",
                    height: "300px"
                }}
            >
                <div
                    className="wistia_responsive_wrapper"
                    style={{
                        left: "0",
                        position: "relative",
                        top: "0",
                        width: "100%"
                    }}
                >
                    <div
                        className={`wistia_embed wistia_async_${
                            this.props.hashedId
                        } videoFoam=true`}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <div
                            ref={this.swatch}
                            className="wistia_swatch"
                            style={{
                                left: 0,
                                opacity: 0,
                                overflow: "hidden",
                                position: "absolute",
                                top: 0,
                                transition: "opacity 200ms",
                                width: "100%"
                            }}
                        >
                            <img
                                src={`https://fast.wistia.com/embed/medias/${
                                    this.props.hashedId
                                }/swatch`}
                                style={{
                                    filter: "blur(5px)",
                                    height: "100%",
                                    objectFit: "contain",
                                    width: "100%"
                                }}
                                alt=""
                                onLoad={this.customLoad.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if (!document.getElementById("wistia_script")) {
            var wistiaMedia = document.createElement("script");
            wistiaMedia.id = "wistiaMedia_script";
            wistiaMedia.type = "text/javascript";
            wistiaMedia.src = `https://fast.wistia.com/embed/medias/${
                this.props.hashedId
            }.jsonp`;
            wistiaMedia.async = true;
            document.body.appendChild(wistiaMedia);
            var wistiaScript = document.createElement("script");
            wistiaScript.id = "wistia_script";
            wistiaScript.type = "text/javascript";
            wistiaScript.src =
                "https://fast.wistia.com/assets/external/E-v1.js";
            wistiaScript.async = true;
            document.body.appendChild(wistiaScript);
        }

        window["_wq"] = window["_wq"] || [];
        window["_wq"].push({
            id: this.props.hashedId,
            onEmbedded: (video: any) => {
                this.handle = video;
            }
        });
    }

    componentWillUnmount() {
        this.handle && this.handle.remove();
    }
}

export default Wistia;
