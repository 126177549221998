import * as React from "react";
import styles from "./styles.scss";
import cx from "classnames";

type Props = {
    children: React.ReactNode;
    className?: string;
};

const AnalyticsBar: React.FC<Props> = ({
    children,
    className = "",
    ...rest
}) => {
    return (
        <div className={cx(styles.analyticsBar, className)} {...rest}>
            {children}
        </div>
    );
};

export default AnalyticsBar;
