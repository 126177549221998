import * as React from "react";
import Button from "components/Button";
import File from "../../../../../../../../../../../CDNCloud/components/File";
import SwitchButton from "components/SwitchButton";
import cx from "classnames";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import styles from "./styles.scss";
import { CMSContext } from "../../../../../..";
import { Redirect } from "react-router-dom";
import { addUpsell } from "../../actions";
import { getFileObject } from "services/getFileObject";
import { sumUpsellVariants } from "services/getReport";
import { upsell } from "types/upsell";
import { useDispatch } from "react-redux";
import { product } from "types/product";
import { odooProduct } from "types/odooProduct";

type Props = {
    upsell: upsell;
    upsellContainerId: string;
    weight: number;
};

const UpsellVariant: React.FC<Props> = ({ upsell, upsellContainerId, weight }) => {
    const [isRedirect, setRedirect] = React.useState(false);
    const [image, setImage] = React.useState("");
    const [product, setProduct] = React.useState({} as any);
    const { _id, title, elementId } = upsell;
    const dispatch = useDispatch();
    const cmsContext = React.useContext(CMSContext);
    const { schema, setId, languageId } = cmsContext;
    const cmsElement = cmsContext.elements.find(el => el._elementId === elementId);
    const { crm, odooProducts, odooProductsProd, products, odooProductsDev } = cmsContext;
    const upsellPrice = Number(cmsElement?.upsellPrice?.variants?.[0]?.value || 0);
    const isOdooCrm = crm !== "KONNEKTIVE";

    React.useEffect(() => {
        if (cmsElement) {
            const { image, product } = cmsElement;
            if (image) {
                const { variants } = image;
                const variant = variants[0];

                if (variant) {
                    setImage(variant.value);
                }
            }

            if (product) {
                const { variants } = product;
                const variant = variants[0];

                let finalOdooProducts: odooProduct[] = [];

                switch (crm) {
                    case "ODOO":
                        finalOdooProducts = odooProducts;
                        break;
                    case "ODOO_DEV":
                        finalOdooProducts = odooProductsDev;
                        break;
                    case "ODOO_PROD":
                    default:
                        finalOdooProducts = odooProductsProd;
                        break;
                }

                if (variant) {
                    let cmsProduct;

                    if (crm === "KONNEKTIVE" && products) {
                        cmsProduct = products.find(
                            product => String(product.productId) === String(variant.value)
                        );
                    }

                    if (isOdooCrm && finalOdooProducts) {
                        cmsProduct = finalOdooProducts.find(
                            product => String(product.sid) === String(variant.value)
                        );
                    }

                    if (cmsProduct) {
                        setProduct(cmsProduct as any);
                    }
                }
            }
        }
    }, [cmsElement, cmsContext]);

    const handleRedirect = () => {
        setRedirect(true);
    };

    const handleSwitchVariant = (
        e: React.SyntheticEvent,
        isActive: boolean,
        variantId: string,
        upsellContainerId: string
    ) => {
        e.stopPropagation();
        dispatch(addUpsell({ _id: variantId, upsellContainerId, isActive }));
    };

    const handleClone = (e: React.SyntheticEvent) => {
        e.stopPropagation();

        dispatch(
            addUpsell({
                isActive: false,
                schema,
                setId,
                languageId,
                upsellContainerId,
                cloneElement: upsell.elementId
            })
        );
    };

    if (isRedirect) {
        return (
            <Redirect
                to={{
                    pathname: `${window.location.pathname}/${upsellContainerId}/${upsell._id}`,
                    state: {
                        backlink: window.location.pathname
                    }
                }}
            />
        );
    }

    const { cos, cog, grossProfit, revenue, upsellSales, upsellViews } = sumUpsellVariants({
        _id,
        variants: [upsell]
    });

    const productPrice = !isOdooCrm
        ? Number((product as product).cycle1_price)
        : Number((product as odooProduct).price);

    const isDuplicating = upsell && upsell.duplication && !upsell.duplication.isCompleted;

    if (isDuplicating) {
        return (
            <div className={styles.variant}>
                Upsell Variant is being duplicated. please refresh the page after some time
            </div>
        );
    }

    return (
        <>
            <div className={styles.variant} onClick={handleRedirect}>
                <div className={styles.switchRow}>
                    <SwitchButton
                        isActive={upsell.isActive}
                        onSwitch={(e, isActive) =>
                            handleSwitchVariant(e, isActive, _id, upsellContainerId)
                        }
                    />
                </div>
                <div>
                    <div className={styles.photoRow}>
                        {image.includes("#") && (
                            <div>
                                <File fileView="wide" file={getFileObject(image as string)} />
                            </div>
                        )}
                        {!isOdooCrm ? (
                            <div className={styles.content}>
                                <div className={styles.title}>{title}</div>
                                {product.hasOwnProperty("productName") && (
                                    <div className={styles.row}>
                                        <div className={styles.item}>
                                            <span>{product.productName}</span>
                                        </div>
                                    </div>
                                )}
                                <div className={styles.row}>
                                    {product.hasOwnProperty("productId") && (
                                        <div className={styles.item}>
                                            #<span>{product.productId}</span>
                                        </div>
                                    )}
                                    {product.hasOwnProperty("cycle1_price") && (
                                        <div className={styles.item}>
                                            {getDollars(Number(product.cycle1_price))}{" "}
                                            <span>Product Price</span>
                                        </div>
                                    )}
                                    <div className={styles.item}>
                                        {weight}% <span>Weight</span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {isOdooCrm ? (
                            <div className={styles.content}>
                                <div className={styles.title}>{title}</div>
                                {product.hasOwnProperty("name") && (
                                    <div className={styles.row}>
                                        <div className={styles.item}>
                                            <span>{product.name}</span>
                                        </div>
                                    </div>
                                )}
                                <div className={styles.row}>
                                    {product.hasOwnProperty("sid") && (
                                        <div className={styles.item}>
                                            #<span>{product.sid}</span>
                                        </div>
                                    )}
                                    {product.hasOwnProperty("cycle1_price") && (
                                        <div className={styles.item}>
                                            {getDollars(Number(product.price))}{" "}
                                            <span>Product Price</span>
                                        </div>
                                    )}
                                    <div className={styles.item}>
                                        {weight}% <span>Weight</span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={cx(styles.row, styles.primary)}>
                        <div className={styles.item}>
                            {getDollars(grossProfit)} <span>GP</span>
                        </div>
                        <div className={styles.item}>
                            {upsellViews} <span>Views</span>
                        </div>
                        <div className={styles.item}>
                            {upsellSales} <span>Conversions</span>
                        </div>
                        <div className={styles.item}>
                            {getPercentage(upsellSales / upsellViews)} <span>CVR</span>
                        </div>
                        <div className={styles.item}>
                            {getDollars(grossProfit / upsellViews)} <span>EPV</span>
                        </div>
                        <div className={styles.item}>
                            {getDollars(revenue / upsellSales)} <span>EPO</span>
                        </div>
                    </div>
                    <div className={cx(styles.row, styles.tertiary)}>
                        <div className={styles.item}>
                            {upsellPrice ? getDollars(upsellPrice) : getDollars(productPrice || 0)}{" "}
                            <span>Upsell Price</span>
                        </div>
                        <div className={styles.item}>
                            {getDollars(revenue)} <span>Revenue</span>
                        </div>
                        <div className={styles.item}>
                            {getDollars(cog)} <span>COG</span>
                        </div>
                        <div className={styles.item}>
                            {getDollars(cos)} <span>COS</span>
                        </div>
                    </div>
                </div>
                <div className={styles.verticalRow}>
                    <Button
                        design="circle"
                        icon="clone"
                        size="small"
                        onClick={(e: React.SyntheticEvent) => handleClone(e)}
                    />
                </div>
            </div>
        </>
    );
};

export default UpsellVariant;
