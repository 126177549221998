import * as actionTypes from "actionTypes";
import { brand } from "types/brand";
import { tagItems } from "services/tagItems";

export type brandStateType = {
    addBrandLoading: boolean;
    brands: brand[];
    isLoading: boolean;
    errors: null | Error;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: brandStateType = {
    addBrandLoading: false,
    brands: [],
    isLoading: false,
    errors: null
};

export const brandStorage: { [key: string]: boolean } = {
    brands: true
};

export default (state: brandStateType = initialState, action: IAction): brandStateType => {
    switch (action.type) {
        case actionTypes.ADD_BRAND_START:
            return {
                ...state,
                addBrandLoading: true
            };
        case actionTypes.ADD_BRAND:
            const { brand } = action.payload;
            const brandMap = new Map();
            state.brands.forEach((brand: brand) => brandMap.set(brand._id, brand));

            brandMap.set(brand._id, brand);

            return {
                ...state,
                brands: Array.from(brandMap.values()),
                addBrandLoading: false
            };
        case actionTypes.ADD_BRAND_ERROR:
            return {
                ...state,
                addBrandLoading: false
            };
        case actionTypes.GET_BRANDS_START:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_BRANDS:
            return {
                ...state,
                brands: action.payload.brands,
                isLoading: false
            };
        case actionTypes.GET_BRANDS_ERROR:
            return {
                ...state,
                brands: [],
                errors: action.payload.error,
                isLoading: false
            };
        case actionTypes.REMOVE_BRAND_START:
            return {
                ...state,
                brands: tagItems(state.brands, action.payload.id, {
                    error: "",
                    isRemoving: true
                })
            };
        case actionTypes.REMOVE_BRAND:
            return {
                ...state,
                brands: state.brands.filter(brand => brand._id !== action.payload.brand._id)
            };
        case actionTypes.REMOVE_BRAND_ERROR:
            const { error, id } = action.payload;

            return {
                ...state,
                brands: tagItems(state.brands, id, {
                    error: error.data.error,
                    isRemoving: false
                })
            };
        default:
            return state;
    }
};
