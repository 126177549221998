import cx from "classnames";
import SwitchButton from "components/SwitchButton";
import * as React from "react";
import getOperators from "services/getOperators";
import { FlowTrigger } from "types/flowTrigger";
import { TriggerVariable } from "types/triggerVariable";
import styles from "./styles.scss";
const { useState, useEffect } = React;

type Props = {
    trigger: FlowTrigger;
    variables: TriggerVariable[];
    updateTrigger: (key: keyof FlowTrigger, value: string | boolean) => void;
    hideLogicalOperator?: boolean;
};

const Trigger: React.FC<Props> = ({
    trigger,
    variables,
    updateTrigger,
    hideLogicalOperator = false
}) => {
    const { compareValue, logicalOperator, operator, variableKey, variableType } = trigger;

    if (!variables) return <div>No upsells support in this version.</div>;

    let initialVariable = variables.find(variable => variable.key == variableKey);

    if (!initialVariable) {
        initialVariable = variables[0];
    }

    const [chosenVariable, setChosenVariable] = useState<TriggerVariable>(initialVariable);

    const operators = getOperators(variableType);

    const selectLogicalOperator = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const { value } = e.currentTarget;

        updateTrigger("logicalOperator", value);
    };

    const chooseVariable = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const { value } = e.currentTarget;

        const variable = variables.find(variable => variable.key === value) as TriggerVariable;

        setChosenVariable(variable);
        updateTrigger("variableKey", value);
        updateTrigger("variableName", variable.name);
        updateTrigger("variableType", variable.type);
    };

    useEffect(() => {
        if (compareValue == false || compareValue == "" || compareValue == "0") {
            updateTrigger("operator", operators[0]);

            let initValue: string | boolean = "";

            switch (chosenVariable.type) {
                case "NUMBER":
                    initValue = "0";
                case "SWITCH":
                    initValue = false;
            }

            updateTrigger("compareValue", initValue);
        }
    }, [variableKey]);

    const chooseOperator = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const { value } = e.currentTarget;

        updateTrigger("operator", value);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { value } = e.currentTarget;
        updateTrigger("compareValue", value);
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const { value } = e.currentTarget;
        updateTrigger("compareValue", value);
    };

    const getInputComponent = (type: TriggerVariable["type"], enums: string[] | null) => {
        switch (type) {
            case "TEXT":
            default:
                return (
                    <input
                        onChange={handleInputChange}
                        type="text"
                        value={String(compareValue)}
                        className={styles.input}
                    />
                );
            case "NUMBER":
                return (
                    <input
                        onChange={handleInputChange}
                        type="number"
                        value={String(compareValue)}
                        className={styles.input}
                    />
                );
            case "SWITCH":
                return (
                    <SwitchButton
                        isActive={compareValue === "true"}
                        onSwitch={(e, value) => updateTrigger("compareValue", String(value))}
                    />
                );
            case "ENUM":
                return (
                    <select
                        value={String(compareValue)}
                        onChange={handleSelectChange}
                        className={styles.input}
                    >
                        {enums &&
                            enums.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                    </select>
                );
        }
    };

    const InputComponent = getInputComponent(chosenVariable.type, chosenVariable.enum);

    return (
        <div className={styles.triggerContainer}>
            {!hideLogicalOperator && (
                <div className={styles.logicalOperator}>
                    <div>Logical Operator</div>
                    <select
                        value={logicalOperator}
                        onChange={selectLogicalOperator}
                        className={styles.input}
                    >
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                    </select>
                </div>
            )}
            <div className={styles.triggerPanel}>
                <div className={cx(styles.row, styles.title)}>Condition</div>
                <div className={styles.row}>
                    <div>Variable</div>
                    <select value={variableKey} onChange={chooseVariable} className={styles.input}>
                        {variables.map(variable => (
                            <option key={variable.key} value={variable.key}>
                                {variable.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.row}>
                    <div>Operator</div>
                    <select onChange={chooseOperator} value={operator} className={styles.input}>
                        {operators.map(operator => (
                            <option key={operator} value={operator}>
                                {operator}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.row}>
                    <div>Value</div>
                    {InputComponent}
                </div>
            </div>
        </div>
    );
};

export default Trigger;
