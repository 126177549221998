import * as React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { language } from "types/language";
import { set } from "types/set";

type Props = {
    backlink: string;
    set: set;
    language: language;
    isContentValid: boolean;
    scrollToInvalid: () => void;
};

const TopBar: React.FC<Props> = ({
    backlink,
    set,
    language,
    isContentValid,
    scrollToInvalid
}) => {
    return (
        <div className={styles.menu}>
            {backlink !== "/" && (
                <Link to={backlink}>
                    <div className={styles.backlink}>
                        <img
                            src="/img/svg/leftarrow.svg"
                            className={styles.arrow}
                        />
                        BACK TO DEPLOYMENT
                    </div>
                </Link>
            )}
            <h2>
                {backlink !== "/" && "/ "}
                &nbsp;{set.name} {language && `(${language.name})`}
            </h2>
            <div
                className={`${styles.validationStatus} ${!isContentValid &&
                    styles.invalid}`}
                onClick={scrollToInvalid}
            >
                <div
                    className={`${styles.diode} ${
                        isContentValid ? styles.valid : styles.invalid
                    }`}
                >
                    <div className={styles.border} />
                </div>

                <div className={styles.status}>
                    {isContentValid ? "VALID" : "INVALID"}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
