import * as React from "react";
import * as actions from "./actions";
import * as queryString from "query-string";
import * as isoCountries from "assets/isoCountries.js";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { Version } from "types/version";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { groupBy } from "lodash";
import { language } from "types/language";
import { withRouter } from "react-router-dom";
import { set } from "types/set";
import EditSetDetails from "../EditSetDetails";

class Languages extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            template: "",
            version: "",
            language: ""
        };
    }

    componentDidMount() {
        const { location } = this.props;

        if (location.search) {
            const parsed = queryString.parse(location.search);
            const {
                template = "",
                version = "",
                language = ""
            } = {
                ...parsed
            };

            this.setState({
                language,
                template,
                version
            });
        }
    }

    handleAddLanguageClick(setId: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.addLanguage(setId);
    }

    handleSaveClick(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.editLanguage(id);
    }

    handleOnChange(id: string, name: string, e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.props.updateSetLanguageField(id, name, value);
    }

    handleDeleteClick(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.deleteLanguage(id);
    }

    chooseTemplate(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.setState({ template: value });
    }

    chooseVersion(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.setState({ version: value });
    }

    chooseLanguage(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.setState({ language: value });
    }

    updateSet(set: set) {
        return this.props.updateContentSet(set);
    }

    render() {
        const { set } = this.props;
        const setId = set._id;
        const { handleAddLanguageClick, handleSaveClick, handleOnChange } = this;
        const { languagesMap, isLoading } = this.props.languages;
        const languages = Array.from(languagesMap.values()).filter(
            (language: language) => language.set == setId
        );
        const { versions } = this.props.deployment;

        if (versions.length === 0) return <LoadingIndicator />;

        const groupedVersions = groupBy(
            versions.filter((v: Version) => v.released === true),
            "template._id"
        );

        return (
            <div className={styles.panel_wide}>
                <EditSetDetails
                    set={set}
                    brands={this.props.brands}
                    onUpdate={this.updateSet.bind(this)}
                />
                <h2>Edit Languages:</h2>
                {languages.map((language: language, index: number) => (
                    <div key={index} className={styles.bar}>
                        <button onClick={handleSaveClick.bind(this, language._id)} title="Save">
                            <img src="/img/svg/save.svg" />
                        </button>
                        <input
                            type="text"
                            value={language.name}
                            onChange={handleOnChange.bind(this, language._id, "name")}
                            placeholder="Language Name"
                        />
                        <select
                            value={language.countryCode ? language.countryCode.toLowerCase() : ""}
                            onChange={handleOnChange.bind(this, language._id, "countryCode")}
                        >
                            <option value=""> select country</option>
                            {Object.entries(isoCountries).map(([country, countryName], index) => (
                                <option key={country} value={country.toLowerCase()}>
                                    {countryName}
                                </option>
                            ))}
                        </select>
                    </div>
                ))}
                {isLoading ? <LoadingIndicator /> : null}
                {languages.length < 1 ? (
                    <button
                        onClick={handleAddLanguageClick.bind(this, setId)}
                        className={styles.primary}
                    >
                        Add new language
                    </button>
                ) : null}
                <h2>Edit content with schema:</h2>
                <br />
                <select value={this.state.language} onChange={this.chooseLanguage.bind(this)}>
                    <option value="">Choose Language</option>
                    {languages.map((language: language) => (
                        <option key={language._id} value={language._id}>
                            {language.name}
                        </option>
                    ))}
                </select>
                &nbsp;&nbsp;
                <select value={this.state.template} onChange={this.chooseTemplate.bind(this)}>
                    <option value="">Choose Template</option>
                    {Object.entries(groupedVersions).map(([templateId, versions]: any) => {
                        const { template } = versions[0];
                        return (
                            <option key={templateId} value={templateId}>
                                {template.name} ({template.shortcut})
                            </option>
                        );
                    })}
                </select>
                &nbsp;&nbsp;
                {this.state.template != "" ? (
                    <select value={this.state.version} onChange={this.chooseVersion.bind(this)}>
                        <option value="">Choose Version</option>
                        {groupedVersions[this.state.template].map((version: any) => (
                            <option key={version._id} value={version._id}>
                                {version.tag}
                            </option>
                        ))}
                    </select>
                ) : (
                    <select disabled>
                        <option>Choose Version</option>
                    </select>
                )}
                &nbsp;&nbsp;
                <Link to={`/dashboard/cms/${set._id}/${this.state.language}/${this.state.version}`}>
                    <button
                        disabled={
                            this.state.language === "" ||
                            this.state.template === "" ||
                            this.state.version === ""
                        }
                    >
                        EDIT CONTENT
                    </button>
                </Link>
                <div>
                    <br />* Try to use <b>{set.name}</b> only for one template to avoid keeping too
                    different content elements in a same set.
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => {
    return {
        languages: state.languages,
        deployment: state.deployment,
        brands: state.brand.brands
    };
};

export default withRouter(
    connect(mapStateToProps, {
        ...actions
    })(Languages)
);
