import { product } from "types/product";

const getKonnektiveProduct = (
    products: product[],
    productId: number | string,
    compareTo: "productId" | "campaignProductId",
    campaignId?: string
) => {
    if (!products || !products.length) {
        return null;
    }

    const konnektiveProduct = products.find(
        (kProduct: product) =>
            String(kProduct[compareTo]) === String(productId) &&
            (campaignId ? String(kProduct.campaignId) === String(campaignId) : true)
    );

    if (!konnektiveProduct) {
        return null;
    }

    return konnektiveProduct;
};

export default getKonnektiveProduct;
