import store from "store";
import { Store } from "redux";
import menu from "./menu";
import { MenuOptionType } from "types/MenuOptionType";
import { apiSignedInUser } from "types/user";
import defaultRoutes from "./defaultRoutes";
import { DefaultRoutesType } from "types/defaultRoutesType";

class Guardian {
    store: Store;
    menu: MenuOptionType[];
    icons: { [k: string]: string };
    defaultRoutes: DefaultRoutesType;

    constructor() {
        this.store = store;
        this.menu = menu;
        this.defaultRoutes = defaultRoutes;
    }

    getUser(): apiSignedInUser {
        return this.store.getState().auth.user;
    }

    checkIfUserHasRoles(user: apiSignedInUser): boolean {
        return user && "roles" in user && Array.isArray(user.roles);
    }

    getAuthenticatedMenu(): MenuOptionType[] {
        const user = this.getUser();

        if (this.checkIfUserHasRoles(user)) {
            return menu.filter(menuOption =>
                menuOption.allowedRoles.some(
                    menuRole =>
                        user.roles.includes(menuRole) &&
                        (menuOption.dev ? process.env.NODE_ENV !== "production" : true)
                )
            );
        } else {
            return [];
        }
    }

    isLocationValid(): boolean {
        const user = this.getUser();

        if (this.checkIfUserHasRoles(user)) {
            window.location.pathname;

            const menuOption = this.menu.find(menuOption =>
                window.location.pathname.includes(menuOption.to)
            );

            if (menuOption) {
                return menuOption.allowedRoles.some(menuRole => user.roles.includes(menuRole));
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getDefaultRoute(): string {
        const user = this.getUser();

        return this.defaultRoutes[user.roles[0]];
    }
}

export default new Guardian();
