import * as React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import { token } from "types/token";

interface Props {
    token_obj: token;
    deleteToken: any;
    editToken: any;
    deleting: boolean;
}

const Token = ({ token_obj, deleteToken, deleting }: Props) => {
    const { name, token, accounts } = token_obj;

    return (
        <div className={styles.panel_token}>
            <div>Name: {name}</div>
            <div>Token Key: {token}</div>
            <div>
                Fb Accounts:
                <select>
                    {accounts.map((account: string, index: number) => (
                        <option key={index}>{account}</option>
                    ))}
                </select>
            </div>

            <div className={styles.buttons}>
                <button
                    className={styles.button}
                    onClick={deleting ? null : deleteToken}
                >
                    {deleting ? "..." : "DELETE"}
                </button>
            </div>
        </div>
    );
};

export default connect(
    null,
    null
)(Token);
