import LoadingIndicator from "components/LoadingIndicator";
import SwitchButton from "components/SwitchButton";
import * as React from "react";
import { connect } from "react-redux";
import { appStateType } from "reducers";
import * as actions from "./actions";
import styles from "./styles.scss";
import * as moment from "moment-timezone";
import { CCDripChart } from "./components/CCDripChart";

class CCDrip extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.pullDripSettings();
        this.props.pullDripCards();
        this.props.getCCDripReport();
    }

    handleSaveClick(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.saveSettings(id);
    }

    updateField(name: string, id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.props.updateField({ id, name, value });
    }

    getFile(e: React.SyntheticEvent) {
        const { files } = e.target as HTMLInputElement;

        if (!!files) {
            this.props.getFile(files[0]);
        }
    }

    handleUploadClick(e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.uploadCards();
    }

    handleAddCron(e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.addCron();
    }

    handleDeleteCron(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.deleteCron(id);
    }

    handleDeleteCard(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.deleteCard(id);
    }

    toggleCard(id: string, isDeclined: boolean) {
        this.props.toggleCard(id, isDeclined);
    }

    toggleCron(cron: { _id: string; isSaved: boolean }, isActive: boolean) {
        const { _id: id, isSaved } = cron;
        if (isSaved) {
            this.props.toggleCronActive(id, isActive);
        } else {
            alert("You have to save settings first");
        }
    }

    render() {
        const {
            cards,
            wrongCards,
            settings,
            isPullingSettings,
            isPullingCards,
            isUploadingCards,
            isPullingReport,
            report
        } = this.props.drip;

        return (
            <>
                <header>
                    <h1>CC Drip</h1>
                </header>
                <main>
                    <div className={styles.panel_wide}>
                        <h2>Upload New Cards:</h2>
                        {isUploadingCards ? (
                            <LoadingIndicator className={styles.loader} />
                        ) : (
                            <React.Fragment>
                                <div>
                                    <br />
                                    Choose CSV file:
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={this.getFile.bind(this)}
                                    />
                                </div>
                                <button
                                    onClick={this.handleUploadClick.bind(this)}
                                    className={styles.primary}
                                >
                                    UPLOAD
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                    <div className={styles.panel_wide}>
                        {isPullingReport ? <LoadingIndicator /> : <CCDripChart data={report} />}
                    </div>
                    <div className={styles.panel_wide} style={{ overflowX: "scroll" }}>
                        <h2>Cron settings:</h2>
                        {isPullingSettings ? (
                            <LoadingIndicator className={styles.loader} />
                        ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <td />
                                        <td>Campaign Id:</td>
                                        <td>Product Id:</td>
                                        <td>Orders MIN Per Day:</td>
                                        <td>Orders MAX Per Day:</td>
                                        <td>Active:</td>
                                        <td />
                                    </tr>
                                </thead>
                                <tbody>
                                    {settings.map((cron: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <button
                                                        onClick={this.handleSaveClick.bind(
                                                            this,
                                                            cron._id
                                                        )}
                                                        title="Save"
                                                    >
                                                        <img src="/img/svg/save.svg" />
                                                    </button>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        value={cron.campaignId}
                                                        disabled={
                                                            cron.isSaved == false ? false : true
                                                        }
                                                        onChange={this.updateField.bind(
                                                            this,
                                                            "campaignId",
                                                            cron._id
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        value={cron.productId}
                                                        disabled={
                                                            cron.isSaved == false ? false : true
                                                        }
                                                        onChange={this.updateField.bind(
                                                            this,
                                                            "productId",
                                                            cron._id
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        value={cron.ordersMin}
                                                        onChange={this.updateField.bind(
                                                            this,
                                                            "ordersMin",
                                                            cron._id
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        value={cron.ordersMax}
                                                        onChange={this.updateField.bind(
                                                            this,
                                                            "ordersMax",
                                                            cron._id
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <SwitchButton
                                                        isActive={cron.isActive}
                                                        onSwitch={this.toggleCron.bind(
                                                            this,
                                                            cron,
                                                            !cron.isActive
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={this.handleDeleteCron.bind(
                                                            this,
                                                            cron._id
                                                        )}
                                                        title="Remove"
                                                    >
                                                        <img src="/img/svg/trash.svg" />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                        <button onClick={this.handleAddCron.bind(this)} className={styles.primary}>
                            Add new settings
                        </button>
                    </div>
                    <div className={styles.panel_wide}>
                        <h2>Cards:</h2>
                        {isPullingCards ? (
                            <LoadingIndicator className={styles.loader} />
                        ) : (
                            <React.Fragment>
                                <div>
                                    <br />
                                    All: {cards.length}
                                </div>
                                {wrongCards.length > 0 ? (
                                    <div>
                                        <br />
                                        Wrong (Check console!): {wrongCards.length}
                                    </div>
                                ) : null}
                                <div>
                                    <br />
                                    Inactive: {cards.filter((card: any) => card.isDeclined).length}
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>First Name:</td>
                                            <td>Last Name:</td>
                                            <td>Card Number:</td>
                                            <td>Transactions:</td>
                                            <td>Active:</td>
                                            <td>Ban until:</td>
                                            <td>Last Decline Reason:</td>
                                            <td />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cards.map((card: any, index: number) => {
                                            const leftBanMinutes = moment(card.banToDate).diff(
                                                moment(),
                                                "minutes"
                                            );

                                            return (
                                                <tr key={index}>
                                                    <td>{card.firstName}</td>
                                                    <td>{card.lastName}</td>
                                                    <td>{card.cardNumber}</td>
                                                    <td>{card.transactions}</td>
                                                    <td>
                                                        <SwitchButton
                                                            isActive={!card.isDeclined}
                                                            onSwitch={this.toggleCard.bind(
                                                                this,
                                                                card._id,
                                                                card.isDeclined
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        {isNaN(leftBanMinutes)
                                                            ? "-"
                                                            : leftBanMinutes + " minutes left"}
                                                    </td>
                                                    <td>{card.lastDeclineReason || "-"}</td>
                                                    <td>
                                                        <button
                                                            onClick={this.handleDeleteCard.bind(
                                                                this,
                                                                card._id
                                                            )}
                                                            title="Remove"
                                                        >
                                                            <img src="/img/svg/trash.svg" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}
                    </div>
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    drip: state.drip
});

export default connect(mapStateToProps, actions)(CCDrip);
