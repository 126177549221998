import * as React from "react";
import * as actions from "./actions";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";

class Templates extends React.PureComponent<any, any> {
    static defaultProps = {
        templates: []
    };

    constructor(props: object) {
        super(props);

        this.state = {
            name: "",
            repoOwner: "",
            repoName: "",
            redirect: ""
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.pullTemplates();
    }

    handleInputChange(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value, name } = e.target as HTMLInputElement;
        this.setState({ [name]: value });
    }

    handleAddTemplate(e: React.SyntheticEvent) {
        e.preventDefault();
        const { name, repoOwner, repoName } = this.state;
        this.props.addTemplate(name, repoOwner, repoName);
        this.setState({ name: "", repoOwner: "", repoName: "" });
    }

    render() {
        const { name, repoOwner, repoName, redirect } = this.state;
        const { templates } = this.props;
        const { isSavingTemplate } = this.props.deployment;

        return (
            <div>
                {templates.length === 0 ? (
                    <LoadingIndicator />
                ) : (
                    <div className={styles.template_grid}>
                        {templates.map((template: any, index: number) => (
                            <div key={index} className={styles.template_box}>
                                <h3>{template.name}</h3>
                                <p>Owner: {template.repoOwner}</p>
                                <p>Name: {template.repoName}</p>
                                <p>Redirect to: {template.redirect}</p>
                            </div>
                        ))}
                    </div>
                )}

                <div className={styles.panel_wide}>
                    <div className={styles.form}>
                        <input
                            name="name"
                            onChange={this.handleInputChange.bind(this)}
                            placeholder="template"
                            type="text"
                            value={name}
                        />
                        <input
                            onChange={this.handleInputChange.bind(this)}
                            placeholder="repo owner"
                            type="text"
                            value={repoOwner}
                            name="repoOwner"
                        />
                        <input
                            onChange={this.handleInputChange.bind(this)}
                            placeholder="repo name"
                            type="text"
                            value={repoName}
                            name="repoName"
                        />
                        <input
                            onChange={this.handleInputChange.bind(this)}
                            placeholder="redirect to"
                            type="text"
                            value={redirect}
                            name="redirect"
                        />
                        {!isSavingTemplate ? (
                            <button
                                onClick={this.handleAddTemplate.bind(this)}
                                className={styles.primary}
                            >
                                ADD NEW TEMPLATE
                            </button>
                        ) : (
                            <LoadingIndicator />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    deployment: state.deployment,
    templates: state.deployment.templates
});

export default connect(
    mapStateToProps,
    actions
)(Templates);
