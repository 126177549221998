import * as actionTypes from "actionTypes";
import { FFFunnel } from "types/FFFunnel";

export type conversionbalancerStateType = {
    funnels: FFFunnel[];
    isLoadingFunnels: boolean;
    isLoadingAddFunnel: boolean;
    report: any;
    isLoadingReport: boolean;
    reportError: object;
    archivedReports: any[];
    isLoadingArchivedReports: boolean;
    googleCampaigns: object[];
    googleConversionActions: object[];
    isLoadingGoogleCampaigns: boolean;
    googleCampaignsError: object | null;
    editFunnelId: string | null;
    reportGeneratingProgress: number;
    reportGeneratingMessages: string[];
    balancerJob: any;
    archivedBalancerJobs: any[];
    isPullingBalancerJobs: boolean;
    balanceConversionsLoading: boolean;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: conversionbalancerStateType = {
    funnels: [],
    isLoadingFunnels: false,
    isLoadingAddFunnel: false,
    report: {},
    isLoadingReport: false,
    reportError: {},
    archivedReports: [],
    isLoadingArchivedReports: false,
    googleCampaigns: [],
    googleConversionActions: [],
    isLoadingGoogleCampaigns: false,
    googleCampaignsError: null,
    editFunnelId: null,
    reportGeneratingProgress: 0,
    reportGeneratingMessages: [],
    balancerJob: {},
    archivedBalancerJobs: [],
    isPullingBalancerJobs: false,
    balanceConversionsLoading: false
};

export const conversionbalancerStorage: { [key: string]: boolean } = {
    error: true,
    ip: true,
    validTo: true
};

export default (
    state: conversionbalancerStateType = initialState,
    action: IAction
): conversionbalancerStateType => {
    switch (action.type) {
        case actionTypes.GET_FF_FUNNELS_START:
            return {
                ...state,
                isLoadingFunnels: true
            };
        case actionTypes.GET_FF_FUNNELS:
            return {
                ...state,
                funnels: action.payload.funnels,
                isLoadingFunnels: false
            };
        case actionTypes.GET_FF_FUNNELS_ERROR:
            return {
                ...state,
                isLoadingFunnels: false
            };
        case actionTypes.ADD_FF_FUNNEL_START:
            return {
                ...state,
                isLoadingAddFunnel: true,
                editFunnelId: null
            };
        case actionTypes.ADD_FF_FUNNEL:
            const newFunnel = action.payload.funnel;
            return {
                ...state,
                funnels: state.funnels.find(funnel => funnel._id === newFunnel._id)
                    ? state.funnels.map(funnel =>
                          funnel._id === newFunnel._id ? newFunnel : funnel
                      )
                    : [...state.funnels, newFunnel],
                isLoadingAddFunnel: false
            };
        case actionTypes.ADD_FF_FUNNEL_ERROR:
            return {
                ...state,
                isLoadingAddFunnel: false
            };
        case actionTypes.DELETE_FF_FUNNEL_START:
            return {
                ...state,
                funnels: state.funnels.filter(funnel => funnel._id !== action.payload.id)
            };
        case actionTypes.DELETE_FF_FUNNEL:
            return {
                ...state,
                funnels: state.funnels.filter(
                    funnel => funnel._id !== action.payload.deletedFunnel._id
                )
            };
        case actionTypes.DELETE_FF_FUNNEL_ERROR:
            return {
                ...state
            };
        case actionTypes.GET_FF_REPORT_START:
            return {
                ...state,
                report: null,
                isLoadingReport: true,
                reportGeneratingProgress: 0,
                reportGeneratingMessages: []
            };
        case actionTypes.GET_FF_REPORT:
            return {
                ...state,
                isLoadingReport: true
            };
        case actionTypes.GET_FF_REPORT_DATA:
            const report = action.payload.populatedDbReport;
            return {
                ...state,
                report,
                archivedReports: [report, ...state.archivedReports],
                isLoadingReport: false
            };
        case actionTypes.GET_FF_REPORT_PROGRESS:
            const { progress, message } = action.payload;
            return {
                ...state,
                reportGeneratingProgress: progress,
                reportGeneratingMessages: [...state.reportGeneratingMessages, message]
            };
        case actionTypes.GET_FF_REPORT_ERROR:
            return {
                ...state,
                isLoadingReport: false,
                reportError: action.payload.error
            };
        case actionTypes.GET_GOOGLE_REPORTS_START:
            return {
                ...state,
                isLoadingArchivedReports: true
            };
        case actionTypes.GET_GOOGLE_REPORTS:
            return {
                ...state,
                archivedReports: action.payload.reports,
                isLoadingArchivedReports: false
            };
        case actionTypes.GET_GOOGLE_REPORTS_ERROR:
            return {
                ...state,
                isLoadingArchivedReports: false,
                reportError: action.payload.error
            };
        case actionTypes.UPDATE_GOOGLE_CAMPAIGNS_START:
            return {
                ...state,
                isLoadingGoogleCampaigns: true,
                googleCampaignsError: null
            };
        case actionTypes.UPDATE_GOOGLE_CAMPAIGNS:
            const { campaigns, conversionActions } = action.payload;
            return {
                ...state,
                isLoadingGoogleCampaigns: false,
                googleCampaigns: campaigns,
                googleConversionActions: conversionActions
            };
        case actionTypes.UPDATE_GOOGLE_CAMPAIGNS_ERROR:
            return {
                ...state,
                isLoadingGoogleCampaigns: false,
                googleCampaignsError: action.payload.error
            };
        case actionTypes.SET_EDIT_FUNNEL_ID:
            return {
                ...state,
                editFunnelId: action.payload.id
            };
        case actionTypes.PULL_BALANCER_JOBS_START:
            return {
                ...state,
                archivedBalancerJobs: [],
                isPullingBalancerJobs: true
            };
        case actionTypes.PULL_BALANCER_JOBS:
            const { balancerJobs } = action.payload;
            return {
                ...state,
                archivedBalancerJobs: balancerJobs,
                isPullingBalancerJobs: false
            };
        case actionTypes.PULL_BALANCER_JOBS_ERROR:
            return {
                ...state,
                isPullingBalancerJobs: false
            };
        case actionTypes.BALANCE_CONVERSIONS_START:
            return {
                ...state,
                balanceConversionsLoading: true
            };
        case actionTypes.BALANCE_CONVERSIONS:
            const { balancerJob } = action.payload;
            return {
                ...state,
                balancerJob,
                archivedBalancerJobs: [balancerJob, ...state.archivedBalancerJobs],
                balanceConversionsLoading: false
            };
        case actionTypes.BALANCE_CONVERSIONS_ERROR:
            return {
                ...state,
                balanceConversionsLoading: false
            };
        default:
            return state;
    }
};
