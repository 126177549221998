import edit from "assets/icons/edit.svg";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import {
    getProductGroups,
    addProductGroup,
    updateProductGroup,
    removeProductGroup
} from "./actions";
import styles from "./styles.scss";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";
import { productGroup } from "types/productGroup";
import { useAppDispatch } from "store";

const defaultProductGroup = {
    name: "",
    id: 0,
    brand: ""
};

interface column {
    columnName: string;
    key: string;
    inputType: "TEXT";
}

const columns: column[] = [
    {
        columnName: "Id",
        key: "id",
        inputType: "TEXT"
    },
    {
        columnName: "Name",
        key: "name",
        inputType: "TEXT"
    }
];

const ProductGroup: React.FC = () => {
    const [productGroup, setProductGroup] = React.useState<productGroup>(defaultProductGroup);
    const { productGroups } = useSelector((state: appStateType) => state.productGroup);
    const [isOpen, openModal] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const isProductGroupId = productGroup.hasOwnProperty("_id");
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch<any>(getProductGroups());
    }, []);

    const handleModal = () => {
        openModal(isOpen => !isOpen);
    };

    const handleCancel = () => {
        openModal(false);
        setProductGroup(defaultProductGroup);
    };

    const handleEdit = (productGroupId: string) => {
        const productGroup = productGroups.find(
            (productGroup: productGroup) => productGroup._id === productGroupId
        );
        if (productGroup) {
            setProductGroup({ ...productGroup });
            window.scrollTo(0, 0);
        } else {
            setProductGroup(defaultProductGroup);
        }
        openModal(true);
    };

    const handleChange = (key: string, value: any) => {
        setProductGroup({ ...productGroup, [key]: value });
    };

    const handleSave = () => {
        const { id, name } = productGroup;

        if (!id || !name) {
            return;
        }

        const isIdExist = productGroups.find(el => Number(el.id) === Number(id));

        if (isIdExist) {
            return;
        }

        setLoading(true);
        dispatch<any>(addProductGroup({ id, name }))
            .then(() => {
                setProductGroup(defaultProductGroup);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const handleUpdate = () => {
        if (!productGroup._id) {
            return;
        }

        setLoading(true);
        dispatch<any>(updateProductGroup(productGroup))
            .then(() => {
                setProductGroup(defaultProductGroup);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const handleRemove = () => {
        if (!productGroup._id) {
            return;
        }

        setLoading(true);
        dispatch<any>(removeProductGroup(productGroup._id))
            .then(() => {
                setProductGroup(defaultProductGroup);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const getInput = (column: column) => {
        switch (column.inputType) {
            case "TEXT":
                return (
                    <input
                        disabled={column.key === "id" && isProductGroupId}
                        className={styles.field}
                        value={productGroup[column.key]}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleChange(column.key, e.currentTarget.value)
                        }
                        placeholder={column.columnName}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <>
            <header className={styles.header}>
                <h1>Product Groups</h1>
            </header>
            <main>
                <section className={styles.section}>
                    <div className={styles.addContainer}>
                        <Button
                            background="green"
                            className={styles.button}
                            disabled={isLoading}
                            flat
                            onClick={handleModal}
                        >
                            Add new productGroup
                        </Button>
                    </div>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.th}>Edit</th>
                                    {columns.map(col => (
                                        <th className={styles.th} key={col.key}>
                                            {col.columnName}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {productGroups.map((productGroup: productGroup, index: number) => (
                                    <tr key={productGroup._id}>
                                        <td>
                                            <Button
                                                onClick={() => handleEdit(productGroup._id || "")}
                                            >
                                                <img src={edit} />
                                            </Button>
                                        </td>
                                        {columns.map(col => {
                                            switch (col.inputType) {
                                                case "TEXT":
                                                    return (
                                                        <td key={col.key}>
                                                            {productGroup[col.key]}
                                                        </td>
                                                    );

                                                default:
                                                    return (
                                                        <td key={col.key}>
                                                            {productGroup[col.key]}
                                                        </td>
                                                    );
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {isOpen ? (
                        <Modal
                            title={
                                isProductGroupId
                                    ? "Update productGroup details"
                                    : "Create a new ProductGroup"
                            }
                            onClose={handleModal}
                        >
                            <div className={styles.form}>
                                {columns.map(col => (
                                    <React.Fragment key={col.key}>{getInput(col)}</React.Fragment>
                                ))}
                                <div>
                                    {!isProductGroupId ? (
                                        <Button
                                            background="green"
                                            className={styles.button}
                                            disabled={isLoading}
                                            flat
                                            onClick={handleSave}
                                            marginRight
                                        >
                                            {isLoading ? (
                                                <LoadingIndicator size={18} />
                                            ) : (
                                                "Add Product Group"
                                            )}
                                        </Button>
                                    ) : null}
                                    {isProductGroupId ? (
                                        <>
                                            <Button
                                                background="green"
                                                className={styles.button}
                                                disabled={isLoading}
                                                flat
                                                onClick={handleUpdate}
                                                marginRight
                                            >
                                                {isLoading ? (
                                                    <LoadingIndicator size={18} />
                                                ) : (
                                                    "Update Product Group"
                                                )}
                                            </Button>
                                            <Button
                                                background="pinkGradient"
                                                className={styles.button}
                                                disabled={isLoading}
                                                flat
                                                onClick={handleRemove}
                                                marginRight
                                                marginLeft
                                            >
                                                {isLoading ? (
                                                    <LoadingIndicator size={18} />
                                                ) : (
                                                    "Remove Product Group"
                                                )}
                                            </Button>
                                        </>
                                    ) : null}
                                    <Button
                                        className={styles.button}
                                        disabled={isLoading}
                                        onClick={handleCancel}
                                        flat
                                        marginLeft
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    ) : null}
                </section>
            </main>
        </>
    );
};

export default ProductGroup;
