import adwords from "assets/labels/adwords.svg";
import analytics from "assets/labels/analytics.svg";
import dmca from "assets/labels/dmca.png";
import ebanx from "assets/labels/ebanx.svg";
import facebook from "assets/labels/facebook.svg";
import klaviyo from "assets/labels/klaviyo.png";
import messenger from "assets/labels/messenger.svg";
import paypal from "assets/labels/paypal.svg";
import pinterest from "assets/labels/pinterest.svg";
import sandbox from "assets/labels/sandbox.png";
import sentry from "assets/labels/sentry.svg";
import sezzle from "assets/labels/sezzle.png";
import shopify from "assets/labels/shopify.svg";
import smartlook from "assets/labels/smartlook.svg";
import snapchat from "assets/labels/snapchat.svg";
import tag from "assets/labels/tag.svg";
import twitter from "assets/labels/twitter.svg";
import ejamLogo from "assets/labels/ejamLogo.svg";
import xcally from "assets/labels/xcally.png";
import yahoo from "assets/labels/yahoo.svg";
import yotpo from "assets/labels/yotpo.svg";
import productGroup from "assets/labels/product-icon.jpg";
import bing from "assets/labels/bing.svg";
import googleMap from "assets/labels/google-map.svg";
import gorgias from "assets/labels/gorgias.svg";
import segment from "assets/labels/segment.svg";
import smartyStreets from "assets/labels/smartystreets.png";

import cx from "classnames";
import SwitchButton from "components/SwitchButton";
import _ from "lodash";
import * as React from "react";
import styles from "./styles.scss";

class DynamicForm extends React.PureComponent<any, any> {
    getIcon(label: string) {
        switch (label) {
            case "dmca":
                return <img src={dmca} alt="dmca" />;
            case "facebook":
                return <img src={facebook} alt="facebook" />;
            case "googleAds":
            case "googleAdsConversion":
                return <img src={adwords} alt="ads" />;
            case "googleAnalytics":
                return <img src={analytics} alt="analytics" />;
            case "googleTagManager":
                return <img src={tag} alt="tag manager" />;
            case "pinterest":
                return <img src={pinterest} alt="pinterest" />;
            case "sentry":
                return <img src={sentry} alt="sentry" />;
            case "snapchat":
                return <img src={snapchat} alt="snapchat" />;
            case "twitter":
                return <img src={twitter} alt="twiter" />;
            case "ejamPixel":
                return <img src={ejamLogo} alt="ejamPixel" />;
            case "yahoo":
                return <img src={yahoo} alt="yahoo" />;
            case "paypalBillerId":
                return <img src={paypal} alt="paypal" />;
            case "chatBotId":
            case "chatAppId":
            case "chatPageId":
                return <img src={messenger} alt="messenger" />;
            case "xCallyLogin":
            case "xCallyPassword":
            case "xcallyCompanyId":
            case "xCallyPartialsId":
            case "xCallyDeclinedId":
            case "xCallyOrderConfirmationId":
            case "xCallyDncListId":
                return <img src={xcally} alt="xcally" />;
            case "ebanxMerchantPaymentCode":
                return <img src={ebanx} alt="ebanx" />;
            case "shopifyLogin":
            case "shopifyPassword":
            case "shopifyStoreName":
                return <img src={shopify} alt="shopify" />;
            case "smartlook":
                return <img src={smartlook} alt="smartlook" />;
            case "yotpoAppKey":
            case "yotpoProductId":
                return <img src={yotpo} alt="yotpo" />;
            case "klaviyoApiKey":
            case "klaviyoAccountId":
                return <img src={klaviyo} alt="klaviyo" style={{ transform: "scale(1.2)" }} />;
            case "sezzleMerchantId":
                return <img src={sezzle} alt="sezzle" style={{ transform: "scale(1.4)" }} />;
            case "ebanxMerchantId":
                return <img src={ebanx} alt="ebanx" style={{ transform: "scale(1.4)" }} />;
            case "sandboxModeSecretKey":
                return (
                    <img
                        src={sandbox}
                        alt="sandbox"
                        style={{
                            width: "initial"
                        }}
                    />
                );
            case "productGroupId":
                return <img src={productGroup} alt="product group id" />;
            case "bingPixel":
                return <img src={bing} alt="product group id" />;
            case "addStoreCashTag":
                return <img title="add store cash" src={paypal} alt="add store cash" />;
            case "storeCashId":
                return <img title="store cash id" src={paypal} alt="store cash id" />;
            case "segment":
                return <img title="segment id" src={segment} alt="segment id" />;
            case "googleMapKey":
                return <img title="Google map key" src={googleMap} alt="google map key" />;
            case "useStagingApi":
                return <img title="use ED Staging api" src={ejamLogo} alt="use ED staging api" />;
            case "useDevMode":
                return <img title="use ED Dev Mode" src={ejamLogo} alt="use ED Dev mode" />;
            case "gorgiasAppId":
                return <img title="Gorgias App Id" src={gorgias} alt="Gorgias App Id" />;
            case "smartyStreets":
                return (
                    <img
                        title="SmartyStreets key"
                        src={smartyStreets}
                        alt="Smarty Streets client key"
                    />
                );
            case "mulberry":
                return (
                    <img
                        title="Mulberry Private Key"
                        src="https://d3hlrrbqydii6y.cloudfront.net/img/1b117b859033327cfbd0d22268bac0d5.png"
                        alt="Mulberry Private Key"
                    />
                );
            case "useEjamAbstra":
                return "EjamAbstra";
            default:
                return label;
        }

        return <div />;
    }
    render() {
        const { formData, formName, handleDynamicFormChange, isPanel = true } = this.props;

        const formClass = cx(styles.form, {
            [styles.plainData]: !handleDynamicFormChange,
            [styles.panel]: isPanel
        });
        return (
            Object.keys(formData).length > 0 && (
                <div className={formClass}>
                    <header className={styles.header}>
                        <h2>{formName}</h2>
                    </header>
                    <div className={styles.inputs}>
                        {Object.keys(formData).map((input: string, index: number) => {
                            switch (input) {
                                case "useStagingApi":
                                case "useDevMode":
                                case "addStoreCashTag":
                                case "useEjamAbstra":
                                    return (
                                        <label key={index} className={styles.label}>
                                            <span>{this.getIcon(input)}</span>
                                            <SwitchButton
                                                isActive={formData[input]}
                                                onSwitch={(e, isActive) => {
                                                    handleDynamicFormChange({
                                                        target: {
                                                            name: input,
                                                            value: isActive
                                                        }
                                                    });
                                                }}
                                                disabled={!handleDynamicFormChange}
                                            />
                                        </label>
                                    );
                                default:
                                    return !!handleDynamicFormChange ? (
                                        <label key={index} className={styles.label}>
                                            <span>{this.getIcon(input)}</span>
                                            <div className={styles.inputBox}>
                                                <input
                                                    name={input}
                                                    onChange={handleDynamicFormChange}
                                                    value={formData[input]}
                                                />
                                                <div className={styles.placeholder}>
                                                    {_.startCase(input)}
                                                </div>
                                            </div>
                                        </label>
                                    ) : (
                                        <label key={index} className={styles.label}>
                                            <span>{this.getIcon(input)}</span>
                                            {formData[input]}
                                        </label>
                                    );
                            }
                        })}
                    </div>
                </div>
            )
        );
    }
}

export default DynamicForm;
