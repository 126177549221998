import * as React from "react";
import * as _ from "lodash";
import Button from "components/Button";
import FlavourProduct from "../FlavourProduct";
import styles from "./styles.scss";
import { FlavourProduct as FlavourProductType } from "types/pricing";
import { useDispatch } from "react-redux";
import { addFlavourProductDraft } from "../../../../actions";
import { PricingContext } from "../../../..";

interface Props {}

const Products: React.FC<Props> = ({}) => {
    const dispatch = useDispatch();
    const {
        pricingContainer,
        crmMode,
        odooProductListOptions,
        isEditing,
        languageId,
        setId,
        versionId,
        schema,
        containerDraft
    } = React.useContext(PricingContext);

    let { flavourProducts = [] } = containerDraft || {};

    const handleAddFlavourProduct = () => {
        dispatch(addFlavourProductDraft(languageId, versionId, setId, schema));
    };

    if (pricingContainer && pricingContainer.flavourProducts) {
        flavourProducts = [...pricingContainer.flavourProducts, ...flavourProducts];
    }

    return (
        <main>
            {flavourProducts.map((product: FlavourProductType) => (
                <FlavourProduct
                    key={product._id}
                    product={product}
                    isDraft={isEditing}
                    crmMode={crmMode}
                    odooProductListOptions={odooProductListOptions}
                />
            ))}
            {isEditing ? (
                <div className={styles.row}>
                    <span />
                    <Button
                        design="circle"
                        icon="add"
                        size="small"
                        background="purple"
                        onClick={handleAddFlavourProduct}
                    />
                </div>
            ) : null}
        </main>
    );
};

export default Products;
