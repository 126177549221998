import * as React from "react";
import Modal from "../../index";
import { PricingSubs as PricingSubTypes } from "types/pricing";
import InsetButton, {
    Inset
} from "scenes/Dashboard/CPDS/components/DeploymentCard/components/InsetButton";
import edit from "assets/icons/edit.svg";
import { odooProductSubs } from "types/odooProduct";
import Button from "components/Button";
import styles from "./styles.scss";
import SwitchButton from "components/SwitchButton";

const defaultSubs: PricingSubTypes = {
    subId: "",
    recurringInterval: "",
    name: "",
    isSelected: false,
    isPopular: false
};

interface Props {
    subs: odooProductSubs[];
    selectedSubs: PricingSubTypes[];
    saveSubs: (subs: PricingSubTypes[]) => void;
}

class PricingSubs extends React.PureComponent<
    Props,
    {
        isModalOpen: boolean;
        selectedSubs: PricingSubTypes[];
        currentIndex: number;
    }
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isModalOpen: false,
            selectedSubs: props.selectedSubs,
            currentIndex: props.selectedSubs.length ? 0 : -1
        };
    }

    handleOpenModal = () => {
        this.setState({ isModalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { selectedSubs, currentIndex } = this.state;
        const { name, value } = e.target;
        const subs = selectedSubs.map((el, idx) =>
            idx === currentIndex ? { ...el, [name]: value } : el
        );
        this.setState({
            selectedSubs: subs
        });
    };

    handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { selectedSubs, currentIndex } = this.state;
        const { name, value } = e.target;
        let newVals = {};

        if (name === "subId") {
            const addedSub = this.props.subs.find(el => Number(el.sub_id) === Number(value));
            if (addedSub) {
                const { name: subName, sub_id, sub_validity } = addedSub;
                newVals = {
                    name: subName,
                    subId: sub_id,
                    recurringInterval: sub_validity,
                    discount: 0,
                    discountType: "PERCENT"
                };
            }
        } else {
            newVals = {
                [name]: value
            };
        }

        const subs = selectedSubs.map((el, idx) =>
            idx === currentIndex ? { ...el, ...newVals } : el
        );
        this.setState({
            selectedSubs: subs
        });
    };

    handleSwitchChange = (field: string, isActive: boolean) => {
        const { selectedSubs, currentIndex } = this.state;
        const subs = selectedSubs.map((el, idx) =>
            idx === currentIndex ? { ...el, [field]: isActive } : el
        );

        this.setState({
            selectedSubs: subs
        });
    };

    handleSave = () => {
        const { selectedSubs } = this.state;
        const subs = selectedSubs.filter(el => el && !!el.subId);
        this.props.saveSubs(subs);
        this.handleCloseModal();
    };

    handleAddSubs = () => {
        const { selectedSubs, currentIndex } = this.state;
        this.setState({
            selectedSubs: [...selectedSubs, { ...defaultSubs }],
            currentIndex: currentIndex + 1
        });
    };

    handleNext = () => {
        const { currentIndex, selectedSubs } = this.state;
        const { subs } = this.props;
        if (currentIndex === -1) {
            this.handleAddSubs();
        } else if (currentIndex < selectedSubs.length - 1) {
            this.setState({
                currentIndex: currentIndex + 1
            });
        } else if (subs.length > selectedSubs.length) {
            this.handleAddSubs();
        }
    };

    handlePrevious = () => {
        const { currentIndex } = this.state;
        if (currentIndex > 0) {
            this.setState({
                currentIndex: currentIndex - 1
            });
        }
    };

    render() {
        const { isModalOpen, selectedSubs, currentIndex } = this.state;
        const { subs } = this.props;
        const selectedSubsObj: { [key: string]: PricingSubTypes } = selectedSubs.reduce(
            (acc, next) => ({ ...acc, [next.subId]: next }),
            {}
        );
        const currentSubs = selectedSubs[currentIndex];

        return (
            <>
                <InsetButton onClick={this.handleOpenModal.bind(this)} flat>
                    Edit Subs
                    <Inset>
                        <img src={edit} />
                    </Inset>
                </InsetButton>
                {isModalOpen && (
                    <Modal onClose={this.handleCloseModal.bind(this)}>
                        <div className={styles.modalBody}>
                            <header className={styles.header}>Edit Susbscriptions</header>
                            <div className={styles.formContent}>
                                <div>
                                    {currentIndex > -1 ? (
                                        <div className={styles.inputBox}>
                                            <label className={styles.label}>Subs Template</label>
                                            <select
                                                value={currentSubs?.subId}
                                                onChange={this.handleSelectChange}
                                                name="subId"
                                                disabled={!!currentSubs?.subId}
                                                className={styles.select}
                                            >
                                                <option value="">Select Subs </option>
                                                {subs.map(el => (
                                                    <option
                                                        disabled={!!selectedSubsObj[el.sub_id]}
                                                        key={el.sub_id}
                                                        value={el.sub_id}
                                                    >
                                                        {el.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : null}
                                    {currentSubs?.subId ? (
                                        <div>
                                            <div className={styles.inputBox}>
                                                <label className={styles.label}>Subs Name</label>
                                                <input
                                                    type="text"
                                                    value={currentSubs?.name || ""}
                                                    name="name"
                                                    onChange={this.handleChange}
                                                    className={styles.input}
                                                />
                                            </div>
                                            <div className={styles.inputBox}>
                                                <label className={styles.label}>Discount</label>
                                                <input
                                                    type="number"
                                                    value={currentSubs?.discount || 0}
                                                    name="discount"
                                                    onChange={this.handleChange}
                                                    className={styles.input}
                                                />
                                            </div>
                                            <div className={styles.inputBox}>
                                                <label className={styles.label}>
                                                    Discount Type
                                                </label>
                                                <select
                                                    value={currentSubs?.discountType}
                                                    onChange={this.handleSelectChange}
                                                    name="discountType"
                                                    className={styles.select}
                                                >
                                                    <option value="">Select Discount type</option>
                                                    <option value="PERCENT">Percent</option>
                                                    <option value="AMOUNT">Amount</option>
                                                </select>
                                            </div>
                                            <div className={styles.inputBox}>
                                                <label className={styles.label}>Is Popular</label>
                                                <SwitchButton
                                                    isActive={currentSubs?.isPopular || false}
                                                    onSwitch={(e, isActive) => {
                                                        this.handleSwitchChange(
                                                            "isPopular",
                                                            isActive
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.inputBox}>
                                                <label className={styles.label}>Is Selected</label>
                                                <SwitchButton
                                                    isActive={currentSubs?.isSelected || false}
                                                    onSwitch={(e, isActive) => {
                                                        this.handleSwitchChange(
                                                            "isSelected",
                                                            isActive
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.selectSubsInfo}>
                                            Select subs template to edit template info
                                        </div>
                                    )}
                                </div>
                                <div className={styles.footer}>
                                    <div>
                                        {currentIndex > 0 ? (
                                            <Button
                                                onClick={this.handlePrevious}
                                                flat
                                                background="purple"
                                            >
                                                Previous
                                            </Button>
                                        ) : null}
                                    </div>
                                    <div className={styles.right}>
                                        {currentIndex < subs.length - 1 ? (
                                            <Button
                                                onClick={this.handleNext}
                                                flat
                                                background="purple"
                                            >
                                                {currentIndex === -1 ? "Add" : " Next"}
                                            </Button>
                                        ) : null}
                                        {selectedSubs.length ? (
                                            <Button
                                                onClick={this.handleSave}
                                                flat
                                                background="purple"
                                            >
                                                Submit
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </>
        );
    }
}

export default PricingSubs;
