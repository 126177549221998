var WebFont = require("webfontloader");

WebFont.load({
    classes: false,
    google: {
        families: ["Maven+Pro:400,500,700,900"]
    }
});

export default WebFont;
