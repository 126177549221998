import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";
import { appStateType } from "reducers";

export function saveSettings(id: string) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const state = getState();
        const { settings } = state.drip;

        const cron = settings.find((cron: any) => id == cron._id) as any;
        dispatch({
            type: actionTypes.START_SAVE_DRIP_SETTINGS
        });

        api()
            .put("drip/settings", {
                id,
                campaignId: cron.campaignId,
                productId: cron.productId,
                ordersMin: Number(cron.ordersMin),
                ordersMax: Number(cron.ordersMax),
                isActive: cron.isActive
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.SAVE_DRIP_SETTINGS,
                    payload: { settings: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ERROR_SAVE_DRIP_SETTINGS,
                    payload: { error: err.response }
                });
            });
    };
}

export function toggleCronActive(id: string, isActive: boolean) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const state = getState();
        const { settings } = state.drip;

        const cron = settings.find((cron: any) => id == cron._id) as any;
        dispatch({
            type: actionTypes.TOGGLE_DRIP_START
        });

        api()
            .put("drip/toggle", {
                id,
                isActive
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.TOGGLE_DRIP,
                    payload: { settings: { ...res.data } }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.TOGGLE_DRIP_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function pullDripSettings(settings: any) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.START_PULL_DRIP_SETTINGS
        });

        api()
            .get("drip/settings", settings)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_DRIP_SETTINGS,
                    payload: { settings: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.START_SAVE_DRIP_SETTINGS,
                    payload: { error: err.response }
                });
            });
    };
}

export function updateField(params: any) {
    return {
        type: actionTypes.UPDATE_DRIP_SETTING,
        payload: params
    };
}

export function getFile(file: File) {
    return {
        type: actionTypes.DRIP_GET_FILE,
        payload: { file }
    };
}

export function uploadCards(file: File) {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const state = getState();
        const { file } = state.drip as { file: File };
        const data = new FormData();
        data.append("file", file);

        dispatch({
            type: actionTypes.START_DRIP_UPLOAD_CARDS
        });

        api()
            .post("drip/addcards", data, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DRIP_UPLOAD_CARDS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ERROR_DRIP_UPLOAD_CARDS,
                    payload: { error: err.response }
                });
            });
    };
}

export function pullDripCards(settings: any) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.START_PULL_DRIP_CARDS
        });

        api()
            .get("drip/cards", settings)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_DRIP_CARDS,
                    payload: { cards: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.START_PULL_DRIP_CARDS,
                    payload: { error: err.response }
                });
            });
    };
}

export function addCron() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_SETTINGS_START
        });

        api()
            .post("drip/settings")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_SETTINGS,
                    payload: { settings: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ADD_SETTINGS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function deleteCron(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_SETTINGS_START
        });

        api()
            .delete("drip/settings", {
                data: {
                    id
                }
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DELETE_SETTINGS,
                    payload: { id }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.DELETE_SETTINGS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function deleteCard(id: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_CARD_START
        });

        api()
            .delete("drip/card", {
                data: {
                    id
                }
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.DELETE_CARD,
                    payload: { id }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.DELETE_CARD_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function toggleCard(id: string, isDeclined: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.TOGGLE_CARD_START
        });

        api()
            .put("drip/card", {
                id,
                isDeclined: !isDeclined
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.TOGGLE_CARD,
                    payload: { id, isDeclined }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.TOGGLE_CARD_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function getCCDripReport() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CC_DRIP_REPORT_START
        });

        api()
            .post("drip/report")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.CC_DRIP_REPORT,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.CC_DRIP_REPORT_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}
