import background from "assets/backgrounds/square_bg.png";
import * as moment from "moment-timezone";
import * as React from "react";
import styles from "./styles.scss";
import "moment-duration-format";

class Timer extends React.Component<any, any> {
    interval: number;

    state = {
        time: 0
    };

    componentDidMount() {
        const { validTo } = this.props;

        this.setState({ time: this.getTimeDiff() });

        if (validTo) {
            this.interval = window.setInterval(() => {
                this.setState({ time: this.getTimeDiff() });
            }, 1000);
        }
    }

    getTimeDiff() {
        const { validTo } = this.props;

        if (validTo) {
            const date = moment(validTo, "dddd, MMMM Do YYYY, h:mm:ss a");
            const duration = moment.duration(date.diff(moment()));
            const ms = duration.asMilliseconds();
            if (ms < 0) {
                return 0;
            }
            return ms;
        }
        return 0;
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    render() {
        const { time } = this.state;

        return (
            <div className={styles.timer}>
                <p>
                    {moment.duration(time, "ms").format("mm:ss", {
                        trim: false
                    })}
                </p>
            </div>
        );
    }
}

export default Timer;
