import * as React from "react";
import * as actions from "./actions";
import LoadingIndicator from "components/LoadingIndicator";
import Token from "./Token";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { token } from "types/token";

class Tokens extends React.PureComponent<any, any> {
    constructor(props: object) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.handleSubmitToken = this.handleSubmitToken.bind(this);
    }

    componentDidMount() {
        this.props.getTokens();
    }

    onChange(e: React.SyntheticEvent) {
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement;
        this.props.updateField(name, value);
    }

    handleSubmitToken(e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.addToken();
    }

    deleteToken(id: string, e: MouseEvent) {
        e.preventDefault();
        this.props.deleteToken(id);
    }

    render() {
        const {
            tokens,
            addTokenLoading,
            getTokensLoading,
            deleteTokenLoading,
            form
        } = this.props.tokens;
        const { name, tokenkey, accounts } = form;
        const { onChange, handleSubmitToken, deleteToken } = this;

        return (
            <>
                <header>
                    <h1>Tokens</h1>
                </header>
                <main>
                    <div className={styles.add_token_panel}>
                        <form onSubmit={handleSubmitToken} id="tokenForm">
                            <div>
                                Name:
                                <input
                                    spellCheck={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    onChange={onChange}
                                    name="name"
                                    value={name}
                                    type="text"
                                    placeholder="Any Name"
                                />
                            </div>
                            <div>
                                Token Key:
                                <input
                                    spellCheck={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    onChange={onChange}
                                    name="tokenkey"
                                    value={tokenkey}
                                    type="text"
                                    placeholder="FB Access Key"
                                />
                            </div>
                            <div>
                                Fb Accounts:
                                <input
                                    spellCheck={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    onChange={onChange}
                                    name="accounts"
                                    value={accounts}
                                    type="text"
                                    placeholder="FB Accounts splitted by comma"
                                />
                            </div>

                            <div className={styles.submitButton}>
                                {addTokenLoading ? (
                                    <LoadingIndicator
                                        className={styles.loaderIndicator}
                                    />
                                ) : null}
                                <button
                                    className={`${styles.primary}`}
                                    type="submit"
                                    form="tokenForm"
                                >
                                    {" "}
                                    {addTokenLoading ? "" : "ADD TOKEN"}
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className={styles.tokensBox}>
                        {getTokensLoading ? (
                            <LoadingIndicator className={styles.pageLoader} />
                        ) : (
                            tokens.map((token: token, index: number) => (
                                <Token
                                    key={index}
                                    token_obj={token}
                                    deleteToken={deleteToken.bind(
                                        this,
                                        token._id
                                    )}
                                    editToken={deleteToken}
                                    deleting={deleteTokenLoading}
                                />
                            ))
                        )}
                    </div>
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    tokens: state.tokens
});

export default connect(
    mapStateToProps,
    actions
)(Tokens);
