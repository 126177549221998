import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { AppThunk } from "store";
import { productGroup } from "types/productGroup";

export function getProductGroups(): AppThunk<Promise<void>> {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_PRODUCT_GROUPS_START
        });

        return api()
            .get("productgroup")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_PRODUCT_GROUPS,
                    payload: { ...res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_PRODUCT_GROUPS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export const addProductGroup = (productGroupData: productGroup): AppThunk<Promise<void>> => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_PRODUCT_GROUP_START
        });

        return api()
            .post("productgroup", productGroupData)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_PRODUCT_GROUP,
                    payload: { ...res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.ADD_PRODUCT_GROUP_ERROR,
                    payload: { error: err.response }
                });
            });
    };
};

export const updateProductGroup = (productGroupData: productGroup): AppThunk<Promise<void>> => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_PRODUCT_GROUP_START
        });

        return api()
            .put(`productgroup/${productGroupData._id}`, productGroupData)
            .then((res: AxiosResponse) => {
                {
                    dispatch({
                        type: actionTypes.UPDATE_PRODUCT_GROUP,
                        payload: { ...res.data }
                    });
                }
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.UPDATE_PRODUCT_GROUP_ERROR,
                    payload: { error: err.response }
                });
            });
    };
};

export function removeProductGroup(productGroupId: string): AppThunk<Promise<void>> {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_PRODUCT_GROUP_START,
            payload: {
                id: productGroupId
            }
        });

        return api()
            .delete(`productgroup/${productGroupId}`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REMOVE_PRODUCT_GROUP,
                    payload: { ...res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REMOVE_PRODUCT_GROUP_ERROR,
                    payload: { error: err.response, id: productGroupId }
                });
            });
    };
}
