import * as types from "actionTypes";
import _ from "lodash";
import getSchemaByKey from "services/getSchemaByKey";
import { element } from "types/element";
import { primeValue } from "types/primeValue";

export type elementsStateType = {
    addElementLoading: boolean;
    currentInvalidPaths: string[];
    currentSchema: any;
    editElementsLoadingIds: string[];
    elements: element[];
    elementsTree: any;
    getElementsLoading: boolean;
    isChooseOneLoading: boolean;
    isContentValid: boolean;
    schemaKey: string;
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: elementsStateType = {
    addElementLoading: false,
    currentInvalidPaths: [],
    currentSchema: {},
    editElementsLoadingIds: [],
    elements: [],
    elementsTree: null,
    getElementsLoading: false,
    isChooseOneLoading: false,
    isContentValid: false,
    schemaKey: ""
};

export const elementsStorage: { [key: string]: boolean } = {
    currentSchema: true,
    elementsTree: true,
    elements: true
};

const isContentValid = ({
    elementsTree,
    schema,
    path = "",
    schemaKey = ""
}: {
    elementsTree: any;
    schema: any;
    path?: string;
    schemaKey?: string;
}) => {
    let valid = true;
    const invalidPaths: string[] = [];

    if (schemaKey !== "") {
        schema = getSchemaByKey(schema, schemaKey);
    }

    Object.entries(schema)
        .filter(([key]) => key[0] !== "_")
        .map(([key, schemaNode]) => {
            let arrayType = "";
            let type = (schemaNode as any)._type;

            if (type.includes("[]")) {
                arrayType = type.replace("[]", "");
                type = "ARRAY";
            }

            const specialKeys = Object.keys(schemaNode as any).filter(key => key[0] == "_");

            if (specialKeys.includes("_file")) {
                if (type === "ARRAY") {
                    arrayType = "FILE";
                } else {
                    type = "FILE";
                }
            }

            let newPath = path;
            if (newPath === "") newPath += key;
            else newPath += "." + key;

            switch (type) {
                case "OBJECT":
                    const { invalidPaths: newPaths, valid: newValid } = isContentValid({
                        elementsTree,
                        schema: schemaNode,
                        path: newPath,
                        schemaKey
                    });

                    if (!newValid) {
                        valid = false;
                        invalidPaths.push(...newPaths);
                    }

                    break;
                case "ARRAY":
                    if (arrayType === "OBJECT") {
                        const array = _.get(elementsTree, newPath);

                        if (array == null) {
                            valid = false;
                            invalidPaths.push(newPath);
                        } else {
                            array.forEach((el: any, index: number) => {
                                const { invalidPaths: newPaths, valid: newValid } = isContentValid({
                                    elementsTree,
                                    schema: schemaNode,
                                    path: newPath + "." + String(index),
                                    schemaKey
                                });

                                if (!newValid) {
                                    valid = false;
                                    invalidPaths.push(newPath);
                                    invalidPaths.push(...newPaths);
                                }
                            });
                        }
                    } else {
                        const brick = _.get(elementsTree, newPath);

                        if (brick == null) {
                            valid = false;
                            invalidPaths.push(newPath);
                        } else {
                            brick.forEach((el: any, index: number) => {
                                if (
                                    el == undefined ||
                                    !el.hasOwnProperty("variants") ||
                                    el.variants.length === 0
                                ) {
                                    valid = false;
                                    invalidPaths.push(newPath);
                                }
                            });
                        }
                    }

                    break;
                case "UPSELLS":
                case "PRODUCTS":
                case "BUNDLES":
                case "FLAVOUR_PRODUCTS":
                    const array = _.get(elementsTree, newPath);

                    if (!array || !array.length) {
                        valid = false;
                        invalidPaths.push(newPath);
                    } else {
                        array.forEach((el: any, index: number) => {
                            if (typeof schemaNode === "object") {
                                const newNode = { ...schemaNode, _type: "OBJECT" };
                                const query = {
                                    elementsTree,
                                    schema: newNode,
                                    path: newPath + "." + String(index)
                                };
                                const result = isContentValid(query);

                                const { invalidPaths: newPaths, valid: newValid } = result;

                                if (!newValid) {
                                    valid = false;
                                    invalidPaths.push(newPath);
                                    invalidPaths.push(...newPaths);
                                }
                            }
                        });
                    }

                    break;
                default: {
                    const brick = _.get(elementsTree, newPath + (false ? ".0" : ""));

                    if (
                        brick == undefined ||
                        !brick.hasOwnProperty("variants") ||
                        brick.variants.length === 0
                    ) {
                        valid = false;
                        invalidPaths.push(newPath);
                    }
                }
            }
        });

    return { invalidPaths, valid };
};

export default (state: elementsStateType = initialState, action: IAction): elementsStateType => {
    let elementId: string;
    switch (action.type) {
        case types.GET_ELEMENTS_START:
            return {
                ...state,
                elements: [],
                getElementsLoading: true
            };
        case types.GET_ELEMENTS:
            return {
                ...state,
                elements: action.payload.elements,
                getElementsLoading: false
            };
        case types.GET_ELEMENTS_ERROR:
            return {
                ...state,
                getElementsLoading: false
            };
        case types.ADD_ELEMENT_START:
            return {
                ...state,
                addElementLoading: true
            };
        case types.ADD_ELEMENT:
            return {
                ...state,
                elements: [...state.elements, ...action.payload.elements],
                addElementLoading: false
            };
        case types.ADD_ELEMENT_ERROR:
            return {
                ...state,
                addElementLoading: false
            };
        case types.DELETE_ELEMENT_START:
            return {
                ...state,
                addElementLoading: true
            };
        case types.DELETE_ELEMENT:
            return {
                ...state,
                elements: state.elements.filter((element: any) => element._id != action.payload.id),
                addElementLoading: false
            };
        case types.DELETE_ELEMENT_ERROR:
            return {
                ...state,
                addElementLoading: false
            };
        case types.UPDATE_ELEMENT_FIELD: {
            const { id, value } = action.payload;
            const newElements = state.elements;

            newElements.forEach((element: any) => {
                if (element._id === id) {
                    element.name = value;
                }
            });

            return {
                ...state,
                elements: newElements
            };
        }
        case types.UPDATE_ELEMENT_START:
            return {
                ...state,
                addElementLoading: true
            };
        case types.UPDATE_ELEMENT:
            return {
                ...state,
                elements: [...state.elements].map((element: element) =>
                    element._id === action.payload.element._id ? action.payload.element : element
                ),
                addElementLoading: false
            };
        case types.UPDATE_ELEMENT_ERROR:
            return {
                ...state,
                addElementLoading: false
            };
        case types.UPDATE_ELEMENT_CHOOSEONE_START:
            const { isActive } = action.payload;
            elementId = action.payload.elementId;
            return {
                ...state,
                elements: state.elements.map((element: element) =>
                    element._id === elementId ? { ...element, chooseOneActive: isActive } : element
                ),
                isChooseOneLoading: true
            };
        case types.UPDATE_ELEMENT_CHOOSEONE:
            return {
                ...state,
                isChooseOneLoading: false
            };
        case types.UPDATE_ELEMENT_CHOOSEONE_ERROR:
            elementId = action.payload.elementId;
            return {
                ...state,
                elements: state.elements.map((element: element) =>
                    element._id === elementId ? { ...element, chooseOneActive: false } : element
                ),
                isChooseOneLoading: false
            };
        case types.SET_ELEMENTS_TREE: {
            const { elementsTree } = action.payload;
            const { invalidPaths, valid } = isContentValid({
                elementsTree,
                schema: state.currentSchema,
                schemaKey: state.schemaKey
            });

            return {
                ...state,
                elementsTree,
                isContentValid: valid,
                currentInvalidPaths: invalidPaths
            };
        }
        case types.ADD_ELEMENTS_TREE_VARIANT_START: {
            let { path, value } = action.payload;
            if (path[0] === ".") path = path.slice(1);
            const newElementsTree = { ...state.elementsTree };
            let oldBrick = _.get(newElementsTree, path);

            if (!oldBrick) {
                oldBrick = {
                    variants: []
                };
            }

            if (Array.isArray(oldBrick)) {
                oldBrick = oldBrick[0];
            }

            _.set(newElementsTree, path, {
                ...oldBrick,
                variants: [
                    ...oldBrick.variants,
                    { _id: `pre-variant-${oldBrick.variants.length}`, value }
                ]
            });

            const { invalidPaths, valid } = isContentValid({
                elementsTree: newElementsTree,
                schema: state.currentSchema,
                schemaKey: state.schemaKey
            });

            return {
                ...state,
                elementsTree: newElementsTree,
                isContentValid: valid,
                currentInvalidPaths: invalidPaths
            };
        }
        case types.ADD_ELEMENTS_TREE_VARIANT: {
            const newElementsTree = { ...state.elementsTree };
            let { path, brick } = action.payload;

            if (path[0] === ".") path = path.slice(1);

            _.set(newElementsTree, path, brick);

            const { invalidPaths, valid } = isContentValid({
                elementsTree: newElementsTree,
                schema: state.currentSchema,
                schemaKey: state.schemaKey
            });

            return {
                ...state,
                elementsTree: newElementsTree,
                isContentValid: valid,
                currentInvalidPaths: invalidPaths
            };
        }
        case types.REMOVE_ELEMENTS_TREE_VARIANT: {
            const newElementsTree = { ...state.elementsTree };
            let { path, variantId } = action.payload;
            if (path[0] === ".") path = path.slice(1);
            const variantsPath = path + ".variants";

            const variants = _.get(newElementsTree, variantsPath).filter(
                (variant: primeValue) => variant._id != variantId
            );

            _.set(newElementsTree, variantsPath, variants);

            const { invalidPaths, valid } = isContentValid({
                elementsTree: newElementsTree,
                schema: state.currentSchema,
                schemaKey: state.schemaKey
            });

            return {
                ...state,
                elementsTree: newElementsTree,
                isContentValid: valid,
                currentInvalidPaths: invalidPaths
            };
        }
        case types.ADD_ARRAY_NODE: {
            const newElementsTree = { ...state.elementsTree };
            let { path } = action.payload;
            if (path[0] === ".") path = path.slice(1);

            const oldArray = _.get(newElementsTree, path);
            oldArray.push(null);

            const { invalidPaths, valid } = isContentValid({
                elementsTree: newElementsTree,
                schema: state.currentSchema,
                schemaKey: state.schemaKey
            });

            return {
                ...state,
                elementsTree: newElementsTree,
                isContentValid: valid,
                currentInvalidPaths: invalidPaths
            };
        }
        case types.DELETE_ARRAY_NODE: {
            const newElementsTree = { ...state.elementsTree };
            let { path } = action.payload;
            if (path[0] === ".") path = path.slice(1);

            let parentPath = path.split(".");
            const deleteIndex = parentPath.pop();
            parentPath = parentPath.join(".");

            const parentArray = _.get(newElementsTree, parentPath);
            const newArray = parentArray.filter(
                (el: any, index: number) => index != Number(deleteIndex)
            );
            _.set(newElementsTree, parentPath, newArray);

            const { invalidPaths, valid } = isContentValid({
                elementsTree: newElementsTree,
                schema: state.currentSchema,
                schemaKey: state.schemaKey
            });

            return {
                ...state,
                elementsTree: newElementsTree,
                isContentValid: valid,
                currentInvalidPaths: invalidPaths
            };
        }
        case types.ELEMENTS_SET_SCHEMA:
            const { schema } = action.payload;

            return {
                ...state,
                currentSchema: schema
            };
        case types.SET_SCHEMA_KEY:
            const { schemaKey } = action.payload;

            return {
                ...state,
                schemaKey
            };
        default:
            return state;
    }
};
