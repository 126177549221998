import React, { useState } from "react";
import styles from "./styles.scss";

type Props = {
    body: { [k: string]: any } | null;
    headline: string;
    className?: string;
    headlineClassName?: string;
};

const JsonFormatter: React.FC<Props> = ({ body, headline, className, headlineClassName }) => {
    const [show, setShow] = useState<boolean>(false);

    const handleShowClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
        setShow(show => !show);
    };

    return (
        <div className={className}>
            <div className={styles.headlineBox}>
                <div className={headlineClassName}>{headline}</div>
                {body && (
                    <div className={styles.arrow} onClick={handleShowClick}>
                        {show ? "▲" : "▼"}
                    </div>
                )}
            </div>
            {body && show && (
                <div>
                    <pre>{JSON.stringify(body, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default JsonFormatter;
