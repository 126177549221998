import _ from "lodash";
import { tabs } from "types/tabs";

const ignoredContentNames = [
    "newUpsells",
    "__CURRENCY",
    "__STYLES",
    "__PRODUCTS",
    "__BUNDLES",
    "__FLAVOUR_PRODUCTS"
];

const getSchemaByKey = (obj: any, key: string) => {
    if (key === "content") {
        obj = _.omit(obj, [...tabs.filter(key => key !== "content"), ...ignoredContentNames]);
    } else {
        obj = _.pick(obj, [key]);
    }

    return obj;
};

export default getSchemaByKey;
