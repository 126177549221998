import api from "api";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function getWhitelist() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.GET_WHITELIST_START
    });

    api()
      .get("domain")
      .then((res: AxiosResponse) =>
        dispatch({
          type: actionTypes.GET_WHITELIST,
          payload: { whitelist: res.data }
        })
      )
      .catch((err: AxiosError) =>
        dispatch({
          type: actionTypes.GET_WHITELIST_ERROR,
          payload: { error: err.response }
        })
      );
  };
}

export function addDomain(name: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_DOMAIN_START
    });

    api()
      .post("domain", { name })
      .then((res: AxiosResponse) =>
        dispatch({
          type: actionTypes.ADD_NEW_DOMAIN,
          payload: { domain: res.data }
        })
      )
      .catch((err: AxiosError) =>
        dispatch({
          type: actionTypes.ADD_NEW_DOMAIN_ERROR,
          payload: { error: err.response }
        })
      );
  };
}

export function deleteDomain(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_NEW_DOMAIN_START
    });

    api()
      .delete("domain", { data: { id } })
      .then((res: AxiosResponse) =>
        dispatch({
          type: actionTypes.REMOVE_NEW_DOMAIN,
          payload: { domain: res.data }
        })
      )
      .catch((err: AxiosError) =>
        dispatch({
          type: actionTypes.REMOVE_NEW_DOMAIN_ERROR,
          payload: { error: err.response }
        })
      );
  };
}
