import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

export const deleteFunnel = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_FF_FUNNEL_START,
            payload: { id }
        });

        api()
            .delete("conversionbalancer/fffunnel", {
                data: {
                    id
                }
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.DELETE_FF_FUNNEL,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.DELETE_FF_FUNNEL_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const setEditFunnel = (id: string | null) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.SET_EDIT_FUNNEL_ID,
            payload: { id }
        });
    };
};
