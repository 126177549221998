import cx from "classnames";
import LoadingIndicator from "components/LoadingIndicator";
import SwitchButton from "components/SwitchButton";
import * as React from "react";
import { connect } from "react-redux";
import { Omit } from "types/utilities";
import { Version as VersionModel } from "types/version";
import * as actions from "./actions";
import styles from "./styles.scss";

interface State extends Omit<VersionModel, "commit" | "template" | "variables"> {
    editMode: boolean | any;
}

interface Props extends VersionModel {
    error: string;
    isRemoving: boolean;
    isSaving: boolean;
    mode?: "tooltip";
    removeVersion: (id: string) => void;
    saveVersion: (version: State) => void;
    archieveVersion: (versionId: string) => void;
}

class Version extends React.Component<Props, State> {
    state = {
        _id: "",
        codebase: "",
        createdAt: "",
        description: "",
        editMode: false,
        released: false,
        tag: "",
        title: "",
        isPagePreviewEnabled: false
    };

    componentDidMount() {
        const {
            _id,
            codebase,
            createdAt,
            description,
            released,
            tag,
            title,
            isPagePreviewEnabled
        } = this.props;

        this.setState({
            _id,
            codebase,
            createdAt,
            description,
            editMode: !released,
            released,
            tag,
            title,
            isPagePreviewEnabled
        });
    }

    handleChange(e: React.SyntheticEvent) {
        const { value, name } = e.target as HTMLInputElement;
        this.setState<never>({ [name]: value });
    }

    handleSwitchReleased() {
        this.setState(prevState => ({
            released: !prevState.released
        }));
    }

    handleSwitchPreview() {
        this.setState(prevState => ({
            isPagePreviewEnabled: !prevState.isPagePreviewEnabled
        }));
    }

    handleSave() {
        this.props.saveVersion(this.state);

        if (this.state.released === true) {
            this.setState({ editMode: false });
        }
    }

    handleEdit() {
        this.setState(prevState => ({
            editMode: !prevState.editMode
        }));
    }

    handleRemove() {
        this.props.removeVersion(this.state._id);
    }

    handleArchieve() {
        this.props.archieveVersion(this.props._id);
    }

    render() {
        const {
            error,
            isRemoving,
            isSaving,
            mode,
            template: { name, shortcut }
        } = this.props;

        const {
            codebase,
            createdAt,
            description,
            editMode,
            released,
            tag,
            title,
            isPagePreviewEnabled
        } = this.state;

        return (
            <div className={cx(styles.version, { [styles.active]: !released })}>
                <div>
                    <span>Date:</span> {createdAt.toString()}
                </div>
                <div>
                    <span>Template:</span> {shortcut} ({name})
                </div>
                <div>
                    <span>Codebase: </span>
                    <a href={codebase ? codebase : "#"} target="_blank">
                        {codebase}
                    </a>
                </div>
                <div>
                    <span>Version: </span>
                    {editMode ? (
                        <input
                            name="tag"
                            onChange={this.handleChange.bind(this)}
                            type="text"
                            value={tag}
                        />
                    ) : (
                        tag
                    )}
                </div>
                <div>
                    <span>Title: </span>
                    {editMode ? (
                        <input
                            name="title"
                            onChange={this.handleChange.bind(this)}
                            type="text"
                            value={title}
                        />
                    ) : (
                        title
                    )}
                </div>
                {(description || editMode) && (
                    <div className={styles.description}>
                        <p>
                            <span>Description: </span>
                        </p>
                        {editMode ? (
                            <textarea
                                cols={50}
                                name="description"
                                onChange={this.handleChange.bind(this)}
                                rows={4}
                                value={description}
                            />
                        ) : (
                            description
                        )}
                    </div>
                )}
                {editMode && (
                    <div>
                        <span>Released: </span>
                        <SwitchButton
                            isActive={released}
                            onSwitch={this.handleSwitchReleased.bind(this)}
                        />
                    </div>
                )}
                {editMode && (
                    <div>
                        <span>Enable real-time preview: </span>
                        <SwitchButton
                            isActive={isPagePreviewEnabled}
                            onSwitch={this.handleSwitchPreview.bind(this)}
                        />
                    </div>
                )}
                {editMode && (
                    <div>
                        <button
                            className={styles.primary}
                            disabled={isSaving}
                            onClick={this.handleSave.bind(this)}
                        >
                            Save
                        </button>
                        <button
                            disabled={isSaving === true || isRemoving === true}
                            onClick={this.handleEdit.bind(this)}
                        >
                            Cancel
                        </button>
                        <button
                            className={styles.danger}
                            disabled={isRemoving}
                            onClick={this.handleRemove.bind(this)}
                        >
                            Remove
                        </button>
                    </div>
                )}
                {!editMode && mode !== "tooltip" ? (
                    <button disabled={isSaving} onClick={this.handleEdit.bind(this)}>
                        Edit
                    </button>
                ) : null}
                {!editMode && mode !== "tooltip" ? (
                    <button disabled={isSaving} onClick={this.handleArchieve.bind(this)}>
                        Archieve
                    </button>
                ) : null}
                {editMode && error ? <p>{error}</p> : null}
                {(isSaving === true || isRemoving === true) && (
                    <div>
                        <LoadingIndicator />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    isSavingVersion: state.deployment.isSavingVersion
});

export default connect(mapStateToProps, actions)(Version);
