import LoadingIndicator from "components/LoadingIndicator";
import Wistia from "components/Wistia";
import * as React from "react";
import { connect } from "react-redux";
import { appStateType } from "reducers";
import Modal from "../../index";
import * as actions from "./actions";
import styles from "./styles.scss";

class FileModal extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            name: "",
            tag: "",
            showTagList: false
        };
    }

    componentDidMount() {
        this.props.pullTags();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.isFileRemoved !== prevProps.isFileRemoved) {
            this.props.onClose();
        }
    }

    componentWillUnmount() {
        this.props.deleteErrors();
    }

    handleInputChange(e: React.SyntheticEvent) {
        const { value, name } = e.target as HTMLInputElement;
        this.setState({ [name]: value });
    }

    handleTagClick(e: React.SyntheticEvent) {
        const { innerHTML } = e.target as HTMLElement;

        this.setState({
            tag: innerHTML
        });
    }

    toggleTagList() {
        this.setState({ showTagList: !this.state.showTagList });
    }

    handleSave() {
        let { name, tag } = this.state;

        if (name == "") {
            name = this.props.file.name;
        }

        const hash = this.props.file.hash;

        this.props.updateFile({ name, tag, hash });
    }

    handleDelete(id: string) {
        this.props.deleteFile(id);
    }

    render() {
        const { deletingError, file, isDeletingFile, isUpdatingFile, onClose, tags } = this.props;
        const { _id: id, extensions, hash, name, path, tags: fileTags = [] } = file;
        const { showTagList } = this.state;
        const isVideo = file.extensions.includes("wistia");

        return (
            <Modal onClose={onClose.bind(this)}>
                <div className={styles.flex}>
                    <div className={this.props.view == "half" ? styles.half : styles.wide}>
                        {isVideo ? (
                            <Wistia hashedId={hash} />
                        ) : (
                            <img
                                className={styles.image}
                                src={`https://d3hlrrbqydii6y.cloudfront.net/${path}/${hash}.${extensions[0]}`}
                            />
                        )}
                        <p>{name}</p>
                        {fileTags.length ? (
                            <ul>
                                {fileTags.map((tag: any) => (
                                    <li key={tag.name}>{tag.name}</li>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                    {this.props.view == "half" && (
                        <div className={styles.half}>
                            <label>
                                Rename:
                                <input
                                    name="name"
                                    onChange={e => this.handleInputChange(e)}
                                    defaultValue={name}
                                />
                            </label>
                            <label className={styles.addTag}>
                                Add Tag (optional):
                                <input
                                    name="tag"
                                    onChange={e => this.handleInputChange(e)}
                                    onClick={() => this.toggleTagList()}
                                    value={this.state.tag}
                                />
                                {showTagList === true && (
                                    <div className={styles.tagList}>
                                        {tags.map((tag: any) => (
                                            <p key={tag.name} onClick={e => this.handleTagClick(e)}>
                                                {tag.name}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </label>
                            <div className={styles.button_box}>
                                {isUpdatingFile ? (
                                    <LoadingIndicator />
                                ) : (
                                    <button
                                        className={styles.primary}
                                        onClick={this.handleSave.bind(this)}
                                    >
                                        Save
                                    </button>
                                )}
                                {isDeletingFile ? (
                                    <LoadingIndicator />
                                ) : (
                                    <button onClick={this.handleDelete.bind(this, id)}>
                                        <img src="/img/svg/trash.svg" />
                                    </button>
                                )}
                            </div>
                            {deletingError && <p className={styles.error}>{deletingError}</p>}
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    deletingError: state.file.deletingError,
    isDeletingFile: state.file.isDeletingFile,
    isFileRemoved: state.file.isFileRemoved,
    isUpdatingFile: state.file.isUpdatingFile,
    tags: state.file.tags
});

export default connect(mapStateToProps, { ...actions })(FileModal);
