import * as React from "react";
import styles from "./styles.scss";
import classNames from "classnames";

interface Props {
    isActive: boolean;
    isOpen: boolean;
    children: any;
}

const Bookmark: React.FC<Props> = ({ isActive, isOpen, children }) => {
    return isOpen ? (
        <span className={classNames(styles.option, { [styles.active]: isActive })}>{children}</span>
    ) : null;
};

export default Bookmark;
