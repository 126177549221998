import * as actionTypes from "actionTypes";

export type treeStateType = {
    count: {};
    error: string;
    isLoading: boolean;
    isProgress: boolean;
    progress: number;
    tree: any[];
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: treeStateType = {
    count: {},
    error: "",
    isLoading: false,
    isProgress: false,
    progress: 0,
    tree: []
};

export const treeStorage: { [key: string]: boolean } = {};

export default (state: treeStateType = initialState, action: IAction): treeStateType => {
    switch (action.type) {
        case actionTypes.GET_TREE_START:
            return {
                ...state,
                isLoading: true,
                progress: 0
            };
        case actionTypes.GET_TREE:
            return {
                ...state,
                isLoading: false,
                tree: action.payload.tree,
                count: action.payload.count,
                error: ""
            };
        case actionTypes.GET_TREE_ERROR:
            return {
                ...state,
                isLoading: false,
                tree: [],
                error: action.payload.error
            };
        case actionTypes.INCREMENT_PROGRESS:
            return {
                ...state,
                progress: state.progress + 1
            };
        case actionTypes.COPY_COMPLETE:
            return {
                ...state,
                isProgress: false
            };
        case actionTypes.COPY_TREE_START:
            return {
                ...state,
                progress: 0,
                isProgress: true
            };
        default:
            return state;
    }
};
