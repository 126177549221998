import * as actionTypes from "actionTypes";
import _ from "lodash";

export type fileStateType = {
    deletingError: "";
    files: object[];
    isDeletingFile: boolean;
    isFileRemoved: boolean;
    isPullingFiles: boolean;
    isSavingVideo: boolean;
    isUpdatingFile: boolean;
    pullingError: object[];
    savingVideoError: string;
    tags: object[];
    uploading: object[];
};

interface IAction {
    type: string;
    payload: any;
}

const initialState: fileStateType = {
    deletingError: "",
    files: [],
    isDeletingFile: false,
    isFileRemoved: false,
    isPullingFiles: false,
    isSavingVideo: false,
    isUpdatingFile: false,
    pullingError: [],
    savingVideoError: "",
    tags: [],
    uploading: []
};

export const fileStorage: { [key: string]: boolean } = {
    files: true
};

export default (state: fileStateType = initialState, action: IAction): fileStateType => {
    switch (action.type) {
        case actionTypes.START_PULL_FILES:
            return {
                ...state,
                isPullingFiles: true
            };
        case actionTypes.PULL_FILES:
            return {
                ...state,
                isPullingFiles: false,
                files: action.payload.files
            };
        case actionTypes.ERROR_PULL_FILES:
            return {
                ...state,
                isPullingFiles: false,
                pullingError: action.payload.error
            };
        case actionTypes.UPLOAD_FILE_START:
            return {
                ...state,
                uploading: [...state.uploading, { name: action.payload.file.name }]
            };
        case actionTypes.UPLOAD_FILE:
            return {
                ...state,
                files: _.uniqBy([action.payload.file, ...state.files], (file: any) => file.hash),
                uploading: state.uploading.filter(
                    (file: any) => file.name !== action.payload.file.name
                )
            };
        case actionTypes.UPDATE_FILE_START:
            return {
                ...state,
                isUpdatingFile: true
            };
        case actionTypes.UPDATE_FILE:
            return {
                ...state,
                isUpdatingFile: false,
                files: [...state.files].map((file: any) =>
                    file.hash === action.payload.file.hash ? action.payload.file : file
                )
            };
        case actionTypes.UPDATE_FILE_ERROR:
            return {
                ...state,
                isUpdatingFile: false
            };
        case actionTypes.DELETE_FILE_START:
            return {
                ...state,
                isDeletingFile: true,
                isFileRemoved: false
            };
        case actionTypes.DELETE_FILE:
            return {
                ...state,
                isDeletingFile: false,
                isFileRemoved: true,
                files: [...state.files].filter(
                    (file: any) => file.hash !== action.payload.file.hash
                )
            };
        case actionTypes.DELETE_FILE_ERROR:
            return {
                ...state,
                isDeletingFile: false,
                isFileRemoved: false,
                deletingError: action.payload.error.data.message
            };
        case actionTypes.DELETE_FILE_ERRORS:
            return {
                ...state,
                deletingError: ""
            };
        case actionTypes.PULL_TAGS:
            return {
                ...state,
                tags: action.payload.tags
            };
        case actionTypes.SAVE_VIDEO_START:
            return {
                ...state,
                isSavingVideo: true
            };
        case actionTypes.SAVE_VIDEO:
            return {
                ...state,
                isSavingVideo: false
            };
        case actionTypes.SAVE_VIDEO_ERROR:
            return {
                ...state,
                isSavingVideo: false,
                savingVideoError: action.payload.error
            };
        default:
            return state;
    }
};
