import star from "assets/icons/star.svg";
import cx from "classnames";
import SearchList from "components/SearchList";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appStateType } from "reducers";
import DataPiece from "scenes/Dashboard/SchemaCMSEditor/components/DataPiece";
import getDollars from "services/getDollars";
import getKonnektiveProduct from "services/getKonnektiveProduct";
import { getOdooProduct } from "services/getOdooProduct";
import getPercentage from "services/getPercentage";
import parentContains from "services/parentContains";
import { ExtendedPricingProduct, PricingProduct } from "types/pricing";
import { product } from "types/product";
import { odooProduct } from "types/odooProduct";
import { PricingModelContext } from "../..";
import { PricingContext } from "../../../..";
import { editPricingModelProductDraft, redirectToPath } from "../../../../actions";
import DragButton from "../../../DragButton";
import headerStyles from "../Header/styles.scss";
import productsStyles from "../Products/styles.scss";
import styles from "./styles.scss";
import isEqual from "lodash/isEqual";
import PricingSubs from "components/Modal/scenes/PricingSubs";
import Button from "components/Button";
import CDNModal from "components/Modal/scenes/CDN";
import EditIcon from "assets/icons/edit-name.svg";
import KonnektiveSubscriptions from "scenes/Dashboard/KonnektiveSubscriptions";

const { useState, useRef, useContext, useMemo } = React;

interface Props {
    product: ExtendedPricingProduct;
    provided: any;
    isElements: boolean;
}

type EditorTypes =
    | "SEARCH_PRODUCT"
    | "GIFT_ID"
    | "STRIKETHROUGH_PRICE"
    | "PRICE"
    | "SP"
    | "PT"
    | "IMAGE"
    | "OPST"
    | "TITLE"
    | "TITLE2"
    | "AL"
    | "UID"
    | "UPGRADE_PRICE"
    | "VARIANTS"
    | "SUBS_REPLACE_PRODUCT"
    | "SUBS_REPLACE_PRICE"
    | "IMAGE"
    | "BUNDLE_SIZE"
    | "FREE_GIFT_ID"
    | "KS"
    | "UNITS"
    | null;

const Product: React.FC<Props> = ({ product, provided, isElements }) => {
    const dispatch = useDispatch();
    const { isDraft } = useContext(PricingModelContext);
    const {
        products,
        productListOptions,
        odooProductListOptions,
        crmMode,
        setId,
        languageId,
        versionId
    } = useContext(PricingContext);
    const [currentProduct, setCurrentProduct] = useState<(product | odooProduct) | null>(null);
    const [activeField, setActiveFieldValue] = useState<EditorTypes>();
    const [isProductHover, setProductHover] = useState<number>(0);
    const editorRef = useRef<HTMLDivElement>(null);
    const cdnRef = React.useRef(null);
    const isOdooCrm = crmMode !== "KONNEKTIVE";
    const productOptions = isOdooCrm ? odooProductListOptions : productListOptions;
    const { subscriptions: konnektiveSubscriptions } = useSelector(
        (state: appStateType) => state.subscription
    );
    const [selectedKonnektiveSubscriptions, deepSetSelectedKonnektiveSubscriptions] = useState<
        string[]
    >([]);

    const setSelectedKonnektiveSubscriptions = (newSelectedKonnektiveSubscriptions: string[]) => {
        deepSetSelectedKonnektiveSubscriptions(newSelectedKonnektiveSubscriptions);
        dispatch(
            editPricingModelProductDraft(product._id, {
                ...product,
                konnektiveSubscriptions: newSelectedKonnektiveSubscriptions
            })
        );
    };

    const handleAddKonnektiveSubscription = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;

        if (value !== "") {
            setSelectedKonnektiveSubscriptions([
                ...new Set([...selectedKonnektiveSubscriptions, value])
            ]);
        }
    };

    const handleRemoveKonnektiveSubscription = (id: string) => (e: React.SyntheticEvent) => {
        setSelectedKonnektiveSubscriptions(
            selectedKonnektiveSubscriptions.filter(sub => sub !== id)
        );
    };

    const productTypes = [
        {
            name: "OFFER",
            value: "OFFER"
        },
        {
            name: "WARRANTY",
            value: "WARRANTY"
        },
        {
            name: "EXPEDITED_SHIPPING",
            value: "EXPEDITED_SHIPPING"
        },
        {
            name: "SHIPPING",
            value: "SHIPPING"
        },
        {
            name: "SHIPPING_INSURANCE",
            value: "SHIPPING_INSURANCE"
        }
    ];

    React.useEffect(() => {
        if (!isOdooCrm) {
            if (products && product.productId) {
                const kProduct = getKonnektiveProduct(products, product.productId, "productId");

                if (kProduct) {
                    setCurrentProduct(kProduct);
                }
            }
        } else {
            if (product.productId) {
                const odooProduct = getOdooProduct(
                    Number(product.productId),
                    crmMode as "ODOO" | "ODOO_PROD" | "ODOO_DEV"
                );

                if (odooProduct) {
                    setCurrentProduct(odooProduct);
                }
            }
        }
    }, [product, products]);

    const handleChangeProduct = (product: ExtendedPricingProduct, field: keyof PricingProduct) => {
        if (isOdooCrm) {
            let newProductData: ExtendedPricingProduct = { ...product };

            switch (field) {
                case "productId": {
                    const odooProduct = getOdooProduct(
                        Number(product.productId),
                        crmMode as "ODOO" | "ODOO_PROD" | "ODOO_DEV"
                    );
                    if (odooProduct) {
                        newProductData = {
                            ...product,
                            price: String(odooProduct.price),
                            title: odooProduct.name,
                            title2: odooProduct.name,
                            shippingPrice: String(odooProduct.cos),
                            subscriptions: [],
                            bundleSize: odooProduct.units
                        };
                    }
                    break;
                }
                case "subsReplaceProduct": {
                    const subsProduct = getOdooProduct(
                        Number(product.productId),
                        crmMode as "ODOO" | "ODOO_PROD" | "ODOO_DEV"
                    );
                    if (subsProduct) {
                        newProductData = {
                            ...product,
                            subsReplacePrice: String(subsProduct.price)
                        };
                    }
                    break;
                }
                case "upgradeId": {
                    const upgradeProduct = getOdooProduct(
                        Number(product.upgradeId),
                        crmMode as "ODOO" | "ODOO_PROD" | "ODOO_DEV"
                    );
                    if (upgradeProduct) {
                        newProductData = {
                            ...product,
                            upgradePrice: String(upgradeProduct.price)
                        };
                    }
                    break;
                }
            }

            dispatch(
                editPricingModelProductDraft(product._id, {
                    ...newProductData
                })
            );

            const selectorFields = [
                "subscriptions",
                "upgradeId",
                "subsReplaceProduct",
                "productId",
                "giftId"
            ];

            if (selectorFields.includes(field)) {
                setActiveFieldValue(null);
            }
        } else {
            if (field === "productId") {
                if (products) {
                    const kProduct = getKonnektiveProduct(products, product.productId, "productId");
                    if (kProduct) {
                        dispatch(
                            editPricingModelProductDraft(product._id, {
                                ...product,
                                price: kProduct.cycle1_price,
                                title: kProduct.productDescription,
                                title2: kProduct.productDescription,
                                shippingPrice: "0"
                            })
                        );
                    }

                    setActiveFieldValue(null);
                }
            } else {
                dispatch(editPricingModelProductDraft(product._id, product));
            }
        }
    };

    const redirectToCms = (elementId?: string) => {
        return () => {
            if (!elementId) {
                return;
            }

            const redirectPath = `/dashboard/cms/${setId}/${languageId}/${versionId}/pricing/products/${elementId}`;
            dispatch(redirectToPath(redirectPath));
        };
    };

    const setActiveField = (newActiveField: EditorTypes, e: MouseEvent) => {
        e.stopPropagation();

        if (!isDraft) {
            return;
        }

        setActiveFieldValue(prevActiveField => {
            if (prevActiveField !== newActiveField) {
                const handleWindowClick = (e: MouseEvent) => {
                    if (editorRef.current) {
                        if (!parentContains(editorRef.current, e.target as Node)) {
                            setActiveFieldValue(null);
                            window.removeEventListener("click", handleWindowClick, false);
                        }
                    }
                };

                if (newActiveField !== null) {
                    window.addEventListener("click", handleWindowClick, false);
                }
            }

            return newActiveField;
        });
    };

    const getEditor = () => {
        switch (activeField) {
            case "STRIKETHROUGH_PRICE":
                return (
                    <input
                        type="number"
                        placeholder="New Strikethrough Price"
                        className={styles.priceField}
                        value={product.strikethroughPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    strikethroughPrice: e.target.value
                                },
                                "strikethroughPrice"
                            )
                        }
                        onBlur={setActiveField.bind(null, "STRIKETHROUGH_PRICE")}
                    />
                );
            case "UNITS":
                return (
                    <input
                        type="number"
                        placeholder="New Units Number"
                        className={styles.priceField}
                        value={product.units}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    units: Number(e.target.value)
                                },
                                "units"
                            )
                        }
                        onBlur={setActiveField.bind(null, "UNITS")}
                    />
                );
            case "PRICE":
                return (
                    <input
                        type="number"
                        placeholder="New Price"
                        className={styles.priceField}
                        value={product.price}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    price: e.target.value
                                },
                                "price"
                            )
                        }
                        onBlur={setActiveField.bind(null, "PRICE")}
                    />
                );
            case "PT":
                return (
                    <select
                        className={styles.select}
                        value={product.productType}
                        onChange={(e: React.ChangeEvent<any>) => {
                            handleChangeProduct(
                                {
                                    ...product,
                                    productType: e.target.value
                                },
                                "productType"
                            );
                            setActiveFieldValue(null);
                        }}
                    >
                        <option value="">select product type</option>
                        {productTypes.map(el => (
                            <option value={el.value} key={el.name}>
                                {el.name}
                            </option>
                        ))}
                    </select>
                );
            case "VARIANTS":
                return (
                    <input
                        type="text"
                        placeholder="variant list ex. RED,KING,TWO"
                        className={styles.priceField}
                        value={product.variants}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    variants: e.target.value
                                },
                                "variants"
                            )
                        }
                        onBlur={setActiveField.bind(null, "VARIANTS")}
                    />
                );
            case "SP":
                return (
                    <input
                        type="number"
                        placeholder="New Shipping Price"
                        className={styles.priceField}
                        value={product.shippingPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    shippingPrice: e.target.value
                                },
                                "shippingPrice"
                            )
                        }
                        onBlur={setActiveField.bind(null, "PRICE")}
                    />
                );
            case "SEARCH_PRODUCT": {
                return (
                    <SearchList
                        options={productOptions}
                        onChange={(e: any, value: string) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    productId: value
                                },
                                "productId"
                            )
                        }
                    />
                );
            }
            case "GIFT_ID": {
                return (
                    <SearchList
                        options={productOptions}
                        onChange={(e: any, value: string) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    giftId: value
                                },
                                "giftId"
                            )
                        }
                    />
                );
            }
            case "SUBS_REPLACE_PRODUCT": {
                return (
                    <SearchList
                        options={productOptions}
                        onChange={(e: any, value: string) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    subsReplaceProduct: value
                                },
                                "subsReplaceProduct"
                            )
                        }
                    />
                );
            }
            case "OPST":
                return (
                    <div
                        className={styles.subscriptions}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <PricingSubs
                            subs={
                                currentProduct ? (currentProduct as odooProduct).subscriptions : []
                            }
                            selectedSubs={product.subscriptions || []}
                            saveSubs={subs => {
                                handleChangeProduct(
                                    { ...product, subscriptions: subs },
                                    "subscriptions"
                                );
                            }}
                        />
                    </div>
                );
            case "KS":
                return (
                    <div
                        className={styles.subscriptions}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <select onChange={handleAddKonnektiveSubscription} value={""}>
                            <option key={"empty-sub"} value={""}>
                                Select...
                            </option>
                            {konnektiveSubscriptions.map(sub => (
                                <option key={sub._id} value={sub._id}>
                                    {sub.name}
                                </option>
                            ))}
                        </select>
                        <div className={styles.subscriptions}>
                            {selectedKonnektiveSubscriptions.map(sub => {
                                const subObj = konnektiveSubscriptions.find(ks => ks._id === sub);

                                if (subObj) {
                                    return (
                                        <div key={subObj._id} className={styles.subscriptions}>
                                            <button
                                                onClick={handleRemoveKonnektiveSubscription(
                                                    subObj._id
                                                )}
                                            >
                                                X
                                            </button>
                                            {subObj.name}
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                );
            case "TITLE":
                return (
                    <input
                        type="text"
                        placeholder="Product title"
                        className={styles.priceField}
                        value={product.title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    title: e.target.value
                                },
                                "title"
                            )
                        }
                        onBlur={setActiveField.bind(null, "TITLE")}
                    />
                );
            case "TITLE2":
                return (
                    <input
                        type="text"
                        placeholder="Product title after subscription"
                        className={styles.priceField}
                        value={product.title2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    title2: e.target.value
                                },
                                "title2"
                            )
                        }
                        onBlur={setActiveField.bind(null, "TITLE2")}
                    />
                );
            case "AL":
                return (
                    <input
                        type="text"
                        placeholder="Amazon product link..."
                        className={styles.priceField}
                        value={product.amazonLink}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    amazonLink: e.target.value
                                },
                                "amazonLink"
                            )
                        }
                    />
                );
            case "UID": {
                return (
                    <SearchList
                        options={productOptions}
                        onChange={(e: any, value: string) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    upgradeId: Number(value)
                                },
                                "upgradeId"
                            )
                        }
                    />
                );
            }

            case "SUBS_REPLACE_PRICE":
                return (
                    <input
                        type="number"
                        placeholder="New Price"
                        className={styles.priceField}
                        value={product.subsReplacePrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    subsReplacePrice: e.target.value
                                },
                                "subsReplacePrice"
                            )
                        }
                        onBlur={setActiveField.bind(null, "SUBS_REPLACE_PRICE")}
                    />
                );
            case "IMAGE":
                return (
                    <div
                        className={styles.subscriptions}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Button onClick={() => (cdnRef.current as any).openModal()}>
                            Select Image
                        </Button>
                        <CDNModal
                            ref={cdnRef}
                            handleChooseFile={(arg: any) => {
                                const cloudfront = "https://d3hlrrbqydii6y.cloudfront.net";
                                const { path, hash } = arg;
                                const image = arg.extensions.reduce((prev: any, ext: string) => {
                                    prev[ext] = `${cloudfront}/${path}/${hash}.${ext}`;
                                    return prev;
                                }, {});
                                handleChangeProduct(
                                    {
                                        ...product,
                                        image
                                    },
                                    "image"
                                );
                                setActiveFieldValue(null);
                            }}
                        />
                    </div>
                );
            case "BUNDLE_SIZE":
                return (
                    <input
                        type="number"
                        placeholder="Bundle size"
                        className={styles.priceField}
                        value={product.bundleSize || 0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    bundleSize: Number(e.target.value)
                                },
                                "bundleSize"
                            )
                        }
                        onBlur={setActiveField.bind(null, "BUNDLE_SIZE")}
                    />
                );
            case "UPGRADE_PRICE":
                return (
                    <input
                        type="number"
                        placeholder="upgrade Price"
                        className={styles.priceField}
                        value={product.upgradePrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    upgradePrice: e.target.value
                                },
                                "upgradePrice"
                            )
                        }
                        onBlur={setActiveField.bind(null, "UPGRADE_PRICE")}
                    />
                );

            default:
                return null;
        }
    };

    let editor = getEditor();

    const getValueForField = (field: string): any => {
        switch (field) {
            case "pid":
                return (
                    <span title={currentProduct ? (currentProduct as any).name : null}>
                        {product.productId || "NA"}
                    </span>
                );
            case "gift_id":
                return <span title={product ? product.giftId : ""}>{product.giftId || "NA"}</span>;
            case "sid":
                return <span style={{ cursor: "help" }}>0/0</span>;
            case "ks":
                const finalSubscriptionsSource =
                    product.hasOwnProperty("konnektiveSubscriptions") &&
                    product.konnektiveSubscriptions.length > 0
                        ? product.konnektiveSubscriptions
                        : selectedKonnektiveSubscriptions;

                const subs = finalSubscriptionsSource.map(
                    sub => konnektiveSubscriptions.find(ks => ks._id === sub)?.name || "UNDEFINED"
                );

                return <span title={subs.join(", ")}>{finalSubscriptionsSource.length}</span>;
            case "opst":
                const sub =
                    product.subscriptions && product.subscriptions.length
                        ? product.subscriptions.map(el => el.name).join(", ")
                        : null;

                return <span title={sub || "NA"}>{sub?.substring(0, 3) || "NA"}</span>;
            case "units":
                return product.units || "NA";
            case "ppu":
                return getDollars(Number(product.price) / product.units);
            case "cog":
                return getDollars(product.cog);
            case "cos":
                return getDollars(product.cos);
            case "margin":
                return getPercentage(
                    (Number(product.price) - product.cog - product.cos) / Number(product.price)
                );
            case "title":
                if (product && product.title) {
                    return <span title={product.title}>{product.title.substring(0, 3)}</span>;
                } else {
                    const current = currentProduct as odooProduct;
                    return (
                        <span title={current ? current.name : "NA"}>
                            {current && current.name ? current.name.substring(0, 3) : "NA"}
                        </span>
                    );
                }
            case "title2":
                if (product && product.title2) {
                    return <span title={product.title2}>{product.title2.substring(0, 3)}</span>;
                } else {
                    const current = currentProduct as odooProduct;
                    return (
                        <span title={current ? current.name : "NA"}>
                            {current && current.name ? current.name.substring(0, 3) : "NA"}
                        </span>
                    );
                }
            case "al":
                const amazonLink = product.amazonLink;

                if (amazonLink != "") {
                    return (
                        <span>
                            <a href={amazonLink} target="_blank">
                                <u>Link</u>
                            </a>
                        </span>
                    );
                }

                return "NA";
            case "uid":
                return (
                    <span title={currentProduct ? (currentProduct as any).uid : null}>
                        {product.upgradeId || "NA"}
                    </span>
                );
            case "variants":
                return (
                    <span title={product ? (product as any).variants : null}>
                        {product?.variants?.substring(0, 3) || "NA"}
                    </span>
                );
            case "srpid":
                return (
                    <span title={product.subsReplaceProduct}>
                        {product.subsReplaceProduct || "NA"}
                    </span>
                );
            case "srp":
                return (
                    <span title={product.subsReplacePrice}>
                        {getDollars(Number(product?.subsReplacePrice)) || "NA"}
                    </span>
                );
            case "productType":
                return (
                    <span title={product?.productType || ""}>
                        {product?.productType?.substring(0, 3) || "NA"}
                    </span>
                );
            case "bundleSize":
                return (
                    <span title={product?.bundleSize?.toString() || ""}>
                        {product?.bundleSize || "NA"}
                    </span>
                );
            case "image": {
                const { _id, ...productImage } = product.image || {};
                const src = Object.values(productImage).reduce((acc, next) => acc || next, "");

                if (!src) {
                    return <span className={styles.image}></span>;
                }
                return <img src={src} className={styles.image} />;
            }
            case "up":
                return (
                    <span title={product.upgradePrice}>
                        {getDollars(Number(product?.upgradePrice)) || "NA"}
                    </span>
                );
            default:
                return "NA";
        }
    };

    return (
        <div
            className={cx(styles.product, {
                [headerStyles.borderBottom]: isDraft
            })}
            onMouseEnter={() => {
                if (isDraft) setProductHover(0.5);
            }}
            onMouseLeave={() => {
                if (isDraft) setProductHover(0);
            }}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div
                className={productsStyles.row}
                style={{ gridTemplateColumns: isDraft ? "1fr 60px" : "1fr" }}
            >
                <div className={cx(productsStyles.data, { [productsStyles.dataCms]: isElements })}>
                    <DataPiece
                        direction="column"
                        label="PT"
                        info="Product Type"
                        onClick={setActiveField.bind(null, "PT")}
                        pointer={isDraft}
                        value={getValueForField("productType")}
                    />
                    <DataPiece
                        direction="column"
                        label=""
                        info="Product Image"
                        onClick={setActiveField.bind(null, "IMAGE")}
                        pointer={isDraft}
                        value={getValueForField("image")}
                    />
                    <DataPiece
                        direction="column"
                        label="VAR"
                        info="Product Variants List"
                        onClick={setActiveField.bind(null, "VARIANTS")}
                        pointer={isDraft}
                        value={getValueForField("variants")}
                    />
                    <DataPiece
                        direction="column"
                        label="PID"
                        info={isOdooCrm ? "Odoo Product ID" : "Konnektive Product ID"}
                        onClick={setActiveField.bind(null, "SEARCH_PRODUCT")}
                        pointer={isDraft}
                        value={getValueForField("pid")}
                    />
                    <DataPiece
                        direction="column"
                        label="GID"
                        info={
                            "Gift id if you want to add additional product as a gift while selecting this product."
                        }
                        onClick={setActiveField.bind(null, "GIFT_ID")}
                        pointer={isDraft}
                        value={getValueForField("gift_id")}
                    />
                    {isOdooCrm ? (
                        <DataPiece
                            direction="column"
                            label="OPST"
                            info="Odoo product subscription type"
                            pointer={isDraft}
                            value={getValueForField("opst")}
                            onClick={setActiveField.bind(null, "OPST")}
                        />
                    ) : (
                        <DataPiece
                            direction="column"
                            label="KS"
                            info="MPE Konnektive Subscriptions"
                            pointer={isDraft}
                            value={getValueForField("ks")}
                            onClick={setActiveField.bind(null, "KS")}
                        />
                    )}
                    <DataPiece
                        direction="column"
                        label="Units"
                        info="Units number info set up manually. This number will be passed to checkout pages."
                        onClick={setActiveField.bind(null, "UNITS")}
                        pointer={isDraft}
                        value={getValueForField("units")}
                    />
                    <DataPiece
                        direction="column"
                        label="STP"
                        info="Strikethrough price for the product visible on ET."
                        onClick={setActiveField.bind(null, "STRIKETHROUGH_PRICE")}
                        pointer={isDraft}
                        value={getDollars(Number(product.strikethroughPrice))}
                    />
                    <DataPiece
                        direction="column"
                        label="Price"
                        info="Price for the product visible on ET"
                        onClick={setActiveField.bind(null, "PRICE")}
                        pointer={isDraft}
                        value={getDollars(Number(product.price))}
                    />
                    <DataPiece
                        direction="column"
                        label="SP"
                        info="Shipping Price to show in ET"
                        onClick={setActiveField.bind(null, "SP")}
                        pointer={isDraft}
                        value={getDollars(Number(product.shippingPrice))}
                    />
                    <DataPiece
                        direction="column"
                        label="Title"
                        info={isDraft ? "Title to show in ET" : ""}
                        onClick={setActiveField.bind(null, "TITLE")}
                        pointer={isDraft}
                        value={getValueForField("title")}
                    />
                    <DataPiece
                        direction="column"
                        label="Title2"
                        info={isDraft ? "Title to show in ET when subscription is ON" : ""}
                        onClick={setActiveField.bind(null, "TITLE2")}
                        pointer={isDraft}
                        value={getValueForField("title2")}
                    />
                    <DataPiece
                        direction="column"
                        label="PPU"
                        value={getValueForField("ppu")}
                        info="Price Per Unit"
                    />
                    <DataPiece
                        direction="column"
                        label="COG"
                        value={getValueForField("cog")}
                        info="Cost of Good"
                    />
                    <DataPiece
                        direction="column"
                        label="COS"
                        value={getValueForField("cos")}
                        info="Cost of Shipping"
                    />
                    <DataPiece
                        direction="column"
                        label="Margin"
                        value={getValueForField("margin")}
                    />
                    <DataPiece
                        direction="column"
                        label="TR"
                        info="Take Rate"
                        value={getPercentage(product.takeRate)}
                    />

                    <DataPiece direction="column" label="U.Sold" value={product.unitsSold || 0} />
                    <DataPiece
                        direction="column"
                        label="AL"
                        info="Amazon product link"
                        value={getValueForField("al")}
                        onClick={setActiveField.bind(null, "AL")}
                        pointer={isDraft}
                    />
                    <DataPiece
                        direction="column"
                        label="UID"
                        info="upgrade id"
                        value={getValueForField("uid")}
                        onClick={setActiveField.bind(null, "UID")}
                        pointer={isDraft}
                    />
                    <DataPiece
                        direction="column"
                        label="UP"
                        info="upgrade product price"
                        value={getValueForField("up")}
                        onClick={setActiveField.bind(null, "UPGRADE_PRICE")}
                        pointer={isDraft}
                    />
                    <DataPiece
                        direction="column"
                        label="UTR"
                        info="Upgrade Take Rate"
                        value={getPercentage(product.upgradeTakeRate)}
                    />
                    <DataPiece
                        direction="column"
                        label="SRPID"
                        info="subscription replace product id"
                        value={getValueForField("srpid")}
                        onClick={setActiveField.bind(null, "SUBS_REPLACE_PRODUCT")}
                        pointer={isDraft}
                    />
                    <DataPiece
                        direction="column"
                        label="SRP"
                        info="subscription replace price"
                        value={getValueForField("srp")}
                        onClick={setActiveField.bind(null, "SUBS_REPLACE_PRICE")}
                        pointer={isDraft}
                    />
                    <DataPiece
                        direction="column"
                        label="BS"
                        info="bundle size"
                        value={getValueForField("bundleSize")}
                        onClick={setActiveField.bind(null, "BUNDLE_SIZE")}
                        pointer={isDraft}
                    />
                    {!isDraft && isElements ? (
                        <DataPiece
                            direction="column"
                            label=""
                            info="Edit Product content"
                            pointer={true}
                            className={styles.cmsEdit}
                            value={
                                <span className={styles.edit}>
                                    <img src={EditIcon} alt={"edit content"} />
                                </span>
                            }
                            onClick={redirectToCms(product?.elementId)}
                        />
                    ) : null}
                    <img
                        alt="Set bestseller"
                        className={cx(styles.bestseller, {
                            [styles.active]: product.isBestseller
                        })}
                        style={
                            isDraft && !product.isBestseller
                                ? {
                                      opacity: isProductHover,
                                      cursor: "pointer"
                                  }
                                : {}
                        }
                        onClick={() =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    isBestseller: !product.isBestseller
                                },
                                "isBestseller"
                            )
                        }
                        src={star}
                    />
                    <img
                        alt="Set Pre Selected"
                        className={cx(styles.preselected, {
                            [styles.active]: product.isSelected
                        })}
                        style={
                            isDraft && !product.isSelected
                                ? {
                                      opacity: isProductHover,
                                      cursor: "pointer"
                                  }
                                : {}
                        }
                        onClick={() =>
                            handleChangeProduct(
                                {
                                    ...product,
                                    isSelected: !product.isSelected
                                },
                                "isSelected"
                            )
                        }
                        src={
                            "https://d3hlrrbqydii6y.cloudfront.net/img/e7f4d25e8e635f1fef3ccdbaf17445e2.png"
                        }
                    />
                </div>
                {isDraft && <DragButton {...provided.dragHandleProps} />}
            </div>
            <div ref={editorRef} className={styles.absoluteSearchList}>
                {editor}
            </div>
        </div>
    );
};

export default React.memo(Product, (prevProps: Props, nextProps: Props) => {
    const areEqual = isEqual(prevProps, nextProps);
    return areEqual;
});
