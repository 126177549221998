import edit from "assets/icons/edit.svg";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import {
    getConversionActions,
    addConversionAction,
    updateConversionAction,
    removeConversionAction
} from "./actions";
import styles from "./styles.scss";
import makeAnimated from "react-select/animated";
import Modal from "components/Modal";
import { conversionAction } from "types/conversionAction";
import { useSelector } from "react-redux";
import { appStateType } from "reducers";
import Select, { ActionMeta } from "react-select";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

const defaultConversionAction: conversionAction = {
    name: "",
    type: "",
    category: "",
    googleAccountId: "",
    customerId: "",
    conversionActionId: 0,
    brand: ""
};

interface column {
    columnName: string;
    key: string;
    inputType: "TEXT" | "BRAND";
}

const columns: column[] = [
    {
        columnName: "Name",
        key: "name",
        inputType: "TEXT"
    },
    {
        columnName: "Type",
        key: "type",
        inputType: "TEXT"
    },
    {
        columnName: "Category",
        key: "category",
        inputType: "TEXT"
    },
    {
        columnName: "Conversion Action Id",
        key: "conversionActionId",
        inputType: "TEXT"
    },
    {
        columnName: "Google Account Id",
        key: "googleAccountId",
        inputType: "TEXT"
    },
    {
        columnName: "CustomerId",
        key: "customerId",
        inputType: "TEXT"
    },
    {
        columnName: "Operating CustomerId",
        key: "operatingCustomerId",
        inputType: "TEXT"
    },
    {
        columnName: "Brand",
        key: "brand",
        inputType: "BRAND"
    }
];

const ConversionAction: React.FC = () => {
    const [conversionAction, setConversionAction] =
        React.useState<conversionAction>(defaultConversionAction);
    const [conversionActions, setConversionActions] = React.useState<conversionAction[]>([]);
    const brands = useSelector((state: appStateType) => state.brand.brands);
    const [isOpen, openModal] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const isConversionActionId = conversionAction.hasOwnProperty("_id");
    const [selectedBrand, setBrand] = React.useState<null | SelectOption>(null);

    React.useEffect(() => {
        getConversionActions()
            .then(data => {
                setConversionActions(data.conversionActions);
            })
            .catch(err => console.log(err.message));
    }, []);

    const handleModal = () => {
        openModal(isOpen => !isOpen);
    };

    const handleCancel = () => {
        openModal(false);
        setConversionAction(defaultConversionAction);
        setBrand(null);
    };

    const handleEdit = (actionId: string) => {
        const conversionAction = conversionActions.find(
            (conversionAction: conversionAction) => conversionAction._id === actionId
        );
        if (conversionAction) {
            setConversionAction({ ...conversionAction });
            const brand = brands.find(el => el._id === conversionAction.brand);

            const brandOption = brand ? { value: brand._id as string, label: brand.name } : null;
            setBrand(brandOption);

            window.scrollTo(0, 0);
        } else {
            setConversionAction(defaultConversionAction);
            setBrand(null);
        }
        openModal(true);
    };

    const handleChange = (key: string, value: any) => {
        setConversionAction({ ...conversionAction, [key]: value });
    };

    const handleSelect = (
        selectedOption: SelectOption | SelectOption[],
        config: ActionMeta<{ name: string; action: string }>
    ) => {
        switch (config.name) {
            case "brand":
                setBrand(selectedOption as SelectOption);
                setConversionAction({
                    ...conversionAction,
                    [config.name]: (selectedOption as SelectOption).value
                });
                break;
            default:
                break;
        }
    };

    const handleSave = () => {
        const { conversionActionId, googleAccountId, customerId, name, brand } = conversionAction;

        if (!brand || !name || !conversionActionId || !googleAccountId || !customerId) {
            return;
        }

        const isBrandExist = conversionActions.find(el => el.brand === brand);

        if (isBrandExist) {
            return;
        }

        setLoading(true);
        addConversionAction({ ...conversionAction, brand })
            .then(data => {
                setConversionAction(defaultConversionAction);
                setConversionActions([data.conversionAction, ...conversionActions]);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const handleUpdate = () => {
        if (!conversionAction._id) {
            return;
        }

        const brand = selectedBrand ? selectedBrand.value : null;

        setLoading(true);
        updateConversionAction({ ...conversionAction, brand: brand as string })
            .then(data => {
                setConversionAction(defaultConversionAction);
                const newActions = conversionActions.map(el =>
                    el._id === data.conversionAction._id ? data.conversionAction : el
                );
                setConversionActions(newActions);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const handleRemove = () => {
        if (!conversionAction._id) {
            return;
        }

        setLoading(true);
        removeConversionAction(conversionAction._id)
            .then(data => {
                setConversionAction(defaultConversionAction);
                const newActions = conversionActions.filter(
                    el => el._id !== data.conversionAction._id
                );
                setConversionActions(newActions);
                setLoading(false);
                openModal(false);
            })
            .catch(() => setLoading(false));
    };

    const getInput = (column: column) => {
        switch (column.inputType) {
            case "TEXT":
                return (
                    <input
                        disabled={column.key === "id" && isConversionActionId}
                        className={styles.field}
                        value={conversionAction[column.key]}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleChange(column.key, e.currentTarget.value)
                        }
                        placeholder={column.columnName}
                    />
                );

            case "BRAND":
                return (
                    <div className={styles.customSelect}>
                        <Select
                            options={brands.map(el => ({ value: el._id, label: el.name }))}
                            components={animatedComponents}
                            name="brand"
                            placeholder="Select Brand"
                            onChange={handleSelect}
                            value={selectedBrand}
                            isClearable
                            isSearchable
                            styles={{
                                menu: () => ({
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    overflowX: "hidden"
                                })
                            }}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <header className={styles.header}>
                <h1>Google Ads Conversion Actions</h1>
            </header>
            <main>
                <section className={styles.section}>
                    <div className={styles.addContainer}>
                        <Button
                            background="green"
                            className={styles.button}
                            disabled={isLoading}
                            flat
                            onClick={handleModal}
                        >
                            Add new conversionAction
                        </Button>
                    </div>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.th}>Edit</th>
                                    {columns.map(col => (
                                        <th className={styles.th} key={col.key}>
                                            {col.columnName}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {conversionActions.map(
                                    (conversionAction: conversionAction, index: number) => (
                                        <tr key={conversionAction._id}>
                                            <td>
                                                <Button
                                                    onClick={() =>
                                                        handleEdit(conversionAction._id || "")
                                                    }
                                                >
                                                    <img src={edit} />
                                                </Button>
                                            </td>
                                            {columns.map(col => {
                                                switch (col.inputType) {
                                                    case "TEXT":
                                                        return (
                                                            <td key={col.key}>
                                                                {conversionAction[col.key]}
                                                            </td>
                                                        );
                                                    case "BRAND": {
                                                        const brand = brands.find(
                                                            el =>
                                                                el._id === conversionAction[col.key]
                                                        );

                                                        if (brand) {
                                                            return (
                                                                <td key={col.key}>{brand.name}</td>
                                                            );
                                                        }
                                                        return (
                                                            <td key={col.key}>
                                                                {conversionAction[col.key]}
                                                            </td>
                                                        );
                                                    }

                                                    default:
                                                        return (
                                                            <td key={col.key}>
                                                                {conversionAction[col.key]}
                                                            </td>
                                                        );
                                                }
                                            })}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    {isOpen ? (
                        <Modal
                            title={
                                isConversionActionId
                                    ? "Update conversionAction details"
                                    : "Create a new ConversionAction"
                            }
                            onClose={handleModal}
                        >
                            <div className={styles.form}>
                                {columns.map(col => (
                                    <React.Fragment key={col.key}>{getInput(col)}</React.Fragment>
                                ))}
                                <div>
                                    {!isConversionActionId ? (
                                        <Button
                                            background="green"
                                            className={styles.button}
                                            disabled={isLoading}
                                            flat
                                            onClick={handleSave}
                                            marginRight
                                        >
                                            {isLoading ? (
                                                <LoadingIndicator size={18} />
                                            ) : (
                                                "Add Conversion Action"
                                            )}
                                        </Button>
                                    ) : null}
                                    {isConversionActionId ? (
                                        <>
                                            <Button
                                                background="green"
                                                className={styles.button}
                                                disabled={isLoading}
                                                flat
                                                onClick={handleUpdate}
                                                marginRight
                                            >
                                                {isLoading ? (
                                                    <LoadingIndicator size={18} />
                                                ) : (
                                                    "Update Product Group"
                                                )}
                                            </Button>
                                            <Button
                                                background="pinkGradient"
                                                className={styles.button}
                                                disabled={isLoading}
                                                flat
                                                onClick={handleRemove}
                                                marginRight
                                                marginLeft
                                            >
                                                {isLoading ? (
                                                    <LoadingIndicator size={18} />
                                                ) : (
                                                    "Remove Product Group"
                                                )}
                                            </Button>
                                        </>
                                    ) : null}
                                    <Button
                                        className={styles.button}
                                        disabled={isLoading}
                                        onClick={handleCancel}
                                        flat
                                        marginLeft
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    ) : null}
                </section>
            </main>
        </>
    );
};

export default ConversionAction;
