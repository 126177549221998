import * as React from "react";
import * as actions from "./actions";
import _ from "lodash";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import Card from "./components/Card";
import styles from "./styles.scss";

class Wildcard extends React.Component<any, any> {
    constructor(props: object) {
        super(props);

        this.createOrRenew = this.createOrRenew.bind(this);
    }

    createOrRenew() {
        this.props.createOrRenew();
    }

    render() {
        const { ip, validTo } = this.props.hackertools;

        return (
            <>
                <div className={styles.panel}>
                    <p>
                        1. CMS returns the same content all the time for the
                        same IP, even when you clean your cache.
                    </p>
                    <p>
                        2. Generate your 5 minutes wildcard to return different
                        content every time you refresh the template with cache
                        clean. During this time, your footprints will be ignored
                        by analytics.
                    </p>
                </div>
                <Card
                    ip={ip}
                    validTo={validTo}
                    createOrRenew={this.createOrRenew}
                />
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    hackertools: state.hackertools
});

export default connect(
    mapStateToProps,
    actions
)(Wildcard);
