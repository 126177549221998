import * as actionTypes from "actionTypes";
import api from "api";
import { Dispatch } from "redux";
import { AxiosResponse, AxiosError } from "axios";

export function pullServers() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.PULL_SERVERS_START
        });

        api()
            .get("server")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.PULL_SERVERS,
                    payload: { servers: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.PULL_SERVERS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}
