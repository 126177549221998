import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { appStateType } from "reducers";
import { Action, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { odooProduct } from "types/odooProduct";
import { Bundle, ExtendedBundle } from "types/pricing";
import {
    FlavourProduct,
    PricingContainer,
    PricingModel,
    PricingProduct,
    PricingSubs
} from "types/pricing";
import { product } from "types/product";
import history from "browserHistory";
import { createTreeNode } from "scenes/Dashboard/SchemaCMSEditor/actions";
import asyncForEach from "services/asyncForEach";
import { buildTree, copyTree } from "components/Modal/scenes/Duplication/actions";
import { v4 as uuid } from "uuid";
import groupBy from "lodash/groupBy";

export function getPricing(setId: string) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.GET_PRICING_START
        });

        api()
            .get(`pricing/${setId}`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_PRICING,
                    payload: { pricingContainers: res.data }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_PRICING_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export const addPricingContainerDraft = (setId: string, redirectPath: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_PRICING_CONTAINER,
            payload: {
                pricingContainer: {
                    _id: uuid(),
                    midasState: {},
                    set: setId,
                    variants: []
                }
            }
        });
        history.push(redirectPath);
    };
};

export const updatePricingContainerDraft = (containerDraft: PricingContainer) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_PRICING_CONTAINER,
            payload: {
                pricingContainer: containerDraft
            }
        });
    };
};

export const addFlavourProductDraft = (
    languageId: string,
    versionId: string,
    setId: string,
    schema: any
) => {
    return async (dispatch: Dispatch) => {
        const id = uuid();

        const elementsChain = await dispatch<any>(
            createTreeNode({
                setId,
                languageId,
                path: "__FLAVOUR_PRODUCTS.__FLAVOUR_PRODUCT.workaround",
                value: "",
                schema
            })
        );

        const contentElement = (elementsChain as any).find(
            (el: any) => el.name === "__FLAVOUR_PRODUCT"
        );

        const {
            newElement: { _id: elementId }
        } = contentElement;

        dispatch({
            type: actionTypes.ADD_PRICING_FLAVOUR_PRODUCT,
            payload: {
                id,
                elementId
            }
        });
    };
};

export const updateFlavourProductDraft = (id: string, product: FlavourProduct) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_PRICING_FLAVOUR_PRODUCT,
            payload: {
                id,
                product
            }
        });
    };
};

export const savePricingContainer = (
    setId: string,
    languageId: string,
    versionId: string,
    isSdk: boolean
) => {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const {
            pricing: { containerDraft }
        } = getState();

        if (!containerDraft || containerDraft.set !== setId) {
            return;
        }

        const { _id, flavourProducts, ...rest } = containerDraft;
        const newFlavourProducts = flavourProducts
            ?.filter(el => !!el.productId)
            .map(({ _id, ...el }) => el);

        dispatch({
            type: actionTypes.SAVE_PRICING_CONTAINER_START
        });

        api()
            .post("pricing/container", {
                ...rest,
                flavourProducts: newFlavourProducts
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.SAVE_PRICING_CONTAINER,
                    payload: { pricingContainer: res.data }
                });

                const path = isSdk
                    ? `/dashboard/deployment/sdklive/${setId}`
                    : `/dashboard/cms/${setId}/${languageId}/${versionId}/pricing`;

                history.push(path);
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.SAVE_PRICING_CONTAINER_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const startPricingContainerEditing = (setId: string, _id: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_PRICING_CONTAINER,
            payload: {
                pricingContainer: {
                    _id,
                    midasState: {},
                    set: setId,
                    variants: []
                }
            }
        });
    };
};

export const updatePricingContainer = (_id: string) => {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const {
            pricing: { containerDraft }
        } = getState();

        if (!containerDraft || containerDraft._id !== _id) {
            return;
        }

        const { flavourProducts } = containerDraft;
        const newFlavourProducts = flavourProducts
            ?.filter(el => !!el.productId)
            .map(({ _id, ...el }) => el);

        dispatch({
            type: actionTypes.UPDATE_PRICING_CONTAINER_START
        });

        api()
            .put("pricing/container", {
                _id,
                flavourProducts: newFlavourProducts
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.UPDATE_PRICING_CONTAINER,
                    payload: { pricingContainer: res.data }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPDATE_PRICING_CONTAINER_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const updateMinViews = (pricingContainerId: string, singleTrustAmount: number) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_MIN_VIEWS_START
        });

        api()
            .put("pricing/minviews", {
                pricingContainerId,
                singleTrustAmount
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.UPDATE_MIN_VIEWS,
                    payload: { minViews: res.data.minViews, pricingContainerId }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.UPDATE_MIN_VIEWS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const addTrafficSources = (pricingContainerId: string = "", sourceList: string[]) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_CONTAINER_SOURCES_START
        });

        return api()
            .put("pricing/container/utm-source", {
                _id: pricingContainerId,
                trafficSources: sourceList
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.UPDATE_CONTAINER_SOURCES,
                    payload: { trafficSources: res.data.trafficSources, pricingContainerId }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.UPDATE_CONTAINER_SOURCES_ERROR,
                    payload: { error: err.message }
                });
            });
    };
};

export const addPricingModelDraft = (trafficSource: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_PRICING_MODEL_DRAFT,
            payload: {
                trafficSource,
                supportedCountries: ["US"]
            }
        });

        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0);
    };
};

export const editPricingModelDraft = (key: string, value: any) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_PRICING_MODEL_DRAFT,
            payload: {
                [key]: value
            }
        });
    };
};

export const addPricingModelProductDraft = (
    languageId: string,
    versionId: string,
    setId: string,
    schema: any,
    containerType: "PRODUCT" | "BUNDLE"
) => {
    return async (dispatch: Dispatch) => {
        const id = uuid();
        let elementId = null;

        if (containerType === "BUNDLE") {
            const elementsChain = await dispatch<any>(
                createTreeNode({
                    setId,
                    languageId,
                    path: "__PRODUCTS.__PRODUCT.workaround",
                    value: "",
                    schema
                })
            );

            if (elementsChain) {
                const contentElement = (elementsChain as any).find(
                    (el: any) => el.name === "__PRODUCT"
                );

                ({
                    newElement: { _id: elementId }
                } = contentElement);
            }
        }

        dispatch({
            type: actionTypes.ADD_PRICING_MODEL_PRODUCT_DRAFT,
            payload: {
                id,
                elementId
            }
        });
    };
};

export const addPricingBundleDraft = (
    languageId: string,
    versionId: string,
    setId: string,
    schema: any
) => {
    return async (dispatch: Dispatch) => {
        const id = uuid();
        const elementsChain = await dispatch<any>(
            createTreeNode({
                setId,
                languageId,
                path: "__BUNDLES.__BUNDLE.workaround",
                value: "",
                schema
            })
        );

        const contentElement = (elementsChain as any).find((el: any) => el.name === "__BUNDLE");

        const {
            newElement: { _id: elementId }
        } = contentElement;

        dispatch({
            type: actionTypes.ADD_PRICING_BUNDLE_DRAFT,
            payload: {
                id,
                elementId
            }
        });
    };
};

export const reorderPricingModelProductDraft = (
    sourceIndex: number,
    destinationIndex: number,
    draggableId: number
) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REORDER_PRICING_MODEL_PRODUCT_DRAFT,
            payload: {
                sourceIndex,
                destinationIndex,
                draggableId
            }
        });
    };
};

export const reorderPricingBundleDraft = (
    sourceIndex: number,
    destinationIndex: number,
    draggableId: number
) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REORDER_PRICING_BUNDLE_DRAFT,
            payload: {
                sourceIndex,
                destinationIndex,
                draggableId
            }
        });
    };
};

export const editPricingModelProductDraft = (draftProductId: string, product: PricingProduct) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_PRICING_MODEL_PRODUCT_DRAFT,
            payload: {
                id: draftProductId,
                product
            }
        });
    };
};

export const editPricingBundleDraft = (draftBundleId: string, bundle: Bundle) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.EDIT_PRICING_BUNDLE_DRAFT,
            payload: {
                id: draftBundleId,
                bundle
            }
        });
    };
};

export const confirmPricingModel = (
    pricingContainerId: string,
    pricingModelDraft: PricingModel
) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.CONFIRM_PRICING_MODEL_START
        });

        const { products, bundles, title = "Model", ...rest } = pricingModelDraft;

        const newProducts = products
            .filter((product: PricingProduct) => product.productId)
            .map(({ _id, ...rest }: PricingProduct) => rest);

        const newBundles = bundles
            .filter((bundle: Bundle) => bundle.id)
            .map(({ _id, ...rest }: Bundle) => rest);

        api()
            .post("pricing/model", {
                pricingContainerId,
                title,
                products: newProducts,
                bundles: newBundles,
                ...rest
            })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.CONFIRM_PRICING_MODEL,
                    payload: { pricingModel: res.data, pricingContainerId }
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.CONFIRM_PRICING_MODEL_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const editPricingModel = ({
    _id,
    title,
    isActive,
    pricingContainerId
}: Partial<PricingModel> & { pricingContainerId: string }) => {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.EDIT_PRICING_MODEL_START
        });

        api()
            .put("pricing/model", {
                _id,
                title,
                isActive,
                pricingContainerId
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.EDIT_PRICING_MODEL,
                    payload: { pricingModel: res.data, pricingContainerId }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.EDIT_PRICING_MODEL_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};

export const copyPricingModel = ({
    priceMultiplier,
    priceDeterminant,
    setId,
    languageId,
    modelId
}: {
    priceMultiplier: number;
    priceDeterminant: "lower" | "higher";
    setId: string;
    languageId: string;
    modelId: string;
}) => {
    return async (dispatch: Dispatch, getState: () => appStateType) => {
        const {
            products: { konnektiveOfferProducts, odooProducts, odooProductsProd, odooProductsDev },
            sets: { sets },
            pricing: { pricingContainers },
            deployment: { version }
        } = getState();

        const schema = version && version._schema ? version._schema : {};
        const contentSet = sets.find(el => el._id === setId);
        const pricingContainer = pricingContainers.find(el => el.set === setId);

        if (!contentSet || !pricingContainer) {
            console.log("PRICING SET/CONTAINER NOT FOUND");
            return;
        }

        const { crm = "KONNEKTIVE" } = contentSet;
        const isOdooCrm = crm.includes("ODOO");
        const isKonnektive = crm === "KONNEKTIVE";

        const { type: containerType, variants: pricingModels } = pricingContainer;
        const pricingModel = pricingModels.find(el => el._id === modelId);

        if (!pricingModel) {
            console.log("PRICING MODEL NOT FOUND");
            return;
        }

        const {
            products = [],
            bundles = [],
            title = "",
            trafficSource = "",
            currency = "",
            supportedCountries = []
        } = pricingModel;

        const newProducts: PricingProduct[] = [];

        let finalOdooProducts: odooProduct[] = [];

        switch (crm) {
            case "ODOO":
                finalOdooProducts = odooProducts;
                break;
            case "ODOO_DEV":
                finalOdooProducts = odooProductsDev;
                break;
            case "ODOO_PROD":
            default:
                finalOdooProducts = odooProductsProd;
                break;
        }

        await asyncForEach(products, async (product: PricingProduct) => {
            const query: { elementId?: string } = {};
            let price = Number(product.price);
            let subs: PricingSubs[] = [];

            if (isKonnektive && !price) {
                const newProduct = konnektiveOfferProducts.find(
                    (el: product) => el.productId === product.productId
                );

                if (newProduct) {
                    price = Number(newProduct.cycle1_price);
                }
            }

            if (isOdooCrm) {
                const newProduct = finalOdooProducts.find(
                    (el: odooProduct) => el.sid === Number(product.productId)
                );

                if (newProduct) {
                    price = price || Number(newProduct.price);

                    const selectedSubs = product.subscriptions.reduce(
                        (acc, sub) => ({ ...acc, [sub.subId]: sub }),
                        {}
                    );

                    subs = newProduct.subscriptions
                        .map(el => ({
                            ...(selectedSubs[el.sub_id] || {}),
                            subId: String(el.sub_id),
                            name: el.name,
                            recurringInterval: String(el.sub_validity)
                        }))
                        .filter(el => !!selectedSubs[el.subId]);
                }
            }

            const x = (priceMultiplier / 100) * price;

            if (priceDeterminant === "higher") {
                price += x;
            }

            if (priceDeterminant === "lower") {
                price -= x;
            }

            try {
                const shouldCopyELements =
                    containerType === "BUNDLE" &&
                    setId &&
                    languageId &&
                    schema &&
                    product.elementId;

                if (shouldCopyELements) {
                    const elementsChain = await dispatch<any>(
                        createTreeNode({
                            setId,
                            languageId,
                            path: "__PRODUCTS.__PRODUCT.workaround",
                            value: "",
                            schema
                        })
                    );

                    const contentElement = (elementsChain as any).find(
                        (el: any) => el.name === "__PRODUCT"
                    );

                    const {
                        newElement: { _id: elementId }
                    } = contentElement;

                    if (product?.elementId) {
                        await dispatch<any>(buildTree(product?.elementId, languageId));
                        await dispatch<any>(
                            copyTree({
                                set: setId,
                                language: languageId,
                                copyVariants: true,
                                parent: elementId
                            })
                        );
                    }

                    query.elementId = elementId;
                }
            } catch (error) {
                console.log("PRICING CONTENT COPY ERROR:", error.message);
            }

            newProducts.push({
                ...product,
                price: price.toFixed(2),
                subscriptions: subs,
                ...query
            });
        });

        const newBundles: Bundle[] = [];
        await asyncForEach(bundles, async (bundle: Bundle) => {
            let price = bundle.unitPrice;
            const query: { elementId?: string } = {};

            const x = (priceMultiplier / 100) * price;

            if (priceDeterminant === "higher") {
                price += x;
            }

            if (priceDeterminant === "lower") {
                price -= x;
            }

            try {
                if (setId && languageId && schema && bundle.elementId) {
                    const elementsChain = await dispatch<any>(
                        createTreeNode({
                            setId,
                            languageId,
                            path: "__BUNDLES.__BUNDLE.workaround",
                            value: "",
                            schema
                        })
                    );

                    const contentElement = (elementsChain as any).find(
                        (el: any) => el.name === "__BUNDLE"
                    );

                    const {
                        newElement: { _id: elementId }
                    } = contentElement;

                    await dispatch<any>(buildTree(bundle.elementId, languageId));
                    await dispatch<any>(
                        copyTree({
                            set: setId,
                            language: languageId,
                            copyVariants: true,
                            parent: elementId
                        })
                    );

                    query.elementId = elementId;
                }
            } catch (error) {
                console.log("BUNDLE CONTENT ERROR:", error.message);
            }

            newBundles.push({ ...bundle, unitPrice: price, ...query });
        });

        dispatch({
            type: actionTypes.COPY_PRICING_MODEL,
            payload: {
                products: newProducts,
                bundles: newBundles,
                trafficSource,
                supportedCountries,
                currency,
                title: `Copy of ${title}`,
                isActive: false
            }
        });
    };
};

export const redirectToPath = (path: string) => {
    return (dispatch: Dispatch) => {
        history.push(path);
    };
};

export function getPricingGraphsChunks(setId: string, isOnDemand: boolean = false) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.GET_PRICING_GRAPHS_START
        });

        api()
            .post(`report/gpperviewxviews`, {
                setId,
                isOnDemand
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_PRICING_GRAPHS,
                    payload: { ...res.data, setId }
                });
                dispatch(recalculateAnalytics());
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_PRICING_GRAPHS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getOldPricingGraphsChunks(setId: string) {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        api()
            .post(`report/oldgpperviewxviews`, {
                setId
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_PRICING_GRAPHS,
                    payload: { ...res.data, setId, isOldReport: true }
                });
                dispatch(recalculateAnalytics());
            })
            .catch((err: AxiosError) =>
                console.log("getting OLD pricing graphs report error", err.response)
            );
    };
}

export const recalculateAnalytics = () => {
    return (dispatch: Dispatch, getState: () => appStateType) => {
        const state = getState();

        const {
            analyticsFromDate,
            analyticsToDate,
            currentPricingModels,
            pricingGraphsData,
            pricingContainers,
            currentSet
        } = state.pricing;

        try {
            const pricingContainer = pricingContainers.find(el => el && el.set === currentSet);
            const containerType = pricingContainer?.type || "PRODUCT";

            const populatedPricingModels = currentPricingModels.map(currentPricingModel => {
                let newPricingModel = currentPricingModel as any;
                const graphData = pricingGraphsData?.[String(currentPricingModel._id)] || null;
                const allowedProductIds = currentPricingModel?.products?.flatMap(
                    ({ productId, upgradeId }) =>
                        upgradeId ? [String(upgradeId), productId] : productId
                );

                if (graphData) {
                    const periodGraphData = graphData.filter((chunk: any) => {
                        const chunkDate = new Date(chunk.bucket.value);

                        if (
                            analyticsFromDate &&
                            Number(chunkDate) - Number(analyticsFromDate) < 0
                        ) {
                            return false;
                        }

                        if (analyticsToDate && Number(chunkDate) - Number(analyticsToDate) > 0) {
                            return false;
                        }

                        return true;
                    });

                    let soldProductsIds: string[] = [];
                    const soldBundlesIds: string[] = [];
                    let revenue = 0,
                        gp = 0,
                        views = 0,
                        conversions = 0,
                        unitsSold = 0,
                        cog = 0,
                        cos = 0;

                    periodGraphData.forEach((chunk: any) => {
                        soldProductsIds.push(
                            ...chunk.products.map((product: any) => product.productId)
                        );

                        if (chunk.bundles && chunk.bundles.length) {
                            soldBundlesIds.push(
                                ...chunk.bundles.map((bundle: any) => bundle.bundleId)
                            );
                        }

                        revenue += chunk?.revenue || 0;
                        gp += chunk?.gp || 0;
                        views += chunk?.views || 0;
                        conversions += chunk?.sales || 0;
                        unitsSold +=
                            containerType === "PRODUCT"
                                ? chunk?.products?.length || 0
                                : chunk?.bundles?.length || 0;

                        // cog, cos for shipping products will be 0
                        chunk?.products?.forEach((product: any) => {
                            cog += product?.cog || 0;
                            cos += product?.cos || 0;
                        });

                        if (containerType === "BUNDLE") {
                            chunk?.bundles?.forEach((bundle: any) => {
                                cog += bundle?.cog || 0;
                                cos += bundle?.cos || 0;
                            });
                        }
                    });

                    soldProductsIds = soldProductsIds.filter(id => allowedProductIds.includes(id));

                    const totalProductsSold = soldProductsIds.length;
                    const totalBundlesSold = soldBundlesIds.length;

                    const groupedSoldProductsIds = soldProductsIds.reduce((totalObj, acc) => {
                        let soldProducts: number = totalObj[acc];
                        if (soldProducts > 0) {
                            soldProducts += 1;
                        } else {
                            soldProducts = 1;
                        }
                        return { ...totalObj, [acc]: soldProducts };
                    }, {});

                    const groupedSoldBundlesIds = soldProductsIds.reduce((totalObj, acc) => {
                        let soldBundles = totalObj[acc];
                        if (soldBundles > 0) {
                            soldBundles += 1;
                        } else {
                            soldBundles = 1;
                        }
                        return { ...totalObj, [acc]: soldBundles };
                    }, {});

                    const products = currentPricingModel?.products?.map(product => {
                        const unitsSold = groupedSoldProductsIds?.[product.productId] || 0;
                        const totalUpgrades = product.upgradeId
                            ? groupedSoldProductsIds?.[product.upgradeId] || 0
                            : 0;
                        const totalSold = unitsSold + totalUpgrades;
                        const takeRate = totalSold / totalProductsSold;
                        const upgradeTakeRate = totalUpgrades / totalSold;
                        return { ...product, unitsSold: totalSold, takeRate, upgradeTakeRate };
                    });

                    let bundles: ExtendedBundle[] = [];

                    if (containerType === "BUNDLE") {
                        bundles = currentPricingModel?.bundles?.map(bundle => {
                            const unitsSold = groupedSoldBundlesIds?.[bundle.id] || 0;
                            const takeRate = unitsSold / totalBundlesSold;
                            return { ...bundle, unitsSold, takeRate };
                        });
                    }

                    newPricingModel = {
                        ...currentPricingModel,
                        products,
                        bundles,
                        performance: {
                            revenue,
                            conversions,
                            cog,
                            cos,
                            unitsSold,
                            gp,
                            views
                        }
                    };
                }

                const allContainersModels: PricingContainer["variants"] = [];

                for (let i = 0; i < pricingContainers.length; i++) {
                    allContainersModels.push(...pricingContainers[i].variants);
                }

                const mongoPricingModel = allContainersModels.find(
                    pricingModel => pricingModel._id === newPricingModel._id
                );

                if (mongoPricingModel) {
                    newPricingModel.isActive = mongoPricingModel.isActive;
                }

                return newPricingModel;
            });

            dispatch({
                type: actionTypes.SET_POPULATED_PRICING_MODELS,
                payload: {
                    populatedPricingModels
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
};

export const setAnalyticsFromDate = (date: Date | null) => {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.SET_PRICING_ANALYTICS_FROM_DATE,
            payload: {
                analyticsFromDate: date
            }
        });
        dispatch(recalculateAnalytics());
    };
};

export const setAnalyticsToDate = (date: Date | null) => {
    return (dispatch: ThunkDispatch<appStateType, void, Action>) => {
        dispatch({
            type: actionTypes.SET_PRICING_ANALYTICS_TO_DATE,
            payload: {
                analyticsToDate: date
            }
        });
        dispatch(recalculateAnalytics());
    };
};
