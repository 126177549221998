import * as queryString from "query-string";
import * as React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { appStateType } from "reducers";
import { set } from "types/set";
import { tabs } from "types/tabs";
import { upsellsFlow } from "types/upsellsFlow";
import * as actions from "./actions";
import PricingEditor from "./scenes/PricingEditor";
import NodeComponent from "./components/NodeComponent";
import TopBar from "./components/TopBar";
import HorizontalMenu from "components/HorizontalMenu";
import LoadingIndicator from "components/LoadingIndicator";
import BrowserBar from "./components/BrowserBar";
import styles from "./styles.scss";
import api from "api";
import { isEmpty } from "lodash";
import Button from "components/Button";

type node = {
    _type: string;
    _static?: boolean;
    _file?: boolean;
    _display?: string;
    _description?: string;
};

export type schemaType = {
    [key: string]: node | schemaType;
};

export type nodeRenderInput = {
    key: string;
    node: node | schemaType;
    path?: string;
    noName?: boolean;
};

function addFullscreenEvent(e: any) {
    document.addEventListener("fullscreenchange", e, false);
    document.addEventListener("mozfullscreenchange", e, false);
    document.addEventListener("MSFullscreenChange", e, false);
    document.addEventListener("webkitfullscreenchange", e, false);
}

function removeFullscreenEvent(e: any) {
    document.removeEventListener("fullscreenchange", e, false);
    document.removeEventListener("mozfullscreenchange", e, false);
    document.removeEventListener("MSFullscreenChange", e, false);
    document.removeEventListener("webkitfullscreenchange", e, false);
}

class SchemaCMSEditor extends React.PureComponent<any, any> {
    headerEl: HTMLElement | null;
    previewContainer: HTMLElement | null;
    cmsContainer: HTMLElement | null;
    previewIframe: HTMLIFrameElement | null;
    browserBar: React.RefObject<HTMLDivElement>;
    iframeContainer: HTMLElement | null;

    constructor(props: object) {
        super(props);
    }

    state = {
        redirect: "",
        searchString: "",
        previewUrl: "",
        socketAuth: "",
        previewMode: "DESKTOP",
        previewUrlUpdated: false,
        urlBase: "",
        isCachingContent: false
    };

    redirect = false;

    componentDidMount() {
        const { setId, languageId, versionId } = this.props.match.params;
        const { location } = this.props;

        this.props.getSetData(setId, versionId, languageId);

        if (location.search) {
            const parsed = queryString.parse(location.search);
            const { redirect = "", previewUrl = "" } = {
                ...parsed
            };

            this.redirect = true;

            if (redirect) {
                this.setState({ redirect });
            }

            if (previewUrl != "") {
                const urlBase = (previewUrl as any).split("/").slice(0, 4).join("/") + "/";
                this.setState({ previewUrl, urlBase });
            }
        }

        window.scrollTo(0, 0);

        this.resize = this.resize.bind(this);
        this.pullSocketAuth = this.pullSocketAuth.bind(this);
        this.setPreviewMode = this.setPreviewMode.bind(this);
        this.updatePreviewUrl = this.updatePreviewUrl.bind(this);
        this.handleIframeOnLoad = this.handleIframeOnLoad.bind(this);

        window.addEventListener("scroll", this.resize);
        window.addEventListener("resize", this.resize);
        this.resize();

        this.pullSocketAuth();

        this.browserBar = React.createRef<HTMLDivElement>();
    }

    componentDidUpdate() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.resize);
        window.removeEventListener("resize", this.resize);
    }

    pullSocketAuth() {
        api()
            .get("brick/pagepreviewtoken")
            .then(res => this.setState({ socketAuth: res.data.socketAuth }))
            .catch(err => console.log("socket auth ERROR", err));
    }

    resize() {
        const { previewMode } = this.state;

        const setVendor = (element: any, property: any, value: any) => {
            if (element) {
                element.style["webkit" + property] = value;
                element.style["moz" + property] = value;
                element.style["ms" + property] = value;
                element.style["o" + property] = value;
            }
        };

        const header = this.headerEl;

        if (header) {
            const scrolled = window.scrollY;
            const headerHeight = header.offsetHeight;
            const previewContainer = this.previewContainer;
            const previewIframe = this.previewIframe;
            const borderStyle = "1px #e2e1e7 solid";
            const browserBar = this?.browserBar?.current || null;
            const iframeContainer = this.iframeContainer;

            if (previewContainer && previewIframe && browserBar && iframeContainer) {
                const cmsContainer = this.cmsContainer;

                if (previewMode === "DESKTOP") {
                    const cmsContainerWidth = cmsContainer ? cmsContainer.offsetWidth : 0;
                    previewContainer.style.width = cmsContainerWidth - 60 + "px";
                    previewIframe.style.width = "200%";
                    previewIframe.style.height = "200%";
                    iframeContainer.style.borderLeft = borderStyle;
                    browserBar.style.borderLeft = borderStyle;
                    setVendor(previewIframe, "Transform", "scale(0.5) translate(-50%, -50%)");
                }

                if (previewMode === "MOBILE") {
                    previewContainer.style.width = "360px";
                    previewIframe.style.width = "100%";
                    previewIframe.style.height = "100%";
                    iframeContainer.style.borderLeft = borderStyle;
                    browserBar.style.borderLeft = borderStyle;
                    setVendor(previewIframe, "Transform", "none");
                }

                if (previewMode === "FULLSCREEN") {
                    previewContainer.style.top = "0";
                    previewContainer.style.left = "0";
                    previewContainer.style.width = "100%";
                    previewContainer.style.height = "100%";
                    previewIframe.style.width = "100%";
                    previewIframe.style.height = "100%";
                    iframeContainer.style.borderLeft = "none";
                    browserBar.style.borderLeft = "none";
                    setVendor(previewIframe, "Transform", "none");
                } else {
                    if (scrolled > headerHeight) {
                        previewContainer.style.top = 60 + "px";
                        previewContainer.style.left = "auto";
                        previewContainer.style.height = `calc(100% - 60px)`;
                    } else {
                        const additionalTop = 60 + header.offsetHeight - scrolled;
                        previewContainer.style.top = additionalTop + "px";
                        previewContainer.style.left = "auto";
                        previewContainer.style.height = `calc(100% - ${additionalTop}px)`;
                    }
                }

                if (iframeContainer) {
                    const browserBarheight = browserBar ? browserBar.offsetHeight : 0;
                    iframeContainer.style.height = `calc(100% - ${browserBarheight}px)`;
                }
            }
        }
    }

    enableFullscreenMode() {
        window.document.documentElement.requestFullscreen();
        window.document.body.style.overflow = "hidden";

        const fullscreenHandler = () => {
            removeFullscreenEvent(fullscreenHandler);

            const isFullScreen =
                (document as any).webkitIsFullScreen ||
                (document as any).mozFullScreen ||
                (document as any).msFullscreenElement !== null;

            if (isFullScreen) {
                const exitHandler = () => {
                    removeFullscreenEvent(exitHandler);

                    const isFullScreen =
                        (document as any).webkitIsFullScreen ||
                        (document as any).mozFullScreen ||
                        (document as any).msFullscreenElement !== null;

                    if (isFullScreen) {
                        this.disableFullscreenMode();
                        const mode = this.state.previewMode;
                        this.setState({ previewMode: mode === "FULLSCREEN" ? "DESKTOP" : mode });
                    }
                };

                addFullscreenEvent(exitHandler);
            }
        };

        addFullscreenEvent(fullscreenHandler);
    }

    disableFullscreenMode() {
        if (window.document) {
            window.document.exitFullscreen();
        }

        window.document.body.style.overflow = "auto";
    }

    setPreviewMode(previewMode: string) {
        this.setState({ previewMode });
        this.resize();

        if (previewMode === "FULLSCREEN") {
            this.enableFullscreenMode();
        } else {
            this.disableFullscreenMode();
        }
    }

    scrollToInvalid(e: any) {
        e.preventDefault();

        let el: any = window.document.querySelector("#invalid-element");

        if (el) {
            let top = -70;

            do {
                top += el.offsetTop;
                el = el.offsetParent;
            } while (el);

            window.scrollTo({ top, behavior: "smooth" });
        }
    }

    search = _.throttle((searchString: string, versionId: string) => {
        this.props.searchfilter(searchString, versionId);
    }, 1500);

    handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { versionId } = this.props.match.params;
        this.setState({ searchString: evt.target.value });
        this.search(evt.target.value, versionId);
    };

    clearSearch = () => {
        this.setState({ searchString: "" });
        const { versionId } = this.props.match.params;
        this.search("", versionId);
    };

    refreshPreview() {
        api()
            .post("brick/refreshpreviewpage")
            .then()
            .catch(err => console.log("refreshPreview ERROR", err));
    }

    updatePreviewUrl(editorUrl: string) {
        const { urlBase } = this.state;
        const previewUrl = urlBase + editorUrl;
        this.setState({ previewUrl, previewUrlUpdated: true });
        const [url, search] = editorUrl.split("?");

        const parsed = queryString.parse("?" + search);
        parsed.socketAuth = null;
        const stringified =
            url +
            "?" +
            queryString.stringify(parsed, {
                skipNull: true
            });
        const { pathname } = window.location;

        const parsedWindowUrl = queryString.parse(window.location.search);
        parsedWindowUrl.previewUrl = urlBase + stringified;

        const stringifiedWindowUrl = queryString.stringify(parsedWindowUrl);

        window.history.replaceState(null, "", `${pathname}?${stringifiedWindowUrl}`);
    }

    handleIframeOnLoad() {
        const { previewUrlUpdated } = this.state;

        if (previewUrlUpdated) {
            this.refreshPreview();
            this.setState({ previewUrlUpdated: false });
        }
    }

    handleCmsCache() {
        const { setId } = this.props.match.params;
        this.setState({
            isCachingContent: true
        });
        this.props.updateCMSCache(setId).finally(() => {
            this.setState({
                isCachingContent: false
            });
        });
    }

    render() {
        let { redirect, previewUrl, socketAuth, urlBase, isCachingContent } = this.state;
        const { upsellsFlow, version, language } = this.props;
        let { isContentValid, schemaKey } = this.props.elements;
        const { state: { backlink } = { backlink: "/" } } = this.props.location;
        const { sets } = this.props.sets;
        const { setId, languageId, versionId } = this.props.match.params;
        const set = sets.find((set: set) => set._id === setId) || { name: "" };
        const path = `/dashboard/cms/:setId/:languageId/:versionId/:schemaKey`;
        const basePath = `/dashboard/cms/${setId}/${languageId}/${versionId}/`;
        const schema =
            version && version._id === versionId && version._schema ? version._schema : {};
        let newTabs = tabs;
        let upsellsFlowId = "";

        let isPagePreviewEnabled = false;

        if (version && process.env.NODE_ENV === "production") {
            ({ isPagePreviewEnabled } = version);
        }

        const search = previewUrl && previewUrl != "" ? `?previewUrl=${previewUrl}` : "";
        const { localPagePreviewUrl } = process.env;

        let prePreviewUrl = previewUrl;
        let editorUrl = previewUrl.split("/").slice(4).join("/");

        const [_url, _search] = previewUrl.split("?");
        const parsed = queryString.parse("?" + _search);
        parsed.socketAuth = null;
        parsed.socketAuth = socketAuth;
        prePreviewUrl =
            _url +
            "?" +
            queryString.stringify(parsed, {
                skipNull: true
            });

        const finalPreviewUrl =
            localPagePreviewUrl && localPagePreviewUrl != ""
                ? `${localPagePreviewUrl}?socketAuth=${socketAuth}`
                : prePreviewUrl;

        if (!isEmpty(schema)) {
            if (schema.hasOwnProperty("newUpsells")) {
                newTabs = tabs.map(tab => (tab === "upsells" ? "newUpsells" : tab));
            }

            if (schema.hasOwnProperty("__PRODUCTS")) {
                newTabs = [...newTabs, "pricing"];
            }

            if (set.isSdk) {
                newTabs = ["pricing", "newUpsells"];
            }

            if (!newTabs.find(tab => window.location.href.includes(tab)) && !redirect) {
                return <Redirect to={basePath + newTabs[0] + search} />;
            }

            if (upsellsFlow.length > 0) {
                const flow = upsellsFlow.find((flow: upsellsFlow) => flow.set === setId);

                if (flow) upsellsFlowId = flow._id;
            }
        }

        if (redirect && !isEmpty(schema)) {
            if (redirect === "upsells" && schema.hasOwnProperty("newUpsells")) {
                redirect = `newUpsells/${upsellsFlowId}`;
            }

            if (this.redirect) {
                this.redirect = false;
                return <Redirect to={redirect + search} />;
            }
        }

        newTabs = newTabs.map(option => option + search) as any;

        if (schemaKey.includes("?")) {
            schemaKey = schemaKey.split("?")[0];
        }

        const isCMSpage = ![
            "upsells",
            "newUpsells",
            "pricing",
            "__PRODUCTS",
            "__BUNDLES",
            "__FLAVOUR_PRODUCTS",
            ""
        ].includes(schemaKey);
        const displayPreview = isCMSpage && isPagePreviewEnabled && previewUrl != "";

        return (
            <>
                <TopBar
                    backlink={backlink}
                    set={set}
                    language={language}
                    isContentValid={isContentValid}
                    scrollToInvalid={this.scrollToInvalid.bind(this)}
                />
                <header className={styles.header} ref={el => (this.headerEl = el)}>
                    <div className={styles.headerBox}>
                        <h1>Content Manager</h1>
                        <Button
                            background="green"
                            className={styles.button}
                            flat
                            onClick={this.handleCmsCache.bind(this)}
                        >
                            {isCachingContent ? "Updating content..." : "Update CMS Cache"}
                        </Button>
                    </div>
                    <HorizontalMenu
                        basePath={basePath}
                        links={newTabs}
                        enrichLinks={{
                            upsells: upsellsFlowId ? `/${upsellsFlowId}` : "",
                            newUpsells: upsellsFlowId ? `/${upsellsFlowId}` : ""
                        }}
                        search={this.handleSearch}
                        clearSearch={this.clearSearch}
                        searchValue={this.state.searchString}
                        showSearch={true}
                    />
                </header>
                <div className={styles.main}>
                    <main
                        className={`${styles.cms} ${!isCMSpage && styles.wide} ${
                            !displayPreview && isCMSpage ? styles.noPreviewMode : ""
                        }`}
                        ref={el => (this.cmsContainer = el)}
                    >
                        <Switch>
                            <Route
                                path={`${basePath}pricing`}
                                render={(props: any) => (
                                    <PricingEditor
                                        languageId={languageId}
                                        setId={setId}
                                        versionId={versionId}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={path}
                                render={(props: any) => {
                                    if (schema === undefined) {
                                        return <LoadingIndicator className={styles.indicator} />;
                                    }
                                    const { schemaKey } = props.match.params;

                                    return (
                                        <NodeComponent
                                            key={schemaKey}
                                            languageId={languageId}
                                            setId={setId}
                                            versionId={versionId}
                                            {...props}
                                            schemaKey={schemaKey}
                                        />
                                    );
                                }}
                            />
                        </Switch>
                    </main>
                    {displayPreview && (
                        <div className={styles.preview} ref={el => (this.previewContainer = el)}>
                            {socketAuth === "" ? (
                                "Authenticating page preview..."
                            ) : (
                                <>
                                    <BrowserBar
                                        urlBase={urlBase}
                                        initialUrl={editorUrl}
                                        browserBarRef={this.browserBar}
                                        setPreviewMode={this.setPreviewMode}
                                        refreshPreview={this.refreshPreview}
                                        updatePreviewUrl={this.updatePreviewUrl}
                                    />
                                    <div
                                        className={styles.previewContainer}
                                        ref={el => (this.iframeContainer = el)}
                                    >
                                        <iframe
                                            src={finalPreviewUrl}
                                            ref={el => (this.previewIframe = el)}
                                            onLoad={this.handleIframeOnLoad}
                                        ></iframe>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    language: state.languages.language,
    version: state.deployment.version,
    sets: state.sets,
    upsellsFlow: state.upsell.upsellsFlow,
    elements: state.elements
});

export default withRouter(
    connect(mapStateToProps, {
        ...actions
    })(SchemaCMSEditor)
);
