import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import HorizontalMenu from "components/HorizontalMenu";
import AddDeployment from "./scenes/AddDeployment";
import ServerDeployments from "./scenes/ServerDeployments";
import LiveDeployments from "./scenes/LiveDeployments";
import SDKLive from "./scenes/SDKLive";
import HotDeployments from "./scenes/HotDeployments";
import Server from "./scenes/Server";
import Servers from "./scenes/Servers";
import Templates from "./scenes/Templates";
import Versions from "./scenes/Versions";
import Whitelist from "../Whitelist";
import PricingEditor from "../SchemaCMSEditor/scenes/PricingEditor";
import PricingInit from "../SchemaCMSEditor/scenes/PricingEditor/scenes/PricingInit";

const CPDS: React.FC<any> = ({ match }) => {
    const { path } = match;
    return (
        <>
            <header>
                <h1>Checkout Page Deployments System</h1>
                <HorizontalMenu
                    basePath={`${path}/`}
                    links={[
                        "live",
                        "hotdeployments",
                        "sdklive",
                        "servers",
                        "whitelist",
                        "templates",
                        "versions"
                    ]}
                />
            </header>
            <main>
                <Switch>
                    <Route exact path={`${path}/hotdeployments`} component={HotDeployments} />
                    <Route exact path={`${path}/sdklive`} component={SDKLive} />
                    <Route
                        exact
                        path={`${path}/sdklive/:setId`}
                        render={(props: any) => <PricingEditor {...props} isSdk={true} />}
                    />
                    <Route
                        path={`${path}/sdklive/:setId/initialize`}
                        render={(props: any) => <PricingInit />}
                        exact
                    />
                    <Route exact path={`${path}/servers`} component={Servers} />
                    <Route exact path={`${path}/servers/add`} component={Server} />
                    <Route exact path={`${path}/servers/:id`} component={ServerDeployments} />
                    <Route exact path={`${path}/servers/:id/add`} component={AddDeployment} />
                    <Route
                        exact
                        path={`${path}/servers/:id/edit`}
                        render={(props: any) => <Server {...props} mode="edit" />}
                    />
                    <Route exact path={`${path}/live`} component={LiveDeployments} />
                    <Route exact path={`${path}/versions`} component={Versions} />
                    <Route exact path={`${path}/whitelist`} component={Whitelist} />
                    <Route exact path={`${path}/templates`} component={Templates} />
                    <Redirect to={`${path}/live`} />
                </Switch>
            </main>
        </>
    );
};

export default CPDS;
