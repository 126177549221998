import * as deepmerge from "deepmerge";
import * as React from "react";
import { connect } from "react-redux";
import { appStateType } from "reducers";
import { compose } from "redux";
import { otherSettings, pixels, server } from "types/server";
import * as actions from "../scenes/Dashboard/CPDS/scenes/Server/actions";
import { isEqual } from "lodash";

type State = {
    host: string;
    username: string;
    password: string;
    domain: string;
    pixels: pixels;
    campaignKeys: {
        default?: {
            [key: string]: string;
        };
    };
    otherSettings: otherSettings;
};

export type WithServerSettings = {
    deployment: {
        servers: server[];
        isPullingServers: boolean;
        isSavingServer: boolean;
        savingServerError: string;
    };
    handleCampaignKeyChange: () => void;
    handleDynamicFormChange: (key: string, e: React.SyntheticEvent) => void;
    handleOnChange: () => void;
    handleSaveClick: (_id: string, e: React.SyntheticEvent) => void;
    match: {
        params: {
            id: string;
        };
    };
    mode: "edit" | "overwrite";
    pullServers: () => void;
    saveServer: (data: any) => void;
    settings: State;
    whitelist: any;
};

const withServerSettings = <P extends WithServerSettings>(
    WrappedComponent: React.ComponentType<P>
) => {
    return class withServerSettings extends React.Component<P & WithServerSettings> {
        state = {
            host: "",
            username: "",
            password: "",
            domain: "disabledDomain",
            pixels: {
                dmca: "",
                facebook: "",
                googleAds: "",
                googleAdsConversion: "",
                googleAnalytics: "",
                googleTagManager: "",
                pinterest: "",
                sentry: "",
                snapchat: "",
                twitter: "",
                ejamPixel: "",
                yahoo: "",
                bingPixel: "",
                storeCashId: "",
                segment: "",
                addStoreCashTag: false
            },
            campaignKeys: {
                default: {
                    US: ""
                }
            },
            otherSettings: {
                paypalBillerId: 1,
                chatBotId: 0,
                chatAppId: 0,
                chatPageId: 0,
                xCallyLogin: "",
                xCallyPassword: "",
                xcallyCompanyId: 0,
                xCallyPartialsId: 0,
                xCallyDeclinedId: 0,
                xCallyOrderConfirmationId: 0,
                xCallyDncListId: "",
                ebanxMerchantPaymentCode: "",
                shopifyLogin: "",
                shopifyPassword: "",
                shopifyStoreName: "",
                smartlook: "",
                yotpoAppKey: "",
                yotpoProductId: "",
                sezzleMerchantId: 0,
                ebanxMerchantId: 0,
                sandboxModeSecretKey: "",
                klaviyoApiKey: "",
                klaviyoAccountId: "",
                productGroupId: "",
                googleMapKey: "",
                gorgiasAppId: "",
                smartyStreets: "",
                mulberry: "",
                useStagingApi: false,
                useDevMode: false,
                useEjamAbstra: false
            }
        };

        componentDidMount() {
            const servers = this.props.deployment.servers;
            this.loadServer(servers);
        }

        componentDidUpdate(prevProps: any) {
            const {
                deployment: { servers = [] },
                match: {
                    params: { id }
                }
            } = this.props;

            const {
                deployment: { servers: prevServers = [] },
                match: {
                    params: { id: prevId }
                }
            } = prevProps;

            if (!isEqual(servers, prevServers) || (id && id !== prevId)) {
                this.loadServer(servers);
            }
        }

        loadServer(servers: any[]) {
            if (Array.isArray(servers)) {
                const {
                    params: { id }
                } = this.props.match;

                const server = servers.find(server => server._id === id);

                if (server) {
                    const { campaignKeys, domain, otherSettings, pixels, ...rest } = server;

                    if (campaignKeys && campaignKeys.hasOwnProperty("default")) {
                        if (typeof campaignKeys.default == "string") {
                            campaignKeys.default = {
                                US: campaignKeys.default
                            };
                        }
                    }

                    this.setState({
                        domain: domain._id,
                        campaignKeys,
                        pixels: {
                            ...this.state.pixels,
                            ...pixels
                        },
                        otherSettings: {
                            ...this.state.otherSettings,
                            ...otherSettings
                        },
                        ...rest
                    });
                }
            }
        }

        handleOnChange(e: React.SyntheticEvent) {
            e.preventDefault();
            const { value, name } = e.target as HTMLInputElement;
            this.setState({ [name]: value });
        }

        handleSaveClick(_id: string, e: React.SyntheticEvent) {
            e.preventDefault();
            let server = {};
            let { campaignKeys, password, ...state } = this.state;

            if (campaignKeys) {
                const { default: defaultKey } = campaignKeys;

                if (defaultKey) {
                    campaignKeys.default.US = Object.values(defaultKey)[0];
                }
            }

            if (password) {
                server = { password };
            }

            server = { ...state, ...server, campaignKeys, _id };

            this.props.saveServer(server);
        }

        updateEditedDeploymentDetails(details: any) {
            this.setState({
                ...details
            });
        }

        handleDynamicFormChange(key: string, e: React.SyntheticEvent) {
            const { value, name } = e.target as HTMLInputElement;
            this.setState({
                [key]: { ...this.state[key], [name]: value }
            });
        }

        handleCampaignKeyChange(key: string, country: string, e: React.SyntheticEvent) {
            e.preventDefault();
            const { value } = e.target as HTMLInputElement;
            const { campaignKeys } = this.state;
            let updatedState;

            updatedState = deepmerge(this.state, {
                campaignKeys: {
                    ...campaignKeys,
                    [key]: {
                        [country]: value
                    }
                }
            });
            this.setState(updatedState);
        }

        render() {
            return (
                <WrappedComponent
                    settings={this.state}
                    handleOnChange={this.handleOnChange.bind(this)}
                    handleCampaignKeyChange={this.handleCampaignKeyChange.bind(this)}
                    handleDynamicFormChange={this.handleDynamicFormChange.bind(this)}
                    updateEditedDeploymentDetails={this.updateEditedDeploymentDetails.bind(this)}
                    handleSaveClick={this.handleSaveClick.bind(this)}
                    {...(this.props as any)}
                />
            );
        }
    };
};

const mapStateToProps = (state: appStateType) => ({
    deployment: state.deployment,
    whitelist: state.whitelist
});

export default compose(connect(mapStateToProps, { ...actions }), withServerSettings);
