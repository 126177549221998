import * as actionTypes from "actionTypes";
import api from "api";
import { Dispatch } from "redux";
import { AxiosResponse, AxiosError } from "axios";

export function getHotDeployments() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_HOT_DEPLOYMENTS_START
        });

        api()
            .get("deployment/hotdeployments")
            .then((res: AxiosResponse) => {
                console.log("res.data", res.data);
                dispatch({
                    type: actionTypes.GET_HOT_DEPLOYMENTS,
                    payload: res.data
                });
            })
            .catch((err: AxiosError) => {
                console.log("hotDeployments error", err);
                dispatch({
                    type: actionTypes.GET_HOT_DEPLOYMENTS_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}
