import * as React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import * as actions from "./actions";
import * as dashboardActions from "../CMSAnalytics/actions";
import { appStateType } from "reducers";
import _ from "lodash";
import FacebookLogin from "@greatsumini/react-facebook-login";
import Assign from "./components/Assign";
interface State {}

class AdAccount extends React.PureComponent<any, State> {
    constructor(props: object) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { sets } = this.props.sets;

        if (!sets) {
            this.props.getSets();
        }

        const { languagesMap } = this.props.languages;

        if (!languagesMap) {
            this.props.getLanguages();
        }
    }

    responseFacebook = (response: any) => {
        this.props.loadAdAccounts(response.userID, response.accessToken);
        this.setState({
            token: response.accessToken
        });
    };

    render() {
        return (
            <>
                <header className={styles.panel_header}>
                    <h1>Ad Accounts</h1>
                    {this.props.accounts.length == 0 && (
                        <div className={styles.input_group}>
                            <FacebookLogin
                                appId="730827263977472"
                                autoLoad={false}
                                style={styles.facebook}
                                fields="name,email,picture"
                                scope="ads_read"
                                onSuccess={this.responseFacebook}
                            />
                        </div>
                    )}
                </header>
                <main>
                    <section className={styles.grid}>
                        {this.props.accounts.length > 0 ? (
                            this.props.accounts.map((account: any, index: number) => {
                                return <Assign key={index} />;
                            })
                        ) : (
                            <div className={`${styles.panel_wide} ${styles.form_wide}`}>
                                Sign in to show ad accounts.
                            </div>
                        )}
                    </section>
                </main>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    accounts: state.analytics.adAccounts,
    sets: state.sets,
    languages: state.languages
});

export default connect(mapStateToProps, { ...actions, ...dashboardActions })(AdAccount);
