import LoadingIndicator from "components/LoadingIndicator";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { appStateType } from "reducers";
import { signInUser } from "types/user";
import * as actions from "./actions";
import styles from "./styles.scss";

type State = {
    login: string;
    password: string;
    [name: string]: string;
};

class SignInForm extends React.PureComponent<any, State> {
    submitButton: HTMLInputElement | null;

    constructor(props: object) {
        super(props);
        this.state = {
            login: "",
            password: ""
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e: React.SyntheticEvent) {
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement;
        this.setState({ [name]: value });
    }

    onSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        if (!!this.submitButton) this.submitButton.blur();

        const { isLoading } = this.props.auth;

        if (!isLoading) {
            const { login, password } = this.state;

            const newUser: signInUser = {
                login,
                password
            };

            this.props.loginUser(newUser);
        }
    }

    render() {
        const { onChange, onSubmit, state, props } = this;
        const { login, password } = state;
        const { isAuthenticated, errors, isLoading } = props.auth;

        if (isAuthenticated)
            return <Redirect to="/dashboard/deployment/live" />;

        return (
            <form onSubmit={onSubmit} className={styles.formBox}>
                <input
                    autoFocus={true}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    name="login"
                    placeholder="Login"
                    type="text"
                    value={login}
                    onChange={onChange}
                />

                <input
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={onChange}
                />

                <div className={styles.errors}>
                    {errors.map((error: string, index: number) => (
                        <div key={index}>{error}</div>
                    ))}
                </div>

                <div className={styles.submitButton}>
                    {isLoading ? (
                        <LoadingIndicator className={styles.loaderIndicator} />
                    ) : null}
                    <input
                        className={isLoading ? styles.inactive : styles.active}
                        ref={selector => (this.submitButton = selector)}
                        type="submit"
                        value={isLoading ? "" : "SIGN IN"}
                    />
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    actions
)(SignInForm);
