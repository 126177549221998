import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { IImpressionReportQuery } from "types/report";

export function getImpressionsReport(query: IImpressionReportQuery) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_IMPRESSION_REPORT_START
        });

        api()
            .post("report/getImpressions", query)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_IMPRESSION_REPORT,
                    payload: { report: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_IMPRESSION_REPORT_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}
