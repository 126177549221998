import * as React from "react";
const { useEffect } = React;
import Button from "components/Button";
import ModelBox from "../../components/ModelBox";
import SpaceBetween from "scenes/Dashboard/SchemaCMSEditor/components/SpaceBetween";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { PricingModel } from "types/pricing";
import { PricingContext } from "../..";
import { useDispatch, useSelector } from "react-redux";
import {
    addPricingModelDraft,
    updateMinViews,
    addTrafficSources,
    setAnalyticsFromDate,
    setAnalyticsToDate,
    recalculateAnalytics
} from "../../actions";
import Headline from "../../../../components/Headline";
import FlavourBox from "../../components/FlavourBox";
import Graph from "components/Graph";
import { DatePicker } from "components/DatePicker";
import LoadingIndicator from "components/LoadingIndicator";
import Tabs from "components/Tabs";
import { isEmpty } from "lodash";
import * as moment from "moment-timezone";
import { getPricingGraphsChunks } from "../../actions";
type Props = any;

const ModelList: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const { pricingContainer, pricingModelDraft, setId } = React.useContext(PricingContext);
    const {
        minViewsError,
        isLoading,
        isOldGraphsReport,
        graphsReportCreatedAt,
        loadingPricingGraphs
    } = useSelector(({ pricing }: { pricing: any }) => ({
        minViewsError: pricing.updatingMinViewsError,
        isLoading: pricing.isLoading,
        isOldGraphsReport: pricing.isOldGraphsReport,
        graphsReportCreatedAt: pricing.graphsReportCreatedAt,
        loadingPricingGraphs: pricing.loadingPricingGraphs
    }));
    const [activeTab, setActiveTab] = React.useState<string>(
        pricingContainer?.trafficSources?.[0] || "new"
    );
    const [sources, setSources] = React.useState<string>("");
    const [minViews, setMinViews] = React.useState<number>(
        pricingContainer?.midasState?.singleTrustAmount || 0
    );
    const [analyticsRange, setAnalyticsRange] = React.useState<{
        isDateChanged: Boolean;
    }>({
        isDateChanged: false
    });

    const {
        pricingGraphsData = {},
        populatedPricingModels,
        isSourcesLoading,
        updateSourceError,
        analyticsFromDate,
        analyticsToDate
    } = useSelector(({ pricing }: { pricing: any }) => pricing);
    const singleTrustAmount = pricingContainer?.midasState?.singleTrustAmount || 0;

    let newPricingModels: PricingModel[] = [];

    if (pricingContainer) {
        const ids = populatedPricingModels.map((pm: any) => pm._id);
        newPricingModels = pricingContainer.variants.filter(variant => !ids.includes(variant._id));
    }

    const currentPricingModels = [...populatedPricingModels, ...newPricingModels].filter(
        (el: PricingModel) => el.trafficSource === activeTab
    );

    const currentGraphData = currentPricingModels.reduce(
        (acc: any, next: any) =>
            pricingGraphsData && pricingGraphsData[next._id]
                ? { ...acc, [next._id]: pricingGraphsData[next._id] }
                : acc,
        {}
    );

    useEffect(() => {
        setMinViews(singleTrustAmount);
    }, [singleTrustAmount]);

    const updateSingleTrustAmount = () => {
        let { _id: pricingContainerId } = pricingContainer as any;
        dispatch(updateMinViews(pricingContainerId, minViews));
    };

    const handleSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSources(e.target.value);
    };

    const handleAnalyticsRange = (name: string, date: null | Date) => {
        setAnalyticsRange({ isDateChanged: true });

        switch (name) {
            case "from":
                dispatch(setAnalyticsFromDate(date));
                break;
            case "to":
                dispatch(setAnalyticsToDate(date));
                break;
            default:
                break;
        }
    };

    const addSources = () => {
        const sourceList = sources
            .toLowerCase()
            .split(",")
            .map(el => el.trim())
            .filter(Boolean);

        if (sourceList.length) {
            dispatch(addTrafficSources(pricingContainer?._id, sourceList));
            setSources("");
            setActiveTab(pricingContainer?.trafficSources?.[0] || "new");
        }
    };

    const updatePricingReport = () => {
        const { isDateChanged } = analyticsRange;
        if (!isDateChanged) {
            return;
        }

        dispatch(recalculateAnalytics());
        setAnalyticsRange({ isDateChanged: false });
    };

    const tabClickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
        const {
            dataset: { tab }
        } = e.currentTarget;

        if (tab) {
            setActiveTab(tab);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const labels = {};
    pricingContainer?.variants.forEach(model => {
        if (model._id) {
            labels[model._id] = model.title;
        }
    });

    return (
        <div>
            <div className={styles.initContainer}>
                <h2>Set ID: {pricingContainer?.set || "no set id"}</h2>
                <div className={styles.editor}>
                    <Headline
                        name={"Pricing Container Type"}
                        description={
                            'select "BUNDLE" for bundle pricing(ET4) and products for product pricing(ET1)'
                        }
                        isArray={false}
                        isGroup={true}
                        isParent={true}
                    >
                        <div>
                            <select
                                value={pricingContainer?.type || "PRODUCT"}
                                onChange={() => {}}
                                className={styles.value}
                                disabled
                            >
                                <option value="" disabled>
                                    Select a value
                                </option>
                                <option value="PRODUCT">Product based</option>
                                <option value="BUNDLE">Bundle Based</option>
                            </select>
                        </div>
                    </Headline>
                </div>
                {pricingContainer?.type === "BUNDLE" ? (
                    <div className={styles.bundleContainer}>
                        <FlavourBox />
                    </div>
                ) : null}
            </div>
            <section className={styles.modelList}>
                <header className={styles.header}>
                    <SpaceBetween>
                        <div className={styles.headlineContainer}>
                            <h3 className={styles.headline}>Pricing Models</h3>
                            <input
                                name="singleTrustAmount"
                                type="number"
                                value={minViews}
                                onChange={e => setMinViews(Number(e.target.value))}
                                className={styles.minViews}
                            />
                            <Button
                                design="button"
                                flat
                                onClick={updateSingleTrustAmount}
                                marginLeft
                            >
                                Update Min. Views
                            </Button>
                            {minViewsError ? (
                                <span className={styles.errMessage}>{minViewsError}</span>
                            ) : null}
                        </div>
                        <div>
                            <Link to="pricing/dynamic">
                                <Button design="button" flat marginLeft>
                                    Dynamic Active Pricing
                                </Button>
                            </Link>
                            <Button
                                design="button"
                                background="purple"
                                color="white"
                                onClick={() => dispatch(addPricingModelDraft(activeTab))}
                                marginLeft
                                flat
                            >
                                Add New Model
                            </Button>
                        </div>
                    </SpaceBetween>
                    {minViewsError && <div className={styles.errMessage}>{minViewsError}</div>}
                </header>
                <Tabs
                    tabList={pricingContainer?.trafficSources || []}
                    active={activeTab}
                    clickHandler={tabClickHandler}
                />
                {activeTab === "new" ? (
                    <div className={styles.newSource}>
                        <label>New Source:</label>
                        <input
                            className={styles.sourceInput}
                            name="sources"
                            placeholder="add comma seperated traffic source"
                            value={sources}
                            onChange={handleSourceChange}
                        />
                        <Button
                            design="button"
                            background="purple"
                            color="white"
                            onClick={addSources}
                            flat
                            disabled={isSourcesLoading}
                        >
                            Add New Sources
                        </Button>
                        {updateSourceError ? <div>{updateSourceError}</div> : null}
                    </div>
                ) : null}
                <div className={styles.pricingGraphsInfo}>
                    {isOldGraphsReport ? (
                        <>
                            You are watching OLD pricing graphs generated at{" "}
                            {moment(graphsReportCreatedAt)
                                .tz("America/Los_Angeles")
                                .format("YYYY-MM-DD HH:mm z")}{" "}
                            - checking accessibility...{" "}
                            <LoadingIndicator className={styles.loader} />
                        </>
                    ) : (
                        <>
                            You are watching pricing graphs generated at{" "}
                            {moment(graphsReportCreatedAt)
                                .tz("America/Los_Angeles")
                                .format("YYYY-MM-DD HH:mm z")}{" "}
                            (Refreshing automatically each 24h)
                            {loadingPricingGraphs ? (
                                <LoadingIndicator className={styles.loader} />
                            ) : (
                                <div
                                    className={styles.button}
                                    onClick={() =>
                                        dispatch(
                                            getPricingGraphsChunks(
                                                pricingContainer?.set || "",
                                                true
                                            )
                                        )
                                    }
                                >
                                    RENEW FOR $0.012
                                </div>
                            )}
                        </>
                    )}
                </div>
                {!isEmpty(currentGraphData) ? (
                    <Graph chartsData={currentGraphData} labels={labels} tab={activeTab} />
                ) : null}
                {currentPricingModels.length > 0 ? (
                    <div className={styles.datePickingContainer}>
                        <div className={styles.text}>From:</div>
                        <DatePicker
                            value={analyticsFromDate}
                            onChange={(date: Date | null) => handleAnalyticsRange("from", date)}
                        />
                        <div className={styles.text}>To:</div>
                        <DatePicker
                            value={analyticsToDate}
                            onChange={(date: Date | null) => handleAnalyticsRange("to", date)}
                        />
                        <div className={styles.text}>
                            <button onClick={updatePricingReport} type="button">
                                Get Report
                            </button>
                        </div>
                    </div>
                ) : null}
                {currentPricingModels.length || pricingModelDraft ? (
                    <main className={styles.pricingGrid}>
                        {currentPricingModels.length > 0
                            ? currentPricingModels.map((model: PricingModel) => (
                                  <ModelBox
                                      {...model}
                                      key={model._id}
                                      containerType={pricingContainer?.type || "PRODUCT"}
                                      currentTab={activeTab}
                                  />
                              ))
                            : null}
                        {pricingModelDraft ? (
                            <ModelBox
                                {...pricingModelDraft}
                                isDraft
                                containerType={pricingContainer?.type || "PRODUCT"}
                                currentTab={activeTab}
                            />
                        ) : null}
                    </main>
                ) : null}
                {!currentPricingModels.length ? (
                    <div className={styles.emptyMessage}>
                        0 Pricing Models found with given Source
                    </div>
                ) : null}
            </section>
        </div>
    );
};

export default ModelList;
