import * as actionTypes from "actionTypes";
import api from "api";
import socket from "../../../../../websocket";
import store from "store";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";

socket.on("googleBalancerReportProgress", (data: any) => {
    store.dispatch({
        type: actionTypes.GET_FF_REPORT_PROGRESS,
        payload: data
    });
});

socket.on("googleBalancerReport", (data: any) => {
    store.dispatch({
        type: actionTypes.GET_FF_REPORT_DATA,
        payload: data
    });
});

type GetReportProps = {
    funnelId: string;
    fromDate: string;
    toDate: string;
};

export const getReport = ({ funnelId, fromDate, toDate }: GetReportProps) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_FF_REPORT_START
        });

        api()
            .post("conversionbalancer/report", { funnelId, fromDate, toDate })
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.GET_FF_REPORT,
                    payload: res.data
                })
            )
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_FF_REPORT_ERROR,
                    payload: { error: err.response }
                })
            );
    };
};
