import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./styles.scss";
import { getFunnels, getReports } from "./actions";
import { useDispatch } from "react-redux";
import TabsMenu from "components/TabsMenu";
import addIcon from "assets/icons/square_add.svg";
import reportIcon from "assets/icons/square_list.svg";
import goalIcon from "assets/icons/goal.svg";
import flaskIcon from "assets/icons/flask.svg";
import cx from "classnames";
import CreateReport from "./scenes/CreateReport";
import ReportsList from "./scenes/ReportsList";
import CreateFunnel from "./scenes/CreateFunnel";
import FunnelsList from "./scenes/FunnelsList";
import BalancerJobs from "./scenes/BalancerJobs";

const reportTabs = [
    { id: "CREATE_REPORT", text: "GENERATE REPORT", icon: addIcon },
    { id: "SHOW_REPORT", text: "GENERATED REPORTS", icon: reportIcon },
    { id: "SHOW_DRAFTS", text: "BALANCER JOBS", icon: flaskIcon }
];

const funnelTabs = [
    { id: "SHOW_FUNNELS", text: "ACTIVE FUNNELS", icon: goalIcon },
    { id: "CREATE_FUNNEL", text: "CREATE FUNNEL", icon: addIcon }
];

const ConversionsBalancer = () => {
    const dispatch = useDispatch();
    const [activeReportTabId, setActiveReportTabId] = useState<string>(reportTabs[0].id);
    const [activeFunnelTabId, setActiveFunnelTabId] = useState<string>(funnelTabs[0].id);

    useEffect(() => {
        dispatch(getFunnels());
        dispatch(getReports());

        document.body.style.overflowY = "scroll";

        return () => {
            document.body.style.overflowY = "auto";
        };
    }, []);

    const handleReportTabClick = (newActiveTabId: string) => (
        e: React.SyntheticEvent<HTMLDivElement>
    ) => {
        setActiveReportTabId(newActiveTabId);
    };

    const renderReportTab = () => {
        switch (activeReportTabId) {
            case "CREATE_REPORT":
                return <CreateReport />;
            case "SHOW_DRAFTS":
                return <BalancerJobs />;
            case "CREATE_REPORT":
            default:
                return <ReportsList setActiveReportTabId={setActiveReportTabId} />;
        }
    };

    const handleFunnelTabClick = (newActiveTabId: string) => (
        e: React.SyntheticEvent<HTMLDivElement>
    ) => {
        setActiveFunnelTabId(newActiveTabId);
    };

    const renderFunnelTab = () => {
        switch (activeFunnelTabId) {
            case "SHOW_FUNNELS":
                return <FunnelsList setActiveFunnelTabId={setActiveFunnelTabId} />;
            case "CREATE_FUNNEL":
            default:
                return <CreateFunnel setActiveFunnelTabId={setActiveFunnelTabId} />;
        }
    };

    return (
        <>
            <header>
                <h1>Conversions Balancer</h1>
            </header>
            <main>
                <div className={cx(styles.panel, styles.withTabs)}>
                    <TabsMenu
                        tabs={funnelTabs}
                        activeOptionId={activeFunnelTabId}
                        onClick={handleFunnelTabClick}
                    />
                    <main>{renderFunnelTab()}</main>
                </div>
                <div className={cx(styles.panel, styles.withTabs)}>
                    <TabsMenu
                        tabs={reportTabs}
                        activeOptionId={activeReportTabId}
                        onClick={handleReportTabClick}
                    />
                    <main>{renderReportTab()}</main>
                </div>
            </main>
        </>
    );
};

export default ConversionsBalancer;
