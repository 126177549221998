import * as React from "react";
import close from "assets/icons/close.svg";
import cx from "classnames";
import styles from "./styles.scss";

type Props = {
    className?: string;
    filter?: (prevValue: number, nextValue: number) => number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: number) => void;
    [x: string]: any;
};

const FlatInput: React.FC<Props> = ({
    children,
    className = "",
    filter,
    onChange,
    ...inputProps
}) => {
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let result;

        if (filter) result = filter(value, Number(e.target.value));
        else result = e.target.value;

        setValue(Number(result));

        if (onChange) {
            onChange(e, Number(result));
        }
    };

    const handleClear = () => {
        setValue(0);
    };

    return (
        <div className={cx(styles.flatInput, className)}>
            <div className={styles.inputWrapper}>
                <input
                    className={styles.input}
                    size={15}
                    value={value !== 0 ? value : ""}
                    onChange={handleChange}
                    placeholder="0"
                    {...inputProps}
                />
                <span className={styles.placeholder}>%</span>
            </div>
            <div className={styles.button} onClick={handleClear}>
                <img src={close} />
            </div>
        </div>
    );
};

export default FlatInput;
