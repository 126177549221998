import * as React from "react";
import Button from "components/Button";
import Headline from "../Headline";
import SpaceBetween from "scenes/Dashboard/SchemaCMSEditor/components/SpaceBetween";
import SwitchButton from "components/SwitchButton";
import cx from "classnames";
import styles from "./styles.scss";
import { PricingContext } from "../../../..";
import { editPricingModel, confirmPricingModel, editPricingModelDraft } from "../../../../actions";
import { useDispatch } from "react-redux";
import { PricingModelContext } from "../..";
import Currency from "assets/icons/currency.svg";
import LocationIcon from "assets/icons/pin.svg";
import SVG from "react-inlinesvg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as isoCountries from "assets/isoCountries";

const animatedComponents = makeAnimated();

type SelectOption = {
    value: string;
    label: string;
};

const Header: React.FC = () => {
    const { pricingContainer, pricingModelDraft, currencies } = React.useContext(PricingContext);
    const {
        isDraft,
        title,
        id: pricingModelId,
        isActive,
        currency,
        supportedCountries
    } = React.useContext(PricingModelContext);
    const [countryOptions, setCountryOptions] = React.useState<Array<SelectOption>>([]);
    const [currencyOptions, setCurrencyOptions] = React.useState<Array<SelectOption>>([]);

    // SUPPORT MODEL DUPLICATION
    const [selectedCurrency, setSelectedCurrency] = React.useState<SelectOption | null>(
        currency ? { value: currency, label: currency } : null
    );
    const [selectedCountry, setSelectedCountry] = React.useState<Array<SelectOption>>(
        supportedCountries?.length ? supportedCountries.map(el => ({ value: el, label: el })) : []
    );

    const dispatch = useDispatch();
    const isEditing = isDraft && pricingModelDraft;

    React.useEffect(() => {
        if (isEditing) {
            const newCurrencyOptions = currencies.map(el => ({ value: el.code, label: el.name }));
            const newCountryOptions = Object.entries(isoCountries).map(([key, value]) => ({
                value: key,
                label: value
            }));

            setCountryOptions(newCountryOptions);
            setCurrencyOptions(newCurrencyOptions);
        }
    }, [isEditing]);

    const selectCurrency = (selectedOption: SelectOption) => {
        const { value: selectedCurrency } = selectedOption;

        setSelectedCurrency({ value: selectedCurrency, label: selectedCurrency });
        dispatch(editPricingModelDraft("currency", selectedCurrency));
    };

    const selectCountries = (selectedOptions: SelectOption[]) => {
        const options = selectedOptions || [];

        const selectedCountries = options.map(el => el.value);

        setSelectedCountry(options.map(({ value }) => ({ value, label: value })));
        dispatch(editPricingModelDraft("supportedCountries", selectedCountries));
    };

    return (
        <div className={styles.modelBox}>
            <header
                className={cx(styles.header, {
                    [styles.borderBottom]: isDraft
                })}
            >
                <SpaceBetween>
                    {isEditing ? (
                        <>
                            <input
                                className={styles.input}
                                placeholder="Model Name"
                                value={pricingModelDraft?.title}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    dispatch(editPricingModelDraft("title", e.target.value))
                                }
                            />
                            <div className={styles.rightColumn}>
                                <Select
                                    options={countryOptions}
                                    components={animatedComponents}
                                    isMulti
                                    name="supportedCountries"
                                    className={styles.country}
                                    placeholder="Countries..."
                                    onChange={selectCountries}
                                    value={selectedCountry}
                                />
                                <Select
                                    options={currencyOptions}
                                    components={animatedComponents}
                                    name="supportedCountries"
                                    className={styles.currency}
                                    placeholder="Currency..."
                                    onChange={selectCurrency}
                                    value={selectedCurrency}
                                />

                                <Button
                                    onClick={() => {
                                        if (pricingContainer && pricingModelDraft)
                                            dispatch(
                                                confirmPricingModel(
                                                    pricingContainer._id,
                                                    pricingModelDraft
                                                )
                                            );
                                    }}
                                    flat
                                    background="purple"
                                >
                                    Confirm
                                </Button>
                            </div>
                        </>
                    ) : null}

                    {!isEditing ? (
                        <>
                            <Headline title={title} />
                            <div className={styles.rightColumn}>
                                {supportedCountries.length ? (
                                    <Button marginRight className={styles.btn}>
                                        <div className={styles.svg}>
                                            <SVG src={LocationIcon} />
                                        </div>
                                        <div className={styles.text}>
                                            {supportedCountries.join(", ")}
                                        </div>
                                    </Button>
                                ) : null}

                                <Button marginRight className={styles.btn}>
                                    <div className={styles.svg}>
                                        <SVG src={Currency} />
                                    </div>
                                    <div className={styles.text}>{currency}</div>
                                </Button>

                                <SwitchButton
                                    isActive={isActive}
                                    onSwitch={(e, isActive) =>
                                        pricingContainer
                                            ? dispatch(
                                                  editPricingModel({
                                                      _id: pricingModelId,
                                                      isActive,
                                                      pricingContainerId: pricingContainer._id
                                                  })
                                              )
                                            : null
                                    }
                                />
                            </div>
                        </>
                    ) : null}
                </SpaceBetween>
            </header>
        </div>
    );
};

export default Header;
