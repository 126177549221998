import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { reportQuery } from "types/report";

export enum Filter {
    paymentMethod = "paymentMethod"
}

export type filterKey = keyof typeof Filter;

export function getDistinctField(filterKey: Filter) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_DISTINCT_FIELD_START
        });

        api()
            .post("report/getDistinctField", { filterKey })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_DISTINCT_FIELD,
                    payload: {
                        filter: {
                            [filterKey]: res.data.fields.filter(
                                (field: any) => field != "" && field != null
                            )
                        }
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_DISTINCT_FIELD_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function getReport(query: reportQuery) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_REPORT_START
        });

        api()
            .post("report/getTotals", query)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_REPORT,
                    payload: { report: res.data }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_REPORT_ERROR,
                    payload: { error: err.response }
                });
            });
    };
}

export function getElements(setId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ELEMENTS_START
        });

        api()
            .get(`element/noparent/${setId}`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_ELEMENTS,
                    payload: { elements: res.data }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_ELEMENTS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getBricks(setId: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_BRICKS_START
        });

        api()
            .get(`brick/${setId}`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_BRICKS,
                    payload: { bricks: res.data }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_BRICKS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function loadAdAccounts(userID: string, access_token: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_ADACCOUNTS_START
        });

        api()
            .post("fbspend/adaccounts", { access_token, userID })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_ADACCOUNTS,
                    payload: { adaccounts: res.data.adaccounts }
                });
            })
            .catch((err: AxiosError) =>
                dispatch({
                    type: actionTypes.GET_ADACCOUNTS_ERROR,
                    payload: { error: err.response }
                })
            );
    };
}

export function getSpend(from: any, to: any, adAccount: string, accessToken: string) {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.GET_SPEND_START });
        api()
            .post("fbspend", { adAccount, accessToken, from, to })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_SPEND,
                    payload: { spend: res.data[0] }
                });
            })
            .catch((err: AxiosError) => {
                const error = err.response ? err.response.data || "" : err.response;
                dispatch({
                    type: actionTypes.GET_SPEND_ERROR,
                    payload: { error }
                });
            });
    };
}
