import * as moment from "moment-timezone";
import * as React from "react";
import "moment-duration-format";

class Timer extends React.Component<any, any> {
    interval: number;

    state = {
        time: 0
    };

    componentDidMount() {
        if (this.props.timeBehind) {
            this.setState({
                time: this.props.timeBehind * 1000
            });
        }

        this.interval = window.setInterval(() => {
            this.setState((currentState: any) => ({
                time: currentState.time + 1000
            }));
        }, 1000);
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    render() {
        const { time } = this.state;

        return (
            <div className={this.props.className} ref={this.props.ref}>
                {moment.duration(time, "ms").format("mm:ss", {
                    trim: false
                })}
            </div>
        );
    }
}

export default Timer;
