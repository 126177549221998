import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { conversionAction } from "types/conversionAction";

export function getConversionActions() {
    return api()
        .get("googleads/conversion-actions")
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((err: AxiosError) => {
            throw err;
        });
}

export const addConversionAction = (conversionActionData: conversionAction) => {
    return api()
        .post("googleads/conversion-actions", conversionActionData)
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((err: AxiosError) => {
            throw err;
        });
};

export const updateConversionAction = (conversionActionData: conversionAction) => {
    return api()
        .put(`googleads/conversion-actions/${conversionActionData._id}`, conversionActionData)
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((err: AxiosError) => {
            throw err;
        });
};

export function removeConversionAction(conversionActionId: string) {
    return api()
        .delete(`googleads/conversion-actions/${conversionActionId}`)
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((err: AxiosError) => {
            throw err;
        });
}
