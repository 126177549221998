import * as React from "react";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

type Props = {};

const SDKLive: React.FC<Props> = () => {
    const sets = useSelector((state: appStateType) => state.sets.sets).filter(set => set.isSdk);

    return (
        <div>
            <h2>SDK Content Set</h2>
            {sets.length === 0 ? (
                <LoadingIndicator />
            ) : (
                <div className={styles.set_grid}>
                    {sets.map(set => (
                        <Link key={set._id} to={`/dashboard/deployment/sdklive/${set._id}`}>
                            <div className={styles.set_box}>
                                <h3>{set.name}</h3>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SDKLive;
