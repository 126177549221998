import * as React from "react";
import * as actions from "./actions";
import * as dashboardActions from "../../../actions";
import * as queryString from "query-string";
import Button from "components/Button";
import CampaignKeys from "../Server/components/CampaignKeys";
import DynamicForm from "../Server/components/DynamicForm";
import Versions from "../Versions";
import _ from "lodash";
import styles from "./styles.scss";
import withServerSettings from "hoc/withServerSettings";
import { appStateType } from "reducers";
import { connect } from "react-redux";
import { validKeys } from "types/server";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ActionMeta } from "react-select";
import { set } from "types/set";
import LoadingIndicator from "components/LoadingIndicator";

const animatedComponents = makeAnimated();

type SelectOption<T = string> = {
    value: T;
    label: string;
};

type DeploymentStateType = {
    basename: string;
    count: number;
    isTooltipOpen: boolean;
    set: string;
    template: string;
    version: string;
    id: string;
};

class AddDeployment extends React.PureComponent<any, DeploymentStateType> {
    static defaultProps = {
        sets: [],
        templates: [],
        versions: []
    };

    state: DeploymentStateType = {
        basename: "",
        count: 0,
        isTooltipOpen: false,
        set: "",
        template: "",
        version: "",
        id: ""
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const { location } = this.props;

        if (location.search) {
            const parsed = queryString.parse(location.search);
            const {
                basename = "",
                set = "",
                template = "",
                version = "",
                id = ""
            } = {
                ...parsed
            };

            this.setState({
                basename,
                set,
                template,
                version,
                id
            });

            if (id && id !== "") {
                this.props.getDeployment(id);
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<any>): void {
        const prevEditedDeployment = prevProps.deployment.editedDeployment;
        const { updateEditedDeploymentDetails } = this.props;
        const { editedDeployment } = this.props.deployment;

        if (!prevEditedDeployment && editedDeployment) {
            updateEditedDeploymentDetails(editedDeployment.details);
        }
    }

    handleOnChange(e: React.SyntheticEvent) {
        e.preventDefault();
        let { value, name } = e.target as HTMLInputElement;

        if (name === "basename") {
            value = value.toLowerCase().replace(/[^a-z0-9]/g, "");
        }

        const updateState =
            <T extends string>(name: keyof DeploymentStateType, value: T) =>
            (prevState: DeploymentStateType): DeploymentStateType => ({
                ...prevState,
                [name]: value
            });

        this.setState(updateState(name as keyof DeploymentStateType, value));
    }

    handleSelectChange(
        selectedOption: SelectOption,
        config: ActionMeta<{ name: string; action: string }>
    ) {
        const value = selectedOption && selectedOption.value ? selectedOption.value : "";
        this.setState(state => ({ ...state, [config.name as string]: value }));
    }

    handleSaveClick(server: string, e: React.SyntheticEvent) {
        e.preventDefault();
        const {
            settings: { campaignKeys, otherSettings, pixels }
        } = this.props;

        this.props.saveDeployment({
            ...this.state,
            server,
            settings: { campaignKeys, otherSettings, pixels }
        });
    }

    toggleTooltip() {
        this.setState(() => ({ isTooltipOpen: !this.state.isTooltipOpen }));
    }

    render() {
        const {
            deployment,
            handleCampaignKeyChange,
            handleDynamicFormChange,
            match: {
                params: { id }
            },
            sets: { sets },
            templates,
            versions = [],
            brands,
            settings: { campaignKeys, otherSettings, pixels }
        } = this.props;
        const { isSavingDeployment, savingDeploymentError, editedDeployment } = deployment;
        if (!editedDeployment) return <LoadingIndicator />;
        const filteredOtherSettings = _.pick(otherSettings, validKeys);

        const setsWithBrands = brands.reduce((current: any[], next: any) => {
            return [...next.sets, ...current];
        }, []);

        const { basename, isTooltipOpen, set, template, version } = this.state;
        const groupedVersions = _(versions)
            .filter((version: any) => version.released === true)
            .groupBy("template._id")
            .value();
        const templateVersions = groupedVersions[template] || [];

        const setOptions = sets
            .filter((el: set) => setsWithBrands.includes(el._id))
            .map((set: any) => ({
                label: set.name,
                value: set._id
            }));

        const templateOptions = templates.map((template: any) => ({
            label: `${template.name} (${template.shortcut})`,
            value: template._id
        }));

        const versionOptions = templateVersions.map((version: any) => ({
            label: `${version.tag} (${version.template.shortcut})`,
            value: version._id
        }));

        const selectedTemplate = templateOptions.find((el: SelectOption) => el.value === template);
        const selectedSet = setOptions.find((el: SelectOption) => el.value === set);
        const selectedVersion = versionOptions.find((el: SelectOption) => el.value === version);

        return (
            <>
                <div className={styles.panel_wide}>
                    <header className={styles.header}>
                        <h2 className={styles.h2}>Deployment Details:</h2>
                        <div className={styles.horizontal}>
                            <div>
                                <input
                                    name="basename"
                                    onChange={this.handleOnChange.bind(this)}
                                    placeholder="Enter Basename"
                                    type="text"
                                    value={basename}
                                />
                            </div>
                            {basename && (
                                <div className={styles.customSelect}>
                                    <Select
                                        options={templateOptions}
                                        components={animatedComponents}
                                        name="template"
                                        placeholder="Select Template"
                                        onChange={this.handleSelectChange.bind(this)}
                                        value={selectedTemplate}
                                        isClearable
                                        isSearchable
                                        styles={{
                                            menu: () => ({
                                                maxWidth: "100%",
                                                border: "1px solid gray",
                                                borderRadius: "4px",
                                                overflowX: "hidden",
                                                background: "white",
                                                position: "absolute"
                                            })
                                        }}
                                    />
                                </div>
                            )}
                            {templateVersions && (
                                <div className={styles.version}>
                                    <div className={styles.customSelect}>
                                        <Select
                                            options={versionOptions}
                                            components={animatedComponents}
                                            name="version"
                                            placeholder="Select Version"
                                            onChange={this.handleSelectChange.bind(this)}
                                            value={selectedVersion}
                                            isClearable
                                            isSearchable
                                            styles={{
                                                menu: () => ({
                                                    maxWidth: "100%",
                                                    border: "1px solid gray",
                                                    borderRadius: "4px",
                                                    overflowX: "hidden",
                                                    background: "white",
                                                    position: "absolute"
                                                })
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={styles.oval}
                                        onClick={() => this.toggleTooltip()}
                                    >
                                        <img alt="versions" src="/img/svg/info.svg" />
                                    </div>
                                    <Versions
                                        mode="tooltip"
                                        list={templateVersions}
                                        isOpen={isTooltipOpen}
                                    />
                                </div>
                            )}
                            {version && (
                                <div className={styles.set}>
                                    <div className={styles.customSelect}>
                                        <Select
                                            options={setOptions}
                                            components={animatedComponents}
                                            name="set"
                                            placeholder="Select CMS Set"
                                            onChange={this.handleSelectChange.bind(this)}
                                            value={selectedSet}
                                            isClearable
                                            isSearchable
                                            styles={{
                                                menu: () => ({
                                                    maxWidth: "100%",
                                                    border: "1px solid gray",
                                                    borderRadius: "4px",
                                                    overflowX: "hidden",
                                                    background: "white",
                                                    position: "absolute"
                                                })
                                            }}
                                        />
                                    </div>
                                    <span
                                        style={{ cursor: "help" }}
                                        title="If you do not find the set you are looking for then you should check if you attach proper brand to that set."
                                    >
                                        &#9888;
                                    </span>
                                </div>
                            )}
                        </div>
                    </header>
                    <>
                        <DynamicForm
                            handleDynamicFormChange={(e: React.SyntheticEvent) =>
                                handleDynamicFormChange("pixels", e)
                            }
                            formData={pixels}
                            formName="Pixels"
                            isPanel={false}
                        />
                        <DynamicForm
                            handleDynamicFormChange={(e: React.SyntheticEvent) =>
                                handleDynamicFormChange("otherSettings", e)
                            }
                            formData={filteredOtherSettings}
                            formName="Other Settings"
                            isPanel={false}
                        />
                        <CampaignKeys
                            campaignKeys={campaignKeys}
                            handleCampaignKeyChange={handleCampaignKeyChange}
                        />
                    </>
                    <footer className={styles.footer}>
                        {_.every([basename, template, version, set], Boolean) ? (
                            <Button
                                background="green"
                                className={styles.bigDeploy}
                                disabled={isSavingDeployment}
                                flat
                                onClick={this.handleSaveClick.bind(this, id)}
                            >
                                {isSavingDeployment ? "Deploying..." : "Deploy"}
                            </Button>
                        ) : (
                            <div> please add basename, template, version and content set. </div>
                        )}
                        {savingDeploymentError ? (
                            <div className={styles.error}>{savingDeploymentError}</div>
                        ) : null}
                    </footer>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: appStateType) => {
    return {
        deployment: state.deployment,
        sets: state.sets,
        brands: state.brand.brands,
        templates: state.deployment.templates,
        versions: state.deployment.versions
    };
};

export default connect(mapStateToProps, {
    ...actions,
    ...dashboardActions
})(withServerSettings(AddDeployment));
