import * as React from "react";
import Button from "components/Button";
import FlatInput from "../../../FlatInput";
import styles from "./styles.scss";
import { PricingModelContext } from "../..";
import { PricingContext } from "../../../../";
import { useDispatch } from "react-redux";
import { copyPricingModel } from "../../../../actions";

interface Props {
    modelId: string;
}

const Footer: React.FC<Props> = ({ modelId }) => {
    const { isDraft } = React.useContext(PricingModelContext);
    const { setId, languageId } = React.useContext(PricingContext);
    const [priceDeterminant, setPriceDeterminant] = React.useState<"lower" | "higher">("lower");
    const [priceMultiplier, setPriceMultiplier] = React.useState<number>(0);
    const dipatch = useDispatch();

    const copyPricing = () => {
        dipatch(
            copyPricingModel({
                priceMultiplier,
                priceDeterminant,
                setId,
                languageId,
                modelId
            })
        );
    };

    return (
        <>
            {!isDraft && (
                <footer className={styles.footer}>
                    <FlatInput
                        filter={(prevValue, value) => {
                            const regexp = new RegExp(/^[0-9]{0,3}$/g);
                            const isValid = regexp.test(String(value));

                            if (isValid) {
                                const asNumber: number = +value;

                                if (asNumber <= 100) {
                                    return asNumber;
                                } else {
                                    return prevValue;
                                }
                            }

                            return prevValue;
                        }}
                        onChange={(e, value) => setPriceMultiplier(value)}
                    />
                    <Button
                        background={priceDeterminant === "lower" ? "pinkGradient" : undefined}
                        onClick={() => setPriceDeterminant("lower")}
                        flat
                    >
                        Lower
                    </Button>
                    <Button
                        background={priceDeterminant === "higher" ? "pinkGradient" : undefined}
                        onClick={() => setPriceDeterminant("higher")}
                        flat
                    >
                        Higher
                    </Button>
                    <Button onClick={copyPricing} background="purple" flat>
                        Duplicate
                    </Button>
                </footer>
            )}
        </>
    );
};

export default Footer;
