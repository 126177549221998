import { TriggerVariable } from "types/triggerVariable";

const triggerVariables: TriggerVariable[] = [
    {
        enum: null,
        type: "NUMBER",
        name: "Cart Value",
        key: "cartValue"
    },
    {
        enum: [
            "AMAZON",
            "BANCODOBRASIL",
            "BANRISUL",
            "BOLETO",
            "BRADESCO",
            "CC",
            "EBANXACCOUNT",
            "ECC",
            "ITAU",
            "OXXO",
            "PAYPAL",
            "SEZZLE",
            "TEF"
        ],
        type: "ENUM",
        name: "Payment Method",
        key: "paymentMethod"
    },
    {
        enum: null,
        type: "SWITCH",
        name: "Warranty Status",
        key: "warrantyStatus"
    },
    {
        enum: null,
        type: "SWITCH",
        name: "Subscription Purchased",
        key: "isSubscription"
    },
    {
        enum: null,
        type: "SWITCH",
        name: "Offer Upgrade Purchase",
        key: "isOfferUpgrade"
    },
    {
        enum: null,
        type: "PRODUCTS",
        name: "Product Id",
        key: "products"
    }
];

export default triggerVariables;
