import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { Dispatch } from "redux";
import { brick } from "types/brick";
import { element } from "types/element";
import { set as setTypes } from "types/set";
import { schemaType } from "./";
import { filterSchame } from "services/filterSchema";
import { pullVersion } from "../CPDS/scenes/Versions/actions";
import {
    getOdooProducts,
    getOdooProductsProd,
    getOdooProductsDev,
    getKonnektiveProducts
} from "../actions";
import { getLanguage } from "../actions";
import { isEmpty } from "lodash";

export function getElementsTree(setId: string, languageId: string) {
    return (dispatch: Dispatch<any>, getState: any) => {
        const {
            elements: { currentSchema: schema }
        } = getState();

        const isElementValid = (path: string, type: string): boolean => {
            const schemaNode = _.get(schema, path);

            if (!schemaNode) {
                return false;
            }

            if (type === "ARRAY") {
                return schemaNode._type.includes("[]");
            }

            if (schemaNode._type === "SWITCH") {
                return type === "SWITCH" || type === "NUMBER";
            }

            if (schemaNode._type === "OBJECT") {
                return type === "FILE" || type === "OBJECT";
            }

            return schemaNode._type === type;
        };

        const elemetsPromise = api().get(`element/noparent/${setId}`);
        const bricksPromise = api().get(`brick/${setId}`);

        Promise.all([elemetsPromise, bricksPromise]).then(([elementsRes, bricksRes]) => {
            const elements = elementsRes.data;
            const bricks = bricksRes.data;

            const allElements = elements.filter(
                (element: any) =>
                    (element.language && element.language._id === languageId) ||
                    element.language == null ||
                    element.language == ""
            );

            const allBricks = bricks.filter((brick: brick) => brick.language === languageId);

            dispatch({
                type: actionTypes.GET_ELEMENTS,
                payload: { elements: allElements }
            });

            dispatch({
                type: actionTypes.GET_BRICKS,
                payload: { bricks: allBricks }
            });

            const elementsWithoutParent = allElements.filter(
                (element: element) => element.parent === ""
            );

            const getElementsLevel = (
                elements: element[],
                isArray: boolean = false,
                path: string = ""
            ) => {
                let elementsLevel = (isArray ? [] : {}) as any;
                elements.forEach((element: element) => {
                    const children = allElements.filter(
                        (children: element) => String(children.parent) == String(element._id)
                    );

                    let newPath = path;

                    if (newPath === "") {
                        newPath += element.name;
                    } else {
                        if (element.name !== "newName" && isArray === false) {
                            newPath += `.${element.name}`;
                        }
                    }

                    if (children.length === 0) {
                        let brick = allBricks.filter(
                            (brick: brick) => brick.element == element._id
                        );

                        if (brick !== undefined) {
                            if (isArray) {
                                elementsLevel.push(...brick);
                            } else {
                                if (isElementValid(newPath, element.type)) {
                                    elementsLevel[element.name] = brick[0];
                                }
                            }
                        }
                    } else {
                        const isArrayType = [
                            "ARRAY",
                            "UPSELLS",
                            "PRODUCTS",
                            "BUNDLES",
                            "FLAVOUR_PRODUCTS"
                        ].includes(element.type);
                        let node = getElementsLevel(children, isArrayType, newPath) as any;

                        const nodeKeys = Object.keys(node);

                        if (nodeKeys.length === 1 && nodeKeys[0] === "newName") {
                            node = node[nodeKeys[0]];
                        } else {
                            node = Array.isArray(node)
                                ? node
                                : {
                                      ...node,
                                      _elementId: element._id,
                                      _type: element.type
                                  };
                        }

                        if (isArray) {
                            if (elementsLevel.length === 0) {
                                if (Array.isArray(node)) {
                                    node = node.flat();
                                }
                            }
                            elementsLevel.push(node);
                        } else {
                            if (isElementValid(newPath, element.type)) {
                                elementsLevel[element.name] = node;
                            }
                        }
                    }
                });

                return elementsLevel;
            };

            let elementsTree = getElementsLevel(elementsWithoutParent);

            Object.entries(elementsTree).map(([key, value]) => {
                if (Array.isArray(value)) {
                    elementsTree[key] = value[0];
                }
            });

            dispatch({
                type: actionTypes.SET_ELEMENTS_TREE,
                payload: { elementsTree }
            });
        });
    };
}

export function getFullElementsTree(allElements: any, allBricks: any, schema: any) {
    const isElementValid = (path: string, type: string): boolean => {
        const schemaNode = _.get(schema, path);

        if (!schemaNode) {
            return false;
        }

        if (type === "ARRAY") {
            return schemaNode._type.includes("[]");
        }

        if (schemaNode._type === "SWITCH") {
            return type === "SWITCH" || type === "NUMBER";
        }

        if (schemaNode._type === "OBJECT") {
            return type === "FILE" || type === "OBJECT";
        }

        return schemaNode._type === type;
    };

    const elementsWithoutParent = allElements.filter((element: element) => element.parent === "");

    const getElementsLevel = (elements: element[], isArray: boolean = false, path: string = "") => {
        let elementsLevel = (isArray ? [] : {}) as any;
        elements.forEach((element: element) => {
            const children = allElements.filter(
                (children: element) => String(children.parent) == String(element._id)
            );

            let newPath = path;

            if (newPath === "") {
                newPath += element.name;
            } else {
                if (element.name !== "newName" && isArray === false) {
                    newPath += `.${element.name}`;
                }
            }

            if (children.length === 0) {
                let brick = allBricks.filter((brick: brick) => brick.element == element._id);

                if (brick !== undefined) {
                    if (isArray) {
                        elementsLevel.push(...brick);
                    } else {
                        if (isElementValid(newPath, element.type)) {
                            elementsLevel[element.name] = brick[0];
                        }
                    }
                }
            } else {
                const isArrayType = [
                    "ARRAY",
                    "UPSELLS",
                    "PRODUCTS",
                    "BUNDLES",
                    "FLAVOUR_PRODUCTS"
                ].includes(element.type);
                let node = getElementsLevel(children, isArrayType, newPath) as any;

                const nodeKeys = Object.keys(node);

                if (nodeKeys.length === 1 && nodeKeys[0] === "newName") {
                    node = node[nodeKeys[0]];
                } else {
                    node = Array.isArray(node)
                        ? node
                        : {
                              ...node,
                              _elementId: element._id,
                              _type: element.type
                          };
                }

                if (isArray) {
                    if (elementsLevel.length === 0) {
                        if (Array.isArray(node)) {
                            node = node.flat();
                        }
                    }
                    elementsLevel.push(node);
                } else {
                    if (isElementValid(newPath, element.type)) {
                        elementsLevel[element.name] = node;
                    }
                }
            }
        });

        return elementsLevel;
    };

    let elementsTree = getElementsLevel(elementsWithoutParent);

    Object.entries(elementsTree).map(([key, value]) => {
        if (Array.isArray(value)) {
            elementsTree[key] = value[0];
        }
    });

    return elementsTree;
}

type SaveVariantInput = {
    brickId: string;
    value: string;
    type: string;
    path: string;
};

export function saveVariant({ brickId, value, type, path }: SaveVariantInput) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_ELEMENTS_TREE_VARIANT_START,
            payload: { path, value }
        });

        api()
            .put("brick", { id: brickId, value, type, path })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.ADD_ELEMENTS_TREE_VARIANT,
                    payload: { path, brick: res.data }
                });
            })
            .catch((err: AxiosError) => console.error(err.response));
    };
}

type DeleteVariantInput = {
    brickId: string;
    variantId: string;
    type: string;
    path: string;
};

export function deleteVariant({ brickId, variantId, type, path }: DeleteVariantInput) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_ELEMENTS_TREE_VARIANT,
            payload: { path, variantId }
        });

        api()
            .delete("brick", { data: { brickId, variantId, type, path } })
            .then()
            .catch((err: AxiosError) => console.error(err.response));
    };
}

export type CreateTreeNodeInput = {
    schema: schemaType;
    setId: string;
    languageId: string;
    path: string;
    value: string;
};

const getSchemaPath = (schema: schemaType, path: string) => {
    const keys = path.split(".");
    const validKeys = [] as string[];
    let prevPath = "";

    keys.forEach(key => {
        let tempPath;

        if (prevPath === "") {
            tempPath = key;
        } else {
            tempPath = prevPath + "." + key;
        }
        const schemaNode = _.get(schema, tempPath);

        if (schemaNode) {
            validKeys.push(key);
            prevPath = tempPath;
        }
    });

    return validKeys.join(".");
};

export function createTreeNode({
    schema,
    setId,
    languageId,
    path,
    value = ""
}: CreateTreeNodeInput) {
    return async (dispatch: Dispatch) => {
        if (path[0] === ".") path = path.slice(1);

        if (value !== "") {
            dispatch({
                type: actionTypes.ADD_ELEMENTS_TREE_VARIANT_START,
                payload: { path, value }
            });
        }

        const pathChain = path.split(".");
        let chain: { name: string; _type: string }[] = [];
        let prevPath = "";

        for (let i = 0; i < pathChain.length; i++) {
            const nodeKey = pathChain[i];
            const parent = chain[i - 1];

            if (prevPath === "") {
                prevPath += nodeKey;
            } else {
                prevPath += "." + nodeKey;
            }

            if (parent && parent._type.includes("[]")) {
                chain.push({
                    name: nodeKey,
                    _type: parent._type.replace("[]", "")
                });
            } else if (
                parent &&
                [
                    "newUpsells",
                    "newUpsell",
                    "__BUNDLES",
                    "__BUNDLE",
                    "__FLAVOUR_PRODUCTS",
                    "__FLAVOUR_PRODUCT",
                    "__PRODUCTS",
                    "__PRODUCT"
                ].includes(parent.name)
            ) {
                chain.push({
                    name: nodeKey,
                    _type: "OBJECT"
                });
            } else {
                const schemaPath = getSchemaPath(schema, prevPath);
                const schemaNode = _.get(schema, schemaPath);

                chain.push({
                    name: nodeKey,
                    _type: schemaNode._type
                });
            }
        }

        chain = chain.map(o => ({
            ...o,
            _type: o._type.includes("[]") ? "ARRAY" : o._type
        }));

        try {
            const res: AxiosResponse = await api().post("element/treenode", {
                setId,
                languageId,
                chain,
                value
            });
            const chainResults = res.data;
            let _prevPath = "";

            chainResults.forEach(
                ({ name, newBrick }: { name: string; newBrick: brick; newElement: element }) => {
                    if (_prevPath === "") _prevPath += name;
                    else _prevPath += "." + name;

                    if (newBrick != null) {
                        dispatch({
                            type: actionTypes.ADD_ELEMENTS_TREE_VARIANT,
                            payload: { path: _prevPath, brick: newBrick }
                        });
                    }
                }
            );

            return chainResults;
        } catch (error) {
            console.error(error.message);
        }
    };
}

export function createArrayNode(path: string) {
    return {
        type: actionTypes.ADD_ARRAY_NODE,
        payload: { path }
    };
}

export function deleteElement(path: string = "", id: string = "", deleteParent: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.DELETE_ARRAY_NODE,
            payload: { path }
        });

        if (id != "" || deleteParent) {
            api()
                .delete("element", { data: { id, deleteParent, path } })
                .then()
                .catch((err: AxiosError) => console.info(err));
        }
    };
}

export function setCurrentSchema(versionId: string) {
    return (dispatch: Dispatch<any>, getState: any) => {
        const { version } = getState().deployment;
        const schema =
            version && version._id === versionId && version._schema ? version._schema : {};

        dispatch({
            type: actionTypes.ELEMENTS_SET_SCHEMA,
            payload: {
                schema: schema
            }
        });
    };
}

export function setSchemaKey(schemaKey: string) {
    return {
        type: actionTypes.SET_SCHEMA_KEY,
        payload: {
            schemaKey
        }
    };
}

// filter cms components
export function searchfilter(searchString: string, versionId: string) {
    return (dispatch: Dispatch<any>, getState: any) => {
        const {
            elements,
            bricks,
            deployments: { version }
        } = getState();

        const schema =
            version && version._id === versionId && version._schema ? version._schema : {};
        const allBricks = Array.from(bricks.bricksMap.values());

        const fullSchemaTree = getFullElementsTree(elements.elements, allBricks, schema._schema);
        const filteredSchame = filterSchame(schema, fullSchemaTree, searchString);

        if (schema && searchString) {
            dispatch({
                type: actionTypes.ELEMENTS_SET_SCHEMA,
                payload: {
                    schema: filteredSchame
                }
            });
        } else {
            dispatch({
                type: actionTypes.ELEMENTS_SET_SCHEMA,
                payload: {
                    schema: schema
                }
            });
        }
    };
}

export const getSetData = (setId: string, versionId: string = "", languageId: string = "") => {
    return (dispatch: Dispatch<any>, getState: any) => {
        const {
            sets: { sets }
        } = getState();

        const set = sets.find((el: setTypes) => el._id === setId);

        if (!set) {
            return;
        }

        const { campaignId, crm = "KONNEKTIVE", konnektiveCrm } = set;

        dispatch(pullVersion(versionId));
        dispatch(getLanguage(languageId));

        const elementsInterval = setInterval(() => {
            const {
                elements: { currentSchema }
            } = getState();
            if (!isEmpty(currentSchema)) {
                clearInterval(elementsInterval);
                dispatch(getElementsTree(setId, languageId));
            }
        });

        switch (crm) {
            case "KONNEKTIVE":
                dispatch(getKonnektiveProducts({ campaignId, konnektiveCrm }));
                break;
            case "ODOO":
                dispatch(getOdooProducts());
                break;
            case "ODOO_DEV":
                dispatch(getOdooProductsDev());
                break;
            case "ODOO_PROD":
            default:
                dispatch(getOdooProductsProd());
        }
    };
};

export const updateCMSCache = (setId: string) => {
    return (dispatch: Dispatch<any>, getState: any) => {
        return api()
            .put("set/cache", { setId })
            .then(() => null)
            .catch((err: AxiosError) => {
                console.error(err.response);
                return null;
            });
    };
};
