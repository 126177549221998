import * as React from "react";
import * as actions from "./actions";
import * as deploymentsActions from "./CPDS/scenes/ServerDeployments/actions";
import * as serverActions from "./CPDS/scenes/Servers/actions";
import * as templateActions from "./CPDS/scenes/Templates/actions";
import * as versionActions from "./CPDS/scenes/Versions/actions";
import * as whitelistActions from "./Whitelist/actions";
import * as konnektiveSubscriptionsActions from "./KonnektiveSubscriptions/actions";
import Brand from "./Brand";
import CCDrip from "./CCDrip";
import ConversionsBalancer from "./ConversionsBalancer";
import CDNCloud from "./CDNCloud";
import CPDS from "./CPDS";
import HackerTools from "./HackerTools";
import CRMS from "./CRMS";
import Users from "./Users";
import OrderList from "./Orders/scenes/OrderList";
import Orders from "./Orders";
import SchemaCMS from "./SchemaCMS";
import KonnektiveSubscriptions from "./KonnektiveSubscriptions";
import SchemaCMSEditor from "./SchemaCMSEditor";
import SchemaSetPage from "./SchemaSetPage";
import SideMenu from "./SideMenu";
import Single from "./Orders/scenes/Single";
import Spend from "./Spend";
import Tokens from "./Tokens";
import Whitelist from "./Whitelist";
import ProductGroup from "./ProductGroup";
import ConversionActions from "./GoogleAdsConversionAction";
import styles from "./styles.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Guardian from "services/Guardian";
import cx from "classnames";
import { appStateType } from "reducers";
import SDK from "./SDK";
import Analytics from "./Analytics";
import SoldOutProducts from "./SoldOutProducts";

const Home = () => (
    <div>
        <h1>Home</h1>
    </div>
);

function importAll(r: any) {
    let images = {};
    r.keys().map((item: any, index: number) => {
        images[item.replace("./", "").split(".")[0]] = r(item);
    });
    return images;
}

importAll(require.context("../../assets/icons", false, /\.(png|jpe?g|svg)$/));

class Dashboard extends React.PureComponent<any, any> {
    componentDidMount() {
        const user = Guardian.getUser();

        if (user.roles.some(role => role.includes("ED_"))) {
            this.props.pullServers();
            this.props.getLanguages();
            this.props.getSets();
            this.props.pullTemplates();
            this.props.pullVersions();
            this.props.getWhitelist();
            this.props.getCurriencies();
            this.props.getSubscriptions();
        }
    }

    render() {
        if (!Guardian.isLocationValid()) {
            return <Redirect to={Guardian.getDefaultRoute()} />;
        }

        return (
            <div className={styles.dashboard}>
                <SideMenu />
                <div className={cx(styles.page, { [styles.wide]: this.props.ui.isSideMenuHidden })}>
                    <Switch>
                        <Route path="/dashboard/spend" component={Spend} />
                        <Route path="/dashboard/tokens" component={Tokens} />
                        <Route
                            path="/dashboard/cms/:setId/:languageId/:versionId"
                            component={SchemaCMSEditor}
                        />
                        <Route path="/dashboard/cms/:setId" component={SchemaSetPage} />
                        <Route path="/dashboard/cms" component={SchemaCMS} />
                        <Route
                            path="/dashboard/subscriptions"
                            component={KonnektiveSubscriptions}
                        />
                        <Route path="/dashboard/cdncloud" component={CDNCloud} />
                        <Route path="/dashboard/deployment" component={CPDS} />
                        <Route path="/dashboard/analytics" component={Analytics} />
                        <Route path="/dashboard/drip" component={CCDrip} />
                        <Route
                            path="/dashboard/conversionsbalancer"
                            component={ConversionsBalancer}
                        />
                        <Route path="/dashboard/whitelist" component={Whitelist} />
                        <Route path="/dashboard/orders/single/:id" component={Single} />
                        <Route
                            path="/dashboard/orders/complete"
                            render={(props: any) => <OrderList {...props} filter="COMPLETE" />}
                        />
                        <Route
                            path="/dashboard/orders/pending"
                            render={(props: any) => <OrderList {...props} filter="PENDING" />}
                        />
                        <Route
                            path="/dashboard/orders/abandoned"
                            render={(props: any) => <OrderList {...props} filter="ABANDONED" />}
                        />
                        <Route path="/dashboard/orders" component={Orders} />
                        <Route path="/dashboard/hackertools" component={HackerTools} />
                        <Route path="/dashboard/brands" component={Brand} />
                        <Route path="/dashboard/productgroups" component={ProductGroup} />
                        <Route path="/dashboard/conversionactions" component={ConversionActions} />
                        <Route path="/dashboard/crms" component={CRMS} />
                        <Route path="/dashboard/users" component={Users} />
                        <Route path="/dashboard/sdk" component={SDK} />
                        <Route path="/dashboard/soldoutproducts" component={SoldOutProducts} />
                        <Route path="/dashboard" component={Home} />
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    ...state
});

export default connect(mapStateToProps, {
    ...actions,
    ...deploymentsActions,
    ...serverActions,
    ...templateActions,
    ...versionActions,
    ...whitelistActions,
    ...konnektiveSubscriptionsActions
})(Dashboard);
