import * as React from "react";
import SpaceBetween from "scenes/Dashboard/SchemaCMSEditor/components/SpaceBetween";
import UpsellContainer from "./components/UpsellContainer";
import styles from "./styles.scss";
import { upsellContainer } from "types/upsellContainer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

type Props = {
    addUpsellContainerLoading: boolean;
    containers: upsellContainer[];
    removeUpsellContainerLoading: boolean;
    upsellsFlowId: string;
    handleContainerReorder: (result: any) => void;
};

const Upsells: React.FC<Props> = ({
    addUpsellContainerLoading,
    removeUpsellContainerLoading,
    containers,
    upsellsFlowId,
    handleContainerReorder
}) => {
    const activeUpsells = containers.reduce(
        (acc, current) =>
            acc + current.variants.reduce((acc, variant) => (variant.isActive ? acc + 1 : acc), 0),
        0
    );

    return (
        <div>
            {containers.length > 0 ? (
                <div className={styles.upsells}>
                    <SpaceBetween className={styles.activeUpsells}>
                        <span>Upsells</span>
                        <span>{activeUpsells} Active</span>
                    </SpaceBetween>
                    <DragDropContext onDragEnd={handleContainerReorder}>
                        <Droppable droppableId="containers-reorder-flowid">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {containers.map((container, index) => {
                                        return (
                                            <Draggable
                                                index={
                                                    container.index === undefined
                                                        ? index
                                                        : Number(container.index)
                                                }
                                                draggableId={String(container._id)}
                                                key={container._id}
                                            >
                                                {provided => (
                                                    <UpsellContainer
                                                        provided={provided}
                                                        addUpsellContainerLoading={
                                                            addUpsellContainerLoading
                                                        }
                                                        container={container}
                                                        key={container._id}
                                                        upsellsFlowId={upsellsFlowId}
                                                        removeUpsellContainerLoading={
                                                            removeUpsellContainerLoading
                                                        }
                                                    />
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            ) : null}
        </div>
    );
};

export default Upsells;
