import api from "api";
import { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import * as actionTypes from "actionTypes";

export function removeSession(set: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.REMOVE_SESSION_START
        });

        api()
            .post(`hackertools/removeIpSetCache`, {
                setId: set
            })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.REMOVE_SESSION,
                    payload: {
                        setId: set
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.REMOVE_SESSION_ERROR,
                    payload: {
                        error: err.response ? err.response.data : err.response
                    }
                });
            });
    };
}

export function getCachedSets() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_CACHED_SETS_START
        });
        api()
            .get(`hackertools/getCachedSets`)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_CACHED_SETS,
                    payload: {
                        cachedSets: res.data
                    }
                });
            })
            .catch((err: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_CACHED_SETS_ERROR
                });
                console.log("getCachedSets error:", err);
            });
    };
}
