import * as React from "react";
import Bar from "./components/Bar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Performance from "./components/Performance";
import Products from "./components/Products";
import Bundles from "./components/Bundles";
import styles from "./styles.scss";
import { PricingModel, ExtendedPricingProduct, Bundle } from "types/pricing";

interface PricingModelContextType {
    id: string;
    isActive: boolean;
    isDraft: boolean;
    products: ExtendedPricingProduct[];
    title: string;
    currency: string;
    bundles?: Bundle[];
    supportedCountries: string[];
}

export const PricingModelContext = React.createContext<PricingModelContextType>({
    id: "",
    isActive: false,
    isDraft: false,
    products: [],
    title: "",
    bundles: [],
    currency: "",
    supportedCountries: []
});

interface Props extends PricingModel {
    isDraft?: boolean;
    performance?: any;
    containerType: "PRODUCT" | "BUNDLE";
    currentTab: string;
}

const ModelBox: React.FC<Props> = ({
    _id = "",
    createdAt,
    isActive,
    isDraft = false,
    products,
    title,
    updatedAt,
    currency,
    performance,
    bundles,
    containerType = "PRODUCT",
    currentTab,
    supportedCountries = ["US"]
}) => {
    return (
        <PricingModelContext.Provider
            value={{
                id: _id,
                isActive,
                isDraft,
                products,
                title,
                currency,
                bundles,
                supportedCountries
            }}
        >
            <div className={styles.modelBox}>
                <Header />
                <Bar createdAt={createdAt} updatedAt={updatedAt} />
                {containerType === "BUNDLE" ? (
                    <span className={styles.typeHeader}>Products</span>
                ) : null}

                <Products products={products} type={containerType} />

                {containerType === "BUNDLE" ? (
                    <div>
                        <span className={styles.typeHeader}>Bundles</span>
                        <Bundles bundles={bundles} />
                    </div>
                ) : null}

                {!isDraft && <Performance performance={performance} />}
                <Footer modelId={_id} />
            </div>
        </PricingModelContext.Provider>
    );
};

export default ModelBox;
