import * as React from "react";
import AnalyticsBar from "scenes/Dashboard/SchemaCMSEditor/components/AnalyticsBar";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import UpsellVariant from "./components/UpsellVariant";
import cx from "classnames";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import getTimeAgo from "services/getTimeAgo";
import styles from "./styles.scss";
import { CMSContext } from "../../../../index";
import {
    addUpsell,
    archieveUpsellContainer,
    duplicateUpsellContainer,
    updateUpsellContainer
} from "./actions";
import { defaultUpsellReportValues, sumUpsellVariants } from "services/getReport";
import { upsell } from "types/upsell";
import { upsellContainer } from "types/upsellContainer";
import { useDispatch } from "react-redux";
import { odooProduct } from "types/odooProduct";

type Props = {
    addUpsellContainerLoading: boolean;
    container: upsellContainer;
    removeUpsellContainerLoading: boolean;
    upsellsFlowId: string;
    provided: any;
};

const UpsellContainer: React.FC<Props> = ({
    container,
    removeUpsellContainerLoading,
    upsellsFlowId,
    provided
}) => {
    const [editMode, setEditMode] = React.useState(false);
    const [report, setReport] = React.useState({
        ...defaultUpsellReportValues
    });
    const [title, setTitle] = React.useState(container.title);
    const [isDownsell, setIsDownsell] = React.useState(container.isDownsell);
    const [removing, setRemoving] = React.useState(false);
    const dispatch = useDispatch();
    const cms = React.useContext(CMSContext);
    const {
        setId,
        languageId,
        schema,
        products,
        odooProducts,
        odooProductsProd,
        odooProductsDev,
        crm
    } = cms;
    const isOdooCrm = crm !== "KONNEKTIVE";

    let finalOdooProducts: odooProduct[] = [];

    switch (crm) {
        case "ODOO":
            finalOdooProducts = odooProducts;
            break;
        case "ODOO_DEV":
            finalOdooProducts = odooProductsDev;
            break;
        case "ODOO_PROD":
        default:
            finalOdooProducts = odooProductsProd;
            break;
    }

    React.useEffect(() => {
        if (removeUpsellContainerLoading === false && removing === true) {
            setRemoving(false);
        }
    }, [removeUpsellContainerLoading]);

    const handleClick = () => {
        if (editMode) {
            setEditMode(false);

            if (container) {
                dispatch(
                    updateUpsellContainer({
                        isDownsell,
                        _id: container._id,
                        title,
                        upsellsFlowId
                    })
                );
            }
        } else {
            setEditMode(true);
        }
    };

    const handleAddVariant = () => {
        dispatch(
            addUpsell({
                upsellContainerId: container._id,
                setId,
                languageId,
                schema
            })
        );
    };

    React.useEffect(() => {
        const hasProducts = (!isOdooCrm && products) || (isOdooCrm && finalOdooProducts);

        if (container && hasProducts) {
            const report = sumUpsellVariants({
                _id: container._id,
                variants: container.variants
            });

            if (report.hasOwnProperty("_id")) {
                setReport(report);
            }
        }
    }, [container, products]);

    const handleContainerCopy = () => {
        dispatch(
            duplicateUpsellContainer(setId, container._id, upsellsFlowId, container.variants.length)
        );
    };

    const handleContainerArchieve = () => {
        dispatch(archieveUpsellContainer(container._id, upsellsFlowId));
    };

    const weight = Number((100 / container.variants.filter(v => v.isActive).length).toFixed(1));

    const isDuplicating = container && container.duplication && !container.duplication.isCompleted;

    return (
        <div
            className={styles.wrapper}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <div
                className={cx(styles.container, {
                    [styles.downsell]: isDownsell
                })}
            >
                <header className={styles.header}>
                    <div>
                        <div className={styles.upsellType}>
                            {isDownsell ? "DOWNSELL" : "UPSELL"}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.title}>
                            {editMode ? (
                                <input value={title} onChange={e => setTitle(e.target.value)} />
                            ) : (
                                <span className={styles.upsellTitle}>{title}</span>
                            )}
                            {editMode && Number(container.index) !== 0 ? (
                                <label className={styles.checkbox}>
                                    <input
                                        type="checkbox"
                                        checked={isDownsell}
                                        onChange={() => setIsDownsell(!isDownsell)}
                                    />
                                    <span>is downsell</span>
                                </label>
                            ) : null}
                            <Button
                                onClick={handleClick}
                                icon="editName"
                                design="icon"
                                marginLeft
                            ></Button>
                        </div>
                        <div className={styles.actionBox}>
                            <Button
                                design="circle"
                                icon="clone"
                                size="small"
                                onClick={(e: React.SyntheticEvent) => {
                                    e.preventDefault();
                                    handleContainerCopy();
                                }}
                            />
                            <Button
                                design="icon"
                                icon="trash"
                                size="small"
                                marginLeft
                                onClick={(e: React.SyntheticEvent) => {
                                    e.preventDefault();
                                    handleContainerArchieve();
                                }}
                            />
                        </div>
                    </div>
                </header>

                <main className={styles.main}>
                    <AnalyticsBar className={styles.analyticsBar}>
                        <div>
                            {getPercentage(report.upsellSales / report.upsellViews)}{" "}
                            <span>Take Rate</span>
                        </div>
                        <div>
                            {getTimeAgo(container.updatedAt)} <span>Updated</span>
                        </div>
                        <div>
                            {getTimeAgo(container.createdAt)} <span>T.Running</span>
                        </div>
                        <div>
                            {getDollars(report.grossProfit)} <span>GP</span>
                        </div>
                        <div>
                            {report.upsellViews} <span>Views</span>
                        </div>
                        <div>
                            {report.upsellSales} <span>Conv.</span>
                        </div>
                        <div>
                            {getDollars(report.grossProfit / report.upsellViews)} <span>EPV</span>
                        </div>

                        <div>
                            {getDollars(report.revenue / report.upsellSales)} <span>EPO</span>
                        </div>
                    </AnalyticsBar>
                    {isDuplicating ? (
                        <div>
                            Upsell Container is being duplicated. please refresh the page after some
                            time
                        </div>
                    ) : null}
                    {container.variants.map((upsell: upsell) => {
                        return (
                            <UpsellVariant
                                key={upsell._id}
                                upsell={upsell}
                                upsellContainerId={container._id}
                                weight={upsell.isActive ? weight : 0}
                            />
                        );
                    })}
                    <div className={styles.buttonWrapper}>
                        <div></div>
                        {container.addUpsellLoading ? (
                            <LoadingIndicator size={18} />
                        ) : (
                            <Button
                                size="small"
                                design="circle"
                                onClick={handleAddVariant}
                                disabled={container.addUpsellLoading}
                            >
                                Add new offer
                            </Button>
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default UpsellContainer;
