import * as actionTypes from "actionTypes";
import { appStateType } from "reducers";
import { ThunkDispatch } from "redux-thunk";
import { saveTriggers } from "../../../TriggersEditor/actions";

export function deleteTrigger(upsellsFlowId: string, triggerId: string) {
    return (dispatch: ThunkDispatch<appStateType, void, any>) => {
        dispatch({
            type: actionTypes.DELETE_UPSELL_TRIGGER,
            payload: { upsellsFlowId, triggerId }
        });

        dispatch(saveTriggers(upsellsFlowId));
    };
}
