import * as React from "react";
import * as actions from "./actions";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { appStateType } from "reducers";
import { connect } from "react-redux";

class Whitelist extends React.PureComponent<any, any> {
    state = {
        inputValue: ""
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleInput(e: React.SyntheticEvent) {
        e.preventDefault();
        const { value } = e.target as HTMLInputElement;
        this.setState({ inputValue: value });
    }

    handleAddDomain(e: React.SyntheticEvent) {
        e.preventDefault();
        const { inputValue } = this.state;
        this.props.addDomain(inputValue);
        this.setState({ inputValue: "" });
    }

    handleDeleteDomain(id: string, e: React.SyntheticEvent) {
        e.preventDefault();
        this.props.deleteDomain(id);
    }

    render() {
        const { inputValue } = this.state;
        const { whitelist = [] } = this.props.whitelist;

        return (
            <div>
                <div className={styles.panel_wide}>
                    {whitelist.map((domain: any, index: number) => (
                        <div key={index} className={styles.bar}>
                            {/* <button onClick={this.handleDeleteDomain.bind(this, domain._id)}>X</button> */}
                            <input type="text" value={domain.name} disabled />
                        </div>
                    ))}
                    {whitelist.length === 0 ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            <input
                                type="text"
                                placeholder="https://newdomain.com"
                                onChange={this.handleInput.bind(this)}
                                value={inputValue}
                            />
                            <button
                                onClick={this.handleAddDomain.bind(this)}
                                className={styles.primary}
                            >
                                ADD NEW DOMAIN
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    whitelist: state.whitelist
});

export default connect(
    mapStateToProps,
    actions
)(Whitelist);
