import * as actionTypes from "actionTypes";
import api from "api";
import { AxiosError, AxiosResponse } from "axios";
import { SubscriptionForm } from "types/Subscription";
import { Dispatch } from "redux";

export function getSubscriptions() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.GET_SUBSCRIPTIONS_START
        });

        api()
            .get("subscription")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: actionTypes.GET_SUBSCRIPTIONS,
                    payload: { subscriptions: res.data }
                });
            })
            .catch((error: AxiosError) => {
                dispatch({
                    type: actionTypes.GET_SUBSCRIPTIONS_ERROR,
                    payload: { error: error.response }
                });
            });
    };
}

export function addSubscription(data: SubscriptionForm) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: actionTypes.ADD_SUBSCRIPTION_START
        });

        api()
            .post("subscription", data)
            .then((res: AxiosResponse) =>
                dispatch({
                    type: actionTypes.ADD_SUBSCRIPTION,
                    payload: { subscription: res.data }
                })
            )
            .catch((error: AxiosError) => {
                dispatch({
                    type: actionTypes.ADD_SUBSCRIPTION_ERROR,
                    payload: { error: error.response }
                });
            });
    };
}
