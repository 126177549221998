import * as React from "react";
import * as actions from "./actions";
import * as serverActions from "../Server/actions";
import * as serversActions from "../Servers/actions";
import * as versionActions from "../Versions/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import LastDeployment from "./components/LastDeployment";
import LoadingIndicator from "components/LoadingIndicator";
import _ from "lodash";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { appStateType } from "reducers";
import { connect } from "react-redux";

class Deployments extends React.PureComponent<any, any> {
    static defaultProps = {
        deployments: [],
        servers: [],
        templates: [],
        versions: []
    };

    componentDidMount() {
        const {
            params: { id }
        } = this.props.match;

        window.scrollTo(0, 0);

        this.props.resetDeployments();
        this.props.setDeploymentFilters({ key: "server", value: id });
        this.props.pullDeployments();
    }

    componentWillUnmount() {
        this.props.resetDeploymentFilters();
        this.props.resetDeployments();
    }

    render() {
        const {
            params: { id }
        } = this.props.match;

        const {
            deployments,
            removeDeployments,
            servers,
            deployment: { isPullingDeployments, isDrained }
        } = this.props;
        const server = servers.find((s: any) => s._id === id);
        return (
            <div className={styles.deployments}>
                {server && <h2>Live deployments of {server.domain.name}</h2>}
                <Link to={`/dashboard/deployment/servers/${id}/add`}>
                    <button className={styles.primary}>New deployment</button>
                </Link>
                <InfiniteScroll
                    className={styles.deployments_grid}
                    dataLength={deployments.length}
                    next={() =>
                        this.props.pullDeployments({
                            server: id
                        })
                    }
                    style={{ overflow: "initial" }}
                    hasMore={!isDrained}
                    scrollableTarget=".content"
                    loader={
                        <div
                            style={{
                                marginTop: 64
                            }}
                        >
                            <LoadingIndicator size={100} />
                        </div>
                    }
                >
                    {deployments.map((deployment: any) => {
                        const { set, template, version } = deployment;

                        return (
                            <LastDeployment
                                {...deployment}
                                availableVersion={version.tag}
                                key={deployment._id}
                                removeDeployments={removeDeployments}
                                server={server}
                                set={set}
                                template={template}
                                version={version}
                            />
                        );
                    })}
                </InfiniteScroll>
                {!isPullingDeployments && !deployments.length && isDrained ? (
                    <div>Oops!! No deploymnets found.</div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state: appStateType) => ({
    deployment: state.deployment,
    deployments: state.deployment.deployments,
    servers: state.deployment.servers,
    sets: state.sets.sets,
    templates: state.deployment.templates,
    versions: state.deployment.versions
});

export default connect(mapStateToProps, {
    ...actions,
    ...serverActions,
    ...serversActions,
    ...versionActions
})(Deployments);
