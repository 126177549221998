import * as React from "react";
import GridItem from "./components/GridItem";
import LoadingIndicator from "components/LoadingIndicator";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Servers extends React.PureComponent<any, any> {
    static defaultProps = {
        servers: []
    };

    render() {
        const { servers = [] } = this.props;

        return (
            <div className={styles.servers}>
                {servers.length > 0 ? (
                    <div className={styles.servers_grid}>
                        {servers.length > 0 &&
                            servers.map((server: any) => (
                                <GridItem
                                    domain={server.domain}
                                    id={server._id}
                                    key={server._id}
                                />
                            ))}
                    </div>
                ) : (
                    <div>
                        <LoadingIndicator />
                    </div>
                )}
                <Link to="/dashboard/deployment/servers/add">
                    <button className={styles.primary}>Add new server</button>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    servers: state.deployment.servers
});

export default connect(
    mapStateToProps,
    null
)(Servers);
