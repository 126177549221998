import cx from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import DataPiece from "scenes/Dashboard/SchemaCMSEditor/components/DataPiece";
import getDollars from "services/getDollars";
import getPercentage from "services/getPercentage";
import parentContains from "services/parentContains";
import { ExtendedBundle } from "types/pricing";
import { odooProduct } from "types/odooProduct";
import { PricingModelContext } from "../..";
import { PricingContext } from "../../../..";
import { editPricingBundleDraft, redirectToPath } from "../../../../actions";
import DragButton from "../../../DragButton";
import headerStyles from "../Header/styles.scss";
import productsStyles from "../Products/styles.scss";
import styles from "./styles.scss";
import isEqual from "lodash/isEqual";
import EditIcon from "assets/icons/edit-name.svg";
import SearchList from "components/SearchList";

const { useState, useRef, useContext, useMemo } = React;

interface Props {
    bundle: ExtendedBundle;
    provided: any;
}

type EditorTypes =
    | "ID"
    | "SIZE"
    | "UNIT_PRICE"
    | "STRIKETHROUGH_PRICE"
    | "PRICE"
    | "SP"
    | "PRODUCT_ID"
    | "GIFT_ID"
    | null;

const Bundle: React.FC<Props> = ({ bundle, provided }) => {
    const dispatch = useDispatch();
    const { isDraft } = useContext(PricingModelContext);
    const { setId, languageId, versionId, odooProducts, odooProductListOptions, pricingContainer } =
        useContext(PricingContext);
    const [activeField, setActiveFieldValue] = useState<EditorTypes>();
    const [currentProduct, setCurrentProduct] = useState<odooProduct | undefined>(undefined);
    const editorRef = useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (pricingContainer && pricingContainer.flavourProducts && odooProducts) {
            const flavourId = pricingContainer.flavourProducts[0]?.productId;
            const flavourOdooProduct = odooProducts.find(
                el => Number(el.sid) === Number(flavourId)
            );
            setCurrentProduct(flavourOdooProduct);
        }
    }, [pricingContainer, odooProducts]);

    const handleChangeBundle = (bundle: ExtendedBundle) => {
        dispatch(editPricingBundleDraft(bundle._id, bundle));
    };

    const setActiveField = (newActiveField: EditorTypes, e: MouseEvent) => {
        e.stopPropagation();

        if (isDraft) {
            setActiveFieldValue(prevActiveField => {
                if (prevActiveField !== newActiveField) {
                    const handleWindowClick = (e: MouseEvent) => {
                        if (editorRef.current) {
                            if (!parentContains(editorRef.current, e.target as Node)) {
                                setActiveFieldValue(null);
                                window.removeEventListener("click", handleWindowClick, false);
                            }
                        }
                    };

                    if (newActiveField !== null) {
                        window.addEventListener("click", handleWindowClick, false);
                    }
                }

                return newActiveField;
            });
        }
    };

    const getEditor = () => {
        switch (activeField) {
            case "UNIT_PRICE":
                return (
                    <input
                        type="number"
                        placeholder="Unit Price "
                        className={styles.priceField}
                        value={bundle.unitPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBundle({
                                ...bundle,
                                unitPrice: Number(e.target.value)
                            })
                        }
                        onBlur={setActiveField.bind(null, "UNIT_PRICE")}
                    />
                );
            case "STRIKETHROUGH_PRICE":
                return (
                    <input
                        type="number"
                        placeholder="New Strikethrough Price"
                        className={styles.priceField}
                        value={bundle.strikethroughPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBundle({
                                ...bundle,
                                strikethroughPrice: String(e.target.value)
                            })
                        }
                        onBlur={setActiveField.bind(null, "STRIKETHROUGH_PRICE")}
                    />
                );
            case "PRICE":
                return (
                    <input
                        type="number"
                        placeholder="Bundle Product Price"
                        className={styles.priceField}
                        value={bundle.price}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBundle({
                                ...bundle,
                                price: Number(e.target.value)
                            })
                        }
                        onBlur={setActiveField.bind(null, "PRICE")}
                    />
                );
            case "SP":
                return (
                    <input
                        type="number"
                        placeholder="New Shipping Price"
                        className={styles.priceField}
                        value={bundle.shippingPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBundle({
                                ...bundle,
                                shippingPrice: Number(e.target.value)
                            })
                        }
                        onBlur={setActiveField.bind(null, "SP")}
                    />
                );
            case "ID":
                return (
                    <input
                        type="text"
                        placeholder="Unique Bundle ID"
                        className={styles.priceField}
                        value={bundle.id}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBundle({
                                ...bundle,
                                id: e.target.value
                            })
                        }
                        onBlur={setActiveField.bind(null, "ID")}
                    />
                );
            case "PRODUCT_ID":
                return (
                    <SearchList
                        options={odooProductListOptions}
                        onChange={(e: any, value: string) =>
                            handleChangeBundle({
                                ...bundle,
                                productId: String(value)
                            })
                        }
                    />
                );
            case "GIFT_ID":
                return (
                    <SearchList
                        options={odooProductListOptions}
                        onChange={(e: any, value: string) =>
                            handleChangeBundle({
                                ...bundle,
                                giftId: String(value)
                            })
                        }
                    />
                );
            case "SIZE":
                return (
                    <input
                        type="number"
                        placeholder="Bundle Size"
                        className={styles.priceField}
                        value={bundle.size}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBundle({
                                ...bundle,
                                size: Number(e.target.value)
                            })
                        }
                        onBlur={setActiveField.bind(null, "SIZE")}
                    />
                );

            default:
                return null;
        }
    };

    let editor = getEditor();

    const getValueForField = (field: string): any => {
        switch (field) {
            case "id":
                return (
                    <span title={bundle ? bundle.id : ""}>{bundle?.id?.slice(0, 4) || "NA"}</span>
                );
            case "product_id":
                return bundle &&
                    bundle.productId &&
                    bundle.productId != "" &&
                    bundle.productId != "0"
                    ? bundle.productId
                    : "NA";
            case "gift_id":
                return bundle && bundle.giftId && bundle.giftId != "" && bundle.giftId != "0"
                    ? bundle.giftId
                    : "NA";
            case "price":
                return bundle ? bundle.price : 0;
            case "size":
                return bundle ? bundle.size : 0;
            case "unitPrice":
                return bundle ? bundle.unitPrice : 0;
            case "shippingPrice":
                return bundle ? bundle.shippingPrice : 0;
            case "ppu":
                return bundle ? bundle.unitPrice : 0;
            case "cog":
                return currentProduct
                    ? getDollars(currentProduct.cog * Number(bundle?.size || 0))
                    : 0;
            case "cos":
                return currentProduct
                    ? getDollars(currentProduct.cos * Number(bundle?.size || 0))
                    : 0;
            case "margin":
                const get = (currentProduct: any) => {
                    const value =
                        (Number(bundle?.unitPrice) -
                            Number(currentProduct.cos) -
                            Number(currentProduct.cog)) /
                        Number(bundle?.unitPrice);

                    return isNaN(value) ? 0 : getPercentage(value);
                };

                return currentProduct ? get(currentProduct) : 0;

            default:
                return "NA";
        }
    };

    return (
        <div
            className={cx(styles.product, {
                [headerStyles.borderBottom]: isDraft
            })}
            // onMouseEnter={() => {
            //     if (isDraft) setProductHover(0.5);
            // }}
            // onMouseLeave={() => {
            //     if (isDraft) setProductHover(0);
            // }}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div
                className={productsStyles.row}
                style={{ gridTemplateColumns: isDraft ? "1fr 60px" : "1fr" }}
            >
                <div className={productsStyles.data}>
                    <DataPiece
                        direction="column"
                        label="ID"
                        info={"Unique Bundle Id"}
                        onClick={setActiveField.bind(null, "ID")}
                        pointer={isDraft}
                        value={getValueForField("id")}
                    />
                    <DataPiece
                        direction="column"
                        label="PID"
                        info={"Product Id if you want to sell bundle as a product"}
                        onClick={setActiveField.bind(null, "PRODUCT_ID")}
                        pointer={isDraft}
                        value={getValueForField("product_id")}
                    />
                    <DataPiece
                        direction="column"
                        label="GID"
                        info={
                            "Gift Id if you want to add additional product as a gift while selecting this bundle."
                        }
                        onClick={setActiveField.bind(null, "GIFT_ID")}
                        pointer={isDraft}
                        value={getValueForField("gift_id")}
                    />
                    <DataPiece
                        direction="column"
                        label="STP"
                        info="Strikethrough price for the product visible on ET"
                        onClick={setActiveField.bind(null, "STRIKETHROUGH_PRICE")}
                        pointer={isDraft}
                        value={getDollars(Number(bundle.strikethroughPrice))}
                    />
                    <DataPiece
                        direction="column"
                        label="Price"
                        info="Bundle Product Price"
                        onClick={setActiveField.bind(null, "PRICE")}
                        pointer={isDraft}
                        value={getDollars(Number(bundle.price))}
                    />
                    <DataPiece
                        direction="column"
                        label="Size"
                        info="Bundle Size"
                        value={getValueForField("size")}
                        onClick={setActiveField.bind(null, "SIZE")}
                        pointer={isDraft}
                    />
                    <DataPiece
                        direction="column"
                        label="U.Price"
                        info="Unit Price"
                        onClick={setActiveField.bind(null, "UNIT_PRICE")}
                        pointer={isDraft}
                        value={getDollars(Number(bundle.unitPrice))}
                    />
                    <DataPiece
                        direction="column"
                        label="SP"
                        info="Shipping Price to show in ET"
                        onClick={setActiveField.bind(null, "SP")}
                        pointer={isDraft}
                        value={getDollars(Number(bundle.shippingPrice))}
                    />

                    <DataPiece
                        direction="column"
                        label="PPU"
                        value={getDollars(Number(bundle.unitPrice))}
                        info="Price Per Unit"
                    />
                    <DataPiece
                        direction="column"
                        label="COG"
                        value={getValueForField("cog")}
                        info="Cost of Good"
                    />
                    <DataPiece
                        direction="column"
                        label="COS"
                        value={getValueForField("cos")}
                        info="Cost of Shipping"
                    />
                    <DataPiece
                        direction="column"
                        label="Margin"
                        value={getValueForField("margin")}
                    />
                    <DataPiece
                        direction="column"
                        label="TR"
                        info="Take Rate"
                        value={getPercentage(bundle.takeRate)}
                    />
                    <DataPiece direction="column" label="U.Sold" value={bundle.unitsSold || 0} />
                    {!isDraft ? (
                        <DataPiece
                            direction="column"
                            label=""
                            info="Edit Flavour Product content"
                            pointer={true}
                            value={
                                <span className={styles.edit}>
                                    <img src={EditIcon} alt={"edit content"} />
                                </span>
                            }
                            onClick={() => {
                                dispatch(
                                    redirectToPath(
                                        `/dashboard/cms/${setId}/${languageId}/${versionId}/pricing/bundles/${bundle?.elementId}`
                                    )
                                );
                            }}
                        />
                    ) : null}
                </div>
                {isDraft && <DragButton {...provided.dragHandleProps} />}
            </div>
            <div ref={editorRef} className={styles.absoluteSearchList}>
                {editor}
            </div>
        </div>
    );
};

export default React.memo(Bundle, (prevProps: Props, nextProps: Props) => {
    const areEqual = isEqual(prevProps, nextProps);
    return areEqual;
});
